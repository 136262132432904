import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'ng2-select';
import { DataProviderService } from '../../../../services/data-provider.service';
import { TableData } from '../../../../common/SharedClasses/TableData';
import { DateConverterPipe } from '../../../../utils/dateConverter.pipe';

@Component({
  selector: 'app-maintenance-ticket-details',
  templateUrl: './maintenance-ticket-details.component.html',
  styleUrls: ['./maintenance-ticket-details.component.scss']
})
export class MaintenanceTicketDetailsComponent implements OnInit {
  showTicketDetails = true;
  @Input() eventDetails;
  @Input() org;
  addList: any[];
  selectedList: SelectItem[];
  linkedTicketGrid;
  linkedTicketGridUrl;
  eventStartDate;
  eventEndDate;
  selectedIssue;
  @Input() IssueId;
  constructor(public _datePipe: DateConverterPipe, public _dataProvider: DataProviderService) { }

  ngOnInit() {
    this.selectedIssue = this.IssueId;
    this.linkedTicketGridUrl = this._dataProvider.SERVICE_TICKET_LINKED + this.eventDetails.key ;
    this.linkedTicketGrid = new TableData();
    this.eventStartDate = this._datePipe.transform(this.eventDetails['"Start Date & Time"']);
    this.eventEndDate = this._datePipe.transform(this.eventDetails['"End Date & Time"']);
    const callist = this.eventDetails.addCalendar;
    const tesmpList = [];
    this.selectedList = [];
    tesmpList.push({ 'label': 'Add To Calendar', 'value': { 'name': '' } });
    Object.keys(callist).forEach(function (key) {
      const obj = { 'label': key, 'value': { 'name': callist[key] } };
      tesmpList.push(obj);
    });
    this.addList = tesmpList;
  }

  showTicketDetail(event) {
    if (event === 'linked') {
      this.showTicketDetails = false;
    } else {
      this.showTicketDetails = true;
    }
  }

  loadlinkedTicketGrid(data) {
    if (this.linkedTicketGrid.cols.length === 0) {
      this.linkedTicketGrid.cols = data['headers'];
      const columns = [];
      this.linkedTicketGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.linkedTicketGrid.selectedColumns = columns;
    }
    this.linkedTicketGrid.colData = [];
    const rawData = data['data'];
    this.linkedTicketGrid.colData = rawData;
    this.linkedTicketGrid.totalRecords = data['total'];
  }

}
