<p-growl [(value)]="msgs" life="1000"></p-growl>
<div class="container-fluid" style="min-height:84%;margin-bottom: 6%;">
    <div class="col-md-4 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_xc_circuit_report_download') !=-1" >
        <div class="col-md-12 screen-shadow" style="height:auto;min-height: auto;">
                <h3 class="screen-header" style="text-align:left;color:#000;"><b> XC Circuit Report</b></h3>
            <section>
                <app-xc-circuit-report [org]="org"></app-xc-circuit-report>
            </section>
        </div>
    </div>
    <div class="col-md-12 form-group"  >
            <div class="col-md-12 screen-shadow" style="height:auto;">
                <section>
                    <app-customer-circuite-grid [org]="org"></app-customer-circuite-grid>
                </section>
            </div>
    </div>
    <div class="col-md-4 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_site_grid') !=-1" >
        <div class="col-md-12 screen-shadow">
            <section>
                <app-customer-site [org]="org"></app-customer-site>
            </section>
        </div>
    </div>
    <div class="col-md-8 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_site_map') !=-1" >
        <div class="col-md-12 screen-shadow">
                <h2 class="screen-header">Customer Sites</h2>
            <section>
                <app-customer-site-map [org]="org"></app-customer-site-map>
            </section>
        </div>
    </div>
    <div class="col-md-4 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_group_grid') !=-1" >
            <div class="col-md-12 screen-shadow" style="height:398px;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_grid_switch') !=-1 ">
                <section>
                    <app-group-notifications></app-group-notifications>
                </section>
            </div>
            <div class="col-md-12 screen-shadow" style="height:auto;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_grid_switch') ==-1 ">
                    <section>
                        <app-group-notifications></app-group-notifications>
                    </section>
                </div>
    </div>
    <div class="col-md-8 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_employee_grid') !=-1" >
            <div class="col-md-12 screen-shadow" style="height:400px;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_grid_switch') !=-1 ">
                <section>
                    <app-custmer-emplyee></app-custmer-emplyee>
                </section>
            </div>
            <div class="col-md-12 screen-shadow" style="height:auto;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_grid_switch') ==-1 ">
                    <section>
                        <app-custmer-emplyee></app-custmer-emplyee>
                    </section>
                </div>
    </div>
</div>
