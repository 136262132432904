import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { TableData } from '../../../common/SharedClasses/TableData';

@Component({
  selector: 'app-tranport-interface',
  templateUrl: './tranport-interface.component.html',
  styleUrls: ['./tranport-interface.component.scss']
})
export class TranportInterfaceComponent implements OnInit, OnChanges {
  cars;
  count = 4;
  @Input() org;
  transportGrid;
  transportGridPopup;
  dataUrl;
  dataUrlpopup;
  tabletitle;
  isPopup;
  popupHeader;
  constructor() { }

  ngOnInit() {
    this.transportGrid = new TableData();
    this.transportGridPopup = new TableData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataUrl = '';
    if (changes['org']) {
      this.dataUrl = '/api/v1/interface/status';
    }

  }

  loadGrid(data) {
    if (this.transportGrid.cols.length === 0) {
      this.transportGrid.cols = [{ header: 'Distribution', field: 'modifiedname', size: '250px' },
      { header: 'Value', field: 'value' }];
      this.transportGrid.selectedColumns = this.transportGrid.cols.slice(0, 6);
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach(element => {
        element.modifiedname = element.name.replace(/_/g, ' ');
        element.modifiedname = element.modifiedname.replace(element.modifiedname.charAt(0), element.modifiedname.charAt(0).toUpperCase());
      });
    }
    this.transportGrid.colData = rawData;
    this.transportGrid.totalRecords = rawData.length;
    this.tabletitle = `Transport Interface Distribution (Total ${this.transportGrid.totalRecords} )`;
    this.transportGrid.paginator = false;
  }


  rowSelect(event) {
    if (event.data.value !== 0) {
      this.popupHeader = event.data.modifiedname;
      this.dataUrlpopup = `/api/v1/interface/status/${event.data.name}?updated=${event.data.updateTime}`;
      this.isPopup = true;
    }
  }


  loadPopupGrid(data) {
    if (this.transportGridPopup.cols.length === 0) {
      this.transportGridPopup.cols = data['headers'];
      this.transportGridPopup.selectedColumns = this.transportGridPopup.cols.slice(0, 6);
    }
    const rawData = data['data'];
    this.transportGridPopup.colData = rawData;
    this.transportGridPopup.totalRecords = data.total;

  }

}
