import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranportInterfaceComponent } from './tranport-interface.component';
import { DialogModule } from 'primeng/primeng';
import { GridTableModule } from '../../../grid-table/grid-table.module';

@NgModule({
  imports: [
    CommonModule,
    GridTableModule,
    DialogModule,
  ],
  declarations: [TranportInterfaceComponent],
  exports: [TranportInterfaceComponent]
})
export class TranportInterfaceModule { }
