import { Component, OnInit, OnDestroy } from '@angular/core';
import { GrafanaService } from './service/grafana.service';
import { Response } from '@angular/http';
import { CustomEvents } from '../../events/customevents';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/primeng';
import { Observable } from 'rxjs/Observable';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
@Component({
  selector: 'app-home',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit, OnDestroy {

  role;
  msgs: Message[] = [];
  renewSession = false;

  // top application
  urls = [];
  onOrgChange;

  //  For graph
  graphCollection = [];
  graphs: any[] = [];
  isDisplayGraph;
  graphName;
  scrollTo;
  fullView;
  isGrafanaSessionPresent;
  isApplicationPopUp;
  displayGraphListener: any;
  isPermission;
  sub: any;
  org: string;


  constructor(private _grafanaService: GrafanaService,
    private _customEvents: CustomEvents, private route: ActivatedRoute,
    private _portalhttp: PortalHttpServiceService) {
    this.onOrgChange = false;
    this.isGrafanaSessionPresent = false;
    this.isApplicationPopUp = false;
    sessionStorage.setItem('islogged', 'true');
    this._customEvents.showGrafanaOption.subscribe(value  => {
      this.reload();
    });
    const user = JSON.parse(sessionStorage.getItem('user'));
    user['orgs'].forEach(element => {
      if (element.key === this.org) {
       this.isPermission = element.permissions;
      }
    });
  }


  initializeDefaults() {
    this.isDisplayGraph = false;
  }

  // to instantiate all the grids
  ngOnInit() {
    this._customEvents.showGrafanaOption.subscribe(value  => {
      this.reload();
    });
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.org = params['org'] || sessionStorage.getItem('org');
        this.initializeDefaults();
        this.onOrgChange = false;
        this.reload();
      });
      const user = JSON.parse(sessionStorage.getItem('user'));
      user['orgs'].forEach(element => {
        if (element.key === this.org) {
         this.isPermission = element.permissions;
        }
      });
    // To display Graph on icon click
    this.displayGraphListener = this._customEvents.displayGraph.subscribe((value: any) => {
      this.loadGraphForDevices(value);
    });

  }

  // call methods to set data for grids
  reload() {
    //  const grafana = sessionStorage.getItem('grafanaPresent');
    //  if (grafana === 'true') {
         this.isGrafanaSessionPresent = true;
         this.onOrgChange = true;
         this.loadGraphs();
         this._customEvents.showHideLoader.emit({ show: false });
    //   }
  }
  // to unsubscribe all the listeners
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.displayGraphListener) {
      this.displayGraphListener.unsubscribe();
    }
  }

  // load top application
  loadGraphs() {
    this.onOrgChange = true;
    this.urls = [];
    this._portalhttp.get('/api/graph/dashboard/urls', this.org).subscribe((rest: any) => {
      const data = rest.data;
      if (data.length > 0) {
        this.urls = rest.data[0].graphUrls;
        this.onOrgChange = true;
        for (const item of this.urls) {
          // if (item.name === 'Top Applications DPI') {
          //   this.topAppUrl = item.url;
          // }
        }
      }

    }, (error) => {
      this.onOrgChange = true;
    });
  }

  // load graph container for net status
  loadGraphForDevices(value) {
    this.isDisplayGraph = false;
    this.graphs = [];
    const id = value.id;
    const type = value.type;
    if (type !== 'vedge' && type !== 'application-aware') {
      this.msgs = [];
      this.msgs.push({ severity: 'error', detail: 'Oops something went downhill' });
    } else {
      const id = value.id;
      const type = value.type;
      let url = '/api/graph/urls/' + type + '?device=' + id;
      this._portalhttp.get(url, this.org).subscribe((rest: any) => {
        if (rest['data'].length === 0) {
          return;
        } else {
          this.graphCollection = rest['data'];
          for (const item of this.graphCollection) {
            item['id'] = id;
            item['type'] = type;
            this.graphs.push(item);
            this.graphName = value.name;
            this.scrollTo = 'graph-block-1';
            this.fullView = false;
          }
          if (value.type === 'application-aware') {
            this.isDisplayGraph = false;
            this.fullView = true;
            this.isApplicationPopUp = true;
          } else {
            this.isDisplayGraph = true;
          }
        }
      }, error => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', detail: 'Oops something went downhill' });
        this.isDisplayGraph = false;
        this.renewSession = true;
      }
      );
    }
  }

}

