import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceStatusService } from './device-status.service';
import { DeviceStatusComponent } from './device-status.component';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  imports: [
    CommonModule,
    GridTableModule,
    TooltipModule,
    DialogModule
  ],
  declarations: [DeviceStatusComponent],
  providers: [DeviceStatusService],
  exports: [DeviceStatusComponent]
})
export class DeviceStatusModule { }

