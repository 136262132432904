import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphContainerComponent } from './graph-container.component';
import { SafePipeModule } from '../utils/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    SafePipeModule
  ],
  declarations: [GraphContainerComponent],
  exports: [GraphContainerComponent]
})
export class GraphContainerModule { }
