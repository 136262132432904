import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { CustomerSiteServiceService } from '../customer-site-service.service';
import { CustomEvents } from '../../../../events/customevents';
import { DateConverterPipe } from '../../../../utils/dateConverter.pipe';
import { Message } from 'primeng/api';

import * as $ from 'jquery';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { DataProviderService } from '../../../../services/data-provider.service';
import { MessageBaseCtrl } from '../../../../base/MessageBaseCtrl';

@Component({
  selector: 'app-customer-site-map',
  templateUrl: './customer-site-map.component.html',
  styleUrls: ['./customer-site-map.component.scss']
})
export class CustomerSiteMapComponent extends MessageBaseCtrl implements OnInit, OnChanges, OnDestroy  {
  mapListener;
  map = null;
  data;
  dataset;
  limit;
  offset;
  @Input() org;
  servicerequestDialog2 = false;
  sitemapdata;
  createReqerrormsg: Message[] = [];
  constructor(private _customEvents: CustomEvents,public _dataProvider: DataProviderService,
    private _datePipe: DateConverterPipe,
    private service: CustomerSiteServiceService) {
      super();
      this.limit = 100;
      this.offset = 0;
      this.data = [];
      this.dataset = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['org']) {
      this.getMapData();
    }
  }

  ngOnInit() {
    this.setMap();
  }

  getMapData() {
    this.mapListener = this.service.getMapData(this.limit, this.offset, this.org).subscribe(value => {
      this.dataset = value['data'];
      if (value['total'] > this.limit) {
        this.offset = 1;
        this.service.getMapData(this.limit, this.offset, this.org).subscribe(value => {
          this.dataset = this.dataset.concat(value['data']);
          this.loadMap(this.dataset);
        },
          (error) => {
            console.log(error);
          }
        );
      } else {
        setTimeout(() => {
        this.loadMap(this.dataset);
       }, 3000);
      }

    }, (error) => {
      console.log(error);
    });
  }
  // initialization of map
  setMap() {
    let minZoom;
    if (window.screen.width <= 425) {
      minZoom = 1;
    } else {
      minZoom = 2;
    }
    const fistLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: minZoom, maxZoom: 13 });
    this.map = L.map('socmap', {
      zoom: 4,
      scrollWheelZoom: false,
      center: [30, -100],
      worldCopyJump: true,
      layers: [fistLayer]
    });
    if (window.screen.width <= 425) {
      this.map.fitWorld();
    }

  }

  // called from parent with cluster values
  loadMap(addressPoints) {
    this.data = addressPoints;
    // should remove the map from UI and clean the inner children of DOM element
    if (this.map) {
      this.map.remove();
      this.setMap();
    }

    if (addressPoints.length > 0) {

      const mcg = L.markerClusterGroup({
        chunkedLoading: true,
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false
      });


      // reset position of icons
      L.marker.prototype.update = function() {
        if (this._icon) {
          const pos = this._map.latLngToLayerPoint(this._latlng).round();
          this._setPos(pos);
        }
      };


      // trigger event to display details
      const displayDetails = ((value) => {
        const result = this.data.find(element => element.unique_customer_reference === value[0]);
        const headers = 'CustomerSite';
        this._customEvents.displayCustomerSiteEvt.emit({ data: result, header: headers, isContact: true });
      });
      const displayCreateTicketPopup = ((value) => {
        const headers = 'GtmEvents';
        this._customEvents.sendGtmEvt.emit({ data: 'SOC_CreateTicketinSOC', header: headers });
         this.servicerequestDialog2 = true;
         this.sitemapdata = value;
      });

      // get the data from each popUp of marker
      const getPopUpData = (() => {
        const rowInfo = [];
        $('.mytable').find('tr').each(function() {
          $(this).children('td:last').each(function() {
            rowInfo.push($(this).text());
          });
        });
        return rowInfo;
      });

      // loop throgh devices to set marker
      for (const item of addressPoints) {

        item['last_validated_date'] = item.hasOwnProperty('last_validated_date') && (item['last_validated_date'] !== 0) ?
          this._datePipe.transform(item['last_validated_date']) : '';

        if (item.hasOwnProperty('site_location')) {
          let geoData = String(item['site_location']);
          if (!geoData.startsWith('POINT(')){
            continue;
          }
          geoData = geoData.replace('POINT(', '');
          geoData = geoData.replace(')', '');
          item['site_location'] = geoData.split(' ');
        }
        // create marker
        const marker = L.marker(new L.LatLng(parseFloat(item['site_location'][1]), parseFloat(item['site_location'][0])), {
          icon: L.divIcon({
            html: ' <i class="fa fa-map-marker" style="font-size:45px;color:#186BA0;margin-left:-13px;margin-top:-15px" aria-hidden="true"></i>',
            iconAnchor: [0, 0],
            iconSize: [0, 0]
          })
        });

        // create popUp for each marker
        const container = $('<div />');
        let popUpHtml = '<table class="mytable" style="width:100%">' +
          '<tr>' +
          '<th>Customer Ref. </th>' +
          '<td><b>:</b></td>' +
          '<td style="word-break:break-all">' + item['unique_customer_reference'] + '</td>' +
          '</tr>' +
          '<tr>' +
          '<th >Apcela Ref. </th>' +
          '<td><b>:</b></td>' +
          '<td>' +
          item['Code'] +
          '</td>' +
          '</tr><tr>' +
          '<th>Last validated </th>' +
          '<td><b>:</b></td>' +
          '<td style="word-break:break-all">' + item['last_validated_date'] + '</td>' +
          '</tr>' +
          '<tr style="display:none">' +
          '<th >Site Id </th>' +
          '<td><b>:</b></td>' +
          '<td>' + item['customer_site_id'] + '</td>' +
          '</tr>' +
          '</table>' +
          '<div class="row">' +
          '<div class="col-xs-6">' +
          '<a class="details">' +
          'Site Info ' +
          '</a>' +
          '</div>' +
          '<div class="col-xs-6" style="display:none;">' +
          '<a class="contact">' +
          'Contact Info' +
          '</a>' +
          '</div>' +
          '<div class="col-xs-6">' +
          '<a class="createTicket">' +
          'Create Ticket' +
          '</a>' +
          '</div>';

        popUpHtml += '</div>';

        container.html(popUpHtml);


        // handle click on contact link
        container.on('click', '.contact', function() {
       //   const data = getPopUpData();
        //  displayContact(data);
        });

        // handle click on details link
        container.on('click', '.details', function(e) {
          const data = getPopUpData();
          displayDetails(data);
        });
        container.on('click', '.createTicket', function() {
          const jiraSessionPresent = sessionStorage.getItem('JiraPresent');
          if (jiraSessionPresent === 'true') {
          const data = getPopUpData();
          displayCreateTicketPopup(data);
          } else {
            const createReqerrormsg = [];
            createReqerrormsg.push({ severity: 'error', summary: '',
             detail: ' You Do not have permission to Raise Request, Please Contact Admin!!' });
          }
      });

        // handles click on popPu
        marker.bindPopup(container[0]);

        // displays tooltip for markers
        const html = popUpHtml.substring(0, popUpHtml.lastIndexOf('</tr>'));

        marker.bindTooltip(html);

        // for mobile devices on click show only popup hide tooltip
        marker.on('click', function() {
          marker.closeTooltip();
        });

        mcg.addLayer(marker);

        // on clucster click, get the cluster into center of screen
        mcg.on('clusterclick', function(a) {
          a['layer'].zoomToBounds({ padding: [20, 20] });
          a['layer'].unbindPopup();
        });
      }


      this.map.addLayer(mcg);

      // zooming on mouse scroll is enables only after map click
      this.map.on('click', function(e) {
        e.target.scrollWheelZoom.enable();
      });

      // on mouse out mouse scroll is disabled
      this.map.on('mouseout', function(e) {
        e.target.scrollWheelZoom.disable();
      });

    }

  }

  // setting different icons based on device type for net status
  setIcons(value) {
    let url;
    switch (value) {
      case 'vedge':
        url = 'assets/vEdge_marker.png';
        break;
      case 'vbond':
        url = 'assets/vBond_marker.png';
        break;
      case 'vsmart':
        url = 'assets/vSmart_marker.png';
        break;
      case 'vmanage':
        url = 'assets/vManage_marker.png';
        break;
      default:
        url = 'assets/marker-icon.png';
        break;
    }
    return url;
  }

  // destroy map event listener
  ngOnDestroy() {
    if (this.mapListener) {
      this.mapListener.unsubscribe();
    }
  }
  closeForm(value) {
    this.servicerequestDialog2 = false;
    this.growlMessage = [];
    if(value['status'] !== 'cancel'){
      this.growlMessage.push(value['message']);
    } 
  }
}
