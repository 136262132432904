import { Component, OnInit, Input } from '@angular/core';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { TableData } from '../../../common/SharedClasses/TableData';
import { DatePickerEvents } from '../../../date-picker/date-picker.component';
import {CustomEvents} from '../../../events/customevents';

@Component({
  selector: 'app-audit-grid',
  templateUrl: './audit-grid.component.html',
  styleUrls: ['./audit-grid.component.scss']
})
export class AuditGridComponent implements OnInit, DatePickerEvents, GridTableEvents {

  @Input() org;
  @Input() isGrafanaSessionPresent;
  auditData;
  auditDataPopup;
  auditPopUpGrid;
  isPopup: boolean;
  isGraphLoading;
  selectedData;
  isRowClick;
  dataUrl;
  id;
  rowWiseDataHeaders;
  constructor(private _datePipe: DateConverterPipe , private _customEvents: CustomEvents) {
    this.auditData = new TableData();
    this.auditDataPopup = new TableData();
    this.isPopup = false;
    this.isGraphLoading = true;
    this.isRowClick = false;
    this.auditData.state = 'Graph';
    this.rowWiseDataHeaders = [];
  }

  ngOnInit() {
    this.dataUrl = '/api/v1/auditlog';
    this.id = 'event';
    // to set data for audit grid in the home page
    this.setInitialData();

  }

  // row wise popUp for audit grid in homepage
  rowSelect(event) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'device_Audits', header: headers });
    this.selectedData = event.data;
    this.isRowClick = true;
    if (this.rowWiseDataHeaders.length === 0) {
      this.rowWiseDataHeaders = this.auditData.cols;
      this.rowWiseDataHeaders.push({
        field: 'auditdetails', header: 'Audit Details'
      });
    }

  }

  // sets data for audit grid in home page
  setInitialData() {
    this.auditData.reloadEvent = '';
    this.auditData.isGraph = true;
  }

  // called when expand button clicked
  openPopUp() {
    this.auditDataPopup.timeRangeToAppendEvent = this.auditData.timeRangeToAppendEvent;
    this.auditDataPopup.reloadEvent = '';
    this.auditDataPopup.isGraph = true;
    this.auditDataPopup.state = this.auditData.state;
    this.isPopup = true;
  }

  loadGrid(data: any) {
    if (this.auditData.cols.length === 0) {
      this.auditData.cols = data['headers'];
      const columns = [];
      this.auditData.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.auditData.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['entry_time'] = element.hasOwnProperty('entry_time') && (element['entry_time'] !== 0) ?
          this._datePipe.transform(element['entry_time']) : '';
      });
    }
    this.auditData.colData = rawData;
    this.auditData.totalRecords = data['total'];
  }

  loadPopupGrid(data: any) {
    if (this.auditDataPopup.cols.length === 0) {
      this.auditDataPopup.cols = data['headers'];
      const columns = [];
      this.auditDataPopup.cols.slice(0, 6).forEach(element => {
        columns.push(element);
      });
      this.auditDataPopup.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['entry_time'] = (element.hasOwnProperty('entry_time') && (element['entry_time'] !== 0)) ?
          this._datePipe.transform(element['entry_time']) : '';
      });
    }
    this.auditDataPopup.colData = rawData;
    this.auditDataPopup.totalRecords = data['total'];
  }

  // swicthing gird to graph
  switchView(value) {
    const state = this.auditData.state;
    if (state !== value) {
      this.auditData.totalRecords = 0;
      this.auditData.colData = [];
      this.auditData.state = value;
    }
  }
  // refresh for grid
  refresh(event) {
    this.auditData.totalRecords = 0;
    this.auditData.colData = [];
    this.auditData.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
  }

  // swicthing gird to graph for popUP
  switchPopupView(value) {
    const state = this.auditDataPopup.state;
    if (state !== value) {
      this.auditDataPopup.totalRecords = 0;
      this.auditDataPopup.colData = [];
      this.auditDataPopup.state = value;
    }
  }
  // refresh for grid for popUP
  refreshPopup(event) {
    this.auditDataPopup.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.auditDataPopup.totalRecords = 0;
    this.auditDataPopup.colData = [];
  }

  updateTime(event) {
    this.auditData.timeRangeToAppendEvent = event.time;
    this.auditData.from = event.from;
    this.auditData.to = event.to;
    this.auditData.label = event.label;
    this.auditData.rangeFrom = event.rangeFrom;
    this.auditData.rangeTo = event.rangeTo;
    this.auditData.dateFrom = event.dateFrom;
    this.auditData.dateTo = event.dateTo;

  }
  updatePopupTime(audit) {
    this.auditDataPopup.timeRangeToAppendEvent = audit.time;
    this.auditDataPopup.from = audit.from;
    this.auditDataPopup.to = audit.to;
  }

}





