import { environment } from '../../environments/environment';

export class BaseCtrl {
  APP_VERSION = '2.0.0';
  ENV = environment;
  routPath = '/';
  isValidSession = false;
  private tokenKey = 'app_token';

  unAuthorised() {
    localStorage.clear();
  }

}
