import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { CustomEvents } from '../events/customevents';
import { BaseCtrl } from '../base/BaseCtrl';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs/Observable';
import { mergeMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {RequestOptions, Request, RequestMethod, RequestOptionsArgs} from '@angular/http';


@Injectable()
export class PortalHttpServiceService extends BaseCtrl {
  config;
  unAuthorizedErrorListener: any;
  user;
  homeworld: Observable<{}>;


  public defaultOrg = new BehaviorSubject<string>(sessionStorage.getItem('org'));

  constructor(private _http: HttpClient, private _customEvents: CustomEvents) {
    super();
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  public setDefaultOrg(value: string) {
    this.defaultOrg.next(value);
  }

  get DefaultOrg() {
    return this.defaultOrg.asObservable();
  }

  // get urls for event, alarm and audit grid
  public get(url: string, org: string) {
    const test = sessionStorage.getItem('grafana-token');
    sessionStorage.setItem('grafana-token', test);
    this.user = JSON.parse(sessionStorage.getItem('user'));
    const httpOptions = {
      headers: new HttpHeaders({
        'org': org,
       'grafana-token': sessionStorage.getItem('grafana-token') ? sessionStorage.getItem('grafana-token') : '',
        'Authorization': 'Bearer ' + this.user['token']
      })
    };
    return this._http.get(this.ENV.serviceUrl + url, httpOptions).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }
  public getwithcontenttype(url: string, org: string) {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    const httpOptions = {
      headers: new HttpHeaders({
        'org': org ? org : '',
        'Authorization': 'Bearer ' + this.user['token']
      })
    };
    return this._http.get(this.ENV.serviceUrl + url, httpOptions).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }


  // get data for event, alarm and audit grid
  public post(url: string, org: string, body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user['token'],
        'org': org,
        'region': 'euc1'
      })
    };
    return this._http.post(this.ENV.serviceUrl + url, body, httpOptions).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }

  // get data for event, alarm and audit grid
  public postWithoutRetry(url: string, org: string, body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user['token'],
        'org': org,
        'region': 'euc1'
      })
    };
    return this._http.post(this.ENV.serviceUrl + url, body, httpOptions);
  }
  public postwithRegion(url: string, org: string, body: any, region: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user['token'],
        'org': org,
        'region': region ? region : ''
      })
    };
    return this._http.post(this.ENV.serviceUrl + url, body, httpOptions).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }
  public getwithRegion(url: string, org: string, region: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'org': org,
        'grafana-token': sessionStorage.getItem('grafana-token') ? sessionStorage.getItem('grafana-token') : '',
        'Authorization': 'Bearer ' + this.user['token'],
        'region': region ? region : ''
      })
    };
    return this._http.get(this.ENV.serviceUrl + url, httpOptions).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }
  // put without Retry
  public putWitoutRetry(url: string, org: string, body: any) {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user['token'],
        'org': org
      })
    };
    return this._http.put(this.ENV.serviceUrl + url, body, httpOptions).catch((error: any) => this.catchError(error));
  }

  // put without Retry
  public putWithError(url: string, org: string, body: any) {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user['token'],
        'org': org
      })
    };
    return this._http.put(this.ENV.serviceUrl + url, body, httpOptions);
  }



  // get data for event, alarm and audit grid
  public getLocalData(file) {
    const url = '/assets/' + file;
    return this._http.get(url).catch((error: any) => this.catchError(error));
  }

   // get urls for event, alarm and audit grid
   public getDatawithoutHeader(url: string) {
    return this._http.get(url);
  }

  // delete group
  public delete(url: string, org: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'org': org,
       'grafana-token': sessionStorage.getItem('grafana-token') ? sessionStorage.getItem('grafana-token') : '',
        'Authorization': 'Bearer ' + this.user['token']
      })
    };
    return this._http.delete(this.ENV.serviceUrl + url, httpOptions).retryWhen((errors: Observable<any>) => {
      return Observable.throw(errors);
    }).catch((error: any) => Observable.throw(error));
  }

  // handles error
  catchError(error: any): any {
    if (error.status === 401) {
      this.unAuthorizedErrorListener = this._customEvents.unAuthorizedError.emit({});
    } else {
      console.log('Cluster Load Data Error');
    }
  }
}
