import { Component, OnInit, Input } from '@angular/core';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import { PapaParseService } from 'ngx-papaparse';
import { Inject } from '@angular/core';
import { CustomEvents } from '../../../events/customevents';


@Component({
  selector: 'app-xc-circuit-report',
  templateUrl: './xc-circuit-report.component.html',
  styleUrls: ['./xc-circuit-report.component.scss']
})
export class XcCircuitReportComponent implements OnInit {
  display = false;

  @Input() org: string;
  totalRecords = 0;
  cols: any[];
  selectedColumns = [];
  colData = [];
  @Input() isGlobalSearch = true;
  @Input() paginator = true;
  @Input() isHeaderWrap = true;

  constructor(private _portalhttp: PortalHttpServiceService, private papa: PapaParseService, private _customEvents: CustomEvents) { }

  ngOnInit() {
  }

  showDialog(event) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'SOC_uploadXcCircuit', header: headers });
       this.papa.parse(event.files[0], {
        header: false,
        skipEmptyLines: true,
        complete: (result, file) => {
          const list = [];
          for (const data of result.data) {
            if (data.length > 0) {
              list.push(data[0]);
            }
        }
        this._portalhttp.post('/api/v1/circuits/xc/report', this.org, {'circuitIds': list}).subscribe((res) => {
          this.selectedColumns = res['headers'];
          this.cols = res['headers'];
          this.colData = res['data'];
          this.totalRecords = res['total'];
          this.display = true;
        }, error => {
          console.log(error);
        });
        }});
  }

}
