import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GroupNotificationsComponent} from './group-notifications.component';
import { AuthenticateService } from '../../../session/authenticate.service';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import {ButtonModule} from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {GrowlModule} from 'primeng/growl';
import { ReactiveFormsModule } from '@angular/forms';
import {SelectTableModule} from '../../../select-Table/select-table/select-table.module';
import {ChipsModule} from 'primeng/chips';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    GridTableModule,
    TooltipModule,
    InputTextModule,
    FormsModule,
    GrowlModule,
    ReactiveFormsModule,
    SelectTableModule,
    ChipsModule,
    MessagesModule,
    MessageModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [GroupNotificationsComponent],
  exports: [GroupNotificationsComponent],
})
export class GroupNotificationsModule { }
