import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AnomalydetectionComponent } from './anomalydetection/anomalydetection.component';
import { AnalyticsComponent } from './analytics.component';
import { AuthenticateService } from '../session/authenticate.service';

const analyticsRoutes: Routes = [
  {
    path: '',
    component: AnalyticsComponent,
    children: [
      {
        path: '', redirectTo: 'anomaly', pathMatch: 'full'
      },
      {
        path: 'anomaly', component: AnomalydetectionComponent
      }
    ],
    canActivate: [AuthenticateService],
    canActivateChild: []
  }
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(analyticsRoutes)
  ],
  exports: [RouterModule]
})

export class AnalyticsRoutingModule { }
