<div style="height:100%">
    <app-date-picker blocktitle="Audits" style="width:100%" (switchView)="switchView($event)" collapseId="audit" isExpand="true"
        (refresh)="refresh($event)" (updateTime)="updateTime($event)" (expand)="openPopUp()" style="display: flow-root"></app-date-picker>

    <section *ngIf="auditData.state === 'Graph'" id="error">
        <app-graph-container [isGrafanaSessionPresent]="isGrafanaSessionPresent" [timeRangeToAppendEvent]="auditData.timeRangeToAppendEvent"
            [reloadEvent]="auditData.reloadEvent" [org]="org" urlid="Audit Logs"></app-graph-container>
    </section>
    <section *ngIf="auditData.state === 'Grid'">
        <app-grid-table [id]="id" [reload]="auditData.reloadEvent" [totalRecords]="auditData.totalRecords" [selectedColumns]="auditData.selectedColumns"
            [cols]="auditData.cols" [colData]="auditData.colData" [from]="auditData.from" [to]="auditData.to" [org]="org" [dataUrl]="dataUrl"
            (update)="loadGrid($event)" (rowSelect)="rowSelect($event)"></app-grid-table>
    </section>
</div>
<p-dialog [contentStyle]="{'height':'500px'}" *ngIf="isPopup" header="Audits" [positionTop]="50" [(visible)]="isPopup" [modal]="true" showEffect="fade"
    [responsive]="true" styleClass="gridactive" resizable="true">
    <app-date-picker collapseId="auditpopup" [selectedstyle]="{'width':'600px'}" [state]="auditData.state" [selectedQuickRangeFrom]="auditData.rangeFrom"
        [selectedQuickRangeTo]="auditData.rangeTo" [dateFrom]="auditData.dateFrom" [dateTo]="auditData.dateTo" [timerangelabel]="auditData.label"
        (switchView)="switchPopupView($event)" (refresh)="refreshPopup($event)" (updateTime)="updatePopupTime($event)" style="display: flow-root"></app-date-picker>
    <section *ngIf="auditDataPopup.state === 'Graph'" id="error">
        <app-graph-container [isGrafanaSessionPresent]="isGrafanaSessionPresent" [timeRangeToAppendEvent]="auditDataPopup.timeRangeToAppendEvent"
            [reloadEvent]="auditDataPopup.reloadEvent" [org]="org" urlid="Audit Logs"></app-graph-container>
    </section>
    <section *ngIf="auditDataPopup.state === 'Grid'">
        <app-grid-table [id]="id" [popup]="true" [reload]="auditDataPopup.reloadEvent" [totalRecords]="auditDataPopup.totalRecords"
            [selectedColumns]="auditDataPopup.selectedColumns" [cols]="auditDataPopup.cols" [colData]="auditDataPopup.colData"
            [from]="auditDataPopup.from" [to]="auditDataPopup.to" [org]="org" [dataUrl]="dataUrl" (update)="loadPopupGrid($event)"></app-grid-table>
    </section>

</p-dialog>

<p-dialog *ngIf="isRowClick" header="Audit Log Details" [(visible)]="isRowClick" [modal]="true" styleClass="gridactive" showEffect="fade"
    resizable="true" (onHide)="isRowClick=false;">
    <div class="ui-grid ui-fluid">
        <div class="ui-grid-row" *ngFor="let col of rowWiseDataHeaders">
            <div class="ui-grid-col-4">
                <label *ngIf="selectedData[col.field]">{{col.header}}</label>
            </div>
            <div class="ui-grid-col-2">
                <span *ngIf="selectedData[col.field]">:</span>
            </div>
            <div class="ui-grid-col-6">
                <span *ngIf="col.field!=='auditdetails'">
                    {{selectedData[col.field]}}
                </span>
                <span *ngIf="col.field==='auditdetails'">
                    <span *ngFor="let item of selectedData.auditdetails">
                        <div>{{item}}</div>
                    </span>
                </span>
            </div>
        </div>
    </div>
</p-dialog>