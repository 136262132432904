import { Injectable } from '@angular/core';
import { BaseCtrl } from '../base/BaseCtrl';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService extends BaseCtrl{
  configData;
  jiraConfig
  userData;
  constructor() { 
    super()
  }

  initJiraConfig(){
    if(!this.jiraConfig){
      this.jiraConfig = JSON.parse(sessionStorage.getItem('jiraData'));
    }
  }

  initConfig(){
    if(!this.configData){
      this.configData = this.configData = JSON.parse(sessionStorage.getItem('config'));
    }
  }

  initUser() {
    if(!this.userData){
      this.userData = JSON.parse(sessionStorage.getItem('user'));
    }
  }

  getTimezone() {
    this.initUser();
    return this.userData['zone'];
  }

  getToken() {
    this.initUser();
    return 'Bearer ' + this.userData['token'];
  }

  getJiraXSFRToken() {
    this.initJiraConfig();
    return this.jiraConfig['xsrfToken'];
  }
  getJiraUrl(uri){
    this.initConfig()
    return this.configData['jiraUrl'] + uri;
  }

  getServiceUrl(uri){
    this.initConfig()
    return this.ENV.serviceUrl + uri;
  }
  getUserInfoByKey(key) {
    this.initUser()
    return this.userData[key];
  }

  getReportTroubleReqId(){
    this.initUser()
    const ids = this.userData['circuitReqType']
    if(ids && ids.length > 0){
      return ids[0];
    }
    return '';
  }

  getPermission(org) {
    this.initUser()
    let permissions = [];
    this.userData['orgs'].forEach(element => {
      if (element.key === org) {
        permissions = element.permissions;
      }
    });
    return permissions;
  }

  isJiraSession(){
    const jiraSession = sessionStorage.getItem('JiraPresent');
    if(jiraSession) {
      return jiraSession === "true"
    }
    return false;
  }
  isStringEmpty(value) {
    if(value){
      return value.trim().length === 0;
    }
    return true;
  }

  isPermission(org, value){
    return this.getPermission(org).includes(value)
  }
  SERVICE_TICKET_SEARCH = '/api/v1/ticket';
  SERVICE_TICKET_PRIORITY = '/api/v1/ticket/priority';
  SERVICE_TICKET_UPDATE = '/api/v1/ticket/update';
  SERVICE_TICKET_MAINTENANCE = '/api/v1/ticket/maintenance';
  SERVICE_TICKET_COMMENT = '/api/v1/ticket/comment/';
  SERVICE_TICKET_LINKED = '/api/v1/ticket/linked/issue/';
  SERVICE_NOTIFICATION_EMAILS = '/api/v1/circuits/notification/emails';
  JIRA_TEMP_FILE_UPLOAD = '/api/rest/servicedesk/1/servicedesk/customer/comment/tempfile';
  JIRA_CREATE_REQUEST = '/api/servicedesk/customer/portal/4/create/';
  JIRA_SERVICEDESK_COMMENT = '/api/rest/servicedesk/1/servicedesk/customer/comment';
  JIRA_SERVICEDESK_REQUEST = '/rest/servicedeskapi/request';
  JIRA_SERVICEDESK_MODEL = '/api/rest/servicedesk/1/customer/models';
  JIRA_SERVICEDESK_ICON = '/servicedesk/customershim/secure/viewavatar?avatarType=SD_REQTYPE&avatarId=10532';
  JIRA_SERVICEDESK_SEARCH = '/api/rest/servicedesk/knowledgebase/latest/articles/shared/search';

}
