import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { BaseCtrl } from '../base/BaseCtrl';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppInitialiserService extends BaseCtrl {

  constructor(private _http: HttpClient) { 
    super();
  }
 
  properties = new BehaviorSubject<any>({}); // {1}

  get Properties() {
    return this.properties.asObservable(); // {2}
  }

  public setproperties(value) {
    this.properties.next(value);
  }

  initialiseApp(url): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this.ENV.serviceUrl+url).toPromise().then((res) => {
       this.setproperties(res['data']);
        resolve();
      }).catch(()=>{
        
      })
    });
  }

}
