import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AppComponent } from './app.component';
import { ValidateService } from './session/validate.service';
import { routes } from './app.routes';
import { LoaderModule } from './spinning/loader.module';
import { CustomEvents } from './events/customevents';
import { AuthenticateService } from './session/authenticate.service';
import { GrafanaService } from './dashboard/device/service/grafana.service';
import { AuthModule, OidcSecurityService } from 'angular-auth-oidc-client';
import { SessiomModule } from './session/session.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateConverterPipe } from './utils/dateConverter.pipe';
import { SafePipe } from './utils/safe.pipe';
import { SidebarModule } from './sidebar/sidebar.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { HttpModule } from '@angular/http';
import { FooterComponent } from './footer/footer.component';
import { TopNavComponent } from './dashboard/top-nav/top-nav.component';
import { PortalHttpServiceService } from './services/portal-http-service.service';
import {JiraTicketServiceService} from './services/jira-ticket-service.service';
import { AppInitialiserService } from './services/app-initialiser.service';
import { ErrorComponent } from './error/error.component';
import {FormModule} from './dashboard/jira/jira-tickets/form/form.module';
import {CircuitInformationModule} from './dashboard/soc/circuit-information/circuit-information.module';
import {CustomerCircuiteGridModule} from './dashboard/soc/customer-circuite-grid/customer-circuite-grid.module';
import {DropdownModule} from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {TabViewModule} from 'primeng/tabview';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import {DatePickerModule} from './date-picker/date-picker.module';
import { DialogModule } from 'primeng/dialog';
import {  SelectButtonModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/primeng';
import { OverlayPanelModule } from 'primeng/primeng';
import {InputSwitchModule} from 'primeng/primeng';
import { GraphModule } from './graph/graph.module';
import {GrowlModule} from 'primeng/growl';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { DataProviderService } from './services/data-provider.service';
import { MaintenanceTicketDetailsModule } from './dashboard/jira/jira-tickets/maintenance-ticket-details/maintenance-ticket-details.module';


export function app_init(service: AppInitialiserService) {
  return () => service.initialiseApp('/api/v1/web/config');
}


@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    FooterComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AuthModule.forRoot(),
    RouterModule.forRoot(routes),
    LoaderModule,
    SidebarModule,
    HttpModule,
    ConfigurationModule,
    FormModule,
    SessiomModule,
    CircuitInformationModule,
    CustomerCircuiteGridModule,
    DropdownModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    TabViewModule,
    FormsModule,
    ButtonModule,
    TooltipModule,
    DatePickerModule,
    SelectButtonModule,
    CalendarModule,
    OverlayPanelModule,
    InputSwitchModule,
    CheckboxModule,
    DialogModule,
    GraphModule,
    GrowlModule,
    MessagesModule,
    MessageModule,
    MaintenanceTicketDetailsModule
  ],
  providers: [ValidateService, FormBuilder, CustomEvents, SafePipe, AppInitialiserService, ConfirmationService,
    {provide : APP_INITIALIZER , useFactory: app_init, deps : [AppInitialiserService], multi: true},
    AuthenticateService,  GrafanaService, Title,
    PortalHttpServiceService, JiraTicketServiceService, OidcSecurityService, DateConverterPipe, DataProviderService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

  constructor() {
  }
}

