<div class="container-fluid logout-form" style="min-height: 91%" >
  <div class="row">
    <div class="col-4 col-offset-4">
     
        <section >
            <img src="/assets/imgs/logo.png" alt="apcela" />
          <h4 class="text-center error">Oops!! Something went downhill </h4>
          <h4 class="text-center">Please contact your admin</h4>
        </section>
     
    </div>
  </div>
</div>