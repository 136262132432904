import { Component, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomEvents } from '../../events/customevents';
import {ButtonModule} from 'primeng/button';
import { ActivatedRoute } from '@angular/router';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import { TableData } from '../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../grid-table/grid-table.component';
import { DateConverterPipe } from '../../utils/dateConverter.pipe';
import { Message } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-onboardusermanagmant',
  templateUrl: './onboardusermanagmant.component.html',
  styleUrls: ['./onboardusermanagmant.component.scss']
})
export class OnboardusermanagmantComponent implements OnInit, GridTableEvents, OnChanges {
  onboardGrid;
  isRowSelect;
  org;
  onboardGridsUrl;
  onboardorgDialog = false;
  customer;
  customerkey;
  editCustomerKey = true;
  userPhone;
  userEmail;
  userPostcode;
  userCity;
  userAddress;
  userwebsite;
  tempValue;
  dashboardListDisplay;
  selecteddashboardListDisplay;
  externalAppListDisplay;
  selectedexternalAppListDisplay;
  updatedDashboardlist: any[] = [];
  updatedExternalApplist: any[] = [];
  createReqerrormsg: Message[] = [];
  customerData;
  customerApiData;
  customertype: any[];
  selectedCustomerType: SelectItem[];

  constructor(private route: ActivatedRoute,
    private _customEvents: CustomEvents, private _portalhttp: PortalHttpServiceService, public _datePipe: DateConverterPipe
    , private formBuilder: FormBuilder) {
      this.onboardGrid = new TableData();
      this.isRowSelect = false;
      this.route.queryParams.subscribe(params => {
        this.org = params['org'];
    });

    }

  ngOnChanges(changes: SimpleChanges) {
  }
  loadPopupGrid(event) {
  }

  ngOnInit() {
    this.onboardGridsUrl = '/api/customers ';
    this.onboardGrid.reloadEvent = '';
  }
  loadGrid(data) {
    if (this.onboardGrid.cols.length === 0) {
      this.onboardGrid.cols = data['headers'];
      const columns = [];
      this.onboardGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.onboardGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.onboardGrid.colData = rawData;
    this.onboardGrid.totalRecords = data['total'];
  }
  rowSelect(event) {
    this.customerData = event['data'];
    this.dashboardListDisplay = [];
    this.selecteddashboardListDisplay = [];
    this.externalAppListDisplay = [];
    this.selectedexternalAppListDisplay = [];
    this.updatedExternalApplist = [];
    this.updatedDashboardlist = [];
    const custDataurl = '/api/customers/' + event.data['_id'];
    this._portalhttp.get(custDataurl, this.org).subscribe((res) => {
      this.customer = this.customerData.CompanyTitle;
      this.customerkey = res['key'];
      this.userPhone = res['Phone'];
      this.userEmail = res['Email'];
      this.userPostcode = res['Postcode'];
      this.userCity = res['City'];
      this.userAddress = res['Address'];
      this.userwebsite = res['Website'];
      this.editCustomerKey = res['present'];
      this.customerApiData = res;
      if (res['selectedDashboard'] !== null) {
        this.updatedDashboardlist = res['selectedDashboard'];
        for (let i = 0; i < res['selectedDashboard'].length; i++) {
        const index = res['dashboards'].map(function(o) { return o.Code; }).indexOf(res['selectedDashboard'][i].Code);
        res['dashboards'].splice(index, 1);
       }
      }
      if (res['selectedExternalApps'] !== null) {
        this.updatedExternalApplist = res['selectedExternalApps'];
        for (let i = 0; i < res['selectedExternalApps'].length; i++) {
        const index = res['externalApps'].map(function(o) { return o.Code; }).indexOf(res['selectedExternalApps'][i].Code);
        res['externalApps'].splice(index, 1);
       }
      }
       this.dashboardListDisplay = res['dashboards'];
       this.externalAppListDisplay = res['externalApps'];
       this.selecteddashboardListDisplay = res['selectedDashboard'];
       this.selectedexternalAppListDisplay  = res['selectedExternalApps'];
       const permissionList = [];
       for (let i = 0; i < res['permissions'].length; i++) {
         const permissionobj = {name: res['permissions'][i] , code: res['permissions'][i] };
          permissionList.push(permissionobj);
       }
       this.customertype = permissionList;
       this.selectedCustomerType = [];
       if (res['selectedPermissions'] !== null) {
         const selectedPerObj =[];
        for (let i = 0; i < res['selectedPermissions'].length; i++) {
         const index = this.customertype.map(function(o) { return o.code; }).indexOf(res['selectedPermissions'][i]);
         if (index !== -1) {
          selectedPerObj.push(this.customertype[index]);
        } else {
          this.selectedCustomerType = [];
        }
        }
        this.selectedCustomerType = selectedPerObj;
       }
       this.onboardorgDialog = true;
      }, err => {
        this.onboardorgDialog = false;
      });
  }
  /** Dashboard pick list events */
  onMoveToTarget(event) {
    for (let i = 0; i < event.items.length; i++) {
      if (this.updatedDashboardlist.indexOf(event.items[i]) === -1) {
      this.updatedDashboardlist.push(event.items[i]);
      }
    }
  }
  onMoveToSource(event) {
    for (let i = 0; i < event.items.length; i++) {
        if (this.updatedDashboardlist.indexOf(event.items[i]) !== -1) {
              const index = this.updatedDashboardlist.indexOf(event.items[i]);
              this.updatedDashboardlist.splice(index, 1);
        }
  }
  }
  onMoveAllToTarget(event) {
    this.updatedDashboardlist = [];
    for (let i = 0; i < event.items.length; i++) {
    this.updatedDashboardlist.push(event.items[i]);
  }
  }
  onMoveAllToSource(event) {
    this.updatedDashboardlist = [];
  }
  /** External App PickList  Events*/
  onMoveToTargetAppList(event) {
    for (let i = 0; i < event.items.length; i++) {
      if (this.updatedExternalApplist.indexOf(event.items[i]) === -1) {
      this.updatedExternalApplist.push(event.items[i]);
      }
    }
  }
  onMoveToSourceAppList(event) {
    for (let i = 0; i < event.items.length; i++) {
        if (this.updatedExternalApplist.indexOf(event.items[i]) !== -1) {
              const index = this.updatedExternalApplist.indexOf(event.items[i]);
              this.updatedExternalApplist.splice(index, 1);
        }
  }
  }
  onMoveAllToTargetAppList(event) {
    this.updatedExternalApplist = [];
    for (let i = 0; i < event.items.length; i++) {
    this.updatedExternalApplist.push(event.items[i]);
  }
  }
  onMoveAllToSourceAppList(event) {
    this.updatedExternalApplist = [];
  }
  enableIcon(data) {
    $('.' + data).css('display', 'inline-block');
    $('.' + data).prop('disabled', false);
    this.tempValue = $('.' + data).val();
   }
   clearIcon(data) {
    $('.' + data).val(this.tempValue);
    $('.' + data).prop('disabled', true);
 }
  oncreateOnboardUser() {
    console.log('create on board user.');
  }
  updatecustomer() {
    this.customerApiData.Email = this.userEmail;
    this.customerApiData.Phone = this.userPhone;
    this.customerApiData.Postcode = this.userPostcode;
    this.customerApiData.City = this.userCity;
    this.customerApiData.Address = this.userAddress;
    this.customerApiData.key = this.customerkey;
    this.customerApiData.selectedDashboard = this.updatedDashboardlist;
    this.customerApiData.selectedExternalApps = this.updatedExternalApplist;
    const apiselectedpermission = [];
    if (this.selectedCustomerType.length !== 0) {
      for (let i = 0; i < this.selectedCustomerType.length; i++) {
        apiselectedpermission.push(this.selectedCustomerType[i]['name']);
    }
  }
 this.customerApiData.selectedPermissions = apiselectedpermission;
    const updateUrl = '/api/customers/' + this.customerData._id;
    console.log(this.customerApiData);
   this._portalhttp.putWitoutRetry(updateUrl, this.org, this.customerApiData).subscribe(() => {

      this.onboardGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated user info!' });
      this.onboardorgDialog = false;
    }, error => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
    });
  }
  cancelReq() {
    this.onboardorgDialog = false;
  }

}
