import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomEvents } from '../events/customevents';
import { BaseCtrl } from '../base/BaseCtrl';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class JiraTicketServiceService extends BaseCtrl {
  config;
  unAuthorizedErrorListener: any;
  public defaultOrg = new BehaviorSubject<string>(sessionStorage.getItem('org'));

  constructor(private _http: HttpClient, private _customEvents: CustomEvents,
    private oidcSecurityService: OidcSecurityService) {
    super();
    this.config = JSON.parse(sessionStorage.getItem('config'));
  }

  public setDefaultOrg(value: string) {
    this.defaultOrg.next(value);
  }
  get DefaultOrg() {
    return this.defaultOrg.asObservable();
  }


  public requestpost(url: string, body: any) {
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this._http.post(url, body, { headers, withCredentials: true })
    .retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }

  public requestpostData(url: string, body: any) {
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this._http.post(url, body, { headers, withCredentials: true })
    .retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3) {
          return Observable.of(error.status).delay(5000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError1(error));
  }

  public validateJiraSession(url: string, body: any) {
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this._http.post(url, body, { headers, withCredentials: true }).toPromise()
  }
  public addComments(url: string, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    });
    return this._http.post(url, body, { headers, withCredentials: true }).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }

  public createTempFile(url: string, body: any) {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/octet-stream',
      'X-Atlassian-Token': 'no-check'
    });
    const jiraData = JSON.parse(sessionStorage.getItem('jiraData'));
    const params = new HttpParams()
      .set('filename', body.filename)
      .set('size', body.size).set('issueId', body.issueId).set('atl_token', jiraData['xsrfToken']);
    return this._http.post(url, body.file, { headers, withCredentials: true, params: params }).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }
  public createTempFileforTicket(url: string, body: any) {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/octet-stream',
      'X-Atlassian-Token': 'no-check'
    });
    const jiraData = JSON.parse(sessionStorage.getItem('jiraData'));
    const params = new HttpParams()
      .set('filename', body.filename)
      .set('size', body.size).set('projectId', body.projectId).set('atl_token', jiraData['xsrfToken']);
    return this._http.post(url, body.file, { headers, withCredentials: true, params: params }).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }
    public createRequestpost(url: string, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Atlassian-Token': 'no-check'
    });
    const body1 = new URLSearchParams();
    const fileoption = body['filetoconvert'];
    if (fileoption) {
      for (let i = 0; i < fileoption.length; i++) {
        body1.append('filetoconvert', fileoption[i]);
      }
    }
    for (let i = 0; i < body.length; i++) {
      const key = Object.keys(body[i]);
      const value = Object.values(body[i]);
      if (key[0].toString() === 'filetoconvert') {
        const finalValue = value[0] as any[];
        for (let j = 0; j < finalValue.length; j++) {
          body1.append('filetoconvert', value[0][j].toString());
        }
      } else {
        body1.set(key[0].toString(), value[0].toString());
      }
    }
    return this._http.post(url, body1.toString(), { headers, withCredentials: true, observe: 'response' })
      .retryWhen((errors: Observable<any>) => {
        let errorCount = 0;
        return errors.flatMap((error: any) => {
          if (errorCount++ < 3 && error.status >= 500) {
            return Observable.of(error.status).delay(1000);
          }
          return Observable.throw(error);
        });
      }).catch((error: any) =>  this.catchError(error));
  }
  public searchKBs(url: string, body: any) {
    const params = new HttpParams()
      .set('query', body.query).set('resultsPerPage', '5');
    return this._http.get(url, { withCredentials: true, params: params })
      .catch((error: any) => this.catchError(error));
  }
   // delete group

   public getNotification(url: string) {
        const jiraData = JSON.parse(sessionStorage.getItem('jiraData'));
        const params = new HttpParams();
      return this._http.get(url, { withCredentials: true})
        .catch((error: any) => this.catchError(error));
  }

  public setNotification(url: string) {
    const jiraData = JSON.parse(sessionStorage.getItem('jiraData'));
    const headers = new HttpHeaders({
      'atl_token':  jiraData['xsrfToken']
    });
  /*  const params = new HttpParams()
    .set('atl_token', jiraData['xsrfToken']);*/
  return this._http.put(url, {headers, withCredentials: true})
    .catch((error: any) => this.catchError(error));
}
public deleteNotification(url: string) {
  const jiraData = JSON.parse(sessionStorage.getItem('jiraData'));
  const headers = new HttpHeaders({
    'atl_token':  jiraData['xsrfToken']
  });
/*  const params = new HttpParams()
  .set('atl_token', jiraData['xsrfToken']);*/
return this._http.delete(url, {headers, withCredentials: true})
  .catch((error: any) => this.catchError(error));
}


  catchError(error: any): any {
    if (error.status === 401) {
      this.unAuthorizedErrorListener = this._customEvents.unAuthorizedError.emit({});
    } else {
      console.log('Cluster Load Data Error');
    }
  }

  catchError1(error: any): any {
    if (error.status === 401 || error.status === 403) {
    this.unAuthorizedErrorListener = this._customEvents.unAuthorizedError.emit({});
    } else {
      console.log('Cluster Load Data Error');
    }
  }

  catchError2(error: any): any {
      console.log('Cluster Load Data Error');
  }

}
