import { Message } from 'primeng/api';

export class MessageBaseCtrl {
  errorMessage = '';
  growlMessage: Message[] = [];
  createLoader = false;
  enableButton = true;
  enableDialogue = true;
  getGrowlMessage(severity: string, details: string){
    return  {severity: severity, summary: '',detail: details}
  }

  setGrowlMessage(severity: string, details: string){
    this.growlMessage = [];
    this.growlMessage.push(this.getGrowlMessage(severity, details));
  }
}
