import { Component, OnInit } from '@angular/core';
import { CustomEvents } from '../events/customevents';
import { Router, ActivatedRoute } from '@angular/router';
import { JiraTicketServiceService } from '../../app/services/jira-ticket-service.service';
import {GrafanaService} from '../dashboard/device/service/grafana.service';
import { promise } from 'selenium-webdriver';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  user: string;
  message: string;
  data;
  isJiraPermissionPresent;
  isGrafanaPremissionPrsent;
  showJira;
  checkingSession;
  checkingGrafanaSession;
  config;
  loginUrl: string;
  grafanalogoutUrl: string;
  jiraUrl: string;
  time = '';
  time1 = '';
  confluenceUrl: string;
  errorMsgForJirasession = [];
  constructor(private customEvents: CustomEvents, private _route: Router, private _httpjira: JiraTicketServiceService
    , private _grafanaService: GrafanaService) {
    this.message = '';
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.grafanalogoutUrl = this.config['grafanaUrl'] + 'logout';
    this.loginUrl = this.config['grafanaUrl'] + 'login/generic_oauth';
    this.jiraUrl = this.config['jiraLoginUrl'];
    this.showJira = true;
    this.confluenceUrl = this.config['confluenceLoginUrl'];
  }

  ngOnInit() {
    this.checkSession();
    this.validateGrafanaSession();
    if (sessionStorage.getItem('user').startsWith('{') && sessionStorage.getItem('org')) {
      this.customEvents.showHideLoader.emit({ show: true });
    } else {
      this.customEvents.showHideLoader.emit({ show: false });
      this.message = 'No organization is mapped. Please contact your admin !!';
    }
  }
  public checkSession() {
    let count = 0;
      this.checkingSession = setInterval(() => {
        count++;
        this.showJira = true;
        if (this.isJiraPermissionPresent) {
          this.errorMsgForJirasession = [];
          this.customEvents.showJiraOption.emit({ show: true });
          window.clearInterval(this.checkingSession);
        }
        this.time =  "#" + new Date().getTime();
        this.checkJiraSession();
        if (count > 10) {
          this.errorMsgForJirasession = [];
          this.errorMsgForJirasession.push({severity: 'info', summary: 'Jira Permission Issue',
          detail: 'Jira  not configured correctly.'});
          window.clearInterval(this.checkingSession);
        }
      }, 4000);
    }

    checkJiraSession() {
      const user = JSON.parse(sessionStorage.getItem('user'));
      const portalId = user['portalId'];
      const bodyreq = {
        'models': ['user', 'organisations', 'sharedPortal', 'helpCenterBranding', 'portal', 'portalWebFragments'],
        'options': {
          'portalId': portalId, 'portal': { 'id': portalId, 'expand': ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'] },
          'portalWebFragments': { 'portalPage': 'PORTAL' }
        }
      };
      this.config = JSON.parse(sessionStorage.getItem('config'));
      const getReqType = this.config['jiraUrl'] + '/api/rest/servicedesk/1/customer/models?_' + new Date().getTime();
      this._httpjira.validateJiraSession(getReqType, bodyreq).then((rest: any) => {
        sessionStorage.setItem('jiraData',  JSON.stringify(rest));
        if (rest['user']['userName'] === user['username']) {
          this.isJiraPermissionPresent = true;
        } else {
          this.isJiraPermissionPresent = false;
        }
        sessionStorage.setItem('JiraPresent', this.isJiraPermissionPresent.toString());
      }
      ).catch(error => this.time = '#' + new Date().getTime());
    }

 validateGrafanaSession() {
  let count = 0;
  this.checkingGrafanaSession = setInterval(() => {
    count++;
    if (this.isGrafanaPremissionPrsent) {
      sessionStorage.setItem('grafanaPresent', 'true');
      this.customEvents.showGrafanaOption.emit({ show: true });
      window.clearInterval(this.checkingGrafanaSession);
    }
    this.time1 =  '#' + new Date().getTime();
    this.checkGrafanaSession();
    if (count > 10) {
      sessionStorage.setItem('grafanaPresent', 'false');
      this.errorMsgForJirasession = [];
      window.clearInterval(this.checkingGrafanaSession);
    }
  }, 3000);
 }
 checkGrafanaSession() {
  const user = JSON.parse(sessionStorage.getItem('user'));
  this._grafanaService.validateGrafanaSessionDashboard(user['grafanaDefault']).then((rest: any) => {
    if (rest.status === 200) {
      sessionStorage.setItem('grafana-token', rest.headers.get('grafana-token'));
      this.isGrafanaPremissionPrsent = true;
    }
  }).catch(error => this.renewGrafanaSession());

 }
 renewGrafanaSession() {
  this.isGrafanaPremissionPrsent = false;
  this._grafanaService.getGrafanaTokenDashboard().then((rest: any) => {
    if (rest.status === 200) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      this._grafanaService.validateGrafanaSessionDashboard(user['grafanaDefault']).then((rest1: any) => {
        if (rest1.status === 200) {
          sessionStorage.setItem('grafana-token', rest1.headers.get('grafana-token'));
          this.isGrafanaPremissionPrsent = true;
        }
      });
    }
  });
 }
}
