import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {CustomEvents} from '../../events/customevents';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'app-soc',
  templateUrl: './soc.component.html',
  styleUrls: ['./soc.component.scss']
})
export class SocComponent implements OnInit {
  org: string;
  servicerequestDialog = false;
  isPermission;
  msgs: Message[] = [];
  constructor(private route: ActivatedRoute, private _customEvents: CustomEvents) {
    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
  });
  
  const user = JSON.parse(sessionStorage.getItem('user'));
  user['orgs'].forEach(element => {
    if (element.key === this.org) {
     this.isPermission = element.permissions;
    }
  });
 }
  ngOnInit() {
    this._customEvents.showHideLoader.emit({ show: false });
  }
  showDialog() {
    if (this.servicerequestDialog === false) {
     this.servicerequestDialog = true;
    } else {
      this.servicerequestDialog = false;
    }
}
}
