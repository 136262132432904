import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppsensorServiceComponent} from './appsensor-service.component';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
 
@NgModule({
  imports: [
    CommonModule,
    GridTableModule,
    TooltipModule,
    DialogModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppsensorServiceComponent],
  exports: [AppsensorServiceComponent]
})
export class AppsensorServiceModule { }
