import { Component, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CustomEvents } from '../events/customevents';
import { AuthenticateService } from '../session/authenticate.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { PortalHttpServiceService } from '../services/portal-http-service.service';
import { DOCUMENT } from '@angular/common';
import {Inject } from '@angular/core';



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  selectedItem;
  selectedOrg;
  selectedSubitem;
  sidebargroup: any[];
  isAuth$: Observable<boolean>;
  externalLinks: any[];
  selectedOrg$: Observable<string>;
  user;
  eapIndex;
  adminIndex;

  constructor(private _route: Router, private _customEvents: CustomEvents, private _portalHttp: PortalHttpServiceService,
    private _authservice: AuthenticateService, @Inject(DOCUMENT) private _document: Document) {
    this.selectedItem = '';
    this.selectedOrg = '';
    this.selectedSubitem = '';
    this.sidebargroup = [];
    this.eapIndex = -1;
    this.adminIndex = -1;
    this.user = {};
    this._route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let page = event.urlAfterRedirects.split('?');
        (<any>window).ga('set', 'dimension1', sessionStorage.getItem('username'));
        (<any>window).ga('set', 'dimension2',  sessionStorage.getItem('selctedorg'));
        (<any>window).ga('set', 'page', page[0]);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

  ngOnInit() {
    this.isAuth$ = this._authservice.isLoggedIn;
    this._authservice.isLoggedIn.subscribe((res) => {
      const values = Object.keys(this.user);
      if (res && values.length === 0) {
        this.user = JSON.parse(sessionStorage.getItem('user'));
        const sidebarlength = this.user['sidebars'].length;
        this.sidebargroup = this.user['sidebars'];
        this.sidebargroup.splice(sidebarlength - 2, 1);
        this.selectedOrg = sessionStorage.getItem('org');
        this.sidebargroup.forEach((element) => {
          if (element['Description']  === 'EAP') {
            this.eapIndex = element.sequence - 1;
          }
          if (element['Description'] === 'Admin Settings') {
            this.adminIndex = element.sequence - 1;
          }
        });
        this.user['orgs'].forEach(element => {
          if (element.key === this.selectedOrg) {
            this.sidebargroup[0]['submenu'] = element.dashboardMenu;
            if (this.adminIndex !== -1) {
              this.sidebargroup[this.adminIndex]['submenu'] = element.settingsMenu;
            }
            if (this.eapIndex !== -1) {
              this.sidebargroup[this.eapIndex]['submenu'] = element.eapMenu;
            }
            this.externalLinks = [{
              name : '',
              submenu: []
            }
            ];
            this.externalLinks = [{
              name: 'External App',
              submenu: element.externalMenu
            }];
           // console.log(element.externalMenu);
           // this.externalLinks[0]['submenu'] = element.externalMenu;
          }
        });
        this.sidebargroup[1]['submenu'] = this.user['orgs'];
        if (this.sidebargroup[0]) {
          this.selectedItem = this.sidebargroup[0]['Description'];
          if (this.sidebargroup[0]['submenu'][0]) {
            this.selectedSubitem = this.sidebargroup[0]['submenu'][0]['name'];
          }
        }
      }
    });

    this._portalHttp.DefaultOrg.subscribe((org) => {
      this.selectedOrg = org;
      const values = Object.keys(this.user);
      if (values.length > 0) {
        this.changeOrg(this.selectedOrg, 'profile');
      }
    });
  }

  listClick(event, item) {
    if (item.Description === 'Logout') {
      this.isAuth$ = new BehaviorSubject<boolean>(false);
      this._customEvents.logoutEvt.emit();
    } else if (item.Description === 'Configuration') {
      this._route.navigate(['configuration']);
      this.selectedItem = 'Configuration';
    } else if (item.Description === 'IP SLA') {
       this._route.navigate(['/ip-sla']);
    }
  }

  changeOrg(item, parentName) {
    this.selectedOrg = item;
    this.user['orgs'].forEach(element => {
      if (element.key === this.selectedOrg) {
        sessionStorage.setItem('selctedorg', element.name);
        this.sidebargroup[0]['submenu'] = element.dashboardMenu;
        if (this.eapIndex !== -1) {
          this.sidebargroup[this.eapIndex]['submenu'] = element.eapMenu;
        }
        this.externalLinks = [{
          name : '',
          submenu: []
        }
        ];
        this.externalLinks = [{
          name: 'External App',
          submenu: element.externalMenu
        }];
       // this.externalLinks[0]['submenu'] = element.externalMenu;
      }
    });
    if (parentName === 'Organization') {
      this._portalHttp.setDefaultOrg(this.selectedOrg);
      sessionStorage.setItem('org', this.selectedOrg);
      this.selectedItem = 'Dashboard';
      for (let i of this.sidebargroup[0]['submenu']) {
        if (i.name === item) {
          this._route.navigate([i['value']], { queryParams: { org: item.toUpperCase() } });
        } else {
         // this._route.navigate([this.sidebargroup[0]['submenu'][0]['value']], { queryParams: { org: item } });
         // this._document.location.reload();
          window.location.href = this.sidebargroup[0]['submenu'][0]['value'] + '?org=' + item ;
        }
      }
    }
    const headers = 'Events';
    this._customEvents.displayRowWiseEvt.emit({ data: '', header: headers, isContact: false });
  }

  externalLinkClick(item) {
    window.open(item.value);
  }


}
