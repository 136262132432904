<div *ngIf="selectedType =='customerEmployeeGrid'">
    <span class="screen-header" style="text-align:left;color:#000;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_grid_switch') ==-1 "><b>Customer Employee</b></span>
    <p-selectButton [options]="types" [(ngModel)]="selectedType" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_grid_switch') !=-1 " ></p-selectButton>
   <div style="float:right;">
    <p-button  icon="fa fa-refresh"  [style]="{'float':'right','margin-bottom':'1.5%','font-size':'10px'}"  pTooltip="Data Sync" tooltipPosition="top" iconPos="left" (click)="onReloadEmployeeList()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_employee_datasync') !=-1 "></p-button>
</div>
    <app-grid-table  [reload]="customerEmployeGrid.reloadEvent" [popup]="false" id="gridwithsmallwidth" [totalRecords]="customerEmployeGrid.totalRecords" [selectedColumns]="customerEmployeGrid.selectedColumns"
  [cols]="customerEmployeGrid.cols" height="248px" [colData]="customerEmployeGrid.colData" [org]="org" [dataUrl]="customerEmployeGridUrl" (update)="loadGrid($event)"
  (rowSelect)="rowSelect($event)" [to]="0"></app-grid-table>
</div>
<div *ngIf="selectedType =='lcon'">
   <p-selectButton [options]="types" [(ngModel)]="selectedType" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_grid_switch') !=-1 "></p-selectButton>
   <div style="float:right;">
     <p-button  icon="fa fa-plus"  [style]="{'float':'right','font-size':'10px'}"  pTooltip="Add Employee" tooltipPosition="top" iconPos="left" (click)="addEmployee()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_create_or_update') !=-1 "></p-button>
   </div>
   <app-grid-table  [reload]="customerEmployeGrid.reloadEvent" [popup]="false" id="gridwithsmallwidth" [totalRecords]="customerEmployeGrid.totalRecords" [selectedColumns]="customerEmployeGrid.selectedColumns"
    [cols]="customerEmployeGrid.cols" height="248px" [colData]="customerEmployeGrid.colData" [org]="org" [dataUrl]="customerlconUrl" (update)="loadGrid($event)"
    (rowSelect)="lconrowSelect($event)" [to]="0"></app-grid-table>
 </div>

<p-dialog header="Employee Detail" *ngIf="customerEmployeeDialog" [focusOnShow]='false' [(visible)]="customerEmployeeDialog" [modal]="true"
[responsive]="true" showEffect="fade" height='auto' [width]="600">
<div class="row">
    <div class="col-md-9 offset-md-9">
            <div>
               <label for="phn">First Name :</label>
                <span> {{employeeDetail.FirstName? employeeDetail.FirstName:'NA'}}</span>
           </div>
           <div>
              <label for="phn">Last Name :</label>
               <span> {{employeeDetail.LastName? employeeDetail.LastName:'NA'}}</span>
            </div>
            <div>
              <label for="phn">Email :</label>
              <span> {{employeeDetail.Email? employeeDetail.Email:'NA'}}</span>
            </div>
            <div>
                <label for="phn">Phone :</label>
                <span> {{employeeDetail.Phone? employeeDetail.Phone:'NA'}}</span>
             </div>
             <div>
               <label for="phn">Mobile :</label>
               <span> {{employeeDetail.Mobile? employeeDetail.Mobile:'NA'}}</span>
             </div>
    </div>
</div>
</p-dialog>

<p-dialog header="Add LCON" *ngIf="addcustomerEmployeeDialog" [focusOnShow]='false' [(visible)]="addcustomerEmployeeDialog" [modal]="true"
[responsive]="true" showEffect="fade" height='auto' [width]="620">
<div class="row">
    <div class="col-md-9 offset-md-9">
        <form [formGroup]="createEmployeeForm" (ngSubmit)="onSubmit()" id="creteEmpolyeeformid">
            <div class="form-group">
                <label>First Name</label>
                <input type="text" formControlName="firstName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && createEmployeeForm.controls.firstName.errors }" />
                <div *ngIf="submitted && createEmployeeForm.controls.firstName.errors"
                    class="invalid-feedback">
                    <div *ngIf="createEmployeeForm.controls.firstName.errors.required">First Name is
                        required</div>
                </div>
            </div>
            <div class="form-group">
                <label>Last Name</label>
                <input type="text" formControlName="lastName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && createEmployeeForm.controls.lastName.errors }" />
                <div *ngIf="submitted && createEmployeeForm.controls.lastName.errors"
                    class="invalid-feedback">
                    <div *ngIf="createEmployeeForm.controls.lastName.errors.required">Last Name is required
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Phone No</label>
                <input type="text" formControlName="phoneno" class="form-control" (keypress)="emailvalidate($event)"
                    [ngClass]="{ 'is-invalid': submitted && createEmployeeForm.controls.phoneno.errors }" />
                <div *ngIf="submitted && createEmployeeForm.controls.phoneno.errors"
                    class="invalid-feedback">
                    <div *ngIf="createEmployeeForm.controls.phoneno.errors.required">Phone No is required
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Mobile No</label>
                <input type="text" formControlName="mobileno" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && createEmployeeForm.controls.mobileno.errors }" />
                <div *ngIf="submitted && createEmployeeForm.controls.mobileno.errors"
                    class="invalid-feedback">
                    <div *ngIf="createEmployeeForm.controls.mobileno.errors.required">Phone No is required
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="text" formControlName="email" class="form-control" (keypress)="emailvalidate($event)" 
                    [ngClass]="{ 'is-invalid': submitted && createEmployeeForm.controls.email.errors }" />
                <div *ngIf="submitted && createEmployeeForm.controls.email.errors" class="invalid-feedback">
                    <div *ngIf="createEmployeeForm.controls.email.errors.required">Email is required</div>
                    <div *ngIf="createEmployeeForm.controls.email.errors.pattern">Email must be a valid email
                        address</div>
                </div>
            </div>
            <div class="form-group">
                <label>Other</label>
                <input type="text" formControlName="other" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && createEmployeeForm.controls.other.errors }" />
                <div *ngIf="submitted && createEmployeeForm.controls.other.errors" class="invalid-feedback">
                    <div *ngIf="createEmployeeForm.controls.other.errors.required">Other is required</div>
                   
                </div>
            </div>
        </form>
    </div>
</div>
<p-footer>
    <button type="submit" form="creteEmpolyeeformid" class="btn btn-primary">Add LCON</button>
    <button class="btn btn-primary" (click) ="cancelCreateReq()">Cancel</button>
</p-footer>
</p-dialog>

<p-dialog header="Update LCON" *ngIf="updateLconDialog" [focusOnShow]='false' [(visible)]="updateLconDialog" [modal]="true"
[responsive]="true" showEffect="fade" height='auto' [width]="600">
<div class="row">
    <div class="col-md-9 offset-md-9" *ngIf="editForm==false">
            <div>
               <label for="phn">First Name :</label>
               <span> {{employeeDetail.FirstName? employeeDetail.FirstName:'NA'}}</span>
               <label class="editIcon" (click)="updatelcon(employeeDetail)" title="Edit Details" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_lcon_create_or_update') !=-1 "><i class="far fa-edit"></i></label>
           </div>
           <div>
              <label for="phn">Last Name :</label>
               <span> {{employeeDetail.LastName? employeeDetail.LastName:'NA'}}</span>
            </div>
            <div>
              <label for="phn">Email :</label>
              <span> {{employeeDetail.Email? employeeDetail.Email:'NA'}}</span>
            </div>
            <div>
                <label for="phn">Phone :</label>
                <span> {{employeeDetail.Phone? employeeDetail.Phone:'NA'}}</span>
             </div>
             <div>
               <label for="phn">Mobile :</label>
               <span> {{employeeDetail.Mobile? employeeDetail.Mobile:'NA'}}</span>
             </div>
             <div>
                <label for="phn">Other :</label>
                <span> {{employeeDetail.other_details? employeeDetail.other_details:'NA'}}</span>
              </div>
    </div>
    
</div>
<div class="row" *ngIf="editForm==true">
    <div class="col-md-9 offset-md-9" >
          <div style="margin-bottom: 2%;">
              <label for="phn">First Name :</label>
              <input class="email" type="text" pInputText [(ngModel)]="userfirstname" style="width:50%;"
               />
          </div>
          <div style="margin-bottom: 2%;">
             <label for="phn">Last Name :</label>
             <input class="email" type="text" pInputText [(ngModel)]="userlastname" style="width:50%;"
              />
           </div>
           <div style="margin-bottom: 2%;">
             <label for="phn">Email :</label>
             <input class="email" type="text"  (keypress)="emailvalidate($event)" pInputText [(ngModel)]="useremail" style="width:50%;margin-left: 8%;"
              />
              <label id="emailerror" style="margin-left: 8%;display:none;color:red;">Invalid Email Id</label>
           </div>
           <div style="margin-bottom: 2%;">
               <label for="phn">Phone :</label>
               <input class="email" type="text" (keypress)="emailvalidate($event)" pInputText [(ngModel)]="userphone" style="width:50%;margin-left: 7%;"
                />
            </div>
            <div style="margin-bottom: 2%;">
              <label for="phn">Mobile :</label>
              <input class="email" type="text" pInputText [(ngModel)]="usermobile" style="width:50%;margin-left: 7%;"
               />
            </div>
            <div style="margin-bottom: 2%;">
               <label for="phn">Other :</label>
               <input class="email" type="text" pInputText [(ngModel)]="userother" style="width:50%;margin-left: 8%;"
                />
             </div>
      </div>
</div>
<p-footer *ngIf="editForm==true">
    <button type="submit" class="btn btn-primary" (click)="updateLcon()">Update LCON</button>
    <button type="submit" class="btn btn-primary" (click)="deleteLcon()">Delete LCON</button>
    <button class="btn btn-primary" (click) ="cancelupdateReq()">Cancel</button>
</p-footer>
</p-dialog>
<p-growl [autoZIndex] ="true" *ngIf="createReqerrormsg.length!==0" [(value)]="createReqerrormsg"></p-growl>