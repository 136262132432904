import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {JiraComponent  } from './jira.component';
import { SafePipeModule } from '../../utils/safe.pipe';
import { JiraTicketsModule } from './jira-tickets/jira-tickets.module';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ProgressBarModule} from 'primeng/progressbar';
import { JiraCommentModule } from './jira-tickets/jira-comment/jira-comment.module';

@NgModule({
  imports: [
    CommonModule,
    SafePipeModule,
    JiraTicketsModule,
    MessagesModule,
    MessageModule,
    ProgressBarModule
  ],
  declarations: [JiraComponent],
  exports: [JiraComponent]
})
export class JiraModule { }
