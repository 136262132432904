import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnomalydetectionComponent } from './anomalydetection/anomalydetection.component';
import { AnalyticsComponent } from './analytics.component';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { AnalyticsService } from './service/analytics.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/primeng';
import { SafePipeModule } from '../utils/safe.pipe';
import { GridTableModule } from '../grid-table/grid-table.module';
import { AnomalyResultComponent } from './anomaly-result/anomaly-result.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { GraphContainerModule } from '../graph-container/graph-container.module';
import {MultiSelectModule} from 'primeng/multiselect';
import { GraphModule } from '../graph/graph.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {GrowlModule} from 'primeng/growl';
@NgModule({
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    ReactiveFormsModule,
    CalendarModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    TableModule,
    TooltipModule,
    SafePipeModule,
    GridTableModule,
    DatePickerModule,
    GraphContainerModule,
    MultiSelectModule,
    GraphModule,
    ConfirmDialogModule,
    GrowlModule
  ],
  declarations: [AnalyticsComponent, AnomalydetectionComponent, AnomalyResultComponent],
  providers: [AnalyticsService,ConfirmationService]
})
export class AnalyticsModule { }
