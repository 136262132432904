import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataViewModule } from 'primeng/dataview';
import { ControlStatusComponent } from './control-status.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { ControlStatusService } from './control-status.service';
import { DialogModule } from 'primeng/dialog';
import { GridTableModule } from '../../../grid-table/grid-table.module';

@NgModule({
  imports: [
    CommonModule,
    DataViewModule,
    FormsModule,
    ButtonModule,
    PanelModule,
    DialogModule,
    GridTableModule
  ],
  declarations: [ControlStatusComponent],
  providers: [ControlStatusService],
  exports: [ControlStatusComponent]
})
export class ControlStatusModule { }
