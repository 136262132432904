import { CommonModule } from '@angular/common';  
import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { LoaderComponent } from './loader.component';
@NgModule({
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [LoaderComponent],
  exports: [LoaderComponent]
})

export class LoaderModule { }


