import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseCtrl } from '../../../base/BaseCtrl';
import { Observable } from 'rxjs';

@Injectable()
export class GrafanaService extends BaseCtrl {
  config;
  constructor(private _http: HttpClient) {
    super();
    this.config = JSON.parse(sessionStorage.getItem('config'));
  }
  getGrafanaTokenDashboard() {
    const url = this.config['grafanaUrl'] + '/api/login/ping';
    const headers = new HttpHeaders({ 'Accept': 'application/json'});
    return this._http.get<any>(
      url,  { headers, withCredentials: true, observe: 'response' }).toPromise();
  }
  validateGrafanaSessionDashboard(grafanaId) {
    const url = this.config['grafanaUrl'] + '/api/org';
    const headers = new HttpHeaders({ 'Accept': 'application/json', 'X-Grafana-Org-Id': grafanaId});
    return this._http.get<any>(
      url,  { headers, withCredentials: true, observe: 'response' }).toPromise();
  }
}
