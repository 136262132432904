<p-growl [(value)]="msgs" life="3000"></p-growl>
<p-table *ngIf="errormsg.length===0" [id]="id" [columns]="selectedColumns" [value]="colData" #dashboardtable selectionMode="single"
    (onRowSelect)="onRowSelect($event)" [scrollable]="true" [scrollHeight]="height" [resizableColumns]="true" [loading]="loading"
    loadingIcon="fa fa-spinner" [lazy]="true" (onLazyLoad)="loadDataLazy($event)" [paginator]="paginator" pageLinks="3" [rows]="rows"
    [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]" [responsive]="true" [reorderableColumns]="true" columnResizeMode="expand">

    <ng-template pTemplate="caption">
        <div class="popUpHeader">
            <div *ngIf="clearSearch" class="search" [ngStyle]="(id =='gridwithautoheight')?{'visibility':'hidden'}:''" [ngClass]="(popup)?'searchinpopupheader':'searchinnormalheader'">
                <span *ngIf="isGlobalSearch">
                    <i class="fa fa-search"></i>
                    <input type="text" #dashfilter pInputText size="50" placeholder="Search" (input)="dashboardtable.filterGlobal($event.target.value, 'contains')">
                </span>
                <span *ngIf="isRowcount">
                    <b> Total {{totalRecords}} Rows</b>
                </span>
            </div>
            <div *ngIf="popup" class="popupmultiselect">
                <p-multiSelect *ngIf="clearSearch" maxSelectedLabels="0" [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                    id="graph" appendTo="body" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '170px'}" defaultLabel="Choose Columns"></p-multiSelect>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns">
        </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [style.width]="col.size" [ngClass]="(isHeaderWrap)?(popup)?'textwrapwithwidth':'textwrapheader':''"
                [pSortableColumn]="(col.sortable)?col.field:''" [ngStyle]="{'cursor':(col.sortable)?'pointer':'default'}" pResizableColumn
                pReorderableColumn>
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
            </th>
        </tr>

    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" style="text-indent: 5px" [ngStyle]="{'cursor':(id =='transport' && rowData.value == 0)?'default':'pointer'}">
            <td *ngFor="let col of columns;let isLast = last" [style.width]="col.size" [ngClass]="popup?'textwrapwithwidth':'textwrapbody'"
                class="ui-resizable-column" [pTooltip]="(col.subfield1)?((col.subfield2)? (rowData[col.field][col.subfield1][col.subfield2]):rowData[col.field][col.subfield1]):rowData[col.field]"
                tooltipPosition="top">
                <span class="ui-column-title">{{col.header}}</span>
                <span *ngIf="(col.field !== 'graph') && (col.header !== 'State') && (col.field !== 'icon')">
                    {{rowData[col.field]}}
                </span>
                <span *ngIf="col.header === 'State'">
                    <i [ngClass]="(rowData[col.field]==='reachable')?'fa fa-arrow-up':'fa fa-arrow-down'" aria-hidden="true"></i>
                </span>
                <span *ngIf="col.field === 'graph'">
                    <i (click)="onShowGraph($event,rowData)" [ngClass]="(rowData.graph === 'Graph')?'fa fa-line-chart':'fa fa-ban'" aria-hidden="true" style="text-indent: 1px;"></i>
                </span>
                <span *ngIf="col.field === 'icon'" pTooltip="Contact" tooltipPosition="top">
                    <i (click)="onShowContact($event,rowData)" class="fa fa-address-card-o" aria-hidden="true"></i>
                </span>
                <span *ngIf="col.field === 'jira'" pTooltip="Create Ticket" tooltipPosition="top">
                        <i (click)="onShowCreateTicket($event,rowData)" class="fa fa-list-ul" aria-hidden="true" style="text-indent: 1px;"></i>
                </span>
                <span *ngIf="col.field === 'createUser'" pTooltip="Create user" tooltipPosition="top">
                        <i (click)="onShowonCreateUser($event,rowData)" class="fas fa fa-plus" aria-hidden="true"></i>
                </span>
            </td>

        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                No records found
            </td>
        </tr>
    </ng-template>
</p-table>

<p-messages *ngIf="errormsg.length!==0" [(value)]="errormsg" [closable]="false"></p-messages>