import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import {GrowlModule} from 'primeng/growl';
import { AuthenticateService } from '../../../session/authenticate.service';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import {ButtonModule} from 'primeng/button';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import {InputTextModule} from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import {CustmerEmplyeeComponent} from '../customer-employee-grid/custmer-emplyee.component';
import {SelectButtonModule} from 'primeng/selectbutton';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    GrowlModule,
    ButtonModule,
    GridTableModule,
    InputTextModule,
    TooltipModule,
    ReactiveFormsModule,
    SelectButtonModule
  ],
  declarations: [CustmerEmplyeeComponent],
  exports: [CustmerEmplyeeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerEmployeeModule { }
