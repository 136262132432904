import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomerCircuiteMapComponent} from './customer-circuite-map.component';
import {ButtonModule} from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule
  ],
  declarations: [CustomerCircuiteMapComponent],
  exports: [CustomerCircuiteMapComponent]
})
export class CustomerCircuiteMapModule { }
