<div class="col-md-2 statisticsSummary" *ngFor="let device of devices">
    <span class="one">
        <img src="{{device.img}}" pTooltip="{{device.name | lowercase}}" />
    </span>
    <span class="two">
        <span>
            <span [ngStyle]="(device.up!= 0)?{'cursor':'pointer'}:{'cursor':'default'}" (click)="(device.up!= 0)?deviceStatuspopUp(device.name,'REACHABLE'):''">
                <label class="text">{{device.up}}</label>
                <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </span>
            <span [ngStyle]="device.down!=0?{'cursor':'pointer'}:{'cursor':'default'}" (click)="(device.down!= 0)?deviceStatuspopUp(device.name,'UNREACHABLE'):''">
                <label class="text">{{device.down}}</label>
                <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </span>
        </span>
        <label class="center">{{device.name | lowercase}}  ({{device.up + device.down}})</label>
    </span>
</div>

<p-dialog *ngIf="isPopup" [header]="popupHeader| titlecase" [(visible)]="isPopup" [modal]="true"
  showEffect="fade" [responsive]="true" styleClass="gridactive" resizable="true">
  <app-grid-table [popup]="true" [totalRecords]="deviceStatusPopup.totalRecords" [selectedColumns]="deviceStatusPopup.selectedColumns"
    [cols]="deviceStatusPopup.cols" [colData]="deviceStatusPopup.colData" [org]="org" [to]="0" [dataUrl]="dataUrl" (update)="loadPopupGrid($event)">
  </app-grid-table>
</p-dialog>