import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatePickerModule } from '../../../date-picker/date-picker.module';
import { DialogModule } from 'primeng/dialog';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { AuditGridComponent } from './audit-grid.component';
import { GraphContainerModule } from '../../../graph-container/graph-container.module';
@NgModule({
  imports: [
    CommonModule ,
    DatePickerModule,
    DialogModule,
    GridTableModule,
    GraphContainerModule
  ],
  declarations: [AuditGridComponent],
  exports: [AuditGridComponent]
})

export class AuditGridModule { }
