import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CustomEvents } from '../events/customevents';
import { MapServiceService } from './map-service.service';

import * as $ from 'jquery';
import * as L from 'leaflet';
import 'leaflet.markercluster';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, OnDestroy {
  mapListener;
  map = null;
  data;
  dataset;
  limit;
  offset;
  @Input() org;

  constructor(private _customEvents: CustomEvents, private service: MapServiceService) {
    this.limit = 100;
    this.offset = 0;
    this.data = [];
    this.dataset = [];
  }

  ngOnInit() {
    this.setMap();
    this.mapListener = this.service.getMapData(this.limit, this.offset, this.org).subscribe(value => {
      this.dataset = value['data'];
      if (value['total'] > this.limit) {
        this.offset = 1;
        this.service.getMapData(this.limit, this.offset, this.org).subscribe(value => {
          this.dataset = this.dataset.concat(value['data']);
          this.loadMap(this.dataset);
        },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.loadMap(this.dataset);
      }

    }, (error) => {
      console.log(error);
    });
  }

  // initialization of map
  setMap() {
    let minZoom;
    if (window.screen.width <= 425) {
      minZoom = 1;
    } else {
      minZoom = 2;
    }
    const fistLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: minZoom, maxZoom: 13 });
    this.map = L.map('map', {
      zoom: 4,
      scrollWheelZoom: false,
      center: [30, -100],
      worldCopyJump: true,
      layers: [fistLayer]
    });
    if (window.screen.width <= 425) {
      this.map.fitWorld();
    }

  }

  // called from parent with cluster values
  loadMap(addressPoints) {
    this.data = addressPoints;
    // should remove the map from UI and clean the inner children of DOM element
    if (this.map) {
      this.map.remove();
      this.setMap();
    }

    if (addressPoints.length > 0) {

      const mcg = L.markerClusterGroup({
        chunkedLoading: true,
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false
      });


      // reset position of icons
      L.marker.prototype.update = function () {
        if (this._icon) {
          const pos = this._map.latLngToLayerPoint(this._latlng).round();
          this._setPos(pos);
        }
      };


      // trigger event to display graph
      const displayGraph = ((value) => {
        const graphName = value[0];
        const id = value[3];
        const type = 'vedge';
        this._customEvents.displayGraph.emit({ name: graphName, id: id, type: type });
      });

      // trigger event to display details
      const displayDetails = ((value) => {
        let obj = {
          'host-name': value[0],
          'site-id': value[1],
          'deviceId': value[3]
        };
        this.data.forEach(element => {
          if (element.deviceId === obj.deviceId) {
            obj = element;
          }
        });
        const headers = 'Net Status : ';
        this._customEvents.displayRowWiseEvt.emit({ data: obj, header: headers, isGraph: true });
      });

      // get the data from each popUp of marker
      const getPopUpData = (() => {
        const rowInfo = [];
        $('.mytable').find('tr').each(function () {
          $(this).children('td:last').each(function () {
            rowInfo.push($(this).text());
          });
        });
        return rowInfo;
      });

      // loop throgh devices to set marker
      for (const item of addressPoints) {
        // create marker
        const marker = L.marker(new L.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)), {
          icon: L.icon({
            iconUrl: this.setIcons(item['device-type']),
            iconAnchor: [30, 35],
            iconSize: [50, 50]
          })
        });

        // create popUp for each marker
        const container = $('<div />');
        let popUpHtml = '<table class="mytable" style="width:100%">' +
          '<tr>' +
          '<th>Host Name </th>' +
          '<td><b>:</b></td>' +
          '<td style="word-break:break-all">' + item['host-name'] + '</td>' +
          '</tr>' +
          '<tr>' +
          '<th >Site Id </th>' +
          '<td><b>:</b></td>' +
          '<td>';
        if (item['site-id']) {
          popUpHtml += item['site-id'];
        } else {
          popUpHtml += ' N/A';
        }
        popUpHtml += '</td>' +
          '</tr>' +
          '<tr>' +
          '<th >Reachability </th>' +
          '<td><b>:</b></td>';
        if (item['reachability'] === 'reachable') {
          popUpHtml += '<td><i class="fa fa-arrow-up" style="color: #4caf50" aria-hidden="true"></i></td>';
        } else {
          popUpHtml += '<td><i class="fa fa-arrow-down" style="color: #ef5350" aria-hidden="true"></i></td>';
        }
        popUpHtml += '</tr>' +
          '<tr style="display:none">' +
          '<th >Device Id </th>' +
          '<td><b>:</b></td>' +
          '<td>' + item['deviceId'] + '</td>' +
          '</tr>' +
          '</table>' +
          '<div class="row">' +
          '<div class="col-xs-6">' +
          '<a  class="details">' +
          'Device Info ' +
          '</a>' +
          '</div>';
        if (item['device-type'] === 'vedge') {
          popUpHtml += '<div class="col-xs-6"><a class="graph" id="content">Graph</a></div></div>';
        } else {
          popUpHtml += '</div>';
        }
        container.html(popUpHtml);

        // handle click on graph link
        container.on('click', '.graph', function () {
          const data = getPopUpData();
          displayGraph(data);
        });

        // handle click on details link
        container.on('click', '.details', function () {
          const data = getPopUpData();
          displayDetails(data);
        });

        // handles click on popPu
        marker.bindPopup(container[0]);

        // displays tooltip for markers
        let html = popUpHtml.substring(0, popUpHtml.lastIndexOf('</tr'));
        const type = '<tr>' +
          '<th>Device Type </th>' +
          '<td><b>:</b></td>' +
          '<td style="word-break:break-all">' + item['device-type'] + '</tr>' +
          '</tr></table>';
        html += type;
        marker.bindTooltip(html);

        // for mobile devices on click show only popup hide tooltip
        marker.on('click', function () {
          marker.closeTooltip();
        });

        mcg.addLayer(marker);

        // on clucster click, get the cluster into center of screen
        mcg.on('clusterclick', function (a) {
          a['layer'].zoomToBounds({ padding: [20, 20] });
          a['layer'].unbindPopup();
        });
      }

      // to display cluster details on hover
      mcg.on('clustermouseover', function (a) {
        if (!a.target._spiderfied) {
          const markers = a['layer'].getAllChildMarkers();
          let content = '<table style="width:100%">';
          const obj = {};
          for (let i = 0; i < markers.length; i++) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = markers[i]['_tooltip']._content;
            const type = tempDiv.children[0].lastChild.lastChild.lastChild.textContent;
            const reachability = markers[i]['_popup']._content.children[0].children[0].childNodes[2].lastElementChild.innerHTML;
            if (!obj.hasOwnProperty(type)) {
              obj[type] = {
                'up': 0,
                'down': 0
              };
            }
            if (reachability.includes('down')) {
              obj[type].down = obj[type].down + 1;
            } else {
              obj[type].up = obj[type].up + 1;
            }
          }

          for (const row of Object.entries(obj)) {
            content += '<tr><td>' + row[0] + '</td><td>'
              + row[1]['up'] + '<i class="fa fa-arrow-up" aria-hidden="true" style="color: #4caf50"></i></td><td>' +
              row[1]['down'] + '<i class="fa fa-arrow-down" aria-hidden="true"  style="color: #ef5350"></i></td></tr>';
          }
          content += '</table>';
          a['layer'].bindPopup(content).openPopup();
        }
      });

      // to display cluster details on hover
      mcg.on('clustermouseout', function (a) {
        a['layer'].closePopup();
      });

      this.map.addLayer(mcg);

      // zooming on mouse scroll is enables only after map click
      this.map.on('click', function (e) {
        e.target.scrollWheelZoom.enable();
      });

      // on mouse out mouse scroll is disabled
      this.map.on('mouseout', function (e) {
        e.target.scrollWheelZoom.disable();
      });

    }

  }

  // setting different icons based on device type for net status
  setIcons(value) {
    let url;
    switch (value) {
      case 'vedge':
        url = 'assets/vEdge_marker.png';
        break;
      case 'vbond':
        url = 'assets/vBond_marker.png';
        break;
      case 'vsmart':
        url = 'assets/vSmart_marker.png';
        break;
      case 'vmanage':
        url = 'assets/vManage_marker.png';
        break;
      default:
        url = 'assets/marker-icon.png';
        break;
    }
    return url;
  }

  // destroy map event listener
  ngOnDestroy() {
    if (this.mapListener) {
      this.mapListener.unsubscribe();
    }
  }
}

