<div style="height:30px">
  <b>Net Status</b>
  <a herf="#" (click)="expandView()" style="float: right">
    <i class="fa fa-th-large" aria-hidden="true" pTooltip="Expand" tooltipPosition="left"></i>
  </a>
</div>
<app-grid-table [popup]="false" id="gridwithsmallwidth" [totalRecords]="deviceGrid.totalRecords" [selectedColumns]="deviceGrid.selectedColumns"
  [cols]="deviceGrid.cols" height="248px" [colData]="deviceGrid.colData" [org]="org" [dataUrl]="dataUrl" (update)="loadGrid($event)"
  (rowSelect)="rowSelect($event)" (showGraph)="openGraphContainer($event)" [to]="0">
</app-grid-table>

<p-dialog *ngIf="isRowSelect" [header]="title" id="devicerowpopup" [(visible)]="isRowSelect" [modal]="true" styleClass="gridactive" showEffect="fade"
  resizable="true" (onHide)="hideDialog()">
  <div>
    <div class="row">
      <div class="col-sm-12 outerSpace" [ngStyle]="(counts.rebootCount!= 0)?{'cursor':'pointer'}:{'cursor':'default'}">
        <div class="col-sm-6  popUpcards space" (click)="(counts.rebootCount!= 0)?disp = !disp :''">
          <div class="col-sm-12 popUpcards" [pTooltip]="'Reboot'" tooltipPosition="top">
            <i class="fa fa-power-off rightSpace" aria-hidden="true"></i>
            Reboot
            <span class="rightAlign" *ngIf="(counts.rebootCount>0) ; else nodata">
              <i [ngClass]="(!disp)?'fa fa-caret-right' :'fa fa-caret-down' " aria-hidden="true"></i>
            </span>
            <ng-template #nodata>
              <span class="rightAlign">N/A</span>  
            </ng-template>
          </div>
        </div>
        <div class="col-sm-6  popUpcards space rightAlign"  [ngStyle]="(selectedData.latitude && selectedData.longitude)?{'cursor':'pointer'}:{'cursor':'default'}">
          <div class="col-sm-12 popUpcards" [pTooltip]="'Locate Device'" (click)="(selectedData.latitude && selectedData.longitude)?openMap(selectedData):''" tooltipPosition="top">
            <i class="fa fa-map-marker rightSpace" aria-hidden="true"></i>
            Locate Device
            <span class="rightAlign" *ngIf="(selectedData.latitude && selectedData.longitude) ; else nodata">
              <i [ngClass]="(!isShowMap)?'fa fa-caret-right' :'fa fa-caret-down' " aria-hidden="true"></i>
            </span>
            <ng-template #nodata>
              <span class="rightAlign">N/A</span>  
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row rebootDetail" id="locator" *ngIf="isShowMap">
      <div style="height:270px;width:100%;z-index:0" id="sitemap"></div>
    </div>
    <div class="row rebootDetail" *ngIf="disp">
      <app-grid-table [popup]="false" id="gridwithautoheight" [totalRecords]="deviceRebootDetails.totalRecords" [selectedColumns]="deviceRebootDetails.selectedColumns"
        [cols]="deviceRebootDetails.cols" [to]="0" height="248px" [colData]="deviceRebootDetails.colData" [org]="org" [dataUrl]="rebootDetailUrl"
        (update)="loadRebootDetail($event)">
      </app-grid-table>
    </div>
    <div class="row">
      <div class="col-sm-12 outerSpace" style="height:240px">
        <div class="col-sm-6  secondRowPopUpCards space">
          <div class="col-sm-12 secondRowPopUpCards" pTooltip="Module" tooltipPosition="top">
            <i class="fa fa-cube rightSpace" aria-hidden="true"></i>
            Module
            <span class="rightAlign" *ngIf="!devices.Module">
              N/A
            </span>
            <span class="rightAlign" *ngIf="devices.Module">
              <span class="rightAlign" *ngIf="(checkType(devices.Module))==='string'">
                <i class="fa fa-times-circle" aria-hidden="true" *ngIf="devices.Module==='Down'"></i>
                <i class="fa fa-check-circle" aria-hidden="true" *ngIf="devices.Module==='OK'"></i>
              </span>
              <span class="rightAlign" *ngIf="(checkType(devices.Module))==='object'">
                <span *ngFor="let item of subDevices;let j=index" style="display: grid; margin-bottom:10px">
                  <span class="rightAlign sameLine">
                    <span *ngFor="let arr of subValues[j]; let k= index" [ngClass]="(k%2==0)?'rightSpace':''">
                      <span class="size">{{arr[0]}}</span>
                      <i class="fa fa-times-circle size" aria-hidden="true" *ngIf="devices.Module[item][arr[0]]==='Down'"></i>
                      <i class="fa fa-check-circle size" aria-hidden="true" *ngIf="devices.Module[item][arr[0]]==='OK'"></i>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div class="col-sm-12 secondRowPopUpCards" pTooltip="Temperature Sensors" tooltipPosition="top">
            <i class="fa fa-thermometer-empty rightSpace" aria-hidden="true"></i>
            Temperature Sensors
            <span class="rightAlign" *ngIf="!devices['Temperature Sensors']">
              N/A
            </span>
            <span class="rightAlign" *ngIf="devices['Temperature Sensors']">
              <span class="rightAlign" *ngIf="(checkType(devices['Temperature Sensors']))==='string'">
                <i class="fa fa-times-circle" aria-hidden="true" *ngIf="devices['Temperature Sensors']==='Down'"></i>
                <i class="fa fa-check-circle" aria-hidden="true" *ngIf="devices['Temperature Sensors']==='OK'"></i>
              </span>
              <span class="rightAlign" *ngIf="(checkType(devices['Temperature Sensors']))==='object'">
                <span *ngFor="let item of subDevices;let j=index" style="display: grid; margin-bottom:10px">
                  <span class="rightAlign sameLine">
                    <span *ngFor="let arr of subValues[j]; let k= index" [ngClass]="(k%2==0)?'rightSpace':''">
                      <span class="size">{{arr[0]}}</span>
                      <i class="fa fa-times-circle size" aria-hidden="true" *ngIf="devices['Temperature Sensors'][item][arr[0]]==='Down'"></i>
                      <i class="fa fa-check-circle size" aria-hidden="true" *ngIf="devices['Temperature Sensors'][item][arr[0]]==='OK'"></i>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div class="col-sm-12 secondRowPopUpCards" pTooltip="USB" tooltipPosition="top">
            <i class="fa fa-usb rightSpace" aria-hidden="true"></i>
            USB
            <span class="rightAlign" *ngIf="!devices.USB">
              N/A
            </span>
            <span class="rightAlign" *ngIf="devices.USB">
              <span class="rightAlign" *ngIf="(checkType(devices.USB))==='string'">
                <i class="fa fa-times-circle" aria-hidden="true" *ngIf="devices.USB==='Down'"></i>
                <i class="fa fa-check-circle" aria-hidden="true" *ngIf="devices.USB==='OK'"></i>
              </span>
              <span class="rightAlign" *ngIf="(checkType(devices.USB))==='object'">
                <span *ngFor="let item of subDevices;let j=index" style="display: grid; margin-bottom:10px">
                  <span class="rightAlign sameLine">
                    <span *ngFor="let arr of subValues[j]; let k= index" [ngClass]="(k%2==0)?'rightSpace':''">
                      <span class="size">{{arr[0]}}</span>
                      <i class="fa fa-times-circle size" aria-hidden="true" *ngIf="devices.USB[item][arr[0]]==='Down'"></i>
                      <i class="fa fa-check-circle size" aria-hidden="true" *ngIf="devices.USB[item][arr[0]]==='OK'"></i>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div class="col-sm-6  secondRowPopUpCards space rightAlign">
          <div class="col-sm-12 secondRowPopUpCards" pTooltip="Power Supply" tooltipPosition="top">
            <i class="fa fa-plug rightSpace" aria-hidden="true"></i>
            Power Supply
            <br/>
            <div style="display:inline-block" *ngIf=" subNames && subNames.length>0">
              <div class="leftSpace" *ngFor="let item of subNames">
                {{item.PEM}}
              </div>
            </div>
            <span class="rightAlign" *ngIf="!devices.PEM">
              N/A
            </span>
            <span class="rightAlign" *ngIf="devices.PEM">
              <span class="rightAlign" *ngIf="(checkType(devices.PEM))==='string'">
                <i class="fa fa-times-circle" aria-hidden="true" *ngIf="devices.PEM==='Down'"></i>
                <i class="fa fa-check-circle" aria-hidden="true" *ngIf="devices.PEM==='OK'"></i>
              </span>
              <span class="rightAlign" *ngIf="(checkType(devices.PEM))==='object'">
                <span *ngFor="let item of subDevices;let j=index" style="display: grid; margin-bottom:10px">
                  <span class="rightAlign sameLine">
                    <span *ngFor="let arr of subValues[j]; let k= index" [ngClass]="(k%2==0)?'rightSpace':''">
                      <span class="size">{{arr[0]}}</span>
                      <i class="fa fa-times-circle size" aria-hidden="true" *ngIf="devices.PEM[item][arr[0]]==='Down'"></i>
                      <i class="fa fa-check-circle size" aria-hidden="true" *ngIf="devices.PEM[item][arr[0]]==='OK'"></i>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div class="col-sm-12 secondRowPopUpCards" style="height:160px;" pTooltip="Fans" tooltipPosition="top">
            <i class="fa fa-modx rightSpace" aria-hidden="true"></i>
            Fans
            <br/>
            <div style="display:inline-block" *ngIf="subNames && subNames.length>0">
              <div class="leftSpace" *ngFor="let item of subNames">
                <div *ngFor="let k of item.Fans" style="margin-bottom:10px">
                  {{k}}
                </div>
              </div>
            </div>
            <span class="rightAlign" *ngIf="!devices.Fans">
              N/A
            </span>
            <span class="rightAlign" *ngIf="devices.Fans">
              <span *ngIf="(checkType(devices.Fans))==='string'">
                <i class="fa fa-times-circle" aria-hidden="true" *ngIf="devices.Fans==='Down'"></i>
                <i class="fa fa-check-circle" aria-hidden="true" *ngIf="devices.Fans==='OK'"></i>
              </span>
              <span *ngIf="(checkType(devices.Fans))==='object'">
                <span *ngFor="let item of subDevices;let j=index" style="display: grid; margin-bottom:10px">
                  <span class="rightAlign sameLine">
                    <span *ngFor="let arr of subValues[j]; let k= index" [ngClass]="(k%2==0)?'rightSpace':''">
                      <span class="size">{{arr[0]}}</span>
                      <i class="fa fa-times-circle size" aria-hidden="true" *ngIf="devices.Fans[item][arr[0]]==='Down'"></i>
                      <i class="fa fa-check-circle size" aria-hidden="true" *ngIf="devices.Fans[item][arr[0]]==='OK'"></i>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12  outerSpace">
        <div class="col-sm-6  popUpcards space">
          <div class="col-sm-12 popUpcards" pTooltip="CPU" tooltipPosition="top">
            <i class="fa fa-microchip rightSpace" aria-hidden="true"></i>
            CPU
            <span class="rightAlign">
              {{storage.CPU}}
            </span>
          </div>
        </div>
        <div class="col-sm-6  popUpcards space rightAlign">
          <div class="col-sm-12 popUpcards" pTooltip="Memory" tooltipPosition="top">
            <i class="fa fa-hdd-o rightSpace" aria-hidden="true"></i>
            Memory
            <span class="rightAlign">
              {{storage.Memory}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 outerSpace" style="cursor:pointer">
        <div class="col-sm-12 rebootDetail" (click)="moreInfo = !moreInfo" pTooltip="More Info" tooltipPosition="top">
          More Info
          <span class="rightAlign">
            <i class="fa fa-info" aria-hidden="true"></i>
          </span>
          <div *ngIf="moreInfo">
            <hr>
            <div class="ui-grid-row" *ngFor="let col of header">
              <div class="ui-grid-col-4" style="word-break: break-all">
                <label *ngIf="selectedData[col.field]">{{col.header}}</label>
              </div>
              <div class="ui-grid-col-2">
                <span *ngIf="selectedData[col.field]">:</span>
              </div>
              <div class="ui-grid-col-6">
                <span *ngIf="(selectedData[col.field])" style="word-break: break-all">
                  {{selectedData[col.field]}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</p-dialog>


<p-dialog *ngIf="isOpenInfo" header="Net Status" [(visible)]="isOpenInfo" [modal]="true" showEffect="fade" [responsive]="true"
  styleClass="gridactive" resizable="true">
  <app-grid-table [popup]="true" [totalRecords]="deviceGridPopup.totalRecords" [selectedColumns]="deviceGridPopup.selectedColumns"
    [cols]="deviceGridPopup.cols" [colData]="deviceGridPopup.colData" [org]="org" [to]="0" [dataUrl]="dataUrl" (update)="loadPopupGrid($event)">
  </app-grid-table>
</p-dialog>