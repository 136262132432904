import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DataProviderService } from '../../../../services/data-provider.service';
import { JiraService } from '../../jira.service';
import { DateConverterPipe } from '../../../../utils/dateConverter.pipe';
import { CustomEvents } from '../../../../events/customevents';
import { MessageBaseCtrl } from '../../../../base/MessageBaseCtrl';

@Component({
  selector: 'app-jira-comment',
  templateUrl: './jira-comment.component.html',
  styleUrls: ['./jira-comment.component.scss']
})
export class JiraCommentComponent extends MessageBaseCtrl implements OnInit {
  @Input() issueId;
  @Input() org;
  comments;
  uploadedFiles: any[] = [];
  commentattachID: any[] = [];
  hideUpload;
  constructor(private _customEvents: CustomEvents, public _dataProvider: DataProviderService, private _jiraService: JiraService, 
    public _datePipe: DateConverterPipe) {
      super();
    }

  ngOnInit() {
    this.enableButton = true;
    this.loadComment();
  }
  uploadCommentFiles(files) {
    this.enableButton = true;
    for (const file of files) {
      const filedata = {
        'filename': file.name,
        'filesize': file.size
      };
      this.createLoader = true;
      this._jiraService.createTempFileforTicket(true, file.name, file.size, this.issueId, file).subscribe((rest: any) => {
        this.commentattachID.push(rest['attachId']);
        this.setGrowlMessage('success', 'Successfully uploaded file ' + file.name);
        this.uploadedFiles.push(filedata);
        this.enableButton = false;
        this.createLoader = false;
      }, () => {
        this.setGrowlMessage('error', 'Failed to upload file ' + file.name);
        this.enableButton = false;
        this.createLoader = false;
      }
      );
      $('.fileupload').val('');
    }
  }
  removeFiles(index) {
    this.uploadedFiles.splice(index, 1);
    this.commentattachID.splice(index, 1);
    $('.fileupload').val('');
    if (this.commentattachID.length > 0 || $('.comentsbox').val() !== '') {
      this.enableButton = false;
    } else {
      this.enableButton = true;
    }
  }
  onRemovefiles(event) {
  }
  addComments() {
    const commenttext = $('.comentsbox').val();
    this.enableButton = false;
    if (commenttext === '' && this.commentattachID.length === 0) {
      this.enableButton = false;
      this.createLoader = false;
      this.setGrowlMessage('error', 'Please enter comment or upload some file.!!');
      return;
    }
    this.createLoader = true;
    const bodyreq = {
      'comment': $('.comentsbox').val(),
      'issueId': this.issueId
    };
    if (this.commentattachID.length > 0) {
      bodyreq['fileIds'] = this.commentattachID;
    }
    this.enableButton = true;
    this._jiraService.addComments(bodyreq).subscribe((rest: any) => {
      {
        this.enableButton = true;
        this.hideUpload = false;
        $('.comentsbox').val('');
        this.uploadedFiles = [];
        this.commentattachID = [];
        this.comments = [];
        const headers = 'GtmEvents';
        this.createLoader = false;
        this._customEvents.sendGtmEvt.emit({ data: 'customerService_addComment', header: headers });
        this.setGrowlMessage('success', 'Successfully added  comments!!');
        this.loadComment();
      }
    }, () => {
      this.enableButton = true;
      this.createLoader = false;
      this.setGrowlMessage('error', 'Failed to add comment!!');
    }
    );
  }
  onUpload(event) {
    for (const file of event.files) {
      const filedata = {
        'filename': file.name,
        'filesize': file.size
      };
      this.uploadedFiles.push(filedata);
    }
  }
  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) onDragEnd(event) {
    event.preventDefault();
    $('.comentsbox').val('');
    if (this.commentattachID.length > 0) {
      this.enableButton = false;
    } else {
      this.enableButton = true;
    }
    this.createLoader = false;
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    this.uploadCommentFiles(files);
  }
  onFileChange(event) {
    this.uploadCommentFiles(event.target.files);
  }


loadComment() {
  this.comments = [];
  this._jiraService.get(this._dataProvider.SERVICE_TICKET_COMMENT + this.issueId, this.org).subscribe((res) => {
    this.comments = res['data'];
    this.comments.forEach(element => {
      element['created'] = element['created'] && (element['created'] !== 0) ?
        this._datePipe.transform(element['created']) : '';
    });
    this.comments.reverse();
    $('.comentsbox').val('');
    }, (err) => {
        this.comments = [];
        $('.comentsbox').val('');
    });
}
enableAddbtn() {
  if ($('.comentsbox').val() !== '' || this.commentattachID.length > 0) {
    this.enableButton = false;
    this.hideUpload = true;
  } else {
    this.enableButton = true;
    this.hideUpload = false;
  }
}
hideupload() {
  $('.comentsbox').val('');
  this.uploadedFiles = [];
  this.commentattachID = [];
  this.enableButton = true;
  this.hideUpload = false;
}
}