import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PasswordchangeComponent } from "./passwordchange/passwordchange.component";
import { ProfileComponent } from "./profile/profile.component";
import { UserComponent } from './user.component';
import { AuthenticateService } from '../session/authenticate.service';

const userRoutes: Routes = [
  {
    path: '',
    component: UserComponent,
    children: [
      {path:'',redirectTo:'profile', pathMatch: 'full'},
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'password',
        component: PasswordchangeComponent
      }
    ],  
    canActivate: [AuthenticateService],
    canActivateChild: []
  }
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(userRoutes)
  ],
  exports: [RouterModule]
})
export class UserRoutingModule { }
