import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/primeng';
import { MessagesModule } from 'primeng/messages';
import { GrowlModule } from 'primeng/growl';
import { CalendarModule } from 'primeng/calendar';
import { AdvanceGridComponent } from './advance-grid.component';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ListboxModule} from 'primeng/listbox';


@NgModule({
  imports: [
    CommonModule,
    TableModule,
    TooltipModule,
    MultiSelectModule,
    MessagesModule,
    GrowlModule,
    CalendarModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    DialogModule,
    SelectButtonModule,
    ListboxModule
  ],
  declarations: [AdvanceGridComponent],
  exports: [AdvanceGridComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AdvanceGridModule { }
