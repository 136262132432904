<div *ngIf="customerCuircuit.length > 0">
<ng-select #Selectcircuit [multiple]="true"
[items]="customerCuircuit"
[active]="circuitdatachange"
[disabled]="customerCircuitdisabled"
(data)="refreshValue($event)"
(typed)="typed($event)"
(selected)="Customerselected($event)"
(removed)="removed($event)"
placeholder="Select Customer Circuit"></ng-select>
</div>
