<p-messages [(value)]="errorMsgForJira"></p-messages>
<div class="container-fluid" style="min-height:84%;">
   <div class="col-md-12 form-group">
      <div class="col-md-12 screen-shadow" *ngIf="onOrgChange" style="height:84%;margin-bottom: 2.5%;">
          <app-jira-tickets [org]="org" *ngIf="onOrgChange"></app-jira-tickets>
          <div *ngIf="!onOrgChange">
              <section style="height:300px">
                <h4 class="text-center error">Loading Please Wait.... </h4>
              </section>
          </div>
          <div *ngIf="showErrorPage">
              <section >
                <h4 class="text-center error">Oops!! Something went downhill </h4>
                <h4 class="text-center">Please Reload application..</h4>
              </section>
          </div>
        </div>
  </div>
  
</div>

