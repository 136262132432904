<p-dataView [value]="data" layout="grid" [loading]="loading" loadingIcon="fa-spinner" [lazy]="true">
  <p-header>
    <div class="ui-g">
      <div class="ui-g-12">
        <b style="cursor: default;"> Control Status (Total {{count}} )</b>
      </div>
    </div>
  </p-header>
  <p-footer *ngIf="errormsg != ''">
    <span style="color: red">{{errormsg}}</span>
  </p-footer>
  <ng-template let-row pTemplate="gridItem">
    <div class="ui-g-12 ui-md-4">
      <p-panel [header]="row.name" (click)="(row.count)!= 0 ?rowClick(row):''" [ngStyle]="((row.count)!= 0)?{'cursor':'pointer'}:{'cursor':'default'}">
        <div>
          <i [class]="row.icon" aria-hidden="true"></i>
          <span style="float:right">{{row.count}} </span>
        </div>
      </p-panel>
    </div>
  </ng-template>
</p-dataView>

<p-dialog *ngIf="isPopup" [header]="popupHeader" [(visible)]="isPopup" [modal]="true" showEffect="fade" [responsive]="true"
  styleClass="gridactive" resizable="true">
  <app-grid-table [popup]="true" [totalRecords]="controlStatusPopup.totalRecords" [selectedColumns]="controlStatusPopup.selectedColumns"
    [cols]="controlStatusPopup.cols" [colData]="controlStatusPopup.colData" [org]="org" [to]="0" [dataUrl]="dataUrl" (update)="loadPopupGrid($event)">
  </app-grid-table>
</p-dialog>