<div style="height:30px">
    <b>Customer Sites</b>
    <p-growl [autoZIndex] ="true" *ngIf="createReqerrormsg.length!==0" life='3000' [(value)]="createReqerrormsg"></p-growl>
    <div style="float:right;"> 
    <p-button  icon="fa fa-refresh"  [style]="{'float':'right','padding-left':'1.5%','font-size':'10px','margin-right':'5px'}"  pTooltip="Data Sync" tooltipPosition="top" iconPos="left" (click)="onReloadCustomerSites()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_custome_sites_datasync') !=-1 "></p-button>
    <a herf="#" (click)="expandView()" style="float: right">
        <i class="fa fa-th-large" aria-hidden="true" pTooltip="Expand" tooltipPosition="left"></i>
    </a>
    </div>
</div>
<app-grid-table [reload]="customersiteGrid.reloadEvent" [popup]="false" id="soc" [isHeaderWrap]="false" [totalRecords]="customersiteGrid.totalRecords" [selectedColumns]="customersiteGrid.selectedColumns"
    [cols]="customersiteGrid.cols" [colData]="customersiteGrid.colData" [org]="org" [dataUrl]="dataUrl" (update)="loadGrid($event)"
    (rowSelect)="rowSelect($event)" (showContact)="showContact($event)" (showCreateTicket)="showCreateTicket($event)" [to]="0">
</app-grid-table>

<app-form *ngIf="servicerequestDialog3" [greetMessage]="sitemapdata" [org]='org' [reqType]="_dataProvider.getReportTroubleReqId()" (closeForm)="closeForm($event)"></app-form>

<p-dialog *ngIf="isContact" id="socpopup" styleClass="socdialogs" header="Site Details" [(visible)]="isContact" [modal]="true"
    showEffect="fade"  [position]="center"  [autoAlign] = "false" [style]="{'width':'1200','height':'auto'}"   (onHide)="hideDialog($event)">
    <p-tabView  (onChange)="tabChange($event)" [style]="{'height':'350px'}">
            <p-tabPanel header="Site Info">
            <div class="row">
                    <div class="md-12">
                    <div class="row">
                    <div class="md-12" style="float:left;padding-left:5%;" *ngIf="updateSiteInfo">
                <div>
                    <label for="name">Site Info :</label>
                    <label class="editIcon" (click)="enableSiteIcon('siteDetail')" title="Edit Details" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_site_edit') !=-1 "><i class="far fa-edit"></i></label>
                </div>
              
              <div>
                 <label for="cr">Customer Reference :</label>
                 <span> {{contactDetail.unique_customer_reference }} </span>
            </div>
            <div>
                <label for="ar">Apcela Site Reference :</label>
                <span> {{contactDetail.Code}}</span>
            </div>
            <div>
                    <label for="ar"> Site Code:</label>
                    <span> {{contactDetail.site_code}}</span>
                </div>
    
            <div>
                <label for="date">Validated Date :</label>
                <span> {{contactDetail.last_validated_date}} </span>
            </div>
            <div>
                <label for="desc">Description :</label>
                <span> {{contactDetail.Description}} </span>
            </div>
    
            <div >
                <label for="add1">Address Line 1 :</label>
                <span> {{contactDetail.address_line1}} </span>
            </div>
            <div>
                 <label for="add1">Address Line 2 :</label>
                <span> {{contactDetail.address_line2}} </span>
            </div>
            <div>
             <label for="add1">Address Line 3 :</label>
             <span> {{contactDetail.address_line3}} </span>
               </div>
            <div>
                <label for="city">City/State:</label>
                <span> {{(contactDetail.address_city||contactDetail.address_state_or_province)?(contactDetail.address_city||contactDetail.address_state_or_province):'N/A'}}</span>
            </div>
    
            <div>
                <label for="pc">Postal Code :</label>
                <span> {{contactDetail.address_postal_code}} </span>
            </div>
    
            <div>
                <label for="cc">Country Code :</label>
                <span> {{contactDetail.address_county_code}} </span>
            </div>
            <div id="locator" *ngIf="isShowMap">
                 <label for="locate" class="locate">Location :</label>
                <div style="height:250px;width:700px;z-index:0" id="sitemap"></div>
            </div>
        </div>
        <div class="md-6" style="float:left;padding-left: 4%;" *ngIf="!updateSiteInfo">
            <div  class="md-6">
                    <h5  class="first">Customer Reference</h5>
                    <input class ="firstName" type="text" pInputText [(ngModel)]="contactDetail.unique_customer_reference" style = "width:270px" disabled/>
                </div>
               <div  class="md-6">
                    <h5  class="first">Apcela Site Reference</h5>
                    <input class ="middleName" type="text" pInputText [(ngModel)]="contactDetail.Code" style = "width:270px" disabled/>
                </div>
                <div  class="md-6">
                  <h5  class="first">Site Code</h5>
                        <input class ="lastName" type="text" pInputText [(ngModel)]="contactDetail.Code" style = "width:270px" disabled/>
                    </div>
                        <div  class="md-6">
                            <h5  class="first">Validated Date</h5>
                            <input class ="displayName" type="text" pInputText [(ngModel)]="contactDetail.last_validated_date" style = "width:270px" disabled/>

                        </div>
                           <div  class="md-6">
                                <h5  class="first">Description</h5>
                                <input  class ="description" type="text" pInputText [(ngModel)]="contactDetail.Description" style = "width:270px" disabled/>
                                <label class="editIcon" (click)="enableSiteTextIcon('description')"><i class="far fa-edit"></i></label>
                                <span class ="description" style="display:none;">
                                <label class="clearIcon" (click)="clearIcon('description')"><i class="fa fa-times" aria-hidden="true"></i></label>
                                </span>
                            </div>
                            <div  class="md-6">
                             <h5  class="first">Address Line1</h5>
                                <input  class ="addressLine1" type="text" pInputText [(ngModel)]="contactDetail.address_line1" style = "width:270px" disabled/>
                                <label class="editIcon" (click)="enableSiteTextIcon('addressLine1')"><i class="far fa-edit"></i></label>
                                <span class ="addressLine1" style="display:none;">
                                <label class="clearIcon" (click)="clearIcon('addressLine1')"><i class="fa fa-times" aria-hidden="true"></i></label>
                                </span>
                             </div>
                             <div  class="md-6">
                                    <h5  class="first">Address Line2</h5>
                                       <input  class ="addressLine2" type="text" pInputText [(ngModel)]="contactDetail.address_line2" style = "width:270px" disabled/>
                                       <label class="editIcon" (click)="enableSiteTextIcon('addressLine2')"><i class="far fa-edit"></i></label>
                                       <span class ="addressLine2" style="display:none;">
                                       <label class="clearIcon" (click)="clearIcon('addressLine2')"><i class="fa fa-times" aria-hidden="true"></i></label>
                                       </span>
                              </div>
                              <div  class="md-6">
                                    <h5  class="first">Address Line3</h5>
                                       <input  class ="addressLine3" type="text" pInputText [(ngModel)]="contactDetail.address_line3" style = "width:270px" disabled/>
                                       <label class="editIcon" (click)="enableSiteTextIcon('addressLine3')"><i class="far fa-edit"></i></label>
                                       <span class ="addressLine3" style="display:none;">
                                       <label class="clearIcon" (click)="clearIcon('addressLine3')"><i class="fa fa-times" aria-hidden="true"></i></label>
                                       </span>
                              </div>
                             <div  class="md-6">
                                    <h5  class="first">City/State</h5>
                                       <input  class ="city" type="text" pInputText [(ngModel)]="contactDetail.address_city" style = "width:270px"  disabled/>
                                       <label class="editIcon" (click)="enableSiteTextIcon('city')"><i class="far fa-edit"></i></label>
                                       <span class ="city" style="display:none;">
                                       <label class="clearIcon" (click)="clearIcon('city')"><i class="fa fa-times" aria-hidden="true"></i></label>
                                       </span>
                             </div>
                             <div  class="md-6">
                                    <h5  class="first">Postal Code</h5>
                                       <input  class ="postalCode" type="text" pInputText [(ngModel)]="contactDetail.address_postal_code" style = "width:270px" disabled/>
                                       <label class="editIcon" (click)="enableSiteTextIcon('postalCode')"><i class="far fa-edit"></i></label>
                                       <span class ="postalCode" style="display:none;">
                                       <label class="clearIcon" (click)="clearIcon('postalCode')"><i class="fa fa-times" aria-hidden="true"></i></label>
                                       </span>
                             </div>
                             <div  class="md-6">
                                    <h5  class="first">Country Code</h5>
                                       <input  class ="countryCode" type="text" pInputText [(ngModel)]="contactDetail.address_county_code" style = "width:270px" disabled/>
                                       <label class="editIcon" (click)="enableSiteTextIcon('countryCode')"><i class="far fa-edit"></i></label>
                                       <span class ="countryCode" style="display:none;">
                                       <label class="clearIcon" (click)="clearIcon('countryCode')"><i class="fa fa-times" aria-hidden="true"></i></label>
                                       </span>
                             </div>
                             <div style="padding-top:5%;">
                                 <div style="padding-bottom :2%;display:none;">
                                    <p-button icon="fa fa-map-marker" iconPos="right" label="Change Location" (click)='editMap()'></p-button>
                                </div>
                              <div id="locator">
                                     <div style="height:250px;width:700px;z-index:0" id="sitemap"></div>
                               </div>
                             </div>
                             <div style="text-align: left;padding-top:6%;" *ngIf="!updateSiteInfo" >
                                    <p-button [style]="{'margin-right':'5px'}" icon="fa fa-check-square" iconPos="right" label="Update" (click)="updateSiteDetail()"></p-button>
                                    <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelsiteReq()'></p-button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Contact Details">
                             <div class="md-12" style="padding:2% 1% 1% 1%">
                                    <div *ngIf="customerContactDetail && primaryContacts; else noprimary">
                                            <div style="padding-bottom:10px">
                                                 <label for="name">Primary Contact :</label>
                                                 <label class="editIcon" (click)="enableIcon('primaryDetail')" title="Edit Details" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_site_edit') !=-1 "><i class="far fa-edit"></i></label>
                                             </div>
                                            <div>
                                                <label for="name">Name :</label>
                                                <span> {{customerContactDetail.primary_contact.FirstName }} {{customerContactDetail.primary_contact.LastName }}</span>
                                            </div>
                                
                                            <div>
                                                <label for="email">Email :</label>
                                                <span> {{ (customerContactDetail.primary_contact.Email) ? (customerContactDetail.primary_contact.Email) : ''}} </span>
                                            </div>
                                
                                            <div>
                                                <label for="mobile">Mobile :</label>
                                                <span>{{ (customerContactDetail.primary_contact.Mobile) ? (customerContactDetail.primary_contact.Mobile) : ''}} </span>
                                            </div>
                                
                                            <div>
                                                <label for="phn">Phone :</label>
                                                <span>{{ (customerContactDetail.primary_contact.Phone) ? (customerContactDetail.primary_contact.Phone) : ''}} </span>
                                            </div>
                                            <div>
                                               <label for="phn">Other Details:</label>
                                               <span>{{ (customerContactDetail.primary_contact.other_details) ? (customerContactDetail.primary_contact.other_details) : ''}} </span>
                                             </div>
                                        </div>
                                        <ng-template #noprimary>
                                            <div style="padding-bottom:10px">
                                                 <label for="name">Primary Contact :</label>
                                                 <label class="editIcon" (click)="enableIcon('primaryDetail')" title="Edit Details" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_site_lcon_edit') !=-1 "><i class="far fa-edit"></i></label>
                                             </div>
                                            <span class="error">Primary contact is not available</span>
                                        </ng-template>
                                        <div class="md-12" style ="padding:2% 1% 1% 1%">
                                                <div *ngIf="isPrimaryDDEnable">
                                                        <div style="float:right;margin-top:-6%;">
                                                             <p-button  icon="fa fa-plus"  label="Add" [style]="{'font-size':'12px'}"   pTooltip="Add LCON" tooltipPosition="top" iconPos="right" (click)="addLconPrimary()" ></p-button>
                                                             <p-button  icon="fas fa-trash"  label="Clear" [style]="{'font-size':'12px','margin-left':'2%'}"   pTooltip="Clear LCON" tooltipPosition="top" iconPos="right" (click)="deleteLconPrimary('primary_contact')" ></p-button>
                                                        </div>
                                                        <app-select-table *ngIf="isPrimaryDDEnable" [popup]="false" [isCheckbox]="false" id="soc1" [isHeaderWrap]="false" [totalRecords]="userGrid.totalRecords" [selectedColumns]="userGrid.selectedColumns"
                                                           [cols]="userGrid.cols" [colData]="userGrid.colData" [org]="org" [dataUrl]="datalconuser" (update)="loaduserGrid($event)" [selectedItems]="selectedItems" 
                                                           (rowSelect)="rowSelect1($event)" >
                                                        </app-select-table>
                                                 </div>
                                                <div style="text-align: right;padding-top:4%;" *ngIf="isPrimaryDDEnable">
                                                        <p-button [style]="{'margin-right':'5px'}" icon="fa fa-check-square" iconPos="right" label="Update" (click)="updateContact('primary_contact')"></p-button>
                                                        <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)="cancelReq('primaryDetail')"></p-button>
                                                </div>
                                           </div>
                                           <div class="row" *ngIf="editFormPrimary==true">
                                                <div class="col-md-9 offset-md-9" >
                                                      <div style="margin-bottom: 2%;">
                                                          <label for="phn">First Name :</label>
                                                          <input class="email" type="text" pInputText [(ngModel)]="userfirstname" style="width:50%;margin-left:1%;"
                                                           />
                                                      </div>
                                                      <div style="margin-bottom: 2%;">
                                                         <label for="phn">Last Name :</label>
                                                         <input class="email" type="text" pInputText [(ngModel)]="userlastname" style="width:50%;margin-left:1%;"
                                                          />
                                                       </div>
                                                       <div style="margin-bottom: 2%;">
                                                         <label for="phn">Email :</label>
                                                         <input class="email" type="text" pInputText [(ngModel)]="useremail" style="width:50%;margin-left: 8%;"
                                                          />
                                                          <label class="emailerror" style ="margin-left:8%;color:red;display:none;">Invalid Email ID !</label>
                                                       </div>
                                                       <div style="margin-bottom: 2%;">
                                                           <label for="phn">Phone :</label>
                                                           <input class="email" type="text" pInputText [(ngModel)]="userphone" style="width:50%;margin-left: 7%;"
                                                            />
                                                        </div>
                                                        <div style="margin-bottom: 2%;">
                                                          <label for="phn">Mobile :</label>
                                                          <input class="email" type="text" pInputText [(ngModel)]="usermobile" style="width:50%;margin-left: 7%;"
                                                           />
                                                        </div>
                                                        <div style="margin-bottom: 2%;">
                                                           <label for="phn">Other :</label>
                                                           <input class="email" type="text" pInputText [(ngModel)]="userother" style="width:50%;margin-left: 9%;"
                                                            />
                                                     </div>
                                                     <div style="margin-left:19%;">
                                                        <button type="submit" class="btn btn-primary" (click)="updateLcon('primary_contact')">Update LCON</button>
                                                         <button class="btn btn-primary" style="margin-left: 10px;" (click) ="cancelupdateReq()">Cancel</button>
                                                     </div>
                                                  </div>
                                            </div>
                              </div>
                              <div class="md-12" style ="padding:2% 1% 1% 1%">
                                    <div *ngIf="customerContactDetail && secondaryContacts ; else nodata">
                                            <div style="padding-bottom:10px">
                                                <label for="name">Secondary Contact:</label>
                                                <label class="editIcon" (click)="enableIcon('secondaryDetail')" title="Edit Details" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_site_lcon_edit') !=-1"><i class="far fa-edit"></i></label>
                                            </div>
                                        <div>
                                            <label for="name">Name :</label>
                                            <span> {{customerContactDetail.secondary_contact.FirstName }} {{customerContactDetail.secondary_contact.LastName }} </span>
                                        </div>
                                        <div>
                                            <label for="email">Email :</label>
                                            <span> {{ (customerContactDetail.secondary_contact.Email) ? (customerContactDetail.secondary_contact.Email) : ''}} </span>
                                        </div>
                                        <div>
                                            <label for="mobile">Mobile :</label>
                                            <span> {{ (customerContactDetail.secondary_contact.Mobile) ? (customerContactDetail.secondary_contact.Mobile) : ''}} </span>
                                        </div>
                                        <div>
                                            <label for="phn">Phone :</label>
                                            <span> {{ (customerContactDetail.secondary_contact.Phone) ? (customerContactDetail.secondary_contact.Phone) : ''}} </span>
                                        </div>
                                        <div>
                                            <label for="phn">Other Details:</label>
                                             <span>{{ (customerContactDetail.secondary_contact.other_details) ? (customerContactDetail.secondary_contact.other_details) : ''}} </span>
                                        </div>
                                    </div>
                                    <ng-template #nodata >
                                         <div style="padding-bottom:10px">
                                            <label for="name">Secondary Contact:</label>
                                            <label class="editIcon" (click)="enableIcon('secondaryDetail')" title="Edit Details" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_customer_site_edit') !=-1"><i class="far fa-edit"></i></label>
                                        </div>
                                        <span class="error">Secondary contact is not available</span>
                                   </ng-template>
                                   
                                    <div class="md-12" style ="padding:2% 1% 1% 1%">
                                            <div *ngIf="issecondaryDDEnable">
                                                    <div style="float:right;margin-top: -36px;">
                                                        <p-button  icon="fa fa-plus" label="Add" [style]="{'font-size':'12px'}"  pTooltip="Add LCON" tooltipPosition="top" iconPos="right" (click)="addLcon()" ></p-button>
                                                        <p-button  icon="fas fa-trash"  label="Clear" [style]="{'font-size':'12px','margin-left':'2%'}"   pTooltip="Clear LCON" tooltipPosition="top" iconPos="right" (click)="deleteLcon('secondary_contact')" ></p-button>
                                                    </div>
                                                    <app-select-table *ngIf="issecondaryDDEnable" [popup]="false" [isCheckbox]="false" id="soc1" [isHeaderWrap]="false" [totalRecords]="userGrid.totalRecords" [selectedColumns]="userGrid.selectedColumns"
                                                       [cols]="userGrid.cols" [colData]="userGrid.colData" [org]="org" [dataUrl]="datalconuser" (update)="loaduserGrid($event)" [selectedItems]="selectedItems" 
                                                       (rowSelect)="rowSelect1($event)" >
                                                    </app-select-table>
                                             </div>
                                            <div style="text-align: right;padding-top:4%;" *ngIf="issecondaryDDEnable" >
                                                    <p-button  [style]="{'margin-right':'5px'}" icon="fa fa-check-square" iconPos="right" label="Update" (click)="updateContact('secondary_contact')"></p-button>
                                                    <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)="cancelReq('secondaryDetail')"></p-button>
                                            </div>
                                       </div>
                                       <div class="row" *ngIf="editForm==true">
                                            <div class="col-md-9 offset-md-9" >
                                                  <div style="margin-bottom: 2%;">
                                                      <label for="phn">First Name :</label>
                                                      <input class="email" type="text" pInputText [(ngModel)]="userfirstname" style="width:50%;margin-left:1%;"
                                                       />
                                                  </div>
                                                  <div style="margin-bottom: 2%;">
                                                     <label for="phn">Last Name :</label>
                                                     <input class="email" type="text" pInputText [(ngModel)]="userlastname" style="width:50%;margin-left:1%;"
                                                      />
                                                   </div>
                                                   <div style="margin-bottom: 2%;">
                                                     <label for="phn">Email :</label>
                                                     <input class="email" type="text" pInputText [(ngModel)]="useremail" style="width:50%;margin-left: 8%;"
                                                      />
                                                      <label class="emailerror" style ="margin-left:8%;color:red;display:none;">Invalid Email Id !</label>
                                                   </div>
                                                   <div style="margin-bottom: 2%;">
                                                       <label for="phn">Phone :</label>
                                                       <input class="email" type="text" pInputText [(ngModel)]="userphone" style="width:50%;margin-left: 7%;"
                                                        />
                                                    </div>
                                                    <div style="margin-bottom: 2%;">
                                                      <label for="phn">Mobile :</label>
                                                      <input class="email" type="text" pInputText [(ngModel)]="usermobile" style="width:50%;margin-left: 7%;"
                                                       />
                                                    </div>
                                                    <div style="margin-bottom: 2%;">
                                                       <label for="phn">Other :</label>
                                                       <input class="email" type="text" pInputText [(ngModel)]="userother" style="width:50%;margin-left: 9%;"
                                                        />
                                                 </div>
                                                 <div style="margin-left:19%;">
                                                    <button type="submit" class="btn btn-primary" (click)="updateLcon('secondary_contact')">Update LCON</button>
                                                     <button class="btn btn-primary" style="margin-left: 10px;" (click) ="cancelupdateReq()">Cancel</button>
                                                 </div>
                                              </div>
                                        </div>
                             </div>
                            <div class="md-12" style ="padding:2% 1% 1% 1%">
                             <div>
                                 <div>
                                     <label for="name">Validator's Info :</label>
                                     <label class="editIcon" (click)="enableIcon('validateDetail')" title="Edit Details" style="display:none;"><i class="far fa-edit"></i></label>
                                    </div>
                                    <div *ngIf="customerContactDetail && validateContacts; else novalidator">
                                            <div>
                                                <label for="name">Name :</label>
                                                <span> {{customerContactDetail.validatedBy.FirstName}} {{customerContactDetail.validatedBy.LastName}}</span>
                                            </div>
                                            <div>
                                                <label for="email">Email :</label>
                                                <span> {{ (customerContactDetail.validatedBy.Email) ? (customerContactDetail.validatedBy.Email) : 'N/A'}} </span>
                                            </div>
                                            <div>
                                                <label for="mob">Mobile :</label>
                                                <span> {{ (customerContactDetail.validatedBy.Mobile) ? (customerContactDetail.validatedBy.Mobile) : 'N/A'}}</span>
                                            </div>
                                            <div>
                                                <label for="phn">Phone :</label>
                                                <span> {{ (customerContactDetail.validatedBy.Phone) ? (customerContactDetail.validatedBy.Phone) : 'N/A'}} </span>
                                            </div>
                                        </div>
                                     </div>
                                        <ng-template #novalidator>
                                            <span class="error">Validator's info is not available</span>
                                        </ng-template>
                                    <div class="md-12" style ="padding:2% 1% 1% 1%">
                                            <div *ngIf="isvalidateDDEnable">
                                                    <app-select-table *ngIf="isvalidateDDEnable" [popup]="false" [isCheckbox]="false" id="soc1" [isHeaderWrap]="false" [totalRecords]="userGrid.totalRecords" [selectedColumns]="userGrid.selectedColumns"
                                                        [cols]="userGrid.cols" [colData]="userGrid.colData" [org]="org" [dataUrl]="datalconuser" (update)="loaduserGrid($event)" [selectedItems]="selectedItems" 
                                                        (rowSelect)="rowSelect1($event)" >
                                                    </app-select-table>
                                                </div>
                                            <div style="text-align: right;padding-top:4%;" *ngIf="isvalidateDDEnable" >
                                                    <p-button [style]="{'margin-right':'5px'}" icon="fa fa-check-square" iconPos="right" label="Update" (click)="updateContact('validatedby')"></p-button>
                                                    <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)="cancelReq('validateDetail')"></p-button>
                                            </div>
                                        </div>
                             </div>
            </p-tabPanel>
            <p-tabPanel header="Notifications" >
                    <div style="padding-bottom:10px">
                        <div *ngIf="!addOrEditDDEnable">
                         <label for="name">Product Details:</label>
                          <a  herf="#" (click)="addOReditProduct()"class="addproduct" style="float:right;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_site_notification_chanel_add') !=-1 "><label for="name">Add Notification Channel</label></a>
                        </div>
                    <div *ngIf="!addOrEditDDEnable">
                     <div *ngFor="let notificationlist of notificationDetail;let i=index">
                          <div>
                            <label for="name">Product :</label>
                            <span>{{notificationlist.Code}}</span>
                            <label class="editIcon" (click)="editProductDetail(i)" title="Edit Details" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_site_notification_chanel_edit') !=-1 "><i class="far fa-edit"></i></label>
                        </div>
                        <div *ngIf="notificationlist.site_name">
                             <label for="name">Site Name :</label>
                             <span>{{(notificationlist.site_name)?(notificationlist.site_name):'NA'}}</span>
                        </div>
                        <div *ngIf="notificationlist.site_code">
                            <label for="name">Site Code :</label>
                            <span>{{(notificationlist.site_code)?(notificationlist.site_code):'NA'}}</span>
                        </div>
                        <div>
                            <label for="name">Group :</label>
                           <p-chips [(ngModel)]="notificationlist.groups" [disabled]="true" styleClass="chip-width"></p-chips>
                        </div>
                        <div>
                            <label for="name">Users :</label>
                            <p-chips [(ngModel)]="notificationlist.users" [disabled]="true" styleClass="chip-width" ></p-chips>
                         </div>
                         <hr style="border-top: 1px solid #000;"/>
                    </div>
                </div>
                    <div>
                        <div *ngIf="addOrEditDDEnable" style="padding-top:5%;">
                       <div>
                           <label>Product</label><br/>
                           <p-dropdown [options]="productlist" [(ngModel)]="selectedProduct" [autoDisplayFirst]="isupdateProductDetails" [disabled]="isupdateProductDetails" [width]="200" (onChange)="changeProduct($event)"></p-dropdown>
                       </div>
                       <div style="padding-top:2%;">
                            <label> Selected Users</label> 
                            <p-chips [(ngModel)]="selectednotificationlistUser" [disabled]="true" styleClass="chip-width"></p-chips>
                       </div>
                        <div style="padding-top:2%;">
                            <label>Users</label> 
                            <app-select-table *ngIf="addOrEditDDEnable" [popup]="false" [isCheckbox]="true" id="soc1" [isHeaderWrap]="false" [totalRecords]="userGrid.totalRecords" [selectedColumns]="userGrid.selectedColumns"
                                    [cols]="userGrid.cols" [colData]="userGrid.colData" [org]="org" [dataUrl]="datauserUrl" (update)="loaduserGrid($event)" [selectedItems]="selectedItems" 
                                    (rowSelect)="notificationUserRowSelect($event)" (rowunSelect)="unselectnotificationUser($event)" >
                            </app-select-table>
                        </div>
                        <div style="padding-top:2%;">
                                <label> Selected Groups</label> 
                                <p-chips [(ngModel)]="selectednotificationlistGroups" [disabled]="true" styleClass="chip-width"></p-chips>
                           </div>
                        <div style="padding-top:2%;">
                                <label>Groups</label> 
                                <app-select-table *ngIf="addOrEditDDEnable" [popup]="false" [isCheckbox]="true" id="soc1" [isHeaderWrap]="false" [totalRecords]="groupNotificationGrid.totalRecords" [selectedColumns]="groupNotificationGrid.selectedColumns"
                                        [cols]="groupNotificationGrid.cols" [colData]="groupNotificationGrid.colData" [org]="org" [dataUrl]="datagroupNotificationGridUrl" (update)="loadgroupNotificationGrid($event)" [selectedItems]="NotificationselectedItems" 
                                        (rowSelect)="notificationGroupRowSelect($event)" (rowunSelect)="unselectnotificationGroups($event)" >
                                </app-select-table>
                            </div>
                        </div>
                        <div style="text-align: right;padding-top:4%;" *ngIf="addOrEditDDEnable && !isupdateProductDetails">
                             <p-button icon="fa fa-check-square" [style]="{'margin-right':'5px'}" iconPos="right" label="Add Product" [disabled]='isvalidProduct' (click)="CreateProduct()"></p-button>
                             <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelNotificationReq()'></p-button>
                        </div>
                        <div style="text-align: right;padding-top:4%;" *ngIf="isupdateProductDetails">
                                <p-button [style]="{'margin-right':'5px'}" icon="fa fa-check-square" iconPos="right" label="update Product" (click)="updateProduct()"></p-button>
                                <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelNotificationReq()'></p-button>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
     </p-tabView>
</p-dialog>
<p-dialog *ngIf="isOpenInfo" header="Customer Sites" [(visible)]="isOpenInfo" [modal]="true" showEffect="fade" [responsive]="true"
    styleClass="gridactive" resizable="true">
    <app-grid-table  [popup]="true" id="socpopup" [totalRecords]="customersitePopup.totalRecords" [selectedColumns]="customersitePopup.selectedColumns"
        [cols]="customersitePopup.cols" [colData]="customersitePopup.colData" [org]="org" [to]="0" [dataUrl]="dataUrl" (update)="loadPopupGrid($event)">
    </app-grid-table>
</p-dialog>
