import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CustomEvents } from '../../events/customevents';
import { ActivatedRoute } from '@angular/router';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import { TableData } from '../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../grid-table/grid-table.component';
import { DateConverterPipe } from '../../utils/dateConverter.pipe';
import { Message } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-orgmangement',
  templateUrl: './orgmangement.component.html',
  styleUrls: ['./orgmangement.component.scss']
})
export class OrgmangementComponent implements  OnInit, GridTableEvents, OnChanges {
  orgGrid;
  isRowSelect;
  org: string;
  orgGridsUrl;
  associateorgDialog = false;
  tempValue;
  orgdisplayName;
  orgInfo;
  createReqerrormsg: Message[] = [];
  createorgDialog = false;
  createorgForm: FormGroup;
  submitted = false;
  displayUpdateReq = false;
  isPermission;

  constructor(private route: ActivatedRoute,
    private _customEvents: CustomEvents, private _portalhttp: PortalHttpServiceService, public _datePipe: DateConverterPipe
    , private formBuilder: FormBuilder) {
      this.orgGrid = new TableData();
      this.isRowSelect = false;
      this.route.queryParams.subscribe(params => {
        this.org = params['org'];
    });
  const user = JSON.parse(sessionStorage.getItem('user'));
  user['orgs'].forEach(element => {
     if (element.key === this.org) {
      this.isPermission = element.permissions;
     }
   });

    }

  ngOnChanges(changes: SimpleChanges) {
  }
  ngOnInit() {
    this.orgGridsUrl = '/api/v1/group/search';
    this.orgGrid.reloadEvent = '';
  }
  loadGrid(data) {
    if (this.orgGrid.cols.length === 0) {
      this.orgGrid.cols = [
        {
        default: true,
        field: 'displayName',
        header: 'Group Name',
        sequence: 1,
        'sortable': false
      },
        {default: true,
          field: 'label',
          header: 'Name',
          sequence: 2,
          'sortable': false}];
      const columns = [];
      this.orgGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.orgGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.orgGrid.colData = rawData;
    this.orgGrid.totalRecords = data['total'];
  }
  rowSelect(event) {
     this.orgInfo = event['data'];
     this.orgdisplayName = this.orgInfo.displayName;
     this.associateorgDialog = true;
     this.displayUpdateReq = false;
  }
  loadPopupGrid(event) {
 }
 enableIcon(data) {
  $('.' + data).css('display', 'inline-block');
  $('.' + data).prop('disabled', false);
  this.tempValue = $('.' + data).val();
  if (data === 'password') {
   $('.conpassword').val('');
   $('.conpassword').css('display', 'block');
   $('.conpassword').prop('disabled', false);
  }
 }
 clearIcon(data) {
    $('.' + data).val(this.tempValue);
    $('.' + data).prop('disabled', true);
 }
 cancelReq() {
  this.associateorgDialog = false;
  this.orgGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
}
updateOrganization() {
  if (this.orgdisplayName && this.orgdisplayName.trim().length !== 0) {
   this.orgInfo.displayName = this.orgdisplayName;
   this.displayUpdateReq = false;
  } else {
    this.displayUpdateReq = true;
    return;
  }
  const headers = 'GtmEvents';
  this._customEvents.sendGtmEvt.emit({ data: 'organisationmanagment_updateorg', header: headers });
  const updateUrl = '/api/v1/group';
  this._portalhttp.putWithError(updateUrl, this.org, this.orgInfo).subscribe(() => {
    this.orgGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated Group!' });
    this.associateorgDialog = false;
  }, error => {
    this.orgGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
  });
}
oncreateOrganization() {
  this.createorgDialog = true;
  this.submitted = false;
  this.createorgForm = this.formBuilder.group({
    displayName: ['', Validators.required]
});
}
get f() { return this.createorgForm.controls; }

onSubmit() {
  this.submitted = true;
  if (this.createorgForm.invalid) {
      return;
  }
  if  (this.createorgForm.value.displayName && this.createorgForm.value.displayName.trim().length === 0) {
      this.createorgForm.controls['displayName'].setErrors({'required': true});
       return;
  } else {
    this.createorgForm.controls['displayName'].setErrors(null);
  }

  const creteorgURL = '/api/v1/group';
  const body = {
    'displayName': this.createorgForm.value.displayName,
  };
  const headers = 'GtmEvents';
  this._customEvents.sendGtmEvt.emit({ data: 'organisationmanagment_createorg', header: headers });
  this._portalhttp.postWithoutRetry(creteorgURL, this.org, body).subscribe((res) => {
    this.submitted = false;
    this.orgGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully created  Gluu Group. !' });
     this.createorgDialog = false;
     }, err => {
       this.orgGrid.reloadEvent  = '';
       this.createReqerrormsg = [];
       this.createReqerrormsg.push({ severity: 'error', summary: '', detail: err['error']['error'] });
     });

}
cancelCreateReq() {
  this.submitted = false;
  this.createorgDialog = false;
}


}
