import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppsensorGridComponent} from './appsensor-grid.component';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {GrowlModule} from 'primeng/growl';


@NgModule({
  imports: [
    CommonModule,
    GridTableModule,
    TooltipModule,
    DialogModule,
    ButtonModule,
    GrowlModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppsensorGridComponent],
  exports: [AppsensorGridComponent]
})
export class AppsensorGridModule { }
