import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DeviceModule } from './device/device.module';
import { SocModule } from './soc/soc.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { JiraModule } from './jira/jira.module';
import {AppsensorModule} from './appsensor/appsensor.module';
import {SecurityModule} from './security/security.module';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { SafePipeModule } from '../utils/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    DeviceModule,
    SocModule,
    JiraModule,
    DashboardRoutingModule,
    AppsensorModule,
    SecurityModule,
    MessagesModule,
    MessageModule,
    SafePipeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DashboardComponent],
  exports: [DashboardComponent]
})
export class DashboardModule { }
