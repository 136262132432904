import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomerCircuitMapDetailComponent} from './customer-circuit-map-detail.component';
import {ButtonModule} from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule
  ],
  declarations: [CustomerCircuitMapDetailComponent],
  exports: [CustomerCircuitMapDetailComponent]
})
export class CustomerCircuitMapDetailModule { }
