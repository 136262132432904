import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomEvents } from '../../events/customevents';
import { Observable } from 'rxjs';
import { BaseCtrl } from '../../base/BaseCtrl';
import { DataProviderService } from '../../services/data-provider.service';

@Injectable({
  providedIn: 'root'
})
export class JiraService extends BaseCtrl {
  unAuthorizedErrorListener: any;
  constructor(private _http: HttpClient, private _customEvents: CustomEvents,
    private _dataProvider: DataProviderService) {
    super()
  }

  public validateJiraSession(url: string, body: any) {
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this._http.post(url, body, { headers, withCredentials: true }).toPromise();
  }

  public addComments(body: any) {
    return this._http.post(this._dataProvider.getJiraUrl(this._dataProvider.JIRA_SERVICEDESK_COMMENT), body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Atlassian-Token': 'no-check'
      }), withCredentials: true
    });
  }


  public createTempFileforTicket(isCommentFile: boolean, fileName: string, size, requestId, uploadedFile: any) {
    return this._http.post(this._dataProvider.getJiraUrl(this._dataProvider.JIRA_TEMP_FILE_UPLOAD), uploadedFile, {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/octet-stream',
        'X-Atlassian-Token': 'no-check'
      }), withCredentials: true, params: new HttpParams()
        .set('filename', fileName)
        .set('size', size).set(isCommentFile ? 'issueId' : 'projectId', requestId).set('atl_token', this._dataProvider.getJiraXSFRToken())
    });
  }

  public createRequestpost(url: string, body: any) {
    const body1 = new URLSearchParams();
    for (let i = 0; i < body.length; i++) {
      const key = Object.keys(body[i]);
      const value = Object.values(body[i]);
      if (key[0].toString() === 'filetoconvert') {
        const finalValue = value[0] as any[];
        for (let j = 0; j < finalValue.length; j++) {
          body1.append('filetoconvert', value[0][j].toString());
        }
      } else {
        body1.set(key[0].toString(), value[0].toString());
      }
    }
    return this._http.post(this._dataProvider.getJiraUrl(url), body1.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Atlassian-Token': 'no-check'
      }), withCredentials: true, observe: 'response'
    });
  }

  public loadFormData(url: string, selectedfrequestype) {
    return this.handleRetry(true, this._http.post(this._dataProvider.getJiraUrl(url), {
      'models': ['portal', 'reqCreate'],
      'options': {
        'portalId': this._dataProvider.getUserInfoByKey('portalId'),
        'portal': {
          'id': this._dataProvider.getUserInfoByKey('portalId')
        },
        'reqCreate': {
          'id': selectedfrequestype
        }
      }
    }, { headers: new HttpHeaders({ 'Accept': 'application/json' }), withCredentials: true }));
  }

  public searchKBs(body: any) {
    return this._http.get(this._dataProvider.getJiraUrl(this._dataProvider.JIRA_SERVICEDESK_SEARCH), { withCredentials: true, params: new HttpParams().set('query', body.query).set('resultsPerPage', '5') });
  }

  public getPortalData(url: string, org: string) {
    return this.handleRetry(false, this._http.get(this._dataProvider.getServiceUrl(url), {
      headers: new HttpHeaders({
        'org': org,
        'Authorization': this._dataProvider.getToken()
      })
    }));
  }

  catchUnauthorizeError(isJira: boolean, error: any): any {
    if (error.status === 401 || isJira && error.status === 403) {
      this._customEvents.unAuthorizedError.emit(error);
    } else {
      throw error;
    }
  }

  handleRetry(isJira, response) {
    return response.retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchUnauthorizeError(isJira, error))

  }

  public put(url: string, org: string, body: any) {
    return this._http.put(this._dataProvider.getServiceUrl(url), body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._dataProvider.getToken(),
        'org': org
      })
    }).catch((error: any) => this.catchUnauthorizeError(false, error));
  }

  
  public get(url: string, org: string) {
    return this.handleRetry(false, this._http.get(this._dataProvider.getServiceUrl(url), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._dataProvider.getToken(),
        'org': org
      })
    }));
  }
  public post(url: string, org: string, body: any) {
    return this.handleRetry(false, this._http.post(this._dataProvider.getServiceUrl(url),body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this._dataProvider.getToken(),
        'org': org
      })
    }));
  }
}
