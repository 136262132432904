export class TableData {
    from;
    to;
    cols = [];
    colData = [];
    selectedColumns = [];
    totalRecords;
    timeRangeToAppendEvent;
    reloadEvent;
    url;
    isGraph: boolean;
    state;
    label;
    rangeFrom;
    rangeTo;
    dateFrom;
    dateTo;
    paginator;
}
