import { Component, OnInit, Input } from '@angular/core';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-ip-sla-table',
  templateUrl: './ip-sla-table.component.html',
  styleUrls: ['./ip-sla-table.component.scss']
})
export class IpSlaTableComponent implements OnInit {
   jitter;
   @Input() data = {};
   @Input() nodes = [];
   @Input() type;
   @Input() unit;
  constructor( private _portalHttp: PortalHttpServiceService) { }
  ngOnInit() {

  }

  getBaseline(data, source, target, type){
    const val =data[source +'_'+target] ? data[source +'_'+target]: data[target +'_'+ source];
    if(val && val['baseline'] && val['baseline'][type]) {
      return '(' + val['baseline'][type] +')';
    } 
    return '';
  }
  getColor(data, source, target, type){
    if(source === target){
      return '#f5f5f5';
    }
    const val =data[source +'_'+target] ? data[source +'_'+target]: data[target +'_'+ source];
    if(val && val['baseline'] && val['baseline'][type +'_color']) {
      return val['baseline'][type +'_color'];
    } 
    return 'white';
  }
}
