import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerSiteComponent } from './customer-site.component';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { CustomerSiteMapComponent } from './customer-site-map/customer-site-map.component';
import { CustomerSiteServiceService } from './customer-site-service.service';
import {FormModule} from '../../jira/jira-tickets/form/form.module';
import {GrowlModule} from 'primeng/growl';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/primeng';
import {ButtonModule} from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import {SelectTableModule} from '../../../select-Table/select-table/select-table.module';
import {ChipsModule} from 'primeng/chips';
@NgModule({
  imports: [
    CommonModule,
    GridTableModule,
    TooltipModule,
    TableModule,
    DialogModule,
    FieldsetModule,
    FormModule,
    GrowlModule,
    FormsModule,
    DropdownModule,
    ButtonModule,
    TabViewModule,
    AccordionModule,
    SelectTableModule,
    ChipsModule
  ],
  declarations: [CustomerSiteComponent, CustomerSiteMapComponent],
  providers: [CustomerSiteServiceService],
  exports: [CustomerSiteComponent, CustomerSiteMapComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerSiteModule { }
