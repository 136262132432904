import { Component, OnInit, Input } from '@angular/core';
import { SiteHealthService } from './site-health.service';
import { TableData } from '../../../common/SharedClasses/TableData';

@Component({
  selector: 'app-site-health',
  templateUrl: './site-health.component.html',
  styleUrls: ['./site-health.component.scss']
})
export class SiteHealthComponent implements OnInit {

  @Input() org;
  data;
  defaultData;
  count;
  siteHealthPopup;
  dataUrl;
  isPopup;
  popupHeader;
  errormsg;
  loading;


  constructor(private service: SiteHealthService) {
    this.count = 0;
    this.data = [];
    this.siteHealthPopup = new TableData();
    this.dataUrl = '';
    this.isPopup = false;
    this.popupHeader = '';
    this.errormsg = '';
    this.loading = true;
    this.defaultData = [{
      'name': 'Full Connectivity',
      'icon': 'fa fa-check-circle',
      'count': 0
    }, {

      'name': 'Partial Connectivity',
      'icon': 'fa fa-exclamation-triangle',
      'count': 0
    }, {

      'name': 'No Connectivity',
      'icon': 'fa fa-times-circle',
      'count': 0
    }
    ];
  }

  ngOnInit() {
    // call service to get all the data
    this.service.getAllData(this.org).subscribe((res) => {
      this.data = res['data'];
      if (this.data.length > 0) {
        this.errormsg = '';
        this.data.forEach(element => {
          this.count = this.count + element.count;
          if (element.status === 'UP') {
            element.name = 'Full Connectivity';
            element.icon = 'fa fa-check-circle';
          } else if (element.status === 'PARTIAL') {
            element.name = 'Partial Connectivity';
            element.icon = 'fa fa-exclamation-triangle';
          } else {
            element.name = 'No Connectivity';
            element.icon = 'fa fa-times-circle';
          }
        });
      } else {
        this.errormsg = 'Failed to get Site information!';
        this.data = this.defaultData;
      }
      this.loading = false;
    }, err => {
      this.data = this.defaultData;
      this.loading = false;
      this.errormsg = 'Failed to get Site information!';
    });
  }

  // Opens dialog box on each row click
  loadPopupGrid(data: any) {
    if (this.siteHealthPopup.cols.length === 0) {
      this.siteHealthPopup.cols = data['headers'];
      this.siteHealthPopup.selectedColumns = this.siteHealthPopup.cols.slice(0, 6);
    }
    const rawData = data['data'];
    this.siteHealthPopup.colData = rawData;
    this.siteHealthPopup.totalRecords = data['total'];
  }

  // changes api urls on each row click based on row status
  rowClick(row) {
    const status = row.status;
    this.popupHeader = row.name;
    this.dataUrl = '/api/v1/site/status/' + status;
    this.isPopup = true;
  }

}

