<div style="height:100%">
        <app-date-picker blocktitle="" [selectedQuickRangeFrom]="selectedQuickRangeFrom" [selectedQuickRangeTo]="selectedQuickRangeTo" [timerangelabel]="timerangelabel"
            [isExpand]="false" [selectedstyle]="{'width':'600px'}" (switchView)="switchView($event)" collapseId="audit" isExpand="true"
            (refresh)="refresh($event)" (updateTime)="updateTime($event)"></app-date-picker>
  
    <section *ngIf="anomalyResult.state === 'Graph'" id="error">
        <app-graph-container [isGrafanaSessionPresent]="isGrafanaSessionPresent" [timeRangeToAppendEvent]="anomalyResult.timeRangeToAppendEvent"
            [reloadEvent]="anomalyResult.reloadEvent" [org]="org" [deviceUrl]="url"></app-graph-container>
    </section>
    <section *ngIf="anomalyResult.state === 'Grid'">
        <app-grid-table [popup]="true" [reload]="anomalyResult.reloadEvent" [totalRecords]="anomalyResult.totalRecords" [dataUrl]="dataUrl"
            [selectedColumns]="anomalyResult.selectedColumns" [cols]="anomalyResult.cols" [colData]="anomalyResult.colData" [from]="anomalyResult.from"
            [to]="anomalyResult.to" [org]="org" [to]="0" (update)="loadGrid($event)"></app-grid-table>
    </section>
</div>