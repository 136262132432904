import { Component, ViewChild, Output, Input, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Table } from 'primeng/table';
import { PortalHttpServiceService } from '../services/portal-http-service.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.scss']
})
export class GridTableComponent implements OnChanges, OnInit {

  @Input() popup: boolean;
  @Input() from;
  @Input() to;
  @Input() org: string;
  @Input() dataUrl: string;
  @Input() totalRecords = 0;
  @Input() cols: any[];
  @Input() selectedColumns = [];
  @Input() colData = [];
  @Input() reload;
  @Input() rows = 10;
  @Input() id;
  @Input() height = '225px';
  @Input() isGlobalSearch = true;
  @Input() paginator = true;
  @Input() isHeaderWrap = true;
  @Input() isRowcount = true;
  @Input() regionValue: string;
  offset = 0;
  filter;
  loading;
  sort;
  limit;
  clearSearch;
  @ViewChild('dashboardtable',{static:false}) dashboardtable: Table;
  @Output() update = new EventEmitter<any>();
  @Output() rowSelect = new EventEmitter<any>();
  @Output() showGraph = new EventEmitter<any>();
  @Output() showContact = new EventEmitter<any>();
  @Output() showCreateTicket = new EventEmitter<any>();
  @Output() onCreateUser = new EventEmitter<any>();
  errormsg: Message[] = [];
  msgs: Message[] = [];
  constructor(private _portalhttp: PortalHttpServiceService) {
    this.loading = true;
    this.clearSearch = true;
    this.popup = false;
  }


  ngOnInit() {
  }
  // on any change in data this method is called
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['from'] || changes['to'] ) {
      if ((changes['from'] && !changes['from'].firstChange) && !changes['to'].firstChange) {
        const fromCurrent = changes['from'].currentValue;
        const toCurrent = changes['to'].currentValue;
        const fromPrevious = changes['from'].previousValue;
        const toPrevious = changes['to'].previousValue;
        if ((fromCurrent !== fromPrevious) || (toPrevious !== toCurrent)) {
          this.dashboardtable.first = 0;
          this.offset = 0;
          this.loading = true;
          this.loadData();
        }
      }
    } else if (changes['reload']) {
      this.dashboardtable.sortOrder = null;
      this.sort = {};
      this.clearSearch = false;
      this.dashboardtable.selection = {};
      this.dashboardtable.reset();
    }  else if (changes['org'] || changes['dataUrl']) {
      this.loadData();
    } else if (changes['regionValue'] ) {
      this.loadData();
    }
  }

  //  on pagination, sort, and search
  loadDataLazy(event) {
    this.loading = true;
    if (Object.keys(event).length !== 0) {
      this.filter = event.globalFilter;
      if (event.rows) {
        this.limit = event.rows;
        this.offset = event.first / event.rows;
      }
      if (event.sortField) {
        this.sort = {
          [event.sortField]: (event.sortOrder === 1) ? 'ASC' : 'DESC'
        };
      }
    }
    this.loadData();
  }

  // to load data to grid make api call
  loadData() {
    if (this.dataUrl && this.dataUrl.endsWith('json')) {
      this._portalhttp.getLocalData('jira.json').subscribe((res) => {
        this.loading = false;
        this.clearSearch = true;
        this.errormsg = [];
        this.update.emit(res);
      });
    } else if (this.dataUrl) {
      const body = {
        'limit': this.limit,
        'offset': this.offset,
        'from': this.from ? this.from : new Date().getTime(),
        'to': this.to,
        'orderBy': this.sort,
        'query': this.filter ? this.filter.trim() : ''
      };
      this.loading = true;
      if (this.regionValue !== undefined && ( this.regionValue['label'] || this.regionValue['name'] )) {
        this._portalhttp.postwithRegion(this.dataUrl, this.org, body,
          this.regionValue['label'] ? this.regionValue['label'] : this.regionValue['name']).subscribe((res) => {
          this.loading = false;
          this.clearSearch = true;
          this.errormsg = [];
          this.update.emit(res);
        }, err => {
          this.loading = false;
          this.errormsg = [];
          this.errormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
        });
      } else {
      this._portalhttp.post(this.dataUrl, this.org, body).subscribe((res) => {
        this.loading = false;
        this.clearSearch = true;
        this.errormsg = [];
        this.update.emit(res);
      }, err => {
        this.loading = false;
        this.errormsg = [];
        this.errormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
      });
    }
    } else {
      this.loading = false;
      this.update.emit();
    }
  }

  // on row click call respective parent methods
  onRowSelect(event) {
    this.rowSelect.emit(event);
  }

  // on graph click this will emit an event
  onShowGraph(event, row) {
    this.showGraph.emit(row);
    event.stopPropagation();
  }

  // for soc, on contact icon click
  onShowContact(event, row) {
    this.showContact.emit(row);
    event.stopPropagation();
  }

  // for soc, on contact icon click
  onShowCreateTicket(event, row) {
    this.showCreateTicket.emit(row);
    event.stopPropagation();
  }
  onShowonCreateUser(event, row) {
    this.onCreateUser.emit(row);
    event.stopPropagation();
  }

}

// these are the methods that must be implemented in parent components
export interface GridTableEvents {
  loadGrid(event): void;
  rowSelect(event): void;
  loadPopupGrid(event): void;
  showGraph?(data): void;
  showContact?(data): void;
}
