import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule, DialogModule } from 'primeng/primeng';
import { FormComponent } from '../form/form.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {FormsModule} from '@angular/forms';
import {RadioButtonModule} from 'primeng/radiobutton';
import {GrowlModule} from 'primeng/growl';
import {SelectModule} from 'ng2-select';
import {ProgressBarModule} from 'primeng/progressbar';
import {SiteDropdownModule} from './site-dropdown/site-dropdown.module';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CircuitDropdownModule} from './circuit-dropdown/circuit-dropdown.module';
import {DatePickerModule} from '../../../../date-picker/date-picker.module';
import {SelectButtonModule} from 'primeng/selectbutton';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    InputTextareaModule,
    DropdownModule,
    InputTextModule,
    FormsModule,
    InputSwitchModule,
    FileUploadModule,
    RadioButtonModule,
    GrowlModule,
    ProgressBarModule,
    SelectModule,
    SiteDropdownModule,
    CircuitDropdownModule,
    DatePickerModule,
    SelectButtonModule,
    
  ],
  declarations: [FormComponent],
  exports: [FormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FormModule { }
