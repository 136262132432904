import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AdvanceGridModule} from '../../../advance-grid/advance-grid.module';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import {GrowlModule} from 'primeng/growl';
import {ButtonModule} from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import {CustomerCircuiteGridComponent} from '../customer-circuite-grid/customer-circuite-grid.component';
import {FormModule} from '../../jira/jira-tickets/form/form.module';
import {CustomerCircuitReportModule} from '../customer-circuite-grid/customer-circuit-report/customer-circuit-report.module';
import {DatePickerModule} from '../../../date-picker/date-picker.module';
import {CustomerCircuiteMapModule} from '../customer-circuite-map/customer-circuite-map.module';
import {CustomerCircuitMapDetailModule} from '../customer-circuit-map-detail/customer-circuit-map-detail.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AdvanceGridModule,
    TabViewModule,
    AccordionModule,
    DialogModule,
    FormModule,
    CustomerCircuitReportModule,
    GrowlModule,
    ButtonModule,
    TooltipModule,
    DatePickerModule,
    CustomerCircuiteMapModule,
    CustomerCircuitMapDetailModule
  ],
 declarations: [CustomerCircuiteGridComponent],
 exports: [CustomerCircuiteGridComponent],
 schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerCircuiteGridModule { }
