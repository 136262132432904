<p-growl [(value)]="msgs" life="3000"></p-growl>
<p-table *ngIf="errormsg.length===0" [id]="id" [columns]="selectedColumns" [value]="colData" #dashboarselectdtable [(selection)]="selectedItems" 
    (onRowSelect)="onRowSelect($event)" [scrollable]="true" [scrollHeight]="height" [resizableColumns]="true" [loading]="loading" selectionMode="mutiple" 
    loadingIcon="fa fa-spinner" [lazy]="true" (onLazyLoad)="loadDataLazy($event)" [paginator]="paginator" pageLinks="3" [rows]="rows"
    [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]" [editable]="true" (onRowUnselect)="onRowUnselect($event)"  [responsive]="true" [reorderableColumns]="true" columnResizeMode="expand" [isCheckbox]="isCheckbox" [metaKeySelection]="false">

    <ng-template pTemplate="caption">
        <div class="popUpHeader">
            <div *ngIf="clearSearch" class="search" [ngStyle]="(id =='gridwithautoheight')?{'visibility':'hidden'}:''" [ngClass]="(popup)?'searchinpopupheader':'searchinnormalheader'">
                <span *ngIf="isGlobalSearch">
                    <i class="fa fa-search"></i>
                    <input type="text" #dashfilter pInputText size="50" placeholder="Search" (input)="dashboardtable.filterGlobal($event.target.value, 'contains')">
                </span>
                <span *ngIf="isRowcount">
                    <b> Total {{totalRecords}} Rows</b>
                </span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns">
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
           <th style="width: 4em">
                <p-tableHeaderCheckbox *ngIf ="isCheckbox" style="display:none;"></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngSwitch]="col.filterType"  [style.width]="col.size" [ngClass]="(isHeaderWrap)?(popup)?'textwrapwithwidth':'textwrapheader':''"
                [pSortableColumn]="(col.sortable)?col.field:''" [ngStyle]="{'cursor':(col.sortable)?'pointer':'default'}" pResizableColumn
                pReorderableColumn>
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
            </th>
        </tr>
        <tr>
             <th *ngFor="let col of columns" [ngSwitch]="col.filterType"   [style.padding-left]="'2'">
                <p-dropdown *ngSwitchCase="'select'" [options]="col.selectOptions" [style]="{'width':'800px'}" appendTo="body" (onChange)="advancedTable.filter($event.value, col.field, 'equals')"></p-dropdown>
                <p-multiSelect *ngSwitchCase="'multiselect'"  maxSelectedLabels="0" selectedItemsLabel="{0} Selected" [panelStyle]="{minWidth:'800px'}"  [options]="col.selectOptions" [style]="{'width':'800px'}" appendTo="body" (onChange)="advancedTable.filter($event.value, col.field, 'equals')">
                </p-multiSelect>
        </th>
        <th [style.width]="'50px'"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" style="text-indent: 5px" [ngStyle]="{'cursor':(id =='transport' && rowData.value == 0)?'default':'pointer'}">
          <td style="width:40px;">
              <p-tableCheckbox [value]="rowData" *ngIf ="isCheckbox"></p-tableCheckbox>
              <p-tableRadioButton [value]="rowData" *ngIf ="!isCheckbox"></p-tableRadioButton>
          </td>
          <td *ngFor="let col of columns;let isLast = last" [style.width]="col.size" [ngClass]="popup?'textwrapwithwidth':'textwrapbody'"
                class="ui-resizable-column" [pTooltip]="(col.subfield1)?((col.subfield2)? (rowData[col.field][col.subfield1][col.subfield2]):rowData[col.field][col.subfield1]):rowData[col.field]"
                tooltipPosition="top">
                <span class="ui-column-title">{{col.header}}</span>
                <span *ngIf="(col.field !== 'graph') && (col.header !== 'State') && (col.field !== 'icon')">
                    {{rowData[col.field]}}
                </span>
                <span *ngIf="col.header === 'State'">
                    <i [ngClass]="(rowData[col.field]==='reachable')?'fa fa-arrow-up':'fa fa-arrow-down'" aria-hidden="true"></i>
                </span>
             </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                No records found
            </td>
        </tr>
    </ng-template>
</p-table>

<p-messages *ngIf="errormsg.length!==0" [(value)]="errormsg" [closable]="false"></p-messages>