import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from '../../session/authenticate.service';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import {ConfirmationService} from 'primeng/api';
import { CustomEvents } from '../../events/customevents';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  displayName: string;
  org = [];
  roleNameTobeDisplayed: string;
  isAuth$: Observable<boolean>;
  timezone: FormGroup;
  timezonelist: any = [];
  futureEvents;
  gtmEventdata;
  eventDetailsDialog;
  eventDetails;
  IssueId;
  selectedOrg;
  constructor(private _route: Router, private _authservice: AuthenticateService, public fb: FormBuilder,
    private _portalhttp: PortalHttpServiceService, private confirmationService: ConfirmationService, private _customEvents: CustomEvents) {
    this.displayName = '';
    const data = JSON.parse(sessionStorage.getItem('user'));
    if (data) {
    this.timezone = this.fb.group({
      timezonelabel: data['zone']
    });
  } else {
    this.timezone = this.fb.group({
      timezonelabel: ''
    });
  }
   }

  ngOnInit() {
    this.isAuth$ = this._authservice.isLoggedIn;
   this._portalhttp.getLocalData('timezone.json').subscribe((res) => {
   this.timezonelist = res;
  });
  this._portalhttp.getLocalData('gtmEventsdata.json').subscribe((res) => {
    this.gtmEventdata = res;
   });
    this._authservice.isLoggedIn.subscribe ((res) => {
      if (res) {
      const data = JSON.parse(sessionStorage.getItem('user'));
      this.displayName = data['name'];
      this.roleName(data['groups']);
      this.loadEvents();
      const timezone =  data['zone'];
      if (timezone && timezone !== '') {
      const index = this.timezonelist.indexOf(timezone);
      if (index !== -1) {
        this.timezone = this.fb.group({
          timezonelabel: [this.timezonelist[index]]
        });
        Object.assign(data, {zone: this.timezonelist[index]});
      }
    } else {
      this.timezone = this.fb.group({
        timezonelabel: [this.timezonelist[0]]
      });
      Object.assign(data, {zone: this.timezonelist[0]});
    }
    }
  });
  this._customEvents.displayRowWiseEvt.subscribe((value: any) => {
    if (value.header === 'Events') {
      this.loadEvents();
    }
  });
   this._customEvents.sendGtmEvt.subscribe((value: any) => {
    if (value.header === 'GtmEvents') {
     this.sendGtmEvent(value.data);
    }
  });

  }
  sendGtmEvent(gtmkey) {
    if (gtmkey) {
    const event = this.gtmEventdata.gtmEvents[0][gtmkey];
    (<any>window).ga('send', 'event', {
      eventCategory: event.eventCategory,
      eventLabel: event.eventLabel,
      eventAction: event.eventAction,
      eventValue: event.eventValue
      });
    }
  }

  loadEvents() {
    const today = new Date().getTime();
    const sevendays = today +  ( 15 * 24 * 60 * 60 * 1000);
    const userorg = sessionStorage.getItem('org');
    const eventurl = '/api/v1/ticket/maintenance?' + 'start=' + today + '&end=' + sevendays + '&Status=' + true;
    this._portalhttp.get(eventurl, userorg).subscribe((res) => {
      this.futureEvents = res['data'];
    }, (err) => {
  });
}
  changeTimezone(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to update Timezone?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
            const updateUrl = '/api/user/update/timezone';
            const org = sessionStorage.getItem('org');
            const value = event.target.value.split(':');
            const body = {
              'zone': value[1].trim()
            };
            this._portalhttp.putWitoutRetry(updateUrl, org, body).subscribe((res) => {
              const data = JSON.parse(sessionStorage.getItem('user'));
              Object.assign(data, {zone: value[1].trim()});
              sessionStorage.setItem('user', JSON.stringify(data));
              const headers = 'GtmEvents';
              this._customEvents.sendGtmEvt.emit({ data: 'timezone', header: headers });
              this._route.navigate(['/dashboard'], { queryParams: { org: org ? org : '' } }).then(() => {
               window.location.reload();
              });
                  }, error => {
                  });
      },
      reject: () => {
        const data = JSON.parse(sessionStorage.getItem('user'));
        const timezone =  data['zone'];
        if (timezone && timezone !== '') {
        const index = this.timezonelist.indexOf(timezone);
        if (index !== -1) {
          this.timezone = this.fb.group({
            timezonelabel: [this.timezonelist[index]]
          });
          Object.assign(data, {zone: this.timezonelist[index]});
        }
      } else {
        this.timezone = this.fb.group({
          timezonelabel: [this.timezonelist[0]]
        });
        Object.assign(data, {zone: this.timezonelist[0]});
      }
      }
  });
  }
  showEvent(index) {
    this.eventDetails = this.futureEvents[index];
    this.IssueId = this.eventDetails.id;
    this.selectedOrg = sessionStorage.getItem('org');
    this.eventDetailsDialog = true;
  }
  hideDialog(event) {
    this.eventDetails = [];
    this.eventDetailsDialog = false;
  }
  roleName(data) {
    if ( data && data.indexOf('PORTAL_ROLE_ADMIN') !== -1) {
      this.roleNameTobeDisplayed = 'Admin';
    } else if (data && data.indexOf('PORTAL_ROLE_CLIENT_ADMIN') !== -1) {
      this.roleNameTobeDisplayed = 'Client Admin';
    } else {
      this.roleNameTobeDisplayed = '';
    }
  }
}
