import { Component, OnInit, Input } from '@angular/core';
import { ControlStatusService } from './control-status.service';
import { TableData } from '../../../common/SharedClasses/TableData';
@Component({
  selector: 'app-control-status',
  templateUrl: './control-status.component.html',
  styleUrls: ['./control-status.component.scss']
})
export class ControlStatusComponent implements OnInit {

  @Input() org;
  data;
  defaultData;
  count;
  controlStatusPopup;
  dataUrl;
  isPopup;
  popupHeader;
  errormsg;
  loading;


  constructor(private service: ControlStatusService) {
    this.count = 0;
    this.data = [];
    this.controlStatusPopup = new TableData();
    this.dataUrl = '';
    this.isPopup = false;
    this.popupHeader = '';
    this.errormsg = '';
    this.loading = true;
    this.defaultData = [{
      'name': 'Control Up',
      'icon': 'fa fa-arrow-circle-up',
      'count': 0
    }, {

      'name': 'Partial',
      'icon': 'fa fa-arrow-circle-up partial',
      'count': 0
    }, {

      'name': 'Control Down',
      'icon': 'fa fa-arrow-circle-down',
      'count': 0
    }
    ];
  }

  ngOnInit() {
    // call service to get all the data
    this.service.getAllData(this.org).subscribe((res) => {
      this.data = res['data'];
      if (this.data.length > 0) {
        this.errormsg = '';
        this.data.forEach(element => {
          this.count = this.count + element.count;
          if (element.status === 'UP') {
            element.name = 'Control Up';
            element.icon = 'fa fa-arrow-circle-up';
          } else if (element.status === 'PARTIAL') {
            element.name = 'Partial';
            element.icon = 'fa fa-arrow-circle-up partial';
          } else {
            element.name = 'Control Down';
            element.icon = 'fa fa-arrow-circle-down';
          }
        });
      } else {
        this.errormsg = 'Failed to get Control information!';
        this.data = this.defaultData;
      }
      this.loading = false;
    }, err => {
      this.errormsg = 'Failed to get Control information!';
      this.data = this.defaultData;
      this.loading = false;
    });
  }

  // Opens dialog box on each row click
  loadPopupGrid(data: any) {
    if (this.controlStatusPopup.cols.length === 0) {
      this.controlStatusPopup.cols = data['headers'];
      this.controlStatusPopup.selectedColumns = this.controlStatusPopup.cols.slice(0, 6);
    }
    const rawData = data['data'];
    this.controlStatusPopup.colData = rawData;
    this.controlStatusPopup.totalRecords = data['total'];
  }

  // changes api urls on each row click based on row status
  rowClick(row) {
    const status = row.status;
    this.popupHeader = row.name;
    this.dataUrl = '/api/v1/control/status/' + status;
    this.isPopup = true;
  }

}
