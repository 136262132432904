<div class="container-fluid" >
        <div class="col-md-12 form-group">
                <div class="col-md-12 screen-shadow" >
                    <div style="height:30px;">
                         <b>Customers</b>
                    </div>
                    <app-onboardusermanagmant></app-onboardusermanagmant>
                </div>
        </div>
    <div class="col-md-12 form-group">
        <div class="col-md-4 screen-shadow" >
            <div style="height:30px;padding-top:3.5%;padding-bottom:8.5%;">
                 <b>Gluu Groups</b>
                 <div style="float:right;">
                    <p-button icon="fa fa-plus"  [style]="{'float':'right','font-size':'10px'}" title="Create Gluu Group"  iconPos="left" (click)="oncreateOrganization()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_settings_organization_gluu_create') !=-1" ></p-button>
                 </div>
            </div>
            <app-grid-table  [reload]="orgGrid.reloadEvent" [popup]="false" id="gridwithsmallwidth" [totalRecords]="orgGrid.totalRecords" [selectedColumns]="orgGrid.selectedColumns"
            [cols]="orgGrid.cols" height="248px" [colData]="orgGrid.colData" [org]="org" [dataUrl]="orgGridsUrl" (update)="loadGrid($event)"
            (rowSelect)="rowSelect($event)" [to]="0"></app-grid-table>
            <p-dialog header="Update group" *ngIf="associateorgDialog" [focusOnShow]='false' [(visible)]="associateorgDialog" [modal]="true"
            [responsive]="true" showEffect="fade" height='auto' width='350'>
            <div>
                <h5  class="first">Display Name</h5>
                <input class ="firstName" type="text" pInputText [(ngModel)]="orgdisplayName" style="width:80%;" disabled/>
                <label class="editIcon" (click)="enableIcon('firstName')"><i class="far fa-edit"></i></label>
                <span class ="firstName" style="display:none;">
                <label class="clearIcon" (click)="clearIcon('firstName')"><i class="fa fa-times" aria-hidden="true"></i></label>
                </span>
                <label style="color:red;"*ngIf='displayUpdateReq'>Display Name is required !</label>
                </div>
            <p-footer>
                    <p-button icon="fa fa-check-square" iconPos="right" label="Update" (click)="updateOrganization()"></p-button>
                    <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelReq()'></p-button>
            </p-footer>
          </p-dialog>
          <p-dialog header="Create group" *ngIf="createorgDialog" [focusOnShow]='false' [(visible)]="createorgDialog" [modal]="true"
          [responsive]="true" showEffect="fade" height='auto' width='350'>
          <div class="row">
                <div class="col-md-9 offset-md-9">
                    <form [formGroup]="createorgForm" (ngSubmit)="onSubmit()" id="creteorgform">
                         <div class="form-group">
                                <label>Display Name</label>
                                <label style="color:red"> *</label>
                                <input type="text" formControlName="displayName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.displayName.errors }" />
                                <div *ngIf="submitted && f.displayName.errors" class="invalid-feedback">
                                 <div *ngIf="f.displayName.errors.required">Display Name is required</div>
                               </div>
                     </div>
                    </form>
                </div>
            </div>
            <p-footer>
                    <button type="submit" form="creteorgform" class="btn btn-primary">Create Group</button>
                    <button  class="btn btn-primary" (click)='cancelCreateReq()'>Cancel</button>
            </p-footer>
        </p-dialog>
          <p-growl *ngIf="createReqerrormsg?.length!==0" [(value)]="createReqerrormsg"></p-growl>
        </div>
    </div>
  </div>
  
