
<div class="navbar navbar-default navbar-static-top" *ngIf="isAuth$ | async">
   <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img src="./assets/imgs/apcela-white-BG.png" class="img-responsive" alt="apcela" />
        </a>
        <div class="menu pull-right">
            <div class="user dropdown">
                <i class="fa fa-user fa-2x"></i>
                <div class="profile-block">
                    <span class="user-name"> {{displayName}} </span><br/>
                    <span class="user-name"> {{roleNameTobeDisplayed}} </span>
                </div>
            </div>
        </div>
        <div  class="menu pull-right timzonemain">
            <form [formGroup]="timezone"  class="timezoneForm">
                    <select class="custom-select" (change)="changeTimezone($event)" formControlName="timezonelabel" >
                       <option *ngFor="let timezonename of timezonelist"  [ngValue]="timezonename">{{timezonename}}</option>
                    </select>
                 </form>
        </div>
        <div class="menu pull-right" style="text-align: right;width:59%;padding-left:7%;">
                <marquee class="scroll-text" style="color:#000000f0;font-size: 15px;"> 
                    <span *ngFor="let futureEvents of futureEvents;let i =index;">
                         <span style="padding-left:2%;padding-right:2%;" class="glyphicon glyphicon-forward"></span>
                       <a><span (click)="showEvent(i)" style="cursor:pointer;">{{futureEvents.summary}}</span></a></span>
                 </marquee>
        </div>
    </div>
</div>
<p-confirmDialog></p-confirmDialog>
