import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { PasswordchangeComponent } from './passwordchange/passwordchange.component';
import { ProfileComponent } from './profile/profile.component';
import { UserService } from './service/user.service';
import { UserRoutingModule } from './user-routing.module';
import { FormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { CheckPasswordDirective} from '../utils/check-password.directive';
import { GrowlModule } from 'primeng/primeng';
import { NotEqualPasswordDirective}  from '../utils/not-equalpassword.directive';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    GrowlModule,
    PasswordModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [UserComponent,
    PasswordchangeComponent,
    ProfileComponent,
    CheckPasswordDirective,
    NotEqualPasswordDirective
  ],
  providers: [UserService]
})
export class UserModule { }
