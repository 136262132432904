<p-growl [(value)]="msgs" life="1000"></p-growl>
<div class="container-fluid">
     <div class="col-md-6 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('appsensor_device_grid') !=-1">
         <div class="col-md-12 screen-shadow">
              <app-appsensor-grid *ngIf="onOrgChange" [org]="org" (appsensorGridValueChange)='appsensorGridValueChange($event)'></app-appsensor-grid>
        </div>
    </div>
     <div class="col-md-6 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('appsensor_service_grid') !=-1">
        <div class="col-md-12 screen-shadow">
            <app-appsensor-service *ngIf="onOrgChange" [org]="org" (appsensorGridValueChange)='appsensorGridValueChange($event)'></app-appsensor-service>
        </div>
    </div>
    <div id="graph-block-2" class="col-md-12 form-group" *ngIf="isDisplayGraph">
      <section class="graphContainer">
          <div>
              <i class="fa fa-window-close-o close" aria-hidden="true" (click)="isDisplayGraph=false;" pTooltip="Close" tooltipPosition="left"></i>
          </div>
          <app-graph [graphs]="graphs" [graphName]="graphName" [isShow]="false"  [fullView]="fullView" [scrollTo]="scrollTo"></app-graph>
      </section>
  </div>
 </div>

