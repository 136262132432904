import { Injectable } from '@angular/core';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';

@Injectable()
export class CustomerSiteServiceService {

  constructor(private _httpService: PortalHttpServiceService) { }

  // get customer map data
  getMapData(limit, offset, org) {
    const url = '/api/v1/customer/sites/location';
    const body = {
      'from': new Date().getTime(),
      'limit': limit,
      'offset': offset,
      'orderBy': {},
      'query': '',
      'to': 0
    };
    return this._httpService.post(url, org, body);
  }

}

