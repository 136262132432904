import { Component, OnInit , Input, OnDestroy} from '@angular/core';
import {CustomEvents} from '../../events/customevents';
import {GrafanaService} from '../device/service/grafana.service';
import {PortalHttpServiceService} from '../../services/portal-http-service.service';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/primeng';
import { Observable } from 'rxjs/Rx';
@Component({
  selector: 'app-appsensor',
  templateUrl: './appsensor.component.html',
  styleUrls: ['./appsensor.component.scss']
})
export class AppsensorComponent implements OnInit , OnDestroy {
  @Input() org;
  role;
  msgs: Message[] = [];
  renewSession = false;
  urls = [];
  onOrgChange;
  isGrafanaSessionPresent;
  isApplicationPopUp;
  displayGraphListener: any;
  sub: any;
  graphCollection = [];
  graphs: any[] = [];
  isDisplayGraph;
  //  For graph
  graphName;
  scrollTo;
  fullView;
  graphData;
  isPermission;

  constructor(private _grafanaService: GrafanaService,
    private _customEvents: CustomEvents, private route: ActivatedRoute,
    private _portalhttp: PortalHttpServiceService ) {
      this.onOrgChange = true;
      this.isGrafanaSessionPresent = false;
      this.isApplicationPopUp = false;
      sessionStorage.setItem('islogged', 'true');
    }
  initializeDefaults() {
      this.isDisplayGraph = false;
    }
  ngOnInit() {

    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.org = params['org'] || sessionStorage.getItem('org');
       this.initializeDefaults();
        this.onOrgChange = true;
        this.reload();
      });
      const user = JSON.parse(sessionStorage.getItem('user'));
      user['orgs'].forEach(element => {
        if (element.key === this.org) {
         this.isPermission = element.permissions;
        }
      });
      this._customEvents.showHideLoader.emit({ show: false });
  }
// load graph container for net status
  loadGraphForAppsensor(value) {
    this.isDisplayGraph = false;
    this.graphs = [];
    const graphUrl = '/api/graph/appsensor/' + this.graphData.id + '/' + this.graphData.type + '/' + '?appsensor=' + this.graphData.code;
      this._portalhttp.get(graphUrl, this.org).subscribe((rest: any) => {
        if (rest['data'].length === 0) {
          return;
        } else {
          this.graphCollection = rest['data'];
          for (const item of this.graphCollection) {
            item['id'] = 'aa';
            item['type'] = 'appsensor';
            this.graphs.push(item);
            this.graphName = this.graphData.title;
            this.scrollTo = 'graph-block-2';
            this.fullView = false;
          }
          this.isDisplayGraph = true;
        }
      }, error => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', detail: 'Oops something went downhill' });
        this.isDisplayGraph = false;
        this.renewSession = true;
      }
      );
  }

    // call methods to set data for grids
    reload() {
      this._customEvents.showGrafanaOption.subscribe(value => {
        this.isGrafanaSessionPresent = true;
        this.loadGraphForAppsensor('');
        });
    }
    appsensorGridValueChange(data) {
      this.graphData = data;
      this.loadGraphForAppsensor('');
    }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.displayGraphListener) {
      this.displayGraphListener.unsubscribe();
    }
  }
}

