import { Component, OnInit, HostListener, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CustomEvents } from '../../../../../events/customevents';
import {SelectItem} from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import { Message } from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {SelectComponent } from '../../../../../../../node_modules/ng2-select/ng2-select';
import { PortalHttpServiceService } from '../../../../../services/portal-http-service.service';

@Component({
  selector: 'app-site-dropdown',
  templateUrl: './site-dropdown.component.html',
  styleUrls: ['./site-dropdown.component.scss'],
})
export class SiteDropdownComponent implements OnInit {
  @ViewChild(SelectComponent,{static:false}) select: SelectComponent;
  @ViewChild('Selectsite',{static:false}) public selectsite: SelectComponent;
  @Output() sitevalueChange = new EventEmitter();
  @Input() org;
  @Input() preSiteValue;
  customerSite: Array<any> = [];
  private value: any = {};
  private _disabledV = '0';
  private disabled = false;
  datachange;
  isdisable: boolean;
  constructor( private _portalhttp: PortalHttpServiceService) { }
  ngOnInit() {
  if (this.preSiteValue && this.preSiteValue.length === 3 ) {
    let siteName = this.preSiteValue[2] ? this.preSiteValue[2].toString() : '';
    this.datachange = [{id: siteName, text: siteName}];
    this.isdisable = true;
    if (siteName === '') {
      siteName = 'null';
    }
    this.sitevalueChange.emit(siteName);
    this.customerSite.push({});
  } else if (this.preSiteValue && this.preSiteValue.length === 6){
    let siteName = this.preSiteValue[2] ? this.preSiteValue[2].toString() : '';
    this.datachange = [{id: siteName, text: siteName}];
    this.isdisable = true;
    if (siteName === '') {
      siteName = 'null';
    }
    this.sitevalueChange.emit(siteName);
    this.customerSite.push({});

  } else if (this.preSiteValue.length === 4) {
    this.datachange = [{id: this.preSiteValue[3].toString(), text: this.preSiteValue[0].toString()}];
    this.sitevalueChange.emit(this.preSiteValue[0]);
    this.isdisable = true;
    this.customerSite.push({});
  } else {
    this.loadSites('');
    }
  }

  public loadSites (query) {
    const siteInfoUrl = '/api/v1/customer/sites';
    const body = {'limit': 100, 'offset': 0, 'query': query};
    this._portalhttp.post(siteInfoUrl, this.org, body).subscribe((res) => {
      this.customerSite = [];
     res['data'].forEach((sitedata: { Code: number, unique_customer_reference: string,
        Description: string}) => {
        this.customerSite.push({
          id: sitedata.Code,
          text: `<span>${sitedata.unique_customer_reference}</span>`
        });
      });
     }, (err) => {
      this.customerSite = [];
     });
  }
  public siteselected(value: any): void {
    const text = value.text.split('<br/>');
    this.select.active  = [text[0].toString().replace('<span>', '').replace('</span>', '')];
    this.sitevalueChange.emit(text[0].toString().replace('<span>', '').replace('</span>', ''));
  }
  public removed(value: any): void {
    this.sitevalueChange.emit('');
  }
  public typed(value: any): void {
    this.loadSites(value);
  }
  public refreshValue(value: any): void {
    this.value = value;
  }
  private get disabledV(): string {
    return this._disabledV;
  }
  private set disabledV(value: string) {
    this._disabledV = value;
    this.disabled = this._disabledV === '1';
  }

}
