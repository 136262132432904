import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpParameterCodec } from '@angular/common/http';
import { BaseCtrl } from '../../base/BaseCtrl';
import { CustomEvents } from '../../events/customevents';
import {JiraTicketServiceService} from '../../services/jira-ticket-service.service';


@Component({
  selector: 'app-logout-cmp',
  templateUrl: 'logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends BaseCtrl implements OnInit, OnDestroy, AfterViewInit {

  grafanalogoutUrl;
  gluulogoutUrl;
  logoutUrl;
  errorMessage: string;
  jiralogoutUrl;
  idpGluu;
  config;
  showJiraLogout = false;

  constructor(private _customEvents: CustomEvents, private _jirahttp: JiraTicketServiceService) {
    super();
    this._customEvents.showHideLoader.emit({ show: true });
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.logoutUrl = this.config['gluuUrl'] + '/identity/logout';
    this.grafanalogoutUrl = this.config['grafanaUrl'] + 'logout';
    const user = JSON.parse(sessionStorage.getItem('user'));
    const portalId = user['portalId'];
    const bodyreq = {
      'models': ['user', 'organisations', 'sharedPortal', 'helpCenterBranding', 'portal', 'portalWebFragments'],
      'options': {
        'portalId': portalId, 'portal': { 'id': portalId, 'expand': ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'] },
        'portalWebFragments': { 'portalPage': 'PORTAL' }
      }
    };
    this.config = JSON.parse(sessionStorage.getItem('config'));
    const getReqType = this.config['jiraUrl'] + '/api/rest/servicedesk/1/customer/models';
    this._jirahttp.validateJiraSession(getReqType, bodyreq).then((rest: any) => {
      if(rest['xsrfToken']){
        this.jiralogoutUrl = this.config['jiraUrl'] + '/servicedesk/customer/user/logout?atl_token=' +rest['xsrfToken'];
        this.showJiraLogout = true;
      }

      this._customEvents.showHideLoader.emit({ show: false });
      this.onLoad();

    }, error => {
      this._customEvents.showHideLoader.emit({ show: false });
      this.onLoad();
    }
    );
    this.gluulogoutUrl = this.config['gluuUrl'] + '/idp/logout.jsp';
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
  }
  ngOnDestroy() {
  }

  onLoad()  {
    sessionStorage.clear();
  }
}

export class UriEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
