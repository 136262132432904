import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import {FormsModule} from '@angular/forms';
import { AdvanceGridModule } from '../../../advance-grid/advance-grid.module';
import { JiraTicketsComponent } from './jira-tickets.component';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import {ButtonModule} from 'primeng/button';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {FormModule} from './form/form.module';
import { SafePipeModule } from '../../../utils/safe.pipe';
import {TabViewModule} from 'primeng/tabview';
import {GrowlModule} from 'primeng/growl';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DropdownModule} from 'primeng/dropdown';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {CalendarModule} from 'primeng/calendar';
import {ListboxModule} from 'primeng/listbox';
import { JiraTicketDetailsModule } from './jira-ticket-details/jira-ticket-details.module';
import { MaintenanceTicketDetailsModule } from './maintenance-ticket-details/maintenance-ticket-details.module';


 @NgModule({
  imports: [
    CommonModule,
    DialogModule,
    AdvanceGridModule,
    GridTableModule,
    TooltipModule,
    ButtonModule,
    FormModule,
    InputTextareaModule,
    FormsModule,
    FileUploadModule,
    SafePipeModule,
    TabViewModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    GrowlModule,
    DropdownModule,
   FullCalendarModule,
   OverlayPanelModule,
   CalendarModule,
   ListboxModule,
   InputTextModule,
   JiraTicketDetailsModule,
   MaintenanceTicketDetailsModule
  ],
  declarations: [JiraTicketsComponent],
  exports: [JiraTicketsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JiraTicketsModule { }
