import { Component, OnDestroy} from '@angular/core';
import { CustomEvents } from '../events/customevents';
import { BaseCtrl } from '../base/BaseCtrl';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-loader-cmp',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnDestroy {

  showLoader = true;
  showHideListener: any;

  constructor(private _customEvents: CustomEvents, private oidcSecurityService: OidcSecurityService) {
    this.showHideListener = this._customEvents.showHideLoader.subscribe((value: any) => {
      this.showLoader = value.show;
    });
  }

  ngOnDestroy() {
    if (this.showHideListener) {
      this.showHideListener.unsubscribe();
    }
  }
}
