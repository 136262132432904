import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { DeviceGridComponent } from './device-grid.component';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { DeviceGridService } from './device-grid.service';

@NgModule({
  imports: [
    CommonModule,
    GridTableModule,
    DialogModule,
    TooltipModule
  ],
  declarations: [DeviceGridComponent],
  exports: [DeviceGridComponent],
  providers: [DeviceGridService]
})
export class DeviceGridModule { }
