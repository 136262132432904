import { Component, OnInit , Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {CustomEvents} from '../../events/customevents';
import {SelectItem} from 'primeng/api';
import { Message } from 'primeng/primeng';
interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  showErrorPage = false;
  org: string;
  config;
  regions: any[];
  selectedregions: SelectItem[];
  userdata;
  regionvalue;
  isPermission;
  msgs: Message[] = [];

  constructor( private _customEvents: CustomEvents, private route: ActivatedRoute) {

    this.config = JSON.parse(sessionStorage.getItem('config'));

    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
  });
  this.userdata = JSON.parse(sessionStorage.getItem('user'));
  this.regionvalue = '';
  for ( let i = 0; i < this.userdata['orgs'].length; i++) {
    if (this.userdata['orgs'][i].key === this.org) {
      this.regionvalue = this.userdata['orgs'][i].regions;
      break;
    }
  }
this.regions = [];
for (let i = 0; i < this.regionvalue.length; i++) {
  this.regions.push({'label': this.regionvalue[i], 'value': {  'name':  this.regionvalue[i] }});
}
this.selectedregions =  this.regions[0];
const user = JSON.parse(sessionStorage.getItem('user'));
user['orgs'].forEach(element => {
  if (element.key === this.org) {
   this.isPermission = element.permissions;
  }
});

}

  ngOnInit() {
    
    const user = JSON.parse(sessionStorage.getItem('user'));
    user['orgs'].forEach(element => {
      if (element.key === this.org) {
       this.isPermission = element.permissions;
      }
    });
    this._customEvents.showHideLoader.emit({ show: false });
  }
  getRegionValue(dd) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'Security_region', header: headers });
    this.selectedregions = dd.value;
  }

}
