
    <div class="flex-container" style="cursor: default">
        <div class="column">
            <div class="col1height">
                <h4 class="background"><span>title</span></h4>
                <div>{{selectedData.summary}}</div>
            </div>
            <div class="ui-grid col1height ui-grid-responsive ui-fluid details">
                <h4 class="background"><span>Details</span></h4>
                <div class="ui-grid-row">
                    <div class="ui-grid-col-6">
                        <div>
                            <div class="ui-grid-col-4 highlightText"> Status </div>
                            <div class="ui-grid-col-8">{{(selectedData.status)?(selectedData.status):'N/A' }} </div>
                        </div>
                        <div>
                            <div class="ui-grid-col-4 highlightText"> Priority </div>
                            <div class="ui-grid-col-8">{{(selectedData.priority)?(selectedData.priority):'N/A' }} </div>
                        </div>

                        <div *ngFor="let fieldData of detailsField">
                            <div *ngIf="selectedData[fieldData.field]">
                                <div class="ui-grid-col-4 highlightText"> {{fieldData.header}} </div>
                                <div class="ui-grid-col-8">{{(selectedData[fieldData.field])?(selectedData[fieldData.field]):'N/A' }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui-grid-col-6">
                        <div>
                            <div class="ui-grid-col-4 highlightText"> Request Type </div>
                            <div class="ui-grid-col-8"> {{selectedData.requestType}}</div>
                        </div>
                        <div>
                            <div class="ui-grid-col-4 highlightText"> Organization </div>
                            <div class="ui-grid-col-8">
                                {{(selectedData.organization)?(selectedData.organization):'N/A' }}</div>
                        </div>
                    </div>
                </div>

            </div>

            <div>
                <h4 class="background"><span>Description</span></h4>
                <div class="ui-grid">
                    <div [innerHTML]="selectedData.description"></div>

                </div>
            </div>

        </div>
        <div class="column2">
            <div class="ui-grid ui-fluid col2details">
                <h4 class="background"><span>Ticket Link</span></h4>
                <div class="ui-grid">
                    <span class="ui-grid-col-6">{{selectedData.key}} </span> <span class="ui-grid-col-6"
                        *ngIf="selectedData.status; else notpresent"><i pTooltip="Open Ticket" tooltipPosition="right"
                            (click)="advanceIconClick(selectedData)" style="cursor: pointer;color: black"
                            class="fas fa-link" aria-hidden="true"></i>

                    </span>
                    <span class="ui-grid-col-6">
                        <ng-template #notpresent>
                            <span>N/A</span>
                        </ng-template>
                    </span>
                </div>

            </div>
            <div class="ui-grid ui-fluid col2details">
                <h4 class="background"><span>People</span></h4>
                <div class="ui-grid">
                    <span class="ui-grid-col-6 highlightText"> Reporter </span> <span
                        class="ui-grid-col-6">{{selectedData.reporter}}</span>
                    <span class="ui-grid-col-6 highlightText"> Creator </span> <span
                        class="ui-grid-col-6">{{selectedData.creator}}</span>
                </div>

            </div>
            <div class="ui-grid ui-fluid">
                <h4 class="background"><span>Dates</span></h4>
                <div class="ui-grid">
                    <span class="ui-grid-col-6 highlightText">Created </span> <span
                        class="ui-grid-col-6">{{selectedData.created}}</span>
                    <span class="ui-grid-col-6 highlightText"> Updated </span> <span
                        class="ui-grid-col-6">{{selectedData.updated}}</span>
                </div>
            </div>

        </div>
        <div class="column3">
            <app-jira-comment [org]="org" [issueId] = "selectedData.id" ></app-jira-comment>
        </div>
    </div>
