import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { MultiSelectModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { GridTableComponent } from './grid-table.component';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { GrowlModule } from 'primeng/growl';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule,
    CommonModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    MultiSelectModule,
    TableModule,
    ButtonModule,
    MessagesModule,
    PaginatorModule,
    GrowlModule,
    CalendarModule
  ],
  declarations: [GridTableComponent],
  exports: [GridTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GridTableModule { }
