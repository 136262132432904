<div style="height:100%">
    <app-date-picker blocktitle="Event" style="width:100%" collapseId="event" isExpand="true" (switchView)="switchView($event)"
        (refresh)="refresh($event)" (updateTime)="updateTime($event)" (expand)="openPopUp()" style="display: flow-root"></app-date-picker>
    <section *ngIf="eventData.state === 'Graph'" id="error">
        <app-graph-container [isGrafanaSessionPresent]="isGrafanaSessionPresent" [timeRangeToAppendEvent]="eventData.timeRangeToAppendEvent"
            [reloadEvent]="eventData.reloadEvent" [org]="org" urlid="Event"></app-graph-container>
    </section>
    <section *ngIf="eventData.state === 'Grid'">
        <app-grid-table [reload]="eventData.reloadEvent" [totalRecords]="eventData.totalRecords" [selectedColumns]="eventData.selectedColumns"
            [cols]="eventData.cols" [colData]="eventData.colData" [from]="eventData.from" [to]="eventData.to" [org]="org" [dataUrl]="dataUrl"
            (update)="loadGrid($event)" (rowSelect)="rowSelect($event)"></app-grid-table>
    </section>
</div>
<p-dialog [contentStyle]="{'height':'500px'}" *ngIf="isPopup" header="Events" [positionTop]="50" [(visible)]="isPopup" [modal]="dialogmode"
    showEffect="fade" [responsive]="true" styleClass="gridactive" resizable="true">
    <app-date-picker collapseId="eventpopup" [selectedstyle]="{'width':'600px'}" [state]="eventData.state" [selectedQuickRangeFrom]="eventData.rangeFrom"
        [selectedQuickRangeTo]="eventData.rangeTo" [dateFrom]="eventData.dateFrom" [dateTo]="eventData.dateTo" [timerangelabel]="eventData.label"
        (switchView)="switchPopupView($event)" (refresh)="refreshPopup($event)" (updateTime)="updatePopupTime($event)" style="display: flow-root"></app-date-picker>
    <section *ngIf="eventDataPopup.state === 'Graph'" id="error">
        <app-graph-container [isGrafanaSessionPresent]="isGrafanaSessionPresent" [timeRangeToAppendEvent]="eventDataPopup.timeRangeToAppendEvent"
        [reloadEvent]="eventDataPopup.reloadEvent" [org]="org" urlid="Event"></app-graph-container>
    </section>
    <section *ngIf="eventDataPopup.state === 'Grid'">
        <app-grid-table [popup]="true" [reload]="eventDataPopup.reloadEvent" [totalRecords]="eventDataPopup.totalRecords" [selectedColumns]="eventDataPopup.selectedColumns"
            [cols]="eventDataPopup.cols" [colData]="eventDataPopup.colData" [from]="eventDataPopup.from" [to]="eventDataPopup.to"
            [org]="org" [dataUrl]="dataUrl" (update)="loadPopupGrid($event)"></app-grid-table>
    </section>

</p-dialog>

<p-dialog *ngIf="isRowClick" header="Event Info : {{selectedData.system_ip}}" [(visible)]="isRowClick" [modal]="true" styleClass="gridactive"
    showEffect="fade" resizable="true" (onHide)="isRowClick=false; fullData=[]; moreInfo=false">
    <div class="ui-grid ui-fluid">
        <div class="ui-grid-row" *ngFor="let col of eventData.cols">
            <div class="ui-grid-col-4">
                <label *ngIf="selectedData[col.field]">{{col.header}}</label>
            </div>
            <div class="ui-grid-col-2">
                <span *ngIf="selectedData[col.field]">:</span>
            </div>
            <div class="ui-grid-col-6">
                <span *ngIf="col.field!=='details'">
                    {{selectedData[col.field]}}
                </span>
                <span *ngIf="col.field==='details'">
                    <span *ngFor="let item of subarry">
                        <div>{{item}}</div>
                    </span>
                </span>
            </div>
        </div>
    </div>
</p-dialog>