import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CircuitInformationComponent} from '../circuit-information/circuit-information.component';
import {ButtonModule} from 'primeng/button';
import {FormModule} from '../../jira/jira-tickets/form/form.module';
import {AccordionModule} from 'primeng/accordion';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormModule,
    AccordionModule
  ],
  declarations: [CircuitInformationComponent],
  exports: [CircuitInformationComponent]
})
export class CircuitInformationModule { }
