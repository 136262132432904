import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {AppsensorComponent} from './appsensor.component';
import { CommonModule } from '@angular/common';
import { AuthenticateService } from '../../session/authenticate.service';

import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import {AppsensorGridModule} from '../appsensor/appsensor-grid/appsensor-grid.module';
import {AppsensorServiceModule} from '../appsensor/appsensor-service-grid/appsensor-service.module';
import { GraphModule } from '../../graph/graph.module';
import { GrowlModule } from 'primeng/primeng';


@NgModule({
  imports: [
    CommonModule,
    GraphModule,
    AppsensorGridModule,
    AppsensorServiceModule,
    GrowlModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppsensorComponent],
  providers: [ PortalHttpServiceService, AuthenticateService],
  exports: [AppsensorComponent]
})
export class AppsensorModule { }
