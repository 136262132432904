import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ProgressBarModule} from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { MaintenanceTicketDetailsComponent } from './maintenance-ticket-details.component';
import { JiraCommentModule } from '../jira-comment/jira-comment.module';
import { GridTableModule } from '../../../../grid-table/grid-table.module';
import { FullCalendarModule } from 'primeng/fullcalendar';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule,
    ProgressBarModule,
    ButtonModule,
    FullCalendarModule,
    GridTableModule,
    JiraCommentModule
  ],
  declarations: [MaintenanceTicketDetailsComponent],
  exports: [MaintenanceTicketDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaintenanceTicketDetailsModule { }
