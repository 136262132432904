import { Component, OnInit, Input , OnDestroy} from '@angular/core';
import { DeviceStatusService } from './device-status.service';
import { TableData } from '../../../common/SharedClasses/TableData';
@Component({
  selector: 'app-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.scss']
})
export class DeviceStatusComponent implements OnInit , OnDestroy {
  @Input() org;
  data;
  summary;
  devices;
  deviceStatusPopup;
  popupHeader;
  dataUrl;
  isPopup;
  serviceListener;

  constructor(private service: DeviceStatusService) {
    this.devices = [];
    this.summary = {};
    this.data = [];
    this.deviceStatusPopup = new TableData();
    this.popupHeader = '';
    this.dataUrl = '';
    this.isPopup = false;
  }

  ngOnInit() {
   this.serviceListener = this.service.getAllDevices(this.org).subscribe((res) => {
      this.data = res['data'];
      if (this.data.length > 0) {
        for (const item of this.data) {
          let url;
          switch (item.deviceType) {
            case 'VEDGE':
              url = 'assets/vEdge.png';
              break;
            case 'VBOND':
              url = 'assets/vBond.png';
              break;
            case 'VSMART':
              url = 'assets/vSmart.png';
              break;
            case 'VMANAGE':
              url = 'assets/vManage.png';
              break;
          }
          if (!this.summary.hasOwnProperty(item.deviceType)) {
            this.summary[item.deviceType] = {
              name: item.deviceType,
              img: url,
              up: 0,
              down: 0
            };
          }
          if (item.reachablity === 'REACHABLE') {
            this.summary[item.deviceType].up = item.count;
          } else {
            this.summary[item.deviceType].down = item.count;
          }
        }
        this.devices = Object.values(this.summary);
      }
    }, err => {
      this.devices = [];
    });
  }

  // on arrow click this method is called
  deviceStatuspopUp(name, state) {
    this.popupHeader = name + ' (' + state + ')';
    this.dataUrl = '/api/v1/device/status/' + name + '/' + state;
    this.isPopup = true;
  }

  // Opens dialog box on each row click
  loadPopupGrid(data: any) {
    if (this.deviceStatusPopup.cols.length === 0) {
      this.deviceStatusPopup.cols = data['headers'];
      this.deviceStatusPopup.selectedColumns = this.deviceStatusPopup.cols.slice(0, 6);
    }
    const rawData = data['data'];
    this.deviceStatusPopup.colData = rawData;
    this.deviceStatusPopup.totalRecords = data['total'];
  }

  ngOnDestroy() {
    if (this.serviceListener) {
      this.serviceListener.unsubscribe();
    }
  }

}
