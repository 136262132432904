<div class="container-fluid">
    <div class="col-md-12 form-group">
        <div class="col-md-12 screen-shadow">
            <div style="height:30px">
                <b>Users</b>
                <div style="float:right;">
                <p-button
                    *ngIf="isAllowed('portal_settings_user_create')"
                    icon="fa fa-plus" label="Create" [style]="{'float':'right','font-size':'13px','margin-right':'5px'}" pTooltip="Create User" tooltipPosition="top"
                    iconPos="right" (click)="oncreateUser()"></p-button>
                <p-button
                    *ngIf="isAllowed('portal_settings_user_invite')"
                    icon="fa fa-plus" label="Invite" [style]="{'float':'right','font-size':'13px'}" pTooltip="Invite User" tooltipPosition="top"
                    iconPos="right" (click)="onInviteUser()"></p-button>
                </div>
            </div>
            <app-grid-table [reload]="groupGrid.reloadEvent" [popup]="false" id="gridwithsmallwidth"
                [totalRecords]="groupGrid.totalRecords" [selectedColumns]="groupGrid.selectedColumns"
                [cols]="groupGrid.cols" height="248px" [colData]="groupGrid.colData" [org]="org"
                [dataUrl]="groupGridsUrl" (update)="loadGrid($event)" (rowSelect)="rowSelect($event)" [to]="0">
            </app-grid-table>
            <p-dialog header="Update User" *ngIf="associategroupDialog" [focusOnShow]='false'
                [(visible)]="associategroupDialog" [modal]="true" [responsive]="true" showEffect="fade" height='auto'
                [width]="700">
                <div class="row">
                    <div class="col-md-9 offset-md-9">
                        <div>
                            <h5 class="first">First Name</h5>
                            <input class="firstName" type="text" pInputText [(ngModel)]="userfirstName"
                                style="width:50%;" disabled />
                            <label class="editIcon" (click)="enableIcon('firstName')"><i
                                    class="far fa-edit"></i></label>
                            <span class="firstName" style="display:none;">
                                <label class="clearIcon" (click)="clearIcon('firstName')"><i class="fa fa-times"
                                        aria-hidden="true"></i></label>
                            </span>
                        </div>
                        <div>
                            <h5 class="first">Middle Name</h5>
                            <input class="middleName" type="text" pInputText [(ngModel)]="usermiddleName"
                                style="width:50%;" disabled />
                            <label class="editIcon" (click)="enableIcon('middleName')"><i
                                    class="far fa-edit"></i></label>
                            <span class="middleName" style="display:none;">
                                <label class="clearIcon" (click)="clearIcon('middleName')"><i class="fa fa-times"
                                        aria-hidden="true"></i></label>
                            </span>
                        </div>
                        <div>
                            <h5 class="first">Last Name</h5>
                            <input class="lastName" type="text" pInputText [(ngModel)]="userlastName" style="width:50%;"
                                disabled />
                            <label class="editIcon" (click)="enableIcon('lastName')"><i class="far fa-edit"></i></label>
                            <span class="lastName" style="display:none;">
                                <label class="clearIcon" (click)="clearIcon('lastName')"><i class="fa fa-times"
                                        aria-hidden="true"></i></label>
                            </span>
                        </div>
                        <div>
                            <h5 class="first">Display Name</h5>
                            <input class="displayName" type="text" pInputText [(ngModel)]="userdisplayName"
                                style="width:50%;" disabled />
                            <label class="editIcon" (click)="enableIcon('displayName')"><i
                                    class="far fa-edit"></i></label>
                            <span class="displayName" style="display:none;">
                                <label class="clearIcon" (click)="clearIcon('displayName')"><i class="fa fa-times"
                                        aria-hidden="true"></i></label>
                            </span>
                        </div>
                        <div>
                            <h5 class="first">User Name</h5>
                            <input class="username" type="text" pInputText [(ngModel)]="username" style="width:50%;"
                                disabled />
                        </div>
                        <div>
                            <h5 class="first">Email</h5>
                            <input class="email" type="text" pInputText [(ngModel)]="useremail" style="width:50%;"
                                disabled />
                        </div>
                        <div>
                            <h5 class="first">Password</h5>
                            <input class="password" type="password" pInputText [(ngModel)]="userpassword"
                                style="width:50%;" disabled />
                            <label class="editIcon" (click)="enableIcon('password')"><i class="far fa-edit"></i></label>
                            <span class="password" style="display:none;">
                                <label class="clearIcon" (click)="clearIcon('password')"><i class="fa fa-times"
                                        aria-hidden="true"></i></label>
                            </span>
                        </div>
                        <div class="conpassword">
                            <h5 class="first">confirm Password</h5>
                            <input class="conpassword" type="text" pInputText [(ngModel)]="userconfirmpassword"
                                style="width:50%;" disabled />
                        </div>
                        <div>
                            <h5 class="first">Status</h5>
                            <p-radioButton name="group2" value="active" label="Active" [(ngModel)]="selectedValue"
                                inputId="preopt1">
                            </p-radioButton>
                            <p-radioButton name="group2" value="inactive" label="Inactive" [(ngModel)]="selectedValue"
                                inputId="preopt2"></p-radioButton>
                        </div>
                        <div class='admin'>
                            <h5 class="first">Role</h5>
                            <p-listbox [options]="updateCustomerRole" [style]="{'width':'310px'}"
                                [(ngModel)]="selectedupdateCustomerRole" multiple="multiple" checkbox="checkbox"
                                filter="filter" optionLabel="name">
                            </p-listbox>
                        </div>
                        <div style="margin-top:1.6%;" class="groupbox"
                            *ngIf="isAllowed('portal_settings_user_update_groups')">
                            <h5 class="first">Organizations</h5>
                            <p-pickList [source]="orgListDisplay" [target]="selectedorgListDisplay"
                                sourceHeader="Available" filterBy="label" targetHeader="Selected"
                                [responsive]="true" dragdrop="true" sourceFilterPlaceholder="Search Organization"
                                targetFilterPlaceholder="Search Organization" [showTargetFilter]='true'
                                [sourceStyle]="{'height':'200px','text-weight':'bold','width':'255px'}"
                                [targetStyle]="{'height':'200px','width':'250px'}" [showSourceControls]='false'
                                [showTargetControls]='false'>
                                <ng-template let-orgname pTemplate="item">
                                    <div class="ui-helper-clearfix">
                                        <div style="font-size:14px;float:left;margin:15px 5px 0 0">
                                            {{orgname.label}}</div>
                                    </div>
                                </ng-template>
                            </p-pickList>
                        </div>
                        <div style="margin-top:1.6%;" class="groupbox"
                            *ngIf="isAllowed('portal_settings_user_update_groups')">
                            <h5 class="first">Groups</h5>
                            <p-pickList [source]="groupListDisplay" [target]="selectedgroupListDisplay"
                                sourceHeader="Available" filterBy="displayName" targetHeader="Selected"
                                [responsive]="true" dragdrop="true" sourceFilterPlaceholder="Search by Group name"
                                targetFilterPlaceholder="Search by Group name" [showTargetFilter]='true'
                                [sourceStyle]="{'height':'200px','text-weight':'bold','width':'255px'}"
                                [targetStyle]="{'height':'200px','width':'250px'}" [showSourceControls]='false'
                                [showTargetControls]='false'>
                                <ng-template let-groupname pTemplate="item">
                                    <div class="ui-helper-clearfix">
                                        <div style="font-size:14px;float:left;margin:15px 5px 0 0">
                                            {{groupname.label}}</div>
                                    </div>
                                </ng-template>
                            </p-pickList>
                        </div>
                    </div>
                </div>
                <p-footer>
                    <p-button icon="fa fa-check-square" iconPos="right" label="Update" (click)="updateUser()"
                        *ngIf="isAllowed('portal_settings_user_update')">
                    </p-button>
                    <p-button icon="fa fa-qrcode" iconPos="right" label="Reset QR" (click)='resetQR()'
                        *ngIf="isAllowed('portal_settings_user_reset_qr')">
                    </p-button>
                    <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelReq()'></p-button>
                </p-footer>
            </p-dialog>
            <p-dialog header="Create User" *ngIf="createuserDialog" [focusOnShow]='false' [(visible)]="createuserDialog"
                [modal]="true" [responsive]="true" showEffect="fade" height='auto' [width]="700">
                <div class="row">
                    <div class="col-md-9 offset-md-9">
                        <form [formGroup]="createuserForm" (ngSubmit)="onSubmit()" id="creteform">
                            <div class="form-group">
                                <label>User Name</label>
                                <label style="color:red"> *</label>
                                <input type="text" formControlName="userName"  style ="width:63%;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.userName.errors }" />
                                <div *ngIf="submitted && createuserForm.controls.userName.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="createuserForm.controls.userName.errors.required">User Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Display Name</label>
                                <label style="color:red"> *</label>
                                <input type="text" formControlName="displayName" style ="width:63%;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.displayName.errors }" />
                                <div *ngIf="submitted && createuserForm.controls.displayName.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="createuserForm.controls.displayName.errors.required">Display Name is
                                        required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>First Name</label>
                                <label style="color:red"> *</label>
                                <input type="text" formControlName="firstName" style ="width:63%;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.firstName.errors }" />
                                <div *ngIf="submitted && createuserForm.controls.firstName.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="createuserForm.controls.firstName.errors.required">First Name is
                                        required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Middle Name</label>
                                <input type="text" formControlName="middleName" style ="width:63%;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.middleName.errors }" />
                            </div>
                            <div class="form-group">
                                <label>Last Name</label>
                                <label style="color:red"> *</label>
                                <input type="text" formControlName="lastName" style ="width:63%;"  class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.lastName.errors }" />
                                <div *ngIf="submitted && createuserForm.controls.lastName.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="createuserForm.controls.lastName.errors.required">Last Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Email</label>
                                <label style="color:red"> *</label>
                                <input type="text" formControlName="email" style ="width:63%;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.email.errors }" />
                                <div *ngIf="submitted && createuserForm.controls.email.errors" class="invalid-feedback">
                                    <div *ngIf="createuserForm.controls.email.errors.required">Email is required</div>
                                    <div *ngIf="createuserForm.controls.email.errors.email">Email must be a valid email
                                        address</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <label style="color:red"> *</label>
                                <input type="password" formControlName="password" style ="width:63%;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.password.errors }" />
                                <div *ngIf="submitted && createuserForm.controls.password.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="createuserForm.controls.password.errors.required">Password is required
                                    </div>
                                    <div *ngIf="createuserForm.controls.password.errors.minlength">Password must be at
                                        least 6 characters</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label> Confirm Password</label>
                                <label style="color:red"> *</label>
                                <input type="password" formControlName="confirmpassword" style ="width:63%;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && createuserForm.controls.confirmpassword.errors }" />
                                <div *ngIf="submitted && createuserForm.controls.confirmpassword.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="createuserForm.controls.confirmpassword.errors.required"> Confirm
                                        Password is required</div>
                                    <div *ngIf="createuserForm.controls.confirmpassword.errors.minlength"> Confirm
                                        Password must be at least 6 characters</div>
                                </div>
                                <div *ngIf="matchpwd" style="color:red;">The password and confirmation password do not
                                    match. !!</div>
                            </div>
                            <div class="form-group">
                                <label>Enable User</label><br />
                                <select class="form-control" formControlName="enableuser" style="width:43%;">
                                    <option value="" selected>active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Admin</label>
                                <p-listbox [options]="updateCustomerRole" formControlName="admin"
                                    [style]="{'width':'310px'}" [(ngModel)]="selectedupdateCustomerRole"
                                    multiple="multiple" checkbox="checkbox" filter="filter" optionLabel="name">
                                </p-listbox>
                            </div>
                            <div class="form-group groupbox"
                                *ngIf="isAllowed('portal_settings_user_create') !=-1">
                                <label>Organizations</label>
                                <p-pickList [source]="orgListDisplay" [target]="selectedorgListDisplay"
                                sourceHeader="Available" filterBy="label" targetHeader="Selected"
                                [responsive]="true" dragdrop="true" sourceFilterPlaceholder="Search Organization"
                                targetFilterPlaceholder="Search Organization" [showTargetFilter]='true'
                                [sourceStyle]="{'height':'200px','text-weight':'bold','width':'255px'}"
                                [targetStyle]="{'height':'200px','width':'250px'}" [showSourceControls]='false'
                                [showTargetControls]='false'>
                                <ng-template let-orgname pTemplate="item">
                                    <div class="ui-helper-clearfix">
                                        <div style="font-size:14px;float:left;margin:15px 5px 0 0">
                                            {{orgname.label}}</div>
                                    </div>
                                </ng-template>
                            </p-pickList>
                            </div>
                            <div class="form-group groupbox"
                                *ngIf="isAllowed('portal_settings_user_create') !=-1">
                                <label>Groups</label>
                                <p-pickList [source]="groupListDisplay" [target]="selectedgroupListDisplay"
                                sourceHeader="Available" filterBy="displayName" targetHeader="Selected"
                                [responsive]="true" dragdrop="true" sourceFilterPlaceholder="Search by Group name"
                                targetFilterPlaceholder="Search by Group name" [showTargetFilter]='true'
                                [sourceStyle]="{'height':'200px','text-weight':'bold','width':'255px'}"
                                [targetStyle]="{'height':'200px','width':'250px'}" [showSourceControls]='false'
                                [showTargetControls]='false'>
                                <ng-template let-groupname pTemplate="item">
                                    <div class="ui-helper-clearfix">
                                        <div style="font-size:14px;float:left;margin:15px 5px 0 0">
                                            {{groupname.label}}</div>
                                    </div>
                                </ng-template>
                            </p-pickList>
                                
                            </div>
                        </form>
                    </div>
                </div>
                <p-footer>
                    <button type="submit" form="creteform" class="btn btn-primary">Create User</button>
                    <button class="btn btn-primary" (click)='cancelCreateReq()'>Cancel</button>
                </p-footer>
            </p-dialog>
            <p-dialog header="Invite User" *ngIf="inviteuserDialog" [focusOnShow]='false' [(visible)]="inviteuserDialog"
                [modal]="true" [responsive]="true" showEffect="fade" height='auto' width='500'>
                <div class="row">
                    <div class="col-md-9 offset-md-9">
                        <form [formGroup]="createInviteuserForm" (ngSubmit)="updateInviteUser()"
                            id="createInviteuserForm">
                            <div class="form-group">
                                <label>User Email</label>
                                <label style="color:red"> *</label>
                                <input type="text" formControlName="inviteuserEmail" class="form-control" 
                                    [ngClass]="{ 'is-invalid': submittedInviteForm && f.inviteuserEmail.errors }" />
                                <div *ngIf="submittedInviteForm && f.inviteuserEmail.errors" class="invalid-feedback">
                                    <div *ngIf="f.inviteuserEmail.errors.required">Email is required</div>
                                    <div *ngIf="f.inviteuserEmail.errors.email || f.inviteuserEmail.errors.pattern">
                                        Email must be a valid email address</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <p-footer>
                    <button type="submit" form="createInviteuserForm" class="btn btn-primary">Invite User</button>
                    <button class="btn btn-primary" (click)='cancelIniteUserReq()'>Cancel</button>
                </p-footer>
            </p-dialog>
            <p-growl *ngIf="createReqerrormsg.length!==0" [(value)]="createReqerrormsg"></p-growl>
        </div>
    </div>
</div>