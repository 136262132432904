import { Component, OnInit, Input } from '@angular/core';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { TableData } from '../../../common/SharedClasses/TableData';
import { DatePickerEvents } from '../../../date-picker/date-picker.component';
import {CustomEvents} from '../../../events/customevents';

@Component({
  selector: 'app-event-grid',
  templateUrl: './event-grid.component.html',
  styleUrls: ['./event-grid.component.scss']
})
export class EventGridComponent implements OnInit, DatePickerEvents, GridTableEvents {

  @Input() org;
  @Input() isGrafanaSessionPresent;
  eventData;
  eventDataPopup;
  eventPopUpGrid;
  isPopup: boolean;
  selectedData;
  subarry;
  isRowClick;
  dataUrl;
  dialogmode;

  constructor(private _datePipe: DateConverterPipe, private _customEvents: CustomEvents) {
    this.eventData = new TableData();
    this.eventDataPopup = new TableData();
    this.isPopup = false;
    this.isRowClick = false;
    this.eventData.state = 'Graph';
    this.dialogmode = true;
  }

  ngOnInit() {
    this.dataUrl = '/api/v1/event';
    // to set data for event grid in the home page
    this.setInitialData();

  }

  // row wise popUp for event grid in homepage
  rowSelect(event) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'device_Events', header: headers });
    this.subarry = [];
    this.selectedData = event.data;
    if (this.selectedData.hasOwnProperty('details')) {
      this.subarry = this.selectedData.details.split(';');
    }
    this.isRowClick = true;
  }


  // sets data for event grid in home page
  setInitialData() {
    this.eventData.reloadEvent = '';
    this.eventData.isGraph = true;
  }

  // called when expand button clicked
  openPopUp() {
    this.eventDataPopup.timeRangeToAppendEvent = this.eventData.timeRangeToAppendEvent;
    this.eventDataPopup.reloadEvent = '';
    this.eventDataPopup.isGraph = true;
    this.eventDataPopup.state = this.eventData.state;
    this.isPopup = true;
  }

  // on grid click this method is called
  loadGrid(data: any) {
    if (this.eventData.cols.length === 0) {
      this.eventData.cols = data['headers'];
      const columns = [];
      this.eventData.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.eventData.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['entry_time'] = (element.hasOwnProperty('entry_time') && (element['entry_time'] !== 0)) ?
          this._datePipe.transform(element['entry_time']) : '';
      });
    }
    this.eventData.colData = rawData;
    this.eventData.totalRecords = data['total'];
  }

  // on popup grid click this method is called
  loadPopupGrid(data: any) {
    if (this.eventDataPopup.cols.length === 0) {
      this.eventDataPopup.cols = data['headers'];
      const columns = [];
      this.eventDataPopup.cols.slice(0, 6).forEach(element => {
        columns.push(element);
      });
      this.eventDataPopup.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['entry_time'] = element.hasOwnProperty('entry_time') && (element['entry_time'] !== 0) ?
          this._datePipe.transform(element['entry_time']) : '';
      });
    }
    this.eventDataPopup.colData = rawData;
    this.eventDataPopup.totalRecords = data['total'];
  }


  // swicthing gird to graph
  switchView(value) {
    const state = this.eventData.state;
    if (state !== value) {
      this.eventData.totalRecords = 0;
      this.eventData.colData = [];
      this.eventData.state = value;
    }

  }
  // refresh for grid
  refresh(event) {
    this.eventData.totalRecords = 0;
    this.eventData.colData = [];
    this.eventData.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
  }

  // swicthing gird to graph for popUP
  switchPopupView(value) {
    const state = this.eventDataPopup.state;
    if (state !== value) {
      this.eventDataPopup.totalRecords = 0;
      this.eventDataPopup.colData = [];
      this.eventDataPopup.state = value;
    }
  }
  // refresh for grid for popUP
  refreshPopup(event) {
    this.eventDataPopup.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.eventDataPopup.totalRecords = 0;
    this.eventDataPopup.colData = [];
  }

  // whenever time or date changes the state is maintained
  updateTime(event) {
    this.eventData.timeRangeToAppendEvent = event.time;
    this.eventData.from = event.from;
    this.eventData.to = event.to;
    this.eventData.label = event.label;
    this.eventData.rangeFrom = event.rangeFrom;
    this.eventData.rangeTo = event.rangeTo;
    this.eventData.dateFrom = event.dateFrom;
    this.eventData.dateTo = event.dateTo;
  }

  // dashboard state is carry forwarded to popUP
  updatePopupTime(event) {
    this.eventDataPopup.timeRangeToAppendEvent = event.time;
    this.eventDataPopup.from = event.from;
    this.eventDataPopup.to = event.to;
  }

}
