import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ProgressBarModule} from 'primeng/progressbar';
import { JiraCommentComponent } from './jira-comment.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule,
    ProgressBarModule,
    ButtonModule
  ],
  declarations: [JiraCommentComponent],
  exports: [JiraCommentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class JiraCommentModule { }
