import { Component, OnInit, Input } from '@angular/core';
import { TableData } from '../../../common/SharedClasses/TableData';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { CustomEvents } from '../../../events/customevents';
import { Message } from 'primeng/api';
import {SelectItem} from 'primeng/api';
import {PortalHttpServiceService} from '../../../services/portal-http-service.service';
import * as L from 'leaflet';
import { DataProviderService } from '../../../services/data-provider.service';
import { MessageBaseCtrl } from '../../../base/MessageBaseCtrl';

interface Product {
  name: string;
  code: string;
}

@Component({
  selector: 'app-customer-site',
  templateUrl: './customer-site.component.html',
  styleUrls: ['./customer-site.component.scss'],
})
export class CustomerSiteComponent extends MessageBaseCtrl implements OnInit, GridTableEvents {
  @Input() org;
  dataUrl;
  customersiteGrid;
  popup = false;
  isrowpopUP;
  isContact;
  primaryContacts;
  secondaryContacts;
  contactDetail;
  isOpenInfo;
  customersitePopup;
  map;
  rowDetails;
  isShowMap;
  marker;
  servicerequestDialog3 = false;
  sitemapdata;
  createReqerrormsg: Message[] = [];
  primaryDetail: any[];
  selectedPrimaryDetial: SelectItem[];
  secondaryDetail: any[];
  selectedsecondaryDetial: SelectItem[];
  isPrimaryDDEnable = false;
  issecondaryDDEnable = false;
  isvalidateDDEnable = false;
  showFooter = false;
  datauserUrl;
  userGrid;
  selectedItems;
  updateContactdata;
  isProductGrid = false;
  productGrid;
  dataProductUrl;
  updateSiteInfo = true;
  tempValue;
  customerContactDetail;
  validateContacts = false;
  notificationDetail;
  addOrEditDDEnable = false;
  productlist: SelectItem[];
  selectedProduct: Product;
  selectednotificationlistUser = [];
  selectedNotificationUserEmails = [];
  selectednotificationlistGroups = [];
  selectedNotificationGroupEmails = [];
  groupNotificationGrid;
  datagroupNotificationGridUrl;
  isvalidProduct = true;
  isupdateProductDetails = false;
  notificationIndex;
  NotificationselectedItems = [];
  isPermission;
  datalconuser;
  editForm = false;
  editFormPrimary = false;
  userfirstname;
  userlastname;
  useremail;
  userphone;
  usermobile;
  userother;
  usergridData;
  constructor(private _customEvents: CustomEvents,public _dataProvider: DataProviderService, private _portalhttp: PortalHttpServiceService,
     private _datePipe: DateConverterPipe) {
       super();
      const user = JSON.parse(sessionStorage.getItem('user'));
      user['orgs'].forEach(element => {
        if (element.key === this.org) {
         this.isPermission = element.permissions;
        }
      });
  }

  ngOnInit() {
    this.isContact = false;
    this.isrowpopUP = false;
    this.popup = false;
    this.isOpenInfo = false;
    this.isShowMap = false;
    this.customersiteGrid = new TableData();
    this.customersitePopup = new TableData();
    this.userGrid = new TableData();
    this.productGrid = new TableData();
    this.groupNotificationGrid = new TableData();
    this.dataUrl = '/api/v1/customer/sites';
    this. datauserUrl = '/api/v1/customer/employee';
    this.datalconuser = '/api/v1/customer/lcon/search';
    this.datagroupNotificationGridUrl = '/api/v1/notification/groups/search';
    this._customEvents.displayCustomerSiteEvt.subscribe((value: any) => {
      if (value.header === 'CustomerSite') {
          this.maprowSelect(value.data);
      }
    });
    const user = JSON.parse(sessionStorage.getItem('user'));
     user['orgs'].forEach(element => {
       if (element.key === this.org) {
        this.isPermission = element.permissions;
       }
     });
  }


  loadGrid(data: any) {
    if (this.customersiteGrid.cols.length === 0) {
      this.customersiteGrid.cols = data['headers'];
      const columns = [];

      this.customersiteGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          delete element.size;
          columns.push(element);
        }
      });
      columns.push({
        'field': 'jira',
        'header': '',
        'sortable': false,
        'size': '40px'
      });
      this.customersiteGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['last_validated_date'] = element.hasOwnProperty('last_validated_date') && (element['last_validated_date'] !== 0) ?
          this._datePipe.transform(element['last_validated_date']) : '';
      });
    }
    this.customersiteGrid.colData = rawData;
    this.customersiteGrid.totalRecords = data['total'];
  }
  loaduserGrid(data: any) {
    if (this.userGrid.cols.length === 0) {
      this.userGrid.cols = data['headers'];
      const columns = [];

      this.userGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          delete element.size;
          columns.push(element);
        }
      });
      this.userGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.userGrid.colData = rawData;
    this.usergridData = rawData;
    if (this.isupdateProductDetails === true) {
      this.productlist = [{
               label: this.notificationDetail[this.notificationIndex].Code,
               value: this.notificationDetail[this.notificationIndex].Code
               }];
               this.selectedProduct = this.productlist['label'];
                  const getRelationUrl = '/api/v1/customer/sites/notification/relation/'
               + this.notificationDetail[this.notificationIndex]['_id'];
           this._portalhttp.get(getRelationUrl, this.org).subscribe((rest) => {
           const userData = rest;
           this.selectednotificationlistUser = [];
           this.selectedNotificationUserEmails = [];
           if (userData) {
               this.selectedItems = userData['employees'];
               for (let i = 0; i < userData['employees'].length; i++) {
                this.selectednotificationlistUser.push(userData['employees'][i]['Email']);
                this.selectedNotificationUserEmails.push(userData['employees'][i]['Id']);
               }
           }
           }, error => {
           console.log('error');
           });
    } else {
      this.selectedItems = [];
    }
    this.userGrid.totalRecords = data['total'];
if (this.isPrimaryDDEnable === true) {
    if (Object.keys(this.customerContactDetail).length > 0) {
      for (let i = 0 ; i < this.userGrid.colData.length; i++) {
        if (this.customerContactDetail.primary_contact && Object.keys(this.customerContactDetail.primary_contact).length > 0) {
          if (this.userGrid.colData[i]['Id'] === this.customerContactDetail.primary_contact['Id']) {
            this.selectedItems = this.userGrid.colData[i];
          }
        }
      }
    }
  } else if (this.issecondaryDDEnable === true) {

    if (Object.keys(this.customerContactDetail).length > 0) {
      for (let i = 0 ; i < this.userGrid.colData.length; i++) {
        if (this.customerContactDetail.secondary_contact && Object.keys(this.customerContactDetail.secondary_contact).length > 0) {
          if (this.userGrid.colData[i]['Id'] === this.customerContactDetail.secondary_contact['Id']) {
            this.selectedItems = this.userGrid.colData[i];
          }
        }
      }
    }

  }
  }

  loadgroupNotificationGrid(data: any) {
    if (this.groupNotificationGrid.cols.length === 0) {
      this.groupNotificationGrid.cols = data['headers'];
      const columns = [];

      this.groupNotificationGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          delete element.size;
          columns.push(element);
        }
      });
      this.groupNotificationGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.groupNotificationGrid.colData = rawData;
    if (this.isupdateProductDetails === true) {
         const getRelationUrl = '/api/v1/customer/sites/notification/relation/'
               + this.notificationDetail[this.notificationIndex]['_id'];
           this._portalhttp.get(getRelationUrl, this.org).subscribe((rest) => {
           const notificationData = rest;
           if (notificationData) {
            this.selectednotificationlistGroups = [];
            this.selectedNotificationGroupEmails = [];
               this.NotificationselectedItems = notificationData['groups'];
               if (notificationData['groups']) {
               for (let i = 0; i < notificationData['groups'].length; i++) {
                const notificationgroup = notificationData['groups'][i]['Code'];
                if (notificationgroup) {
                this.selectednotificationlistGroups.push(notificationData['groups'][i]['Code']);
                this.selectedNotificationGroupEmails.push(notificationData['groups'][i]['_id']);
                }
               }
              }
           }
           }, error => {
           console.log('error');
           });
    } else {
      this.selectedItems = [];
    }
    this.groupNotificationGrid.totalRecords = data['total'];
  }

  loadProductGrid(data: any) {
    if (this.productGrid.cols.length === 0) {
      this.productGrid.cols = data['headers'];
      const columns = [];

      this.productGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          delete element.size;
          columns.push(element);
        }
      });
      this.productGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.productGrid.colData = rawData;
    this.productGrid.totalRecords = data['total'];
  }
  rowSelect(event) {
    this.isShowMap  = false;
    this.marker = null;
    this.editForm = false;
    this.editFormPrimary = false;
    this.contactDetail = event.data;
    this.isContact = true;
    this.updateSiteInfo = true;
    this.servicerequestDialog3 = false;
    this.isPrimaryDDEnable = false;
    this.isvalidateDDEnable = false;
    this.issecondaryDDEnable = false;
    this.addOrEditDDEnable = false;
    this. isupdateProductDetails = false;
    this.selectedItems = [];
    this.selectednotificationlistUser = [];
    this.selectedNotificationUserEmails = [];
    this.selectednotificationlistGroups = [];
    this.selectedNotificationGroupEmails = [];
    this.openMap(this.contactDetail);
  }
  maprowSelect(event) {
    this.isShowMap  = false;
    this.marker = null;
    this.editForm = false;
    this.editFormPrimary = false;
    this.contactDetail = event;
    this.isContact = true;
    this.updateSiteInfo = true;
    this.servicerequestDialog3 = false;
    this.addOrEditDDEnable = false;
    this. isupdateProductDetails = false;
    this.isPrimaryDDEnable = false;
    this.isvalidateDDEnable = false;
    this.issecondaryDDEnable = false;
    this.selectedItems = [];
    this.selectednotificationlistUser = [];
    this.selectedNotificationUserEmails = [];
    this.selectednotificationlistGroups = [];
    this.selectedNotificationGroupEmails = [];
    this.openMap(this.contactDetail);

  }
  rowSelect1(event) {
    this.updateContactdata = event;
  }

  showCreateTicket(data) {
   const headers = 'GtmEvents';
   this._customEvents.sendGtmEvt.emit({ data: 'SOC_CreateTicketinSOC', header: headers });
   this.servicerequestDialog3 = true;
    const sitedata = [];
    sitedata.push(data['unique_customer_reference']);
    sitedata.push(data['Code']);
    sitedata.push(data['last_validated_date']);
    sitedata.push(data['customer_site_id'] ? data['customer_site_id'] : '');
    this.sitemapdata = sitedata;
  }

  showContact(data) {
    this.contactDetail = {};
    this.contactDetail = data;
    this.primaryContacts = false;
    this.secondaryContacts = false;
    this.issecondaryDDEnable = false;
    this.isPrimaryDDEnable = false;
    for (const prop in data) {
      if (prop.startsWith('primary')) {
        if ((this.contactDetail.primary_contact_first_name) &&
          (this.contactDetail.primary_contact_first_name !== '') || (
            this.contactDetail.primary_contact_last_name) && (
            this.contactDetail.primary_contact_last_name !== '')) {
            this.primaryContacts = true;
        }
      } else if (prop.startsWith('secondary')) {
        if ((this.contactDetail.secondary_contact_first_name) &&
          (this.contactDetail.secondary_contact_first_name !== '') || (
            this.contactDetail.secondary_contact_last_name) && (
            this.contactDetail.secondary_contact_last_name !== '')) {
            this.secondaryContacts = true;
        }
      }
    }
    this.isContact = true;
  }
  cancelReq(data) {
    if (data === 'secondaryDetail') {
      this.issecondaryDDEnable = false;
    } else if ('primaryDetail') {
       this.isPrimaryDDEnable = false;
    } else {
      this.isvalidateDDEnable = false;
    }
  }
  enableIcon(data) {
    if (data === 'secondaryDetail') {
      if (Object.keys(this.customerContactDetail).length > 0) {
      for (let i = 0 ; i < this.userGrid.colData.length; i++) {
        if (this.customerContactDetail.secondary_contact && Object.keys(this.customerContactDetail.secondary_contact).length > 0) {
          if (this.userGrid.colData[i]['Id'] === this.customerContactDetail.secondary_contact['Id']) {
            this.selectedItems = this.userGrid.colData[i];
          }
        }
      }
    }
    this.isPrimaryDDEnable = false;
    this.isvalidateDDEnable = false;
    this.issecondaryDDEnable = true;
    this.editFormPrimary = false;
    } else if (data ===  'primaryDetail') {
      if (Object.keys(this.customerContactDetail).length > 0) {
        for (let i = 0 ; i < this.userGrid.colData.length; i++) {
          if (this.customerContactDetail.primary_contact && Object.keys(this.customerContactDetail.primary_contact).length > 0) {
            if (this.userGrid.colData[i]['Id'] === this.customerContactDetail.primary_contact['Id']) {
              this.selectedItems = this.userGrid.colData[i];
            }
          }
        }
      }
      this.isPrimaryDDEnable = true;
      this.isvalidateDDEnable = false;
      this.issecondaryDDEnable = false;
      this.editForm = false;
    } else {
      /*if (Object.keys(this.customerContactDetail).length > 0) {
        for (let i = 0 ; i < this.userGrid.colData.length; i++) {
          if (this.customerContactDetail.validatedBy && Object.keys(this.customerContactDetail.validatedBy).length > 0) {
            if (this.userGrid.colData[i]['Id'] === this.customerContactDetail.validatedBy['Id']) {
              this.selectedItems = this.userGrid.colData[i];
            }
          }
        }
      }
      this.isPrimaryDDEnable = false;
      this.isvalidateDDEnable = true;
      this.issecondaryDDEnable = false;*/
    }
  }
  enableSiteIcon(data) {
    this.updateSiteInfo = false;
    setTimeout(() => {
    const fistLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: 1, maxZoom: 13});
    this.map = L.map('sitemap', {
      zoom: 2,
      center: [0, 0],
      scrollWheelZoom: false,
      worldCopyJump: true,
      layers: [fistLayer]
    });
    let geoData = this.contactDetail['site_location'];
    if (geoData && geoData !== 'undefined') {
    if (typeof (geoData) === 'string') {
      geoData = geoData.replace('POINT(', '');
      geoData = geoData.replace(')', '');
      geoData = geoData.split(' ');
    }
    this.marker = L.marker(new L.LatLng(parseFloat(geoData[1]), parseFloat(geoData[0])), {
      icon: L.divIcon({
        html: ' <i class="fa fa-map-marker" style = "font-size:45px;color:#186BA0;margin-left:-13px;margin-top:-15px" aria-hidden="true"></i>',
        iconAnchor: [0, 0],
        iconSize: [0, 0],
      }),
      draggable: true,
    });
    this.marker.addTo(this.map);
    this.map.flyTo([parseFloat(geoData[1]), parseFloat(geoData[0])], 2);

 } else {
    this.map.on('click', function(e) {
      const lat = e.latlng.lat;
      const lon = e.latlng.lng;
      if (this.marker !== undefined) {
        this.removeLayer(this.marker);
      }
        this.marker = L.marker(new L.LatLng(parseFloat(lat), parseFloat(lon)), {
    icon: L.divIcon({
      html: ' <i class="fa fa-map-marker" style = "font-size:45px;color:#186BA0;margin-left:-13px;margin-top:-15px" aria-hidden="true"></i>',
      iconAnchor: [0, 0],
      iconSize: [0, 0],
    }),
    draggable: true,
  });
  this.marker.addTo(this);
  this.flyTo([parseFloat(lat), parseFloat(lon)], 10);
    } );
}
  }, 0);
  }
  editMap() {
     this.marker.dragging.enable();
  }
  enableSiteTextIcon(data) {
    $('.' + data).css('display', 'inline-block');
    $('.' + data).prop('disabled', false);
    this.tempValue = $('.' + data).val();
   }
   clearIcon(data) {
      $('.' + data).val(this.tempValue);
      $('.' + data).prop('disabled', true);
   }
  updateContact(data) {
    if (this.updateContactdata == null) {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please select a user!!' });
      return;
    }
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'SOC_updatesiteinfo', header: headers });
     const updateContactUrl = '/api/v1/customer/sites/contacts/' + this.contactDetail._id;
     const body = {
      'id': this.updateContactdata['Id'],
      'type': data
    };
    this._portalhttp.putWitoutRetry(updateContactUrl, this.org, body).subscribe(() => {
      this.isContact = false;
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated contact detail!' });
    }, error => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
    });
  }
  // to be impleented
  expandView() {
    this.isOpenInfo = true;
  }
  hideDialog(event) {
    this.isShowMap = false;
    this.marker = null;
    this.customersiteGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.isContact = false;
    this.addOrEditDDEnable = false;
    this. NotificationselectedItems = [];
    this.isupdateProductDetails = false;
    this.editForm = false;
    this.editFormPrimary = false;
    this.userfirstname = '';
    this.userlastname = '';
    this.useremail = '';
    this.userphone = '';
    this.usermobile = '';
    this.userother = '';
  }
  addOReditProduct() {
    this.selectednotificationlistUser = [];
    this.selectedNotificationUserEmails = [];
    this.selectednotificationlistGroups = [];
    this.selectedNotificationGroupEmails = [];
    this.isupdateProductDetails = false;
    this.isvalidProduct = false;
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'SOC_updatesiteNotification', header: headers });
    const productUrl = '/api/v1/circuits/site/products' + '?site=' + this.contactDetail['unique_customer_reference'];
    this._portalhttp.get(productUrl, this.org).subscribe((rest) => {
      if (rest['data'].length > 0) {
      this.productlist = rest['data'];
      if (this.productlist.length === 1) {
         this.isvalidProduct = false;
      }
      this.addOrEditDDEnable = true;
      } else {
        this.addOrEditDDEnable = false;
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'No Product found to add Notification!!' });

      }
     }, error => {
       console.log('error');
     });
  }
  changeProduct(event) {
    for (let i = 0; i < this.notificationDetail.length; i++) {
      if (this.notificationDetail.length !== 0 && this.notificationDetail[i]['Code'] === event.value) {
        this.isvalidProduct = true;
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Notification already define for this Product.!!' });
      } else {
        this.isvalidProduct = false;
      }
    }
  }
  cancelNotificationReq() {
    this.isContact = false;
    this.addOrEditDDEnable = false;
   this. NotificationselectedItems = [];
  }
  notificationUserRowSelect(event) {
    this.selectednotificationlistUser = [];
    this.selectedNotificationUserEmails = [];
    for (let i = 0; i < event.length; i++) {
    if (this.selectednotificationlistUser.indexOf(event[i]['Email']) === -1) {
    this.selectednotificationlistUser.push(event[i]['Email']);
    this.selectedNotificationUserEmails.push(event[i]['Id']);
    }
    }
  }
  notificationGroupRowSelect(event) {
    this.selectednotificationlistGroups = [];
    this.selectedNotificationGroupEmails = [];
    for (let i = 0; i < event.length; i++) {
  if (this.selectednotificationlistGroups.indexOf(event[i]['Code']) === -1) {
    this.selectednotificationlistGroups.push(event[i]['Code']);
    this.selectedNotificationGroupEmails.push(event[i]['_id']);
  }
    }
  }
  unselectnotificationUser(event) { }
  unselectnotificationGroups(event) { }

  editProductDetail(index) {
    this.notificationIndex = index;
    this.addOrEditDDEnable = true;
    this.isupdateProductDetails = true;
  }
  CreateProduct() {
    if (((!this.selectedProduct) || (!this.selectedProduct))) {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please Select Product !' });
      return;
    }
    if (this.selectedNotificationUserEmails.length === 0 &&  this.selectedNotificationGroupEmails.length === 0) {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please Select atleast one user or group !' });
      return;
    }
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'SOC_CreatesiteNotification', header: headers });
     const createProductUrl = '/api/v1/customer/sites/notification/' + this.contactDetail._id;
     const body = {
      'groupIds': this.selectedNotificationGroupEmails,
      'product': this.selectedProduct,
      'userIds': this.selectedNotificationUserEmails
    };
    this._portalhttp.post(createProductUrl, this.org, body).subscribe((res) => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully Added Product !' });
      const getNotificationUrl = '/api/v1/customer/sites/notification/' + this.contactDetail._id;
      this._portalhttp.get(getNotificationUrl, this.org).subscribe((rest) => {
        for (let i = 0; i < rest['data'].length; i++) {
         const group = rest['data'][i].groups;
          if (group) {
            rest['data'][i].groups = rest['data'][i].groups.split(',');
          }
          const user = rest['data'][i].emails;
          if (user) {
          rest['data'][i].users = rest['data'][i].emails.split(',');
          }
        }
        this.notificationDetail = rest['data'];
        this.addOrEditDDEnable = false;
       }, error => {
         console.log('error');
       });
      }, err => {
      });
  }
  updateProduct() {
     const updateProductUrl = '/api/v1/customer/sites/notification/' + this.notificationDetail[this.notificationIndex]._id;
     const body = {
      'groupIds': this.selectedNotificationGroupEmails,
      'userIds': this.selectedNotificationUserEmails
    };
    this._portalhttp.putWitoutRetry(updateProductUrl, this.org, body).subscribe(() => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated Product!' });
      const getNotificationUrl = '/api/v1/customer/sites/notification/' + this.contactDetail._id;
      this._portalhttp.get(getNotificationUrl, this.org).subscribe((rest) => {
        for (let i = 0; i < rest['data'].length; i++) {
         const group = rest['data'][i].groups;
          if (group) {
            rest['data'][i].groups = rest['data'][i].groups.split(',');
          }
          const users = rest['data'][i].emails;
          if (users) {
            rest['data'][i].users = rest['data'][i].emails.split(',');
          }
        }
        this.notificationDetail = rest['data'];
        this.addOrEditDDEnable = false;
        this.isupdateProductDetails = false;
       }, error => {
         console.log('error');
       });
    }, error => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
    });
  }
  tabChange(event) {
    if (event.index === 1) {
      this.customerContactDetail = '';
      const getAddress = '/api/v1/customer/sites/contacts/' + this.contactDetail._id;
      this._portalhttp.get(getAddress, this.org).subscribe((res) => {
       this.customerContactDetail = res;
       if (Object.keys(this.customerContactDetail).length > 0) {
        if (this.customerContactDetail.primary_contact && Object.keys(this.customerContactDetail.primary_contact).length > 0) {
          this.primaryContacts = true;
        } else {
          this.primaryContacts = false;
        }
        if (this.customerContactDetail.secondary_contact && Object.keys(this.customerContactDetail.secondary_contact).length > 0) {
          this.secondaryContacts = true;
        } else {
          this.secondaryContacts = false;
        }
        if (this.customerContactDetail.validatedBy && Object.keys(this.customerContactDetail.validatedBy).length > 0) {
          this.validateContacts = true;
        } else {
          this.validateContacts = false;
        }
      } else {
        this.primaryContacts = false;
        this.secondaryContacts = false;
        this.validateContacts =  false;
      }
      }, error => {
        this.primaryContacts = false;
        this.secondaryContacts = false;
        this.validateContacts =  false;
      });
    } else if (event.index === 2) {
      const getNotificationUrl = '/api/v1/customer/sites/notification/' + this.contactDetail._id;
      this._portalhttp.get(getNotificationUrl, this.org).subscribe((res) => {
        for (let i = 0; i < res['data'].length; i++) {
         const group = res['data'][i].groups;
          if (group) {
            res['data'][i].groups = res['data'][i].groups.split(',');
          }
          const users = res['data'][i].emails;
          if (users) {
            res['data'][i].users = res['data'][i].emails.split(',');
          }

        }
        this.notificationDetail = res['data'];
       }, error => {
         console.log('error');
       });
    }

  }

  updateSiteDetail() {
    if (this.marker && this.marker !== 'undefined') {
    const postion = this.marker.getLatLng();
    const curPos = this.marker.getLatLng().toString().replace('LatLng', 'POINT').replace(',', '');
    const locobj = {
      'Location': curPos };
    Object.assign(this.contactDetail, {geoAttributes: locobj});
    Object.assign(this.contactDetail, {latitude: postion['lat']});
    Object.assign(this.contactDetail, {longitude: postion['lng']});
    } else {
      if (this.map.marker && this.map.marker !== 'undefined') {
      const latlon = 'POINT(' + this.map.marker._latlng.lat + ' ' + this.map.marker._latlng.lng + ')';
      const locobj = {
        'Location': latlon };
      Object.assign(this.contactDetail, {geoAttributes: locobj});
      Object.assign(this.contactDetail, {latitude: this.map.marker._latlng.lat});
      Object.assign(this.contactDetail, {longitude: this.map.marker._latlng.lng});
      }
    }
    const mapData = Object.assign({}, this.contactDetail);
    mapData['site_location'] = '';
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'SOC_updatesiteinfo', header: headers });
    const updateSiteUrl = '/api/v1/customer/sites/' + this.contactDetail._id;
    this._portalhttp.putWitoutRetry(updateSiteUrl, this.org, mapData).subscribe(() => {
      this.customersiteGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated Site info!' });
      this.isContact = false;
      this.updateSiteInfo = true;
      this.isShowMap = false;
      this.marker = null;
    }, error => {
      this.customersiteGrid.reloadEvent = '';
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
    });
  }
  cancelsiteReq() {
    this.customersiteGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.isContact = false;
    this.updateSiteInfo = true;
    this.isupdateProductDetails = false;
    this.editForm = false;
    this.editFormPrimary = false;
  }
  cancelupdateReq() {
    this.editForm = false;
    this.editFormPrimary = false;
    this.customersiteGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.isContact = false;
    this.updateSiteInfo = true;
    this.isupdateProductDetails = false;
  }
  addLcon() {
    this.userfirstname = '';
    this.userlastname = '';
    this.useremail = '';
    this.userphone = '';
    this.usermobile = '';
    this.userother = '';
    this.editForm = true;
    this.editFormPrimary = false;
    this.issecondaryDDEnable = false;
  }
  addLconPrimary() {
    this.userfirstname = '';
    this.userlastname = '';
    this.useremail = '';
    this.userphone = '';
    this.usermobile = '';
    this.userother = '';
    this.editForm = false;
     this.editFormPrimary = true;
     this.isPrimaryDDEnable = false;
  }
  deleteLconPrimary(data) {
    this.contactDetail.primary_contact = 0;
    const updateContactUrl = '/api/v1/customer/sites/contacts/' + this.contactDetail._id;
    const body = {
     'id': 0,
     'type': data
   };
   const headers = 'GtmEvents';
   this._customEvents.sendGtmEvt.emit({ data: 'SOC_updatesiteContactDetails', header: headers });
   this._portalhttp.putWitoutRetry(updateContactUrl, this.org, body).subscribe(() => {
     this.isContact = false;
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated contact detail!' });
   }, error => {
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
   });
  }
  deleteLcon(data) {
    this.contactDetail.secondary_contact = 0;
    const updateContactUrl = '/api/v1/customer/sites/contacts/' + this.contactDetail._id;
    const body = {
     'id': 0,
     'type': data
   };
   const headers = 'GtmEvents';
   this._customEvents.sendGtmEvt.emit({ data: 'SOC_updatesiteContactDetails', header: headers });
   this._portalhttp.putWitoutRetry(updateContactUrl, this.org, body).subscribe(() => {
     this.isContact = false;
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated contact detail!' });
   }, error => {
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
   });
  }
  updateLcon(type) {
    if (this.useremail) {
      const expr = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;
       if (!expr.test(this.useremail)) {
         $('.emailerror').css('display', 'inline-block');
         return;
       } else {
         $('.emailerror').css('display', 'none');
       }
     }
    if (this.useremail !== '' || this.userphone !== null && this.userphone.trim() !== '') {
    const body =   {
      'Email': this.useremail,
      'FirstName': this.userfirstname,
     'LastName': this.userlastname,
      'Mobile': this.usermobile,
      'Phone': this.userphone,
      'other_details': this.userother
    };
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'SOC_updatesiteContactDetails', header: headers });
     const  creteuserURL = '/api/v1/customer/lcon?type=' + type + '&siteId=' + this.contactDetail._id;
     this._portalhttp.post(creteuserURL, this.org, body).subscribe((res) => {
     this.isContact = false;
     this.updateSiteInfo = true;
     this.editForm = false;
     this.editFormPrimary = false;
     this.customersiteGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully created LCON user !' });
       }, err => {
        this.customersiteGrid.reloadEvent  = '';
         this.createReqerrormsg = [];
         this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
       });
      } else {
        this.customersiteGrid.reloadEvent  = '';
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please Provide Email Id or Phone No.!' });

      }


  }

  // to be implemented
  loadPopupGrid(data) {
    if (this.customersitePopup.cols.length === 0) {
      this.customersitePopup.cols = data['headers'];
      this.customersitePopup.selectedColumns = this.customersitePopup.cols.slice(0, 5);
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach(element => {
        element['last_validated_date'] = element['last_validated_date'] && (element['last_validated_date'] !== 0) ?
          this._datePipe.transform(element['last_validated_date']) : 'N/A';
      });
    }
    this.customersitePopup.colData = rawData;
    this.customersitePopup.totalRecords = data['total'];
  }
  onReloadCustomerSites() {
    const reloadUrl = '/api/v1/customer/sites/sync';
    this._portalhttp.get(reloadUrl, this.org).subscribe((res) => {
      console.log(res['status']);
      }, err => {
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Scheduler service not available!' });
      });

  }

  openMap(item) {
    if (!this.marker) {
      this.isShowMap = true;
      setTimeout(() => {
        const fistLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: 1, maxZoom: 13 });
        this.map = L.map('sitemap', {
          zoom: 2,
          center: [0, 0],
          scrollWheelZoom: false,
          worldCopyJump: true,
          layers: [fistLayer]
        });
        let geoData = item['site_location'];
        if (geoData && geoData !== 'undefined') {
        if (typeof (geoData) === 'string') {
          geoData = geoData.replace('POINT(', '');
          geoData = geoData.replace(')', '');
          geoData = geoData.split(' ');
        }
        this.marker = L.marker(new L.LatLng(parseFloat(geoData[1]), parseFloat(geoData[0])), {
          icon: L.divIcon({
            html: ' <i class="fa fa-map-marker" style="font-size:45px;color:#186BA0;margin-left:-13px;margin-top:-15px" aria-hidden="true"></i>',
            iconAnchor: [0, 0],
            iconSize: [0, 0]
          })
        });
        this.marker.addTo(this.map);
        this.map.flyTo([parseFloat(geoData[1]), parseFloat(geoData[0])], 10);
      } else {
        this.isShowMap = false;
      }
      }, 0);
    } else {
      this.isShowMap = false;
      this.marker = null;
    }
  }
  closeForm(value) {
    this.servicerequestDialog3 = false;
    this.growlMessage = [];
    if(value['status'] !== 'cancel'){
      this.growlMessage.push(value['message']);
    } 
  }
}
