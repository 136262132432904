import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter  } from '@angular/core';
import { TableData } from '../../../common/SharedClasses/TableData';
import { CustomEvents } from '../../../events/customevents';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';

@Component({
  selector: 'app-appsensor-service',
  templateUrl: './appsensor-service.component.html',
  styleUrls: ['./appsensor-service.component.scss']
})
export class AppsensorServiceComponent implements OnInit, OnChanges {
  @Input() org;
  @Output() appsensorGridValueChange = new EventEmitter();
  appsensorServiceGrid;
  dataUrl;
  isRowSelect;
  title;
  moreInfo;
  moreservice;
  selectedData;
  header;
  telemetryServiceDetails;
  telemetryServiceDetailsUrl;

  constructor(private _customEvents: CustomEvents, public _datePipe: DateConverterPipe) {
    this.appsensorServiceGrid = new TableData();
    this.telemetryServiceDetails = new TableData();
    this.isRowSelect = false;
    this.moreInfo = true;
    this.moreservice = true;
  }
  ngOnChanges(changes: SimpleChanges) {
  }


  ngOnInit() {
    this.dataUrl = '/api/v1/appsensor/service';
  }

  rowSelect(event) {

  }

  loadGrid(data) {
    if (this.appsensorServiceGrid.cols.length === 0) {
      this.appsensorServiceGrid.cols = data['headers'];
      const columns = [];
      this.appsensorServiceGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      columns.push({
        'field': 'graph',
        'header': '',
        'size': '55px'
      });
      this.appsensorServiceGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    rawData.forEach(element => {
      if (element['ref_count'] !== 0) {
        element['graph'] = 'Graph';
       } else {
         element['graph'] = 'No Graph';
       }
  });
    this.appsensorServiceGrid.colData = rawData;
    this.appsensorServiceGrid.totalRecords = data['total'];

  }
  showGraph(event) {
    const title = event.Code + '-' + event.host;
    this.appsensorGridValueChange.emit({id: event._id, code: event.host, type: 'service', title: title });
  }

  loadPopupGrid(event) {

  }

}
