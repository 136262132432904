<div style="height:30px">
  <b>Transport Interface Distribution</b>
</div>

<app-grid-table [popup]="false" id="transport" [isGlobalSearch]="false" [paginator]="this.transportGrid.paginator" [totalRecords]="transportGrid.totalRecords"
  [selectedColumns]="transportGrid.selectedColumns" [isRowcount]="false" [cols]="transportGrid.cols" height="100px" [colData]="transportGrid.colData"
  [org]="org" [dataUrl]="dataUrl" (update)="loadGrid($event)" (rowSelect)="rowSelect($event)" [to]="0">
</app-grid-table>

<p-dialog *ngIf="isPopup" [header]="popupHeader" [(visible)]="isPopup" [modal]="true" showEffect="fade" [responsive]="true"
  styleClass="gridactive" resizable="true">
  <app-grid-table [popup]="true" [totalRecords]="transportGridPopup.totalRecords" [selectedColumns]="transportGridPopup.selectedColumns"
    [cols]="transportGridPopup.cols" [colData]="transportGridPopup.colData" [org]="org" [to]="0" [dataUrl]="dataUrlpopup" (update)="loadPopupGrid($event)">
  </app-grid-table>
</p-dialog>
