import { Injectable } from '@angular/core';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';

@Injectable()
export class AnalyticsService {

  constructor(private _httpService: PortalHttpServiceService) { }

  saveAnomaly(data) {
    const url = "api/v1/analytics/analyze";
    let org;
    this._httpService.defaultOrg.subscribe((res) => {
      org = res;
    });
    return this._httpService.post(url, org, data);
  }

  saveTraining(data) {
    const url = "api/v1/analytics/training";
    let org;
    this._httpService.defaultOrg.subscribe((res) => {
      org = res;
    });
    return this._httpService.post(url, org, data);
  }

  getAppsesnors() {
    let org;
    this._httpService.defaultOrg.subscribe((res) => {
      org = res;
    });
    const url = '/api/v1/analytics/trained/appsensor';
    return this._httpService.get(url, org);
  }

  getTrainedAppsesnors() {
    let org;
    this._httpService.defaultOrg.subscribe((res) => {
      org = res;
    });
    const url = '/api/v1/appsensor';
    return this._httpService.get(url, org);
  }

  getGraphs() {
    let org;
    this._httpService.defaultOrg.subscribe((res) => {
      org = res;
    });
    const url = '/api/graph/analytics';
    return this._httpService.get(url, org);
  }

}

