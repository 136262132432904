
<span class="screen-header" style="text-align:left;color:#000;"><b>Inventory</b></span>
<div style="float:right;">
  <p-button  icon="fa fa-refresh"  [style]="{'float':'right','font-size':'10px'}"  pTooltip="Data Sync" tooltipPosition="top" iconPos="left" (click)="onReloadCircuit()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_circuits_datasync') !=-1 "></p-button>    
</div>
<div style="float:right;height: 25px;">
<app-date-picker  [types]="types" (switchView)="switchView($event)"  isExpand="false" [isTime] ="false"
[isDropdown]= "false"  [isReload]= "false" [isExpand]="false"  style="display:flow-root; float:right;margin-top:-35.3%;width:108%;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_circuit_switch_view') !=-1"></app-date-picker>
</div>
<p style="padding-top:1%;"  *ngIf="circuiteData.state === 'Graph'" >
  <app-advance-grid id="circuitInformation" [totalRecords]="circuiteData.totalRecords" height="auto"  popup="true" [selectedColumns]="circuiteData.selectedColumns"
  [cols]="circuiteData.cols" [colData]="circuiteData.colData"   [org]="org" [dataUrl]="dataUrl" [requestTypeDropDown]="requestTypeDropDown"
  [normalDropDown]="normalDropDown" [multiselct]="multiselct" (update)="advanceLoadGrid($event)" (advanceRowSelect)="advanceRowSelect($event)"
  (advanceIconClick)="advanceIconClick($event)" [ticketicon]="circuitticketicon" [ticketTitle]="circuitticketTitle"></app-advance-grid>
   <p-growl *ngIf="createReqerrormsg.length!==0" life='3000' [(value)]="createReqerrormsg"></p-growl>
</p>
<app-form *ngIf="servicerequestDialog1" [greetMessage]="circuitdata" [org]='org' [reqType]="_dataProvider.getReportTroubleReqId()" (closeForm)="closeForm($event)"></app-form>
<p style="padding-top:2%;"  *ngIf="circuiteData.state === 'Grid'" >
    <app-customer-circuite-map [org]="org" [id]="id"></app-customer-circuite-map>
</p>
<div id ="circuit_info_details">
<div class="col-lg-12 col-offset-12" *ngIf="servicerequestDialog" style="border:1px solid lightgray;margin-top:2%;">
        <div style ="margin-bottom:2%;margin-top:2%;">
                <a style="float:right;" (click)="hideCircuiteInformation()"><i class="fa fa-times" aria-hidden="true" style ="font-size: 23Px;color:#000;font-weight:normal;"></i></a> 
           </div>
    <p-tabView>
            <p-tabPanel  header="Inventory Information" style="text-align:center;">
                    <p-accordion *ngIf="Header?.length !==0" [multiple]="true">
                            <p-accordionTab  *ngFor="let group of Header; index as i" [selected]="i === 0" header ="{{group.label}}" style="height:100%;color:red;">
                             <div class="row" *ngIf="Header?.length !==0">
                                <div class="col-lg-12 col-offset-12 ">
                                      <div class="col-lg-12 col-offset-11 " *ngFor="let formvalue of group.rows" style="padding-bottom:.7%;">
                                          <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;"> {{formvalue.field}}</span> </div>
                                          <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="!formvalue.ref" ><span  [innerHtml]='formvalue.value'></span></div>
                                          <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="formvalue.ref" ><span> <a title="{{formvalue.value}}" (click)='displaygridInfo(formvalue)' style="color:skyblue;text-decoration:underline;" [innerHtml]='formvalue.value'></a></span></div>
                                      </div>
                                </div>
                              </div>
                            </p-accordionTab>
                        </p-accordion>
                        <p-dialog [header]="inventoryInfoTitle" *ngIf="circuitInfoDialog" [(visible)]="circuitInfoDialog" [modal]="true" [responsive]="true" showEffect="fade" [style]="{height :'auto', width:'800'}">
                        <p-accordion   *ngIf="circuitInfoData?.length !==0" [multiple]="true">
                             <p-accordionTab  *ngFor="let circuitInfo of circuitInfoData; index as i" [selected]="i===0" header ="{{circuitInfo.label}}" style="height:100%;color:red;">
                                                <div class="row" *ngIf="circuitInfoData?.length !==0">
                                                <div class="col-lg-12 col-offset-12 ">
                                                        <div class="col-lg-12 col-offset-11 " *ngFor="let formvalue of circuitInfo.rows" style="padding-bottom:.7%;">
                                                        <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">{{formvalue.field}}</span> </div>
                                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="!formvalue.ref" ><span [innerHtml]='formvalue.value'></span>
                                                        </div>
                                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="formvalue.ref" ><span> <a title="{{formvalue.value}}" (click)='displaygridInfo(formvalue)' style="color:skyblue;text-decoration:underline;" [innerHtml]='formvalue.value'></a></span></div>
                                                        </div>
                                                </div>
                                                </div>
                               </p-accordionTab>
                                        </p-accordion> 
                                </p-dialog>
            </p-tabPanel>
            <p-tabPanel header="Associated Customer Inventory" style="text-align:center;" *ngIf="showReportTab">
                 <ng-template pTemplate="content">
                   <app-customer-circuit-report [org]='org' [slectedCircuit]='slectedCircuit'></app-customer-circuit-report>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Inventory Map" style="text-align:center;" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_circuit_detail_map') !=-1">
                <ng-template pTemplate="content">
                        <app-customer-circuit-map-detail [org]="org" [id]="detailId"></app-customer-circuit-map-detail>
               </ng-template>
           </p-tabPanel>
          
</p-tabView>
</div>
</div>