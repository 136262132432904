import { Component, ViewChild, Output, Input, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { Table } from 'primeng/table';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import { Message } from 'primeng/api';


@Component({
  selector: 'app-select-table',
  templateUrl: './select-table.component.html',
  styleUrls: ['./select-table.component.scss']
})
export class SelectTableComponent implements OnInit {
  @Input() popup: boolean;
  @Input() from;
  @Input() to;
  @Input() org: string;
  @Input() dataUrl: string;
  @Input() totalRecords = 0;
  @Input() cols: any[];
  @Input() selectedColumns = [];
  @Input() colData = [];
  @Input() reload;
  @Input() rows = 10;
  @Input() id;
  @Input() height = '100%';
  @Input() isGlobalSearch = true;
  @Input() paginator = true;
  @Input() isHeaderWrap = true;
  @Input() isRowcount = true;
  @Input() regionValue: string;
  @Input() selectedItems: any[];
  @Input() selectionMode: string;
  @Input() isCheckbox = true;
  @ViewChild('advancedTable',{static: false}) private _table: Table;
  offset = 0;
  filter;
  loading;
  sort;
  limit;
  clearSearch;
  @ViewChild('dashboarselectdtable',{static: false}) dashboardtable: Table;
  @Output() update = new EventEmitter<any>();
  @Output() rowSelect = new EventEmitter<any>();
  @Output() rowunSelect = new EventEmitter<any>();
  errormsg: Message[] = [];
  msgs: Message[] = [];
  constructor(private _portalhttp: PortalHttpServiceService) {
    this.loading = true;
    this.clearSearch = true;
    this.popup = false;
  }
  ngOnInit() {
  }
  
   //  on pagination, sort, and search
   loadDataLazy(event) {
    this.loading = true;
    if (Object.keys(event).length !== 0) {
      this.filter = event.globalFilter;
      if (event.rows) {
        this.limit = event.rows;
        this.offset = event.first / event.rows;
      }
      if (event.sortField) {
        this.sort = {
          [event.sortField]: (event.sortOrder === 1) ? 'ASC' : 'DESC'
        };
      }
    }
    this.loadData();
  }
   // to load data to grid make api call
   loadData() {
    if (this.dataUrl && this.dataUrl.endsWith('json')) {
      this._portalhttp.getLocalData(this.dataUrl).subscribe((res) => {
        this.loading = false;
        this.clearSearch = true;
        this.errormsg = [];
        this.update.emit(res);
      });
    } else if (this.dataUrl) {
      const body = {
        'limit': this.limit,
        'offset': this.offset,
        'from': this.from ? this.from : new Date().getTime(),
        'to': this.to,
        'orderBy': this.sort,
        'query': this.filter ? this.filter.trim() : ''
      };
      this.loading = true;
      if (this.regionValue !== undefined && ( this.regionValue['label'] || this.regionValue['name'] )) {
        this._portalhttp.postwithRegion(this.dataUrl, this.org, body,
          this.regionValue['label'] ? this.regionValue['label'] : this.regionValue['name']).subscribe((res) => {
          this.loading = false;
          this.clearSearch = true;
          this.errormsg = [];
          this.update.emit(res);
        }, err => {
          this.loading = false;
          this.errormsg = [];
          this.errormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
        });
      } else {
      this._portalhttp.post(this.dataUrl, this.org, body).subscribe((res) => {
        this.loading = false;
        this.clearSearch = true;
        this.errormsg = [];
        this.update.emit(res);
      }, err => {
        this.loading = false;
        this.errormsg = [];
        this.errormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
      });
    }
    } else {
      this.loading = false;
      this.update.emit();
    }
  }
   // on row click call respective parent methods
   onRowSelect(event) {
    this.rowSelect.emit(this.selectedItems);
  }
  onRowUnselect(event) {
    this.rowSelect.emit(this.selectedItems);
  }

}

// these are the methods that must be implemented in parent components
export interface GridTableEvents {
  loadGrid(event): void;
  rowSelect(event): void;
  rowunSelect(event): void;
}
