<span class="screen-header" style="text-align:left;color:#000;"><b>Groups</b></span>
<div style="float:right;">
<p-button  icon="fa fa-plus"  [style]="{'float':'right','font-size':'10px'}"  pTooltip="Create Group" tooltipPosition="top" iconPos="left" (click)="oncreateNotification()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_group_add') !=-1"></p-button>
</div>
<app-grid-table  [reload]="groupNotificationGrid.reloadEvent" [popup]="false" id="gridwithsmallwidth" [totalRecords]="groupNotificationGrid.totalRecords" [selectedColumns]="groupNotificationGrid.selectedColumns"
[cols]="groupNotificationGrid.cols" height="248px" [colData]="groupNotificationGrid.colData" [org]="org" [dataUrl]="groupNotificationGridUrl" (update)="loadGrid($event)"
(rowSelect)="rowSelect($event)" [to]="0"></app-grid-table>

<p-dialog header="Update Group" *ngIf="GroupNotificationDialog" [focusOnShow]='false' [(visible)]="GroupNotificationDialog" [modal]="true"
[responsive]="true" showEffect="fade" (onHide)="hideupdateDialog($event)" [position]="center"  [autoAlign] = "false"  width="1200"  height="auto" >
<div class="row">
    <div class="col-md-12 offset-md-9">
     <div id="deletegroup">
          <p-messages [(value)]="deletegroupmsg" [style]="{'display':'inline-block'}"></p-messages>
      </div>
    <div>
        <h5  class="first">Group Name</h5>
        <input class ="firstName" type="text" pInputText [(ngModel)]="groupName" style="width:50%;" disabled/>
    </div>
    <div>
       <h5  class="first">Selected user</h5>
       <p-chips [(ngModel)]="selectedUser" id="emailchip" [allowDuplicate]="false" [disabled]="true" (onRemove)="removeUser($event)"></p-chips>
    </div>
   <div *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_group_edit') !=-1">
        <h5  class="first">User Name</h5>
        <app-select-table *ngIf="GroupNotificationDialog" [popup]="false" [isCheckbox]="true" id="soc1" [isHeaderWrap]="false" [totalRecords]="userGrid.totalRecords" [selectedColumns]="userGrid.selectedColumns"
        [cols]="userGrid.cols" [colData]="userGrid.colData" [org]="org" [dataUrl]="datauserUrl" (update)="loaduserGrid($event)" [selectedItems]="selectedItems" 
        (rowSelect)="slectedUserName($event)" (rowunSelect)="unselectUserName($event)" >
     </app-select-table>
    </div>
  </div>
 </div>
<p-footer>
        <p-button icon="fa fa-check-square" iconPos="right" label="Update" (click)="updateNotification()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_group_edit') !=-1"></p-button>
        <p-button icon="fa fa-trash" iconPos="right" label="Delete" (click)="deleteGroup()" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_group_edit') !=-1"></p-button>
        <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelReq()'></p-button>
</p-footer>
</p-dialog>
<p-dialog header="Create Group" *ngIf="createGroupDialog" [focusOnShow]='false' [(visible)]="createGroupDialog" [modal]="true"
[responsive]="true" showEffect="fade" [position]="center"  [autoAlign] = "false"  width="1200"  height="auto" (onHide)="hideCreateDialog($event)">
<div class="row">
      <div class="col-md-12 offset-md-9">
          <form [formGroup]="createGroupForm" (ngSubmit)="onSubmit()" id="creteGroupform">
                  <div class="form-group">
                          <label>Group Name</label>
                          <label style="color:red"> *</label>
                          <input type="text" (keypress)="groupvalidate($event)" formControlName="creategroupName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.creategroupName.errors }" />
                          <div *ngIf="submitted && f.creategroupName.errors" class="invalid-feedback">
                           <div *ngIf="f.creategroupName.errors.required">Group Name is required</div>
                        </div>
               </div>
               <div class="form-group">
                    <h5  class="first">Selected user</h5>
                    <p-chips formControlName="creategroupselectedName" [disabled]="true" [allowDuplicate]="false" [(ngModel)]="selectedCreateUser"  (onRemove)="removeCreateUser($event)"></p-chips>
               </div>
               <div class="form-group">
                      <label>User Name</label>
                      <app-select-table *ngIf="createGroupDialog" [popup]="false" [isCheckbox]="true" id="soc1" [isHeaderWrap]="false" [totalRecords]="userGrid.totalRecords" [selectedColumns]="userGrid.selectedColumns"
                      [cols]="userGrid.cols" [colData]="userGrid.colData" [org]="org" [dataUrl]="datauserUrl" (update)="loaduserGrid($event)" [selectedItems]="selectedItems" 
                      (rowSelect)="slectedCreateUserName($event)" (rowunSelect)="rowUnselectCreateUser($event)"></app-select-table>
           </div>  
          </form>
      </div>
  </div>
  <p-footer>
          <button type="submit" form="creteGroupform" class="btn btn-primary">Create Group</button>
          <button  class="btn btn-primary" (click)='cancelCreateReq()'>Cancel</button>
  </p-footer>
</p-dialog>
<p-growl *ngIf="createReqerrormsg?.length!==0" [(value)]="createReqerrormsg"></p-growl>
