<div class="container-fluid">
    <p-growl [value]="msgs"></p-growl>
    <div class="col-md-12 form-group">
        <div class="col-md-12 formcontainer">
            <div id="mandatory"><sup> *</sup> Mandatory Fields </div>

            <div class="col-md-8">
                <h4><b>Analyse</b> <a pTooltip="Information" tooltipPosition="right" 
                    (click)="openErrorPopup()"><i class="fas fa-info-circle"></i></a></h4>
                <form [formGroup]="anomaly" *ngIf="isShowForm" class="seperator" id="anomalyform" (ngSubmit)="onSubmit()">

                    <div class="inputs">
                        <span class="ui-float-label">
                            <p-dropdown id="anomalydrop" *ngIf="appSensorlist.length>0" [options]="appSensorlist" formControlName="appSensor" optionLabel="name"
                                [(ngModel)]="selectedAppsensor"></p-dropdown>
                            <label for="float-input">AppSensor <sup> *</sup></label>
                        </span>

                        <span class="ui-float-label">
                            <p-calendar id="anomalyFrom" formControlName="fromDate" [showTime]="true" [(ngModel)]="fromDate"></p-calendar>
                            <label for="float-input">From <sup> *</sup></label>
                        </span>

                        <span class="ui-float-label">
                            <p-calendar id="anomalyTo" formControlName="toDate" [showTime]="true" [minDate]="fromDate"></p-calendar>
                            <label for="float-input">To <sup> *</sup></label>
                        </span>

                        <span class="ui-float-label">
                            <p-dropdown id="anomalydrop" [options]="schedulerlist" formControlName="scheduler" optionLabel="name"></p-dropdown>
                            <label for="float-input">Scheduler </label>
                        </span>
                    </div>

                    <div class="secondRow">

                        <div class="buttons">
                            <button class="btn btn-primary" type="submit" [disabled]="!anomaly.valid">Submit</button>
                            <button class="btn btn-primary" type="button" (click)="anomalyCancel()">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-md-4">
                <h4><b>Training</b></h4>
                <form [formGroup]="createTrainingForm" id="anomalyform" *ngIf="isCreateForm">
                    <div class="inputs">
                        <span class="ui-float-label">
                            <p-multiSelect id="traininganomalydrop" scrollHeight="100px" [options]="trainingappSensorlist"
                                maxSelectedLabels="0" selectedItemsLabel="{0} sensors selected" formControlName="trainingsensorList"
                                optionLabel="name"></p-multiSelect>
                            <label for="float-input">AppSensor <sup> *</sup></label>
                        </span>
                        <span class="ui-float-label">
                            <p-dropdown id="anomalydrop" [style]="{'width':'168px'}" [options]="trainingschedulerlist" formControlName="scheduler" optionLabel="name"></p-dropdown>
                            <label for="float-input">Scheduler</label>
                        </span>

                        <div class="buttons">
                            <button class="btn btn-primary" type="button" (click)="saveTrainingModel()" [disabled]="!createTrainingForm.valid">Submit</button>
                            <button class="btn btn-primary" type="button" (click)="createTrainingForm.reset()">Cancel</button>
                        </div>
                    </div>
                    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>
                </form>
            </div>
        </div>
    </div>

    <div class="col-md-12 form-group" id="result-block" *ngIf="graphs.length>0">
        <div class="col-md-12 screen-shadow result">
            <app-graph [graphs]="graphs" [graphName]="" [fullView]="fullView"></app-graph>
        </div>
    </div>
</div>


<p-dialog header="Threshold" *ngIf="isErrorpopup" [(visible)]="isErrorpopup" [modal]="true" showEffect="fade" [responsive]="true"
    resizable="true" (onHide)="isErrorpopup= false">
    <table class="table">
        <thead>
            <tr>
                <th>Hour Range</th>
                <th>Quarter</th>
                <th>Difference</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of information">
                <td>{{item.hour}}</td>
                <td>{{item.quarter}}</td>
                <td>{{item.error}}</td>
            </tr>
        </tbody>
    </table>

</p-dialog>


<p-dialog [header]="gridData.currentDevice.name" [contentStyle]="{'height':'400px'}" styleClass="gridactive" *ngIf="isGrid" [(visible)]="isGrid" [modal]="true" showEffect="fade" [responsive]="true"
    resizable="true" (onHide)="isGrid= false">
   <app-anomaly-result [currentSelection]="gridData.currentDevice" [selectedQuickRangeFrom]="gridData.selectedQuickRangeFrom" [selectedQuickRangeTo]="gridData.selectedQuickRangeTo" [url]="gridData.currentDevice.url" [timerangelabel]="gridData.timerangelabel"></app-anomaly-result>

</p-dialog>