<p-dialog header="XC Circuit Report" [(visible)]="display" *ngIf="display"  [modal]="true" [responsive]="true" [position]="center"  [autoAlign] = "false"  width="1200"  height="auto" >
  
  <p-table [columns]="selectedColumns" [value]="colData" #dashboardtable selectionMode="single"
   [scrollable]="true" [scrollHeight]="height" [resizableColumns]="true" exportFilename ="xc_circuits"
    [totalRecords]="totalRecords"  [responsive]="true" [reorderableColumns]="true" columnResizeMode="expand">
    
    <ng-template pTemplate="caption">
        <div class="popUpHeader" style="padding-bottom:2%;">
            <div class="search" [ngStyle]="(id =='gridwithautoheight')?{'visibility':'hidden'}:''" [ngClass]="(popup)?'searchinpopupheader':'searchinnormalheader'" style="text-align:left;">
                <span *ngIf="isGlobalSearch">
                    <input type="text" #dashfilter pInputText size="50" placeholder="Search" (input)="dashboardtable.filterGlobal($event.target.value, 'contains')">
                    <i class="fa fa-search" style ="position: relative;left:-30px;"></i>
                </span>
                <span>
                    <b> Total {{totalRecords}} Rows</b>
                </span>
            </div>
            <span style="float:right;position: relative;margin-left: 52%;">
                    <p-button icon="fa fa-download" iconPos="right" label="Download" (click)="dashboardtable.exportCSV()" ></p-button>
           </span>
        </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns">
        </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [style.width]="col.size" [ngClass]="(isHeaderWrap)?(popup)?'textwrapwithwidth':'textwrapheader':''"
                [pSortableColumn]="col.field" [ngStyle]="{'cursor':(col.sortable)?'pointer':'default'}" pResizableColumn
                pReorderableColumn>
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
        </tr>

    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" style="text-indent: 5px" [ngStyle]="{'cursor':(id =='transport' && rowData.value == 0)?'default':'pointer'}">
            <td *ngFor="let col of columns;let isLast = last" [style.width]="col.size" [ngClass]="popup?'textwrapwithwidth':'textwrapbody'"
                class="ui-resizable-column" [pTooltip]="(col.subfield1)?((col.subfield2)? (rowData[col.field][col.subfield1][col.subfield2]):rowData[col.field][col.subfield1]):rowData[col.field]"
                tooltipPosition="top">
                <span class="ui-column-title">{{col.header}}</span>
                <span *ngIf="(col.field !== 'graph') && (col.header !== 'State') && (col.field !== 'icon')">
                    {{rowData[col.field]}}
                </span>
                <span *ngIf="col.header === 'State'">
                    <i [ngClass]="(rowData[col.field]==='reachable')?'fa fa-arrow-up':'fa fa-arrow-down'" aria-hidden="true"></i>
                </span>
                <span *ngIf="col.field === 'graph'">
                    <i (click)="onShowGraph($event,rowData)" [ngClass]="(rowData.graph === 'Graph')?'fa fa-line-chart':'fa fa-ban'"></i>
                </span>
                <span *ngIf="col.field === 'icon'" pTooltip="Contact" tooltipPosition="top">
                    <i (click)="onShowContact($event,rowData)" class="fa fa-address-card-o" aria-hidden="true"></i>
                </span>
                <span *ngIf="col.field === 'jira'" pTooltip="Create Ticket" tooltipPosition="top">
                        <i (click)="onShowCreateTicket($event,rowData)" class="fas fa-list-ul" aria-hidden="true"></i>
                </span>
                <span *ngIf="col.field === 'createUser'" pTooltip="Create user" tooltipPosition="top">
                        <i (click)="onShowonCreateUser($event,rowData)" class="fas fa fa-plus" aria-hidden="true"></i>
                </span>
            </td>

        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                No records found
            </td>
        </tr>
    </ng-template>
</p-table>

</p-dialog>

<p-fileUpload name="myfile[]" accept=".csv" customUpload="true" (uploadHandler)="showDialog($event)"></p-fileUpload>