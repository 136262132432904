import { Component, ViewChild, Output, Input, EventEmitter, OnInit, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Table } from 'primeng/table';
import { PortalHttpServiceService } from '../services/portal-http-service.service';
import { Message } from 'primeng/api';
import { DatePipe } from '@angular/common';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import {SelectItem} from 'primeng/api';
import { CustomEvents } from '../events/customevents';


@Component({
  selector: 'app-advance-grid',
  templateUrl: './advance-grid.component.html',
  styleUrls: ['./advance-grid.component.scss'],
})
export class AdvanceGridComponent implements OnInit {

  @Input() popup: boolean;
  @Input() from;
  @Input() to;
  @Input() org: string;
  @Input() dataUrl: string;
  @Input() totalRecords = 0;
  @Input() cols: any[];
  @Input() selectedColumns;
  @Input() colData = [];
  @Input() reload;
  @Input() rows = 10;
  @Input() id;
  @Input() height = '225px';
  @ViewChild('advancedTable', {static: false}) private _table: Table;
  @Input() normalDropDown = [];
  @Input() multiselct = [];
  @Input() requestTypeDropDown = [];
  @Input() ticketicon: string;
  @Input() ticketTitle: string;
  selectedPriority = [];
  @Input() showDownload = false;
  types = [
    {label: 'None', value: 'None'},
    {label: 'Group By', value: 'groupby'},
];
selectedType;

  offset = 0;
  filter;
  loading;
  sort;
  limit;
  clearSearch;
  createDate: Date;
  updateDate: Date;
  advanceFilter = {};
  selectgroupgDialog = false;
  groupName: any[];
  selectedgroup;
  excelHeaders;
  @ViewChild('dashboardtable', {static: false}) dashboardtable: Table;
  @Output() update = new EventEmitter<any>();
  @Output() advanceRowSelect = new EventEmitter<any>();
  @Output() advanceIconClick = new EventEmitter<any>();

  errormsg: Message[] = [];
  msgs: Message[] = [];
  errorgroupbymsg: Message[] = [];
  fetchAllRecordData = [];

  constructor(private _portalhttp: PortalHttpServiceService, private _customEvents: CustomEvents) {
    this.loading = true;
    this.clearSearch = true;
    this.popup = false;
    this.selectedPriority = [];
  }


  ngOnInit() {
}
fetchAllrecords(pageNo, limit) {
    if (this.dataUrl) {
      const body = {
        'limit': limit,
        'offset': pageNo,
        'from': this.from ? this.from : new Date().getTime(),
        'to': this.to,
        'orderBy': this.sort,
        'query': this.filter ? this.filter.trim() : '',
        'filter': this.advanceFilter,
      };
      this.loading = true;
      this._portalhttp.post(this.dataUrl, this.org, body).subscribe((res) => {
        this.fetchAllRecordData.push(res['data']);
        const total = res['total'];
        const newOffset = pageNo + 1;
        if (newOffset * limit < total ) {
          this.loading = false;
          this.fetchAllrecords(newOffset , limit);
        } else {
          this.loading = false;
          this.genereateExcel(this.fetchAllRecordData, this.selectedgroup);
          return;
        }
        }, err => {
        this.loading = false;
        this.errormsg = [];
        this.errormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
        return;
      });
  }
}

  // filter for create and updated
  dateFilter(value, field, dt) {
    if (field === 'created') {
      value = this.createDate.toUTCString();
    } else {
      value = this.updateDate.toUTCString();
    }
    if (!value || value !== 'undefined') {
      dt.filter(value, field, 'gt');
    }
  }

  //  on pagination, sort, and search
  loadDataLazy(event) {
    this.loading = true;
    this.advanceFilter = {};
    if (Object.keys(event).length !== 0) {
      this.filter = event.globalFilter;
      for (const key of Object.keys(event.filters)) {
        const value = event.filters[key];
        if (value.value instanceof Array) {
          this.advanceFilter[key] = value.value;
        } else {
          this.advanceFilter[key] = value.value.trim();
        }
      }
      if (event.rows) {
        this.limit = event.rows;
        this.offset = event.first / event.rows;
      }
      if (event.sortField) {
        this.sort = {
          [event.sortField]: (event.sortOrder === 1) ? 'ASC' : 'DESC'
        };
      }
    }
    this.loadData();
  }

  // to load data to grid make api call
  loadData() {
    if (this.dataUrl) {
      const body = {
        'limit': this.limit,
        'offset': this.offset,
        'from': this.from ? this.from : new Date().getTime(),
        'to': this.to,
        'orderBy': this.sort,
        'query': this.filter ? this.filter.trim() : '',
        'filter': this.advanceFilter,
      };
      this.loading = true;
      this._portalhttp.post(this.dataUrl, this.org, body).subscribe((res) => {
        this.loading = false;
        this.clearSearch = true;
        this.errormsg = [];
        this.groupName = [];
        const headerValue = res['headers'];
        this.excelHeaders = res['headers'];
        this.groupName.push({'label': 'None', 'value':   'none'});
        for (let i = 0; i < headerValue.length; i++) {
          this.groupName.push({'label': headerValue[i].header, 'value':  headerValue[i].field });
        }
        this.selectedgroup = 'none';
        this.update.emit(res);
      }, err => {
        this.loading = false;
        this.errormsg = [];
        this.errormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
      });
    } else {
      this.loading = false;
      this.update.emit();
    }
  }

  // on row click call respective parent methods
  onRowSelect(event) {
    this.advanceRowSelect.emit(event);
  }
  genereateExcel(res, groupby) {
    const headers = [];
    let key ;
    if (groupby !== 'none') {
      key = groupby;
    } else {
       key = '';
    }
    const colheader = this.excelHeaders;
    for (let  i = 0; i < colheader.length; i++) {
      const col = { 'header': colheader[i]['header'], 'key': colheader[i]['field'], 'width': 20 };
      if ( key === colheader[i]['field']) {
         headers.unshift(col);
      } else {
      headers.push(col);
      }
    }
     const Ticketworkbook = new Excel.Workbook();
     const worksheet = Ticketworkbook.addWorksheet('Tickets');
     worksheet.columns = headers;
     const excelData = [].concat.apply([], res);
    const rowData = excelData.sort(function(a, b) {
    var nameA=a[key], nameB=b[key]
    if (nameA < nameB) //sort string ascending
        return -1 
    if (nameA > nameB)
        return 1
    return 0 //default return value (no sorting)
});
     for (let i = 0; i < rowData.length; i++) {
         worksheet.addRow(rowData[i]);
     }
     worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'cccccc' },
      bold: true
      };
      worksheet.getRow(1).font = { bold: true };
    if(key){
     const idCol = worksheet.getColumn(key);
     const  mergerCol = idCol.values;
     const group = mergerCol.reduce((r, a, i) => {
     const index = idCol.letter + i;
      r[a] = [...r[a] || [], index];
      return r;
     }, {});
     for (let [key, value] of Object.entries(group)) {
       let objValue:any = [];
         objValue = value;
       if (objValue.length > 1) {
        let mergeValue = objValue[0] + ':' + objValue[objValue.length -1];
        worksheet.mergeCells(mergeValue);
       }
    }
  }
     worksheet.autoFilter = 'A1:N1';
     const headersevt = 'GtmEvents';
     this._customEvents.sendGtmEvt.emit({ data: 'customerService_exoportExcel', header: headersevt });
     Ticketworkbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let DateTime = new Date();
      let month = DateTime.getMonth() + 1;
      const Filename = 'Ticket_' + DateTime.getDate() + '_' + month + '_' + DateTime.getFullYear();
      fs.saveAs(blob, Filename + '.xlsx');
    });
  }
  download() {
    this.selectedType = 'None';
    this.selectgroupgDialog = true;
  }
  selectgroupBy() {
    this.selectgroupgDialog = false;
    this.fetchAllrecords(0, 100);
  }
  // for soc, on contact icon click
  OnIconClick(event, row) {
    this.advanceIconClick.emit(row);
    event.stopPropagation();
  }


}

// these are the methods that must be implemented in parent components
export interface AdvanceGridTableEvents {
  advanceLoadGrid(event): void;
  advanceRowSelect(event): void;
  advanceLoadPopupGrid?(event): void;
  advanceIconClick?(data): void;
}
