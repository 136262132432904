<div>
    <nav class="navbar navbar-default">
      <div class="navbar-header">
        <a class="navbar-brand" [pTooltip]="blocktitle" tooltipPosition="right">
          <b>{{blocktitle}}</b>
        </a>
      </div>
      <div class="collapse navbar-collapse" [attr.id]="collapseId">
        <ul class="nav navbar-nav navbar-right">
          <li *ngIf="isTime">
            <a herf="#" (click)="panel2.toggle($event)" pTooltip="Time" tooltipPosition="left">{{timerangelabel}}
              <i class="fa fa-clock-o" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <p-overlayPanel class="ui-g ui-fluid"  #panel2 id="customdatepicker1" >
      <div class="ui-g-12 ui-md-6">
        <h5>Quick Ranges</h5>
        <div class="border">
          <ul class="quickrange">
            <div class="col-xs-12 col-sm-6">
              <div class="row">
                <li *ngFor="let range of quickRangeHour" class="col-xs-12" >
                  <a herf="#" (click)="panel2.hide(); selectQuickRange($event,range)">{{range.label}}</a>
                </li>
              </div>
            </div>
            <li *ngFor="let range of quickRangeDays" class="col-xs-12 col-sm-6">
              <a herf="#" (click)="panel2.hide(); selectQuickRange($event,range)">{{range.label}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div>
          <div class="ui-g-12 ui-md-6">
              <h5>Select Month</h5>
              <div id='dropdown' style="padding-bottom:10%;">
                  <select [value]="selectedyear" >
                      <option *ngFor="let year of yerslist" value={{year}}>
                          {{year}}
                        </option>
                      </select>
               </div>
                  <span *ngFor="let range of quickRangeMonth"  style="float:left;padding-left: 5%;padding-right: 10%;">
                     <a herf="#" (click)="panel2.hide(); selectQuickRangeMonth($event,range)">{{range.label}}</a>
                   </span>
            </div>
      </div>
    </p-overlayPanel>
  </div>
