<p-dialog header="Create Request" (onHide)="cancelReq()" [(visible)]="enableDialogue" [modal]="true" [positionTop]="0" [baseZIndex]="10000"
    [responsive]="true" showEffect="fade"  [maximizable]="true" [style]="{width: '50vw'}">
<div class="container-fluid">
  <div class="col-md-12 form-group">
    <div class="col-md-12 screen-shadow">
      <section>
          <div class="col-md-8 col-offset-6">
            <h5  class="first">Request Type</h5>
            <p-dropdown #dd [options]="circuitRequesTypeObject" (onChange)="reloadForm(dd)" [(ngModel)]="selectedRequestype" [style]="{'width':'450px'}">
            </p-dropdown>
            <div *ngFor="let field of requestAttr" [ngSwitch]="field.fieldType"  style ="width:450px" >
                <h5 class="first" *ngIf="field.label !=='Raise this request on behalf of'">{{field.label}}
                <label class="first" *ngIf="field.required == true && field.label  !=='Raise this request on behalf of'" style="color:red;">*</label></h5>
                <input style ="width:450px" class="formtext" *ngSwitchCase="'text'" type="text" size="61" id="{{field.fieldId}}"  (keyup)="enableBtn(field)" [placeholder] = "getPlaceHolder(field)" pInputText autocomplete="off">
                <input  style ="width:450px" *ngSwitchCase="'multiuserpicker'" class="formtext" type="text" size="61" id="{{field.fieldId}}"  (keyup)="enableBtn(field)"  pInputText autocomplete="off">
                <textarea style ="width:450px" class="formtext" *ngSwitchCase="'textarea'" rows="5" cols="61" id="{{field.fieldId}}" (keyup)="enableBtn(field)" [(ngModel)]="description" [placeholder] = "getPlaceHolder(field)" pInputTextarea  autocomplete="off"></textarea>
                <p-dropdown   *ngSwitchCase="'select'" placeholder="Select" [style]="{'width':'450px'}"  [options]="field.values"  (onChange)="selectOption($event,field.fieldId, field)"></p-dropdown>
                <div *ngIf ="field.label ==='Impact'" style ="width:450px" class="priority">
                   <h5 *ngIf ="displayPriority" class="first" >Priority</h5>
                   <div *ngIf ="displayPriority">
                   <i class="fa fa-arrow-up"   *ngIf ="priorty.label ==='Highest' || priorty.label ==='High'" aria-hidden="true" style="color:red;"></i>
                   <i class="fa fa-arrow-up"   *ngIf ="priorty.label ==='Medium'" aria-hidden="true" style="color:orange;"></i>
                   <i class="fa fa-arrow-down" *ngIf ="priorty.label ==='Low' || priorty.label ==='Lowest'" aria-hidden="true" style="color:green;"></i>
                   <label>{{priorty.label}}</label>
                   <label style="padding-left:4%;">Response Target : </label>
                   <label> {{priorty.response_SLA}} </label>
                   <label style="padding-left:4%;">Resolution Target : </label>
                   <label> {{priorty.resolution_SLA}} </label>
                  </div>
                </div>
                <div *ngSwitchCase="'radio'">
                  <p-radioButton #rd *ngFor="let virtualInterfaceType of field.values" name="virtualInterfaceType" (onClick)="selectRadio(rd,field.fieldId, field)" [value]="virtualInterfaceType.value" [label]="virtualInterfaceType.label"></p-radioButton>
                </div>
                <div class="formtext" style="width:450px;">
                <div draggable="true"  *ngSwitchCase="'attachment'" style="border: 2px dashed lightgrey;;margin-top: 10px;padding: 10%;">
                    <div>
                      <div>
                         Drag & Drop to upload your files or
                        <a href="javascript:void(0)" (click)="file.click()"  style="color: deepskyblue;">
                           browse
                        </a>
                        <input type="file"
                               #file
                               [multiple]="(maxFiles > 1)"
                               (change) = "onFileChange($event)"
                               style="display:none" id="fileuploadcreatereq"/>
                               <div style="text-align: center;">
                                  <ul style="padding:0px;">
                                    <li *ngFor ="let filesup of uploadedFiles;index as i" style="border: 1px solid lightgray; font-size: 12px;font-weight: 100;list-style-type: none;">
                                      <label style="font-size: 14px;">{{filesup.filename}}</label>
                                      <label style="padding-left:4%">{{filesup.filesize}}</label>
                                      <button type="text"  pButton  label="X"  (click)="removeFiles(i)" style="height:11%;margin-top:10%;"></button>
                                    </li>
                                  </ul>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 *ngSwitchCase="'organisationpicker'" class="first">Share With</h5>
                <p-dropdown [style]="{'width':'450px'}" *ngSwitchCase="'organisationpicker'" autofocus = true [autoDisplayFirst]=true [options]="requestusertype" (onChange)="selectOrg($event,field.fieldId)" optionLabel="name" [(ngModel)]="selectedOrg"></p-dropdown>
            </div>
            <div *ngIf="_dataProvider.isPermission(org, 'portal_form_create_ticket_site_name')">
            <div *ngIf="enableCheck">
            <h5 class="first">More Options <p-inputSwitch [(ngModel)]="displaycircuitoption" (onChange)="handleChange($event)"></p-inputSwitch></h5>
            </div>
            <div  style="width:450px;overflow: visible;" *ngIf="displaycircuitoption">
              <h5  class="first">Customer Sites</h5>
             <app-site-dropdown (sitevalueChange)='sitevalueChange($event)' [org] ="org" [preSiteValue]='greetMessage' ></app-site-dropdown>
             <div style ="width:450px;" *ngIf="siteName && siteName !==''">
              <h5  class="first">Customer Circuit</h5>
              <app-circuit-dropdown [sitename] ='siteName' [org] ="org" (circuitvalueChange)='circuitvalueChange($event)' [precircuitValue]='greetMessage' ></app-circuit-dropdown>
            </div>
            </div>
            </div>
              <div style="position: absolute;padding-left: 3%; margin-left: 27%;float: left;top: 86%;">
                <p-growl *ngIf="growlMessage.length!==0"[(value)]="growlMessage"></p-growl>
            </div>
          </div>
      </section>
    </div>
  </div>

</div>
<p-footer>

  <div *ngIf="createLoader">
    <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
  </div>
  <label  *ngIf="!_dataProvider.isStringEmpty(errorMessage)" style="color:red;">{{errorMessage}}</label>
  <p-button icon="fa fa-check-square" [disabled]="enableButton" iconPos="right" label="Create"  (click)="createRequest()">
  </p-button>
  <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelReq()'></p-button>

</p-footer>
</p-dialog>
