import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef,
  ViewChildren, QueryList} from '@angular/core';
import { AdvanceGridTableEvents } from '../../../advance-grid/advance-grid.component';
import { TableData } from '../../../common/SharedClasses/TableData';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { Message } from 'primeng/api';
import { CustomEvents } from '../../../events/customevents';
import {SelectItem} from 'primeng/api';
import * as jQuery from 'jquery';
import momentPlugin from '@fullcalendar/moment-timezone';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

(window as any).jQuery = (window as any).$ = jQuery;
import * as moment from 'moment-timezone';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { MessageBaseCtrl } from '../../../base/MessageBaseCtrl';
import { DataProviderService } from '../../../services/data-provider.service';
import { JiraService } from '../jira.service';


@Component({
  selector: 'app-jira-tickets',
  templateUrl: './jira-tickets.component.html',
  styleUrls: ['./jira-tickets.component.scss'],
})
export class JiraTicketsComponent extends MessageBaseCtrl implements OnInit, AfterViewInit, AdvanceGridTableEvents {
  @Input() org;
  jiraData;
  jiraDataPopup;
  openTicketDialogue;
  selectedData;
  multiselct;
  normalDropDown;
  requestTypeDropDown;
  detailsField;
  servicerequestDialog = false;
  kbDialog = false;
  requestUrl;
  searchResult;
  kbtitle;
  KBURL;
  createReqerrormsg: Message[] = [];
  showSearchIcon = true;
  kbsReqType;
  kbsReqTypeoption;
  iconurl;
  showStanderReq = true;
  childmessage: any[] = [];
  reqType;
  showJiraGrid = false;
  showCalendar = false;
  jiraOptionData;
  ticketicon;
  ticketTitle;
  displayemptyResponse;
  showHideCreateTicketPopup: any;
  reqGroups;
  showKBsLoader = false;
  events: any[];
  header;
  options;
  eventDetailsDialog = false;
  eventDetails;
  datechange: any[];
  selecteddateList: SelectItem[];
  selectedTimezone;
  startdate;
  enddate;
  dateTo;
  dateFrom;
  exportCalDialog = false;
  groupName: any[];
  selectedgroup;
 
  fetchAllRecordData = [];
  isJiraSessionPresent = true;
  activepanel = 1;
  showCalendarLoader = false;
  excelHeader;
  @ViewChildren('sc', { read: true }) fcs: QueryList<any>;
  constructor(public _datePipe: DateConverterPipe, public _dataProvider: DataProviderService,
    private _jiraService: JiraService,
    private _customEvents: CustomEvents,
    private cd: ChangeDetectorRef) {
      super();
    }
  ngOnInit() {
    this.jiraData = new TableData();
    this.jiraDataPopup = new TableData();
  
    this._customEvents.showJiraOption.subscribe(value  => {
    this.isJiraSessionPresent = false;
    this.showStanderReq = true;
    this.jiraOptionData = JSON.parse(sessionStorage.getItem('jiraData'));
    const data1 = this.jiraOptionData['portal']['reqTypes'];
    this.reqGroups = this.jiraOptionData['portal']['reqGroups'];
    this.kbsReqType = data1;
    this.kbsReqTypeoption = data1;
    this.iconurl = this._dataProvider.getJiraUrl(this._dataProvider.JIRA_SERVICEDESK_ICON);
    }
      );
    const jiraSessionPresent = sessionStorage.getItem('JiraPresent');
    if (jiraSessionPresent === 'true') {
      this.isJiraSessionPresent = false;
    } else {
      this.isJiraSessionPresent = true;
    }
    this.showJiraGrid = true;
    this.loadCalender();
    this.jiraData.cols = [];
    this.ticketicon = 'fas fa-link';
    this.ticketTitle = 'Ticket Link';
    this.jiraOptionData = JSON.parse(sessionStorage.getItem('jiraData'));
    const data = this.jiraOptionData['portal']['reqTypes'];
    this.reqGroups = this.jiraOptionData['portal']['reqGroups'];
    this.kbsReqType = data;
    this.kbsReqTypeoption = data;
   
}
  ngAfterViewInit() {
    this.cd.detectChanges();
  }

 loadCalender() {
  this.options = {};
  this.options = {
    plugins: [momentPlugin , dayGridPlugin, timeGridPlugin, interactionPlugin , listPlugin],
    timeZone: this._dataProvider.getTimezone(),
    header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,timeGridDay,listMonth'
    },
    events: function(start) {
      eventdata(start);
    },
    eventClick: function($event) {
      eventClickData($event);
    },
    eventRender: function(event) {
      const data = JSON.parse(sessionStorage.getItem('user'));
      const timezone =  data['zone'];
      let start;
    if (timezone === 'local') {
        start =  moment(event.event.start).format('Z');
      } else {
       start = moment.tz(event.event.start, timezone).format('Z');
      }
      $(event.el).find('.fc-title').before(
        $('<div class="tzo"/>').text(start)
      );
    },
    datesRender: function($event) {
       daydata($event);
    }
  };
  const eventdata = ((value) => {
    this.startdate =  Date.parse(value.start);
    this.enddate = Date.parse(value.end);
    this.loadEvents(this.startdate, this.enddate);
  });
  const daydata = ((value) => {
    this.startdate =  Date.parse(value.view.activeStart);
    this.enddate = Date.parse(value.view.activeEnd);
    this.loadEvents(this.startdate, this.enddate);
  });
  const eventClickData = ((value) => {
    this.handleEventClick(value);
  });
 }

 showExportDialog() {
   this.exportCalDialog = true;
 }
 exportExcelFile() {
  const startDate = moment(this.dateFrom).format('x');
  const enddate =  moment(this.dateTo).format('x');
  if (startDate && enddate) {
    this.showCalendarLoader = true;
    this._jiraService.get(this._dataProvider.SERVICE_TICKET_MAINTENANCE + '?start=' + startDate + '&end=' + enddate, this.org).subscribe((res) => {
      this.fetchAllRecordData.push(res['data']);
      this.showCalendarLoader = false;
      this.genereateExcel(this.fetchAllRecordData, this.selectedgroup);
    }, () => {
      this.showCalendarLoader = false;
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
    });
  } else {
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please Select Satrt Date and End Date!!' });
  }
}

 genereateExcel(res, groupby) {
  const headers = [];
  let key ;
  if (groupby !== 'none') {
    key = groupby;
  } else {
     key = '';
  }
  const colheader = this.excelHeader;
  for (let  i = 0; i < colheader.length; i++) {
    const col = { 'header': colheader[i]['header'], 'key': colheader[i]['field'], 'width': 20 };
    if ( key === colheader[i]['field']) {
       headers.unshift(col);
    } else {
    headers.push(col);
    }
  }
   const Ticketworkbook = new Excel.Workbook();
   const worksheet = Ticketworkbook.addWorksheet('Events');
   worksheet.columns = headers;
   const excelData = [].concat.apply([], res);
  const rowData = excelData.sort(function(a, b) {
  var nameA=a[key], nameB=b[key]
  if (nameA < nameB) //sort string ascending
      return -1 
  if (nameA > nameB)
      return 1
  return 0 //default return value (no sorting)
});
   for (let i = 0; i < rowData.length; i++) {
       worksheet.addRow(rowData[i]);
   }
   worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'cccccc' },
    bold: true
    };
    worksheet.getRow(1).font = { bold: true };
  if(key) {
   const idCol = worksheet.getColumn(key);
   const  mergerCol = idCol.values;
   const group = mergerCol.reduce((r, a, i) => {
   const index = idCol.letter + i;
    r[a] = [...r[a] || [], index];
    return r;
   }, {});
   for (let [key, value] of Object.entries(group)) {
     let objValue: any = [];
       objValue = value;
     if (objValue.length > 1) {
      const mergeValue = objValue[0] + ':' + objValue[objValue.length  - 1 ];
      worksheet.mergeCells(mergeValue);
     }
  }
}
   worksheet.autoFilter = 'A1:N1';
   const headersEvt = 'GtmEvents';
   this._customEvents.sendGtmEvt.emit({ data: 'customerService_exoportExcelCal', header: headersEvt });
   Ticketworkbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let DateTime = new Date();
    let month = DateTime.getMonth() + 1;
    const Filename = 'Ticket_' + DateTime.getDate() + '_' + month + '_' + DateTime.getFullYear();
    fs.saveAs(blob, Filename + '.xlsx');
  });
}

  loadEvents(startdate, enddate) {
    this.showCalendarLoader = true;
    this.events = [];
    this._jiraService.get(this._dataProvider.SERVICE_TICKET_MAINTENANCE + '?start=' + startdate + '&end=' + enddate, this.org).subscribe((res) => {
      this.events = res['data'];
      this.excelHeader = res['headers'];
      this.groupName = [];
      this.groupName.push({'label': 'None', 'value':   'none'});
      for (let i = 0; i < this.excelHeader.length; i++) {
        this.groupName.push({'label': this.excelHeader[i].header, 'value':  this.excelHeader[i].field });
      }
      this.selectedgroup = 'none';
      this.showCalendarLoader = false;
    }, () => {
      this.showCalendarLoader = false;
    });
}
handleEventClick(event) {
 this.eventDetails = [];
  this.eventDetails = event.event.extendedProps;
  this.eventDetailsDialog = true;
}
getValue(dd) {
  if (dd.value.name !== '') {
    window.open(dd.value.name, '_blank');
  }
}
hideDialog(event) {
  this.eventDetails = [];
  this.eventDetailsDialog = false;
}
closeTicketDialogue() {
  this.openTicketDialogue = false;
}
  // row wise popUp for event grid in homepage
  advanceRowSelect(event) {
    this.selectedData = [];
    this.selectedData = event.data;
    this.openTicketDialogue = true;
  }
  
  // called when expand button clicked
  openPopUp() {
    this.jiraDataPopup.timeRangeToAppendEvent = this.jiraData.timeRangeToAppendEvent;
    this.jiraDataPopup.reloadEvent = '';
    this.jiraDataPopup.isGraph = true;
    this.jiraDataPopup.state = this.jiraData.state;
  }

  // on grid click this method is called
  advanceLoadGrid(data: any) {
    const fieldArray = ['summary', 'creator', 'requestType', 'created',
    'organization', 'description', 'reporter', 'id', 'updated', 'key', 'status', 'priority'];
    if (this.jiraData.cols.length === 0) {
      this.jiraData.cols = data['headers'];
      const columns = [];
      this.detailsField = [];
      this.jiraData.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
        if (!fieldArray.includes(element['field'])) {
          this.detailsField.push(element);
        }
      });
      this.jiraData.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element) => {
        if (element['created']) {
          element['created'] = element['created'] && (element['created'] !== 0) ?
            this._datePipe.transform(element['created']) : '';
        }
        if (element['updated']) {
          element['updated'] = element['updated'] && (element['updated'] !== 0) ?
            this._datePipe.transform(element['updated']) : '';
        }
      });
    }
    this.jiraData.colData = rawData;
    this.jiraData.totalRecords = data['total'];
  }

  advanceIconClick(data) {
    window.open(data.link);
  }
  showDialog(name, id) {
    this.childmessage = [];
    this.childmessage.push({ name: name, id: id });
    this.reqType = id;
    this.servicerequestDialog = true;
  }
  
  clearsearchKBs() {
    $('#Searchinput').val('');
    this.searchResult = [];
    this.showStanderReq = true;
    this.showSearchIcon = true;
    this.displayemptyResponse = false;
  }
  ChangeTab(event) {
    if (event.index === 0) {
      this._customEvents.eventEmitter('Customer Service', 'Customer Service Home', '', 1);
    }
    if (event.index === 1) {
      this._customEvents.eventEmitter('Customer Service', 'Jira  Ticket Grid', '', 1);
      this.showJiraGrid = true;
    } else {
      this.showJiraGrid = false;
    }
    if (event.index === 2) {
      this._customEvents.eventEmitter('Customer Service', 'maintenance Calendar', '', 1);
      this.showCalendar = true;
      setTimeout(function() {
      $('#colortab').css('display', 'inline-block');
      $('#exportCal').css('display', 'inline-block');
    }, 200);
    } else {
      this.showCalendar = false;
      $('#colortab').css('display', 'none');
      $('#exportCal').css('display', 'none');
    }
    if ($('#Searchinput').val() !== '') {
      $('#Searchinput').val('');
      this.searchResult = [];
      this.showStanderReq = true;
      this.showSearchIcon = true;
    }
  }
  searchKBs() {
    if ($('#Searchinput').val() === '') {
      this.showSearchIcon = true;
      this.displayemptyResponse = false;
    } else {
      this.showSearchIcon = false;
    }
    if ($('#Searchinput').val() !== '' && $('#Searchinput').val().toString().length > 2) {
      this.showKBsLoader = true;
      this._jiraService.searchKBs({'query': $('#Searchinput').val()}).subscribe((rest: any) => {
        this.showKBsLoader = false;
        this.searchResult = rest['results'];
        if (this.searchResult.length > 0) {
          this.showStanderReq = false;
        } else {
          this.showStanderReq = true;
        }
        for (let i = 0; i < this.searchResult.length; i++) {
          if (this.searchResult[i].bodyTextHighlights.indexOf('@') !== -1) {
            const modifiedstring = this.searchResult[i].bodyTextHighlights.
                                   replace(/@@@hl@@@/g, '<strong>').replace(/@@@endhl@@@/g, '</strong>');
            this.searchResult[i].bodyTextHighlights = modifiedstring;
          }
          if (this.searchResult[i].title.indexOf('@') !== -1) {
            const modifiedstring = this.searchResult[i].title.replace(/@@@hl@@@/g, '<strong>').replace(/@@@endhl@@@/g, '</strong>');
            this.searchResult[i].title = modifiedstring;
          }

        }
        if (this.searchResult.length > 0) {
          this.displayemptyResponse = false;
        } else {
          this.displayemptyResponse = true;
        }
      }, error => {
        this.showKBsLoader = false;
      }
      );
    } else {
      this.showKBsLoader = false;
      this.showStanderReq = true;
      this.searchResult = [];

    }
  }
  /**  Add Comments on Issue*/
  openKBpopup(index) {
    if (this.searchResult[index].title.indexOf('<strong>') !== -1) {
      this.kbtitle = this.searchResult[index].title.replace(/<strong>/g, '').replace('</strong>', '');
    } else {
      this.kbtitle = this.searchResult[index].title;
    }
    this.kbDialog = true;
    this.KBURL = this.searchResult[index].appLinkUrl + '/plugins/servlet/remotepageview?pageId=' + this.searchResult[index].id;
  }

  
  closeTicketDetails(val){
    this.openTicketDialogue = false;
  }

  closeForm(value) {
    this.servicerequestDialog = false;
    this.growlMessage = [];
    if(value['status'] !== 'cancel'){
      this.growlMessage.push(value['message']);
    } 
  }
}
