import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableData } from '../../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { CustomEvents } from '../../../events/customevents';

@Component({
  selector: 'app-application-routing',
  templateUrl: './application-routing.component.html',
  styleUrls: ['./application-routing.component.scss']
})
export class ApplicationRoutingComponent implements OnInit, GridTableEvents, OnChanges {

  @Input() org;
  applicationGrid;
  applicationGridPopup;
  dataUrl;
  dataUrlPopup;
  selectedTypePopup;
  isOpenInfo;

  // variables of row select
  isRowSelect;
  header;
  selectedData;
  title;
  types;
  selectedType;

  constructor(private _customEvents: CustomEvents) {
    this.selectedType = 'LATENCY';
  }

  ngOnInit() {
    this.applicationGrid = new TableData();
    this.applicationGridPopup = new TableData();
    this.isOpenInfo = false;
    this.isRowSelect = false;
    this.applicationGrid.totalRecords = 0;

  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataUrl = '';
    this.dataUrlPopup = '';
    if (changes && changes['org']) {
      this.dataUrl = '/api/v1/application/route/?type=' + this.selectedType;
    }
  }

  // to be implemented
  rowSelect(event) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'device_ApplicatonAwareRouting', header: headers });
    this.selectedData = event.data;
    const param = event.data['deviceId'];
    this.title = 'Router Info : ' + this.selectedData['host-name'];
    this.header = this.applicationGrid.cols;
    this.isRowSelect = true;
  }

  // to be implemented
  loadPopupGrid(data) {
    if (this.applicationGridPopup.cols.length === 0) {
      this.applicationGridPopup.cols = data['headers'];
      this.applicationGridPopup.selectedColumns = this.applicationGridPopup.cols.slice(0, 6);
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['jitter'] = (element.hasOwnProperty('jitter') && (element['jitter'] !== 0)) ?
          (element['jitter']).toFixed(3) : element['jitter'];
        element['latency'] = (element.hasOwnProperty('latency') && (element['latency'] !== 0)) ?
          (element['latency']).toFixed(3) : element['latency'];
        element['loss_percentage'] = (element.hasOwnProperty('loss_percentage') && (element['loss_percentage'] !== 0)) ?
          (element['loss_percentage']).toFixed(3) : element['loss_percentage'];
      });
    }

    this.applicationGridPopup.colData = rawData;
    this.applicationGridPopup.totalRecords = data['total'];
  }


  // load dashboard tabel
  loadGrid(data: any) {
    if (this.applicationGrid.cols.length === 0) {
      this.applicationGrid.cols = data['headers'];
      const columns = [];
      this.applicationGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      columns.push({
        'field': 'graph',
        'header': '',
        'size': '42px'
      });
      this.applicationGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach(element => {
        element['graph'] = 'Graph';
        element['jitter'] = (element.hasOwnProperty('jitter') && (element['jitter'] !== 0)) ?
          (element['jitter']).toFixed(3) : element['jitter'];
        element['latency'] = (element.hasOwnProperty('latency') && (element['latency'] !== 0)) ?
          (element['latency']).toFixed(3) : element['latency'];
        element['loss_percentage'] = (element.hasOwnProperty('loss_percentage') && (element['loss_percentage'] !== 0)) ?
          (element['loss_percentage']).toFixed(3) : element['loss_percentage'];
      });
    }
    this.applicationGrid.colData = rawData;
    this.applicationGrid.totalRecords = data['total'];
  }

  // to be impleented
  expandView() {
    this.selectedTypePopup = this.selectedType;
    this.dataUrlPopup = this.dataUrl;
    this.isOpenInfo = true;
  }

  // on click of each graph icon this method is called
  openGraphContainer(data) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'device_ApplicatonAwareRouting_graph', header: headers });
    this._customEvents.displayGraph.emit({ name: data.name, id: data.name, type: 'application-aware' });
  }

  ChangeInType(event) {
    if (this.isOpenInfo) {
      this.selectedTypePopup = event.value;
      this.dataUrlPopup = '/api/v1/application/route/?type=' + this.selectedTypePopup;
    } else {
      this.selectedType = event.value;
      this.dataUrl = '/api/v1/application/route/?type=' + this.selectedType;
    }

  }

}
