<div style="height:100%">
    <app-date-picker blocktitle="Applicaton Aware Routing" style="width:100%" collapseId="applicationview" isExpand="false" [isTime]="false"
        [isSwitch]="false" [isReload]="false" [selectedType]="selectedType" (expand)="expandView()" isDropdown="true" (appTypeChange)="ChangeInType($event)"
        style="display: flow-root"></app-date-picker>

    <app-grid-table [popup]="false" id="soc" [isGlobalSearch]="false" [isHeaderWrap]="false" [totalRecords]="applicationGrid.totalRecords"
        [selectedColumns]="applicationGrid.selectedColumns" [cols]="applicationGrid.cols" [colData]="applicationGrid.colData"
        [org]="org" [dataUrl]="dataUrl" (update)="loadGrid($event)" (rowSelect)="rowSelect($event)" (showGraph)="openGraphContainer($event)"
        [to]="0">
    </app-grid-table>
</div>
<p-dialog *ngIf="isRowSelect" [header]="selectedData.name" [(visible)]="isRowSelect" [modal]="true" styleClass="gridactive"
    showEffect="fade" resizable="true" (onHide)="isRowSelect=false; fullData=[]; moreInfo=false">
    <div class="ui-grid ui-fluid">
        <div class="ui-grid-row" *ngFor="let col of header">
            <div class="ui-grid-col-4">
                <label>{{col.header}}</label>
            </div>
            <div class="ui-grid-col-2">
                <span>:</span>
            </div>
            <div class="ui-grid-col-6">
                <span>
                    {{selectedData[col.field]}}
                </span>
            </div>
        </div>
    </div>
</p-dialog>


<p-dialog *ngIf="isOpenInfo" header="Application Aware Routing" [(visible)]="isOpenInfo" [modal]="true" showEffect="fade"
    [responsive]="true" styleClass="gridactive" resizable="true">
    <app-date-picker blocktitle="" style="width:100%" collapseId="applicationviewpopup" [isTime]="false" [isSwitch]="false" [isReload]="false"
        [selectedType]="selectedTypePopup" isDropdown="true" (appTypeChange)="ChangeInType($event)" style="display: flow-root"></app-date-picker>
    <app-grid-table [popup]="true" id="soc" [isGlobalSearch]="false" [isHeaderWrap]="false" [totalRecords]="applicationGridPopup.totalRecords"
        [selectedColumns]="applicationGridPopup.selectedColumns" [cols]="applicationGridPopup.cols" [colData]="applicationGridPopup.colData"
        [org]="org" [to]="0" [dataUrl]="dataUrlPopup" (update)="loadPopupGrid($event)">
    </app-grid-table>
</p-dialog>