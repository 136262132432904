import { Component, OnInit } from '@angular/core';
import { CustomEvents } from '../../events/customevents';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AnalyticsService } from '../service/analytics.service';
import { Message } from 'primeng//api';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-anomalydetection',
  templateUrl: './anomalydetection.component.html',
  styleUrls: ['./anomalydetection.component.scss']
})
export class AnomalydetectionComponent implements OnInit {
  anomaly;
  selectetrainingdmodel;
  selectedAppsensor;
  isGrid;
  url;
  anomalyData;
  anomalyHeader;
  appSensorlist = [{ name: 'Select', code: 'NY' }];
  schedulerlist
  information;
  createTrainingForm;
  trainingappSensorlist = [];
  trainingschedulerlist;
  isErrorpopup;
  graphs = [];
  msgs: Message[] = [];
  gridData;
  isShowForm;
  isCreateForm;

  constructor(private _customEvents: CustomEvents, private confirmationService: ConfirmationService,
    private service: AnalyticsService, private formBuilder: FormBuilder) {
    this.information = [
      {
        hour: '1-12',
        quarter: 1,
        error: 5
      },
      {
        hour: '12-17',
        quarter: 2,
        error: 12
      },
      {
        hour: '17-24',
        quarter: 3,
        error: 8
      }
    ];
    this.isShowForm = false;
    this.isCreateForm = false;
  }

  ngOnInit() {

    this.anomaly = new FormGroup({
      appSensor: new FormControl('', [Validators.required]),
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl('', [Validators.required]),
      scheduler: new FormControl('')
    });
    this.isGrid = false;

    this.service.getAppsesnors().subscribe((res) => {
      const data = res['data'];
      this.isShowForm = true;
      data.forEach(element => {
        this.appSensorlist.push({
          name: element,
          code: element
        })
      });
    }, (error) => {
      this.isShowForm = true;
      this.appSensorlist = [{ name: 'Select', code: 'NY' }];
    });
    this.schedulerlist = [
      { name: 'Select', code: null },
      { name: '1 Hour', code: '1' },
      { name: '3 Hours', code: '3' },
      { name: '6 Hours', code: '6' },
      { name: '12 Hours', code: '12' },
      { name: '24 Hours', code: '24' },
      { name: 'None', code: 0 },
    ];
    this.trainingschedulerlist = [
      { name: 'Select', code: null },
      { name: 'Hourly', code: '24h' },
      { name: 'Daily', code: '1d' },
      { name: 'Weekly', code: '7d' },
      { name: 'None', code: 0 },
    ];
    this.createTrainingForm = new FormGroup({
      trainingsensorList: new FormControl('', [Validators.required]),
      scheduler: new FormControl('')
    });
    this.isErrorpopup = false;
    this.service.getTrainedAppsesnors().subscribe((res) => {
      const data = res['data'];
      this.isCreateForm = true;
      data.forEach(element => {
        this.trainingappSensorlist.push({
          name: element,
          code: element
        })
      });
    }, (error) => {
      this.isCreateForm = true;
      this.trainingappSensorlist = [];
    });
    this.service.getGraphs().subscribe((res) => {
      this.graphs = res['data'];
      this._customEvents.showHideLoader.emit({ show: false });
    }, (error) => {
      this.graphs = [];
      this._customEvents.showHideLoader.emit({ show: false });
    }
    );

    this._customEvents.displayGridfromGraphComponent.subscribe((res) => {
      this.isGrid = true;
      this.gridData = res.data;
    });

  }

  onSubmit() {
    const from = new Date(this.anomaly.value.fromDate).getTime();
    const to = new Date(this.anomaly.value.toDate).getTime();
    const appsen = this.anomaly.value.appSensor.name;
    const body = {
      "from": from,
      "to": to,
      "appsensor_id": appsen
    };
    this.service.saveAnomaly(body).subscribe((res) => {
      this.msgs = [];
      this.msgs.push({ severity: 'success', summary: 'Success', detail: 'Added for analyse' });
    },(error)=>{
      this.msgs = [];
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Not added for analyse' });
    });

  }

  anomalyCancel() {
    this.anomaly.reset();
  }

  openErrorPopup() {
    this.isErrorpopup = true;
  }

  saveTrainingModel() {
    let str = '';
    this.createTrainingForm.value.trainingsensorList.forEach((element) => {
      str += element.name + ', ';
    });

    const data = str.lastIndexOf(',');
    str = str.substring(0, data);
    this.confirmationService.confirm({

      message: `Are you sure that you want to add ${str} to training?`,
      accept: () => {
        let data = {
          "appsensor_id": str.split(',')
        };
        this.service.saveTraining(data).subscribe((res) => {
          this.msgs = [];
          this.msgs.push({ severity: 'success', summary: 'Success', detail: 'Added to training' });
        }, (error) => {
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Not added to training' });
        })
      },
      reject: () => {
        this.createTrainingForm.reset();
        this.msgs = [];
        this.msgs.push({ severity: 'warn', summary: 'Cancelled', detail: 'Not added to training' });
      }
    });

    this.anomaly.reset();
  }

}
