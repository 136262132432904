import { Component, OnInit, SystemJsNgModuleLoader , ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, PreloadingStrategy } from '@angular/router';
import {CustomEvents} from '../../events/customevents';
import { JiraTicketServiceService } from '../../services/jira-ticket-service.service';


@Component({
  selector: 'app-jira',
  templateUrl: './jira.component.html',
  styleUrls: ['./jira.component.scss']
})
export class JiraComponent implements OnInit {
  org: string;
  reqGroups;
  config;
  jiraUrl: string;
  onOrgChange = true;
  showErrorPage = false;
  portalId;
  errorMsgForJira = [];
  isJiraSessionPresent;

  constructor(private route: ActivatedRoute, private _customEvents: CustomEvents, private _jirahttp: JiraTicketServiceService) {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.jiraUrl = this.config['jiraLoginUrl'];
    _customEvents.showHideLoader.emit({ show: true });
    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
      this.onOrgChange = false;
      const user = JSON.parse(sessionStorage.getItem('user'));
      this.portalId = user['portalId'];
    if ( this.portalId) {
     // this.showCreateOptions();
     }
  });
  }

  ngOnInit() {
    this.onOrgChange = true;
    this._customEvents.showHideLoader.emit({ show: false });
  }

  showCreateOptions() {
    this.onOrgChange = true;
    const bodyreq = {
      'models': ['user', 'organisations', 'sharedPortal', 'helpCenterBranding', 'portal', 'portalWebFragments'],
      'options': {
        'portalId': this.portalId, 'portal': { 'id': this.portalId, 'expand': ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'] },
        'portalWebFragments': { 'portalPage': 'PORTAL' }
      }
    };
    this.config = JSON.parse(sessionStorage.getItem('config'));
    const getReqType = this.config['jiraUrl'] + '/api/rest/servicedesk/1/customer/models';
    this._jirahttp.requestpostData(getReqType, bodyreq).subscribe((rest: any) => {
      sessionStorage.setItem('jiraData',  JSON.stringify(rest));
     this.validateOrg(rest);
      const user = JSON.parse(sessionStorage.getItem('user'));
      if (rest['user']['userName'] === user['username']) {
        this.onOrgChange = true;
      } else {
        this.showErrorPage = true;
      }
      this._customEvents.showHideLoader.emit({ show: false });
    }, error => {
      this.showErrorPage = true;
      this._customEvents.showHideLoader.emit({ show: false });
    }
    );
  }
  validateOrg(rest) {
    this.errorMsgForJira = [];
    const orgName = this.getSelectedOrgName();
    const orgs = [];
    orgs.push('ALL');
    orgs.push('Default');
    if (rest['organisations'] && rest['organisations']['organisations']
     && Array.isArray(rest['organisations']['organisations'])) {
      for (const o of rest['organisations']['organisations']) {
        orgs.push(o.name);
      }
    }
    if (orgs.indexOf(orgName) === -1 && this.errorMsgForJira.length === 0) {
      this.errorMsgForJira.push({severity: 'info', summary: 'Permission Issue',
          detail: 'Organization not configured correctly. Attachments will not work'});

    }
  }

  getSelectedOrgName() {
    let orgName = '';
    if (this.org) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      user['orgs'].forEach(element => {
        if (element.key === this.org) {
          orgName = element.name;
        }
      });
    }
    return orgName;
  }
}
