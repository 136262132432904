import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BaseCtrl } from '../base/BaseCtrl';
import { CustomEvents } from '../events/customevents';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Inject } from '@angular/core';

@Injectable()
export class ValidateService extends BaseCtrl implements CanActivate, CanActivateChild {
  isAuthorized: boolean;
  private oidcSecurityService: OidcSecurityService;
  private _customEvents: CustomEvents;

  constructor( @Inject(CustomEvents) _customEvents:CustomEvents ,@Inject(OidcSecurityService) oidcSecurityService:OidcSecurityService) {
    
    super();
    this._customEvents= _customEvents;
    this.oidcSecurityService = oidcSecurityService;
  }

  canActivate(): any {
    this._customEvents.showHideLoader.emit({ show: true });
    if (window.location.hash) {
     // this.oidcSecurityService.authorizedCallbackWithCode(window.location.toString());

      this.oidcSecurityService.authorizedCallback();
    } else {
      if (sessionStorage.getItem('_isAuthorized') !== 'true') {
        this.unAuthorised();
      }
    }
    this.oidcSecurityService.getIsAuthorized().subscribe((val: boolean) => {
      this.isAuthorized = val;
    });
    if (this.isAuthorized) {
      if (sessionStorage.getItem('user')) {
        this._customEvents.routToDashBoard.emit({orgname : sessionStorage.getItem('org')});
      } else {
        this._customEvents.authorizeblock.emit({});
      }
    }
    return this.isAuthorized;

  }
  canActivateChild() {
    return true;
  }
  unAuthorised() {
    super.unAuthorised();
    this._customEvents.unAuthorizedError.emit({});
  }

}
