<div id ="jittertable">
<table  style="border: 1px solid #000">
    <tr>
      <td style="background-color: #f5f5f5;"></td>
      <th *ngFor="let node of nodes" style="background-color: #f5f5f5;">{{node.label}}</th>
    </tr>
    <tr *ngFor="let node of nodes">
      <th>{{node.label}}</th>
      <td *ngFor="let n1 of nodes" [style.background-color] ="getColor(data, node.value,n1.value,type)">
        <div *ngIf="data[node.value +'_'+n1.value]"  tooltipPosition="top" pTooltip="<span>{{node.label}}</span><span style='padding-left:2%;padding-right:2%;'><i class='fas fa-arrow-right'></i></span> <span>{{n1.label}} </span><br/><span>Jitter : </span><span>  {{data[node.value +'_'+n1.value]['avg_jitter']}} ms {{getBaseline(data, node.value,n1.value,'avg_jitter')}}</span><br/><span>Latency : </span><span> {{data[node.value +'_'+n1.value]['avg_latency']}} ms {{getBaseline(data, node.value,n1.value,'avg_latency')}}</span><br/><span>Packet Loss : </span><span> {{data[node.value +'_'+n1.value]['avg_packet_loss']}} % {{getBaseline(data, node.value,n1.value,'avg_packet_loss')}}</span><br/><span> Last Record  : </span><span>{{data[node.value +'_'+n1.value]['@timestamp']}}</span>" [escape]="false">
          {{data[node.value +'_'+n1.value][type]}} <span *ngIf="data[node.value +'_'+n1.value][type] || data[node.value +'_'+n1.value][type]===0 ">{{unit}}</span>
        </div>
        <div *ngIf="!data[node.value +'_'+n1.value] && data[n1.value +'_'+node.value]" tooltipPosition="top" pTooltip="<span> {{node.label}} </span><span style='padding-left:2%;padding-right:2%;'><i class='fas fa-arrow-right'></i></span> <span>{{n1.label}}</span><br/><span>Jitter : </span><span> {{data[n1.value +'_'+node.value]['avg_jitter']}} ms {{getBaseline(data, node.value,n1.value,'avg_jitter')}}</span><br/><span>Latency : </span><span>{{data[n1.value +'_'+node.value]['avg_latency']}} ms {{getBaseline(data, node.value,n1.value,'avg_latency')}}</span><br/><span>Packet Loss : </span><span>{{data[n1.value +'_'+node.value]['avg_packet_loss']}} % {{getBaseline(data, node.value,n1.value,'avg_packet_loss')}}</span><br/><span> Last Record  : </span><span>{{data[n1.value +'_'+node.value]['@timestamp']}}</span>" [escape]="false">
            {{data[n1.value +'_'+node.value][type]}} <span *ngIf="data[n1.value +'_'+node.value][type] || data[n1.value +'_'+node.value][type]===0">{{unit}}</span>
         </div>
      </td>
    </tr>
  </table>
</div>
