import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AdvanceGridModule} from '../../../../advance-grid/advance-grid.module';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import {DialogModule } from 'primeng/dialog';
import {FormModule} from '../../../jira/jira-tickets/form/form.module';
import {CustomerCircuitReportComponent} from '../customer-circuit-report/customer-circuit-report.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AdvanceGridModule,
    TabViewModule,
    AccordionModule,
    DialogModule,
    FormModule,
  ],
  declarations: [CustomerCircuitReportComponent],
  exports: [CustomerCircuitReportComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomerCircuitReportModule { }
