import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceComponent } from './device.component';
import { SharedModule } from '../../common/SharedModule';
import { GraphModule } from '../../graph/graph.module';
import { AuthenticateService } from '../../session/authenticate.service';
import { TooltipModule } from 'primeng/primeng';
import { GrowlModule } from 'primeng/primeng';
import { MapModule } from '../../map/map.module';
import { InputSwitchModule } from 'primeng/primeng';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { EventGridModule } from './event-grid/event-grid.module';
import { AlarmGridModule } from './alarm-grid/alarm-grid.module';
import { AuditGridModule } from './audit-grid/audit-grid.module';
import { SiteHealthModule } from './site-health/site-health.module';
import { ControlStatusModule } from './control-status/control-status.module';
import { DeviceStatusModule } from './device-status/device-status.module';
import { DeviceGridModule } from './device-grid/device-grid.module';
import { ApplicationRoutingModule } from './application-routing/application-routing.module';
import { TranportInterfaceModule } from './tranport-interface/tranport-interface.module';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GraphModule,
    TooltipModule,
    GrowlModule,
    MapModule,
    InputSwitchModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    EventGridModule,
    AlarmGridModule,
    AuditGridModule,
    SiteHealthModule,
    ControlStatusModule,
    DeviceStatusModule,
    DeviceGridModule,
    ApplicationRoutingModule,
    TranportInterfaceModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DeviceComponent],
  providers: [ PortalHttpServiceService, AuthenticateService],
  exports: [DeviceComponent]
})
export class DeviceModule { }
