<div style="height:30px">
    <b>Appsensor</b>
    <div style = "float:right;">
       <p-button  icon="fa fa-refresh"   *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_soc_circuits_datasync') !=-1 " [style]="{'float':'right','font-size':'10px'}"   pTooltip="Data Sync" tooltipPosition="top" iconPos="left" (click)="onReloadAppsensorData()"></p-button>
   </div>
    </div>
<app-grid-table [popup]="false" [reload]="appsensorGrid.reloadEvent" id="gridwithsmallwidth" [totalRecords]="appsensorGrid.totalRecords" [selectedColumns]="appsensorGrid.selectedColumns"
  [cols]="appsensorGrid.cols" height="248px" [colData]="appsensorGrid.colData" [org]="org" [dataUrl]="dataUrl" (update)="loadGrid($event)"
  (rowSelect)="rowSelect($event)" (showGraph)="showGraph($event)" [to]="0">
</app-grid-table>
<p-dialog *ngIf="isRowSelect" [header]="title" id="appsensorrowpopup" [(visible)]="isRowSelect" [modal]="true" styleClass="gridactive" showEffect="fade"
resizable="true" fullData=[]>
<div class="row">
    <div class="col-sm-12 outerSpace" style="cursor:pointer">
      <div class="col-sm-12 rebootDetail" pTooltip="More Info" tooltipPosition="top" style="min-height: 54px;">
         Info
        <span class="rightAlign">
          <i class="fa fa-info" aria-hidden="true"></i>
        </span>
        <div *ngIf="moreInfo">
          <hr>
          <div class="ui-grid-row" *ngFor="let col of header">
            <div class="ui-grid-col-4" style="word-break: break-all">
              <label *ngIf="selectedData[col.field]">{{col.header}}</label>
            </div>
            <div class="ui-grid-col-2">
              <span *ngIf="selectedData[col.field]">:</span>
            </div>
            <div class="ui-grid-col-6">
              <span *ngIf="(selectedData[col.field])" style="word-break: break-all">
                {{selectedData[col.field]}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="col-sm-12 outerSpace" style="cursor:pointer">
        <div class="col-sm-12 rebootDetail" pTooltip="Services" tooltipPosition="top" style="min-height: 54px;">
           Services
          <div>
            <div class="row ">
                <app-grid-table [popup]="false" id="gridwithautoheightandSearch" [totalRecords]="appsensorServiceDetails.totalRecords" [selectedColumns]="appsensorServiceDetails.selectedColumns"
                  [cols]="appsensorServiceDetails.cols" [to]="0" height="248px" [colData]="appsensorServiceDetails.colData" [org]="org" [dataUrl]="appsensorServiceDetailsUrl"
                  (update)="loadAppSensorServiceDetail($event)">
                </app-grid-table>
              </div>
          </div>
        </div>
      </div>
    </div>
</p-dialog>
<p-growl *ngIf="createReqerrormsg.length!==0" [(value)]="createReqerrormsg"></p-growl>
