import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';

import { XcCircuitReportComponent } from './xc-circuit-report.component';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import {FileUploadModule} from 'primeng/fileupload';
import { PapaParseModule} from 'ngx-papaparse';
import {ButtonModule} from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    DialogModule,
    TooltipModule,
    FileUploadModule,
    PapaParseModule,
    ButtonModule
  ],
  declarations: [XcCircuitReportComponent],
  providers: [],
  exports: [XcCircuitReportComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class XcCircuitReportModule { }
