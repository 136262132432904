import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerSiteModule } from './customer-site/customer-site.module';
import {CircuitInformationModule} from './circuit-information/circuit-information.module';
import { SocComponent } from './soc.component';
import {ButtonModule} from 'primeng/button';
import {CustomerCircuiteGridModule} from '../soc/customer-circuite-grid/customer-circuite-grid.module';
import {GroupNotificationsModule} from '../soc/group-notifications/group-notifications.module';
import {CustomerEmployeeModule} from '../soc/customer-employee-grid/customer-employee.module';
import { XcCircuitReportModule } from './xc-circuit-report/xc-circuit-report.module';
import { GrowlModule } from 'primeng/primeng';


@NgModule({
  imports: [
    CommonModule,
    CustomerSiteModule,
    ButtonModule,
    CircuitInformationModule,
    CustomerCircuiteGridModule,
    GroupNotificationsModule,
    CustomerEmployeeModule,
    XcCircuitReportModule,
    GrowlModule
  ],
  declarations: [SocComponent],
  exports: [SocComponent]
})
export class SocModule { }
