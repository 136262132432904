import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { ApplicationRoutingComponent } from './application-routing.component';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { GraphModule } from '../../../graph/graph.module';
import { DatePickerModule } from '../../../date-picker/date-picker.module';

@NgModule({
  imports: [
    CommonModule,
    GridTableModule,
    DialogModule,
    TooltipModule,
    DropdownModule,
    FormsModule,
    GraphModule,
    DatePickerModule
  ],
  declarations: [ApplicationRoutingComponent],
  exports: [ApplicationRoutingComponent]
})
export class ApplicationRoutingModule { }
