import { Component, OnInit } from '@angular/core';
import {CustomEvents} from'../events/customevents';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  constructor(private _customEvents:CustomEvents) { }

  ngOnInit() {
    this._customEvents.showHideLoader.emit({ show: false });
  }

}
