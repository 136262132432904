import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminSettingComponent} from './admin-setting.component';
import {AdminSettingRoutingModule} from './admin-setting-routing.module';
import {UserassociateModule} from './usermangement/userassociate/userassociate.module';
import {OrgmanagementModule} from '../admin-setting/orgmangement/orgmanagement.module';
import {PortalHttpServiceService} from '../services/portal-http-service.service';
import {UserassociateComponent} from '../admin-setting/usermangement/userassociate/userassociate.component';
import {OrgmangementComponent} from '../admin-setting/orgmangement/orgmangement.component';

@NgModule({
  imports: [
    CommonModule,
    AdminSettingRoutingModule,
    UserassociateModule,
    OrgmanagementModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AdminSettingComponent],
  exports: [AdminSettingComponent],
  providers: [PortalHttpServiceService]
})
export class AdminSettingModule { }
