import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageBaseCtrl } from '../../../../base/MessageBaseCtrl';

@Component({
  selector: 'app-jira-ticket-details',
  templateUrl: './jira-ticket-details.component.html',
  styleUrls: ['./jira-ticket-details.component.scss']
})
export class JiraTicketDetailsComponent extends MessageBaseCtrl implements OnInit {

  @Input() selectedData;
  @Input() org;
  @Input() detailsField;
  @Output() closeForm = new EventEmitter<any>();
  constructor() { 
    super();
  }

  ngOnInit() {
  }

  hideDialog(){
    this.closeForm.emit('close')
  }

}
