<div style="min-height:84%">
  <p-growl [(value)]="msgs" life="1000"></p-growl>
  <div class="col-md-12 form-group">
    <div class="col-md-12 screen-shadow">
      <form class="center-block" *ngIf="!isSave ; else successpage" #passwordsForm='ngForm' (ngSubmit)="onSubmit(passwordsForm)">
        <div id="mandatory"><sup> *</sup> Mandatory Fields</div>
        <div ngModelGroup="passwords" #passwords="ngModelGroup" appCheckPassword appNotEqualPassword>
          <div class="form-group">

            <div class="ui-float-label">
              <input id="float-input1" class="form-control" [feedback]="false" promptLabel="" type="password" #current_password="ngModel" name="current_password"
                [(ngModel)]="currentpwd" required pPassword>
              <label for="float-input">Current Password<sup> *</sup></label>
            </div>

          </div>
          <div class="form-group">

            <div class="ui-float-label">
              <input id="float-input2" class="form-control" promptLabel="" type="password" #new_password="ngModel" name="new_password"
                [(ngModel)]="newpwd" required pPassword (keyup) ="validate(newpwd,$event)">
              <label for="float-input">New Password<sup> *</sup></label>
            </div>
            <span id="err" *ngIf="passwords.errors?.noMatchwithOld && (new_password.dirty || new_password.touched)">
              New password must be different
            </span>
          </div>
          <div class="form-group mt-1">

            <div class="ui-float-label">
              <input id="float-input3" class="form-control" [feedback]="false" promptLabel="" type="password" #confirm_password="ngModel" name="confirm_password"
                [(ngModel)]="confirmpwd" required pPassword>
              <label for="float-input">Confirm New Password<sup> *</sup></label>
            </div>
            <span id="err" *ngIf="passwords.errors?.passwordCheck && (confirm_password.dirty || confirm_password.touched)">
              Password does not match
            </span>
          </div>
        </div>
        <div class="formbuttons">
          <button type="submit" class="btn btn-primary" [disabled]="!passwordsForm.valid">Save</button>
          <button type="submit" class="btn btn-primary" (click)="passwordsForm.reset(); isSave=false">Cancel</button>
        </div>
      </form>
      <ng-template #successpage>
        <div class="check_mark">
          <div class="sa-icon sa-success animate">
            <span class="sa-line sa-tip animateSuccessTip"></span>
            <span class="sa-line sa-long animateSuccessLong"></span>
            <div class="sa-placeholder"></div>
            <div class="sa-fix"></div>
          </div>
          <h4>Password has been updated successfully.</h4>
        </div>

      </ng-template>
    </div>
  </div>
</div>