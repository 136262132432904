<nav class="navbar navbar-inverse navbar-fixed-top" id="sidebar-wrapper" role="navigation" *ngIf="isAuth$ | async">
    <ul class="nav sidebar-nav">

        <li [pTooltip]="item.Description !== 'Logout' && item.Description !== 'IP SLA'?'':item.Description" tooltipPosition="right" (click)="listClick($event, item)" *ngFor="let item of sidebargroup;let i = index"
            [ngClass]="{'active': selectedItem == item.Description,'logout': item.Description== 'Logout'}">

            <a [ngClass]="{'dropdwonn': item.submenu?.length>0}">
                <i [class]="item.icon" aria-hidden="true"></i>
            </a>
            <ul  *ngIf="item.Description !== 'Logout' && item.Description !== 'IP SLA'" class="customsubmenu">
                <li>
                    <a> {{ item.Description }}</a>
                </li>
                <li [routerLinkActive]="" 
                    #rla="routerLinkActive" *ngFor="let subitem of item.submenu" [ngClass]="(subitem.value && rla.isActive)||(selectedOrg ===  subitem.key) ?'active':''">
                    <a [routerLink]="subitem.value" [queryParams]="(item.queryparam)?{org: selectedOrg}:{}" (click)="(!subitem.value)?changeOrg(subitem.key, item.Description):selectedItem=item.Description">{{subitem.name}}</a>
                </li>
            </ul>
        </li>
    </ul>

    <ul class="nav sidebar-nav">
        <li>
            <a>
                <i class="fas fa-external-link-square-alt" aria-hidden="true"></i>
            </a>
            <ul class="customsubmenu" *ngFor="let item of externalLinks">
                <li>
                    <a> {{ item.name }}</a>
                </li>
                <li *ngFor="let subitem of item.submenu">
                    <a (click)="externalLinkClick(subitem)">{{subitem.name}}</a>
                </li>
            </ul>
        </li>
    </ul>

</nav>