import { Injectable } from '@angular/core';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';


@Injectable()
export class DeviceStatusService {

  constructor(private _httpService: PortalHttpServiceService) { }

  // get data for dashborad view
  getAllDevices(org) {
    const url = '/api/v1/device/status';
    const body = {
      'from': new Date().getTime(),
      'limit': 0,
      'offset': 0,
      'orderBy': {},
      'query': '',
      'to': 0
    };
    return this._httpService.post(url, org, body);
  }

}
