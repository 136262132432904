import { Component, OnInit, HostListener, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CustomEvents } from '../../../../events/customevents';
import { SelectItem } from 'primeng/api';

import { SelectComponent} from '../../../../../../node_modules/ng2-select/ng2-select';
import { JiraService } from '../../jira.service';
import { DataProviderService } from '../../../../services/data-provider.service';
import { MessageBaseCtrl } from '../../../../base/MessageBaseCtrl';

interface Requesttype {
  name: string;
  id: string;
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class FormComponent extends MessageBaseCtrl implements OnInit {
  @ViewChild(SelectComponent, {static: false}) select: SelectComponent;
  @ViewChild('Selectsite', {static: false}) public selectsite: SelectComponent;
  @ViewChild('Selectcircuit', {static: false}) public Selectcircuit: SelectComponent;
  @Input() greetMessage: any[] = [];
  @Input() tableGrid;
  @Input() org;
  @Input() reqType;
  @Input() siteName;
  @Output() closeForm = new EventEmitter<any>();
  requestusertype: SelectItem[];
  selectedRequestype: Requesttype;
  requestAPIData;
  requestAttr;
  reporter;
  uploadedFiles: any[] = [];
  commentattachID: any[] = [];
  formEnable = false;
  enableRequestTypeDropDown = true;
  requestTypename;
  virtualInterfaceType;
  showvirtualInterfaceType: boolean;
  displaycircuitoption: boolean;
  customerCircuitdisabled: boolean;
  circuitdatachange;
  circuitRequesTypeObject;
  circuitId;
  SiteNameId;
  enableCircuit;
  enableCheck;
  ddvalues = {};
  prioritydata = [];
  sladata = [];
  selectedUrgency = '';
  selectedImpact = '';
  priorty;
  displayPriority = false;
  
  selectedType;
  constructor(private _customEvents: CustomEvents, private _jiraService: JiraService, 
    public _dataProvider: DataProviderService) {
      super()
      this.circuitRequesTypeObject = [];
      this._dataProvider.getReportTroubleReqId();
  }

  // ngOnInit
  ngOnInit() {
    this.selectedType = 'none';
    const jiraSessionPresent = sessionStorage.getItem('JiraPresent');
    if (jiraSessionPresent === 'true') {
      this.errorMessage = '';
      const jiraData = JSON.parse(sessionStorage.getItem('jiraData'));
      jiraData['portal']['reqTypes'].forEach(element => {
        const d = { label: element['name'], value: element['id'] };
        if (this.reqType) {
          this.circuitRequesTypeObject.push(d);
        } else if (this._dataProvider.getUserInfoByKey('circuitReqType').includes(element['id'])) {
          this.circuitRequesTypeObject.push(d);
        }
      });
      this._jiraService.getPortalData(this._dataProvider.SERVICE_TICKET_PRIORITY, this.org).subscribe((rest: any) => {
        this.prioritydata = rest['data']['priority'];
        this.sladata = rest['data']['sla'];
      }, () => {
        this.prioritydata = [];
        this.sladata = [];
      });
      this.enableButton = true;
      this.enableCircuit = false;
      if (typeof this.greetMessage !== 'undefined' && this.greetMessage.length > 0) {
        if (this.reqType) {
          this.selectedRequestype = this.reqType;
        } else if (this.circuitRequesTypeObject.length > 0) {
          this.selectedRequestype = this.circuitRequesTypeObject[0].value;
        }
      }
      this.loadFormData();
    } else {
      this.setGrowlMessage('error', 'You Do not have permission to Raise Request, Please Contact Admin!!');
    }
  }

  loadFormData() {
    this.selectedUrgency = '';
    this.selectedImpact = '';
    this.enableCheck = false;
    this.errorMessage = '';
    this.displaycircuitoption = false;
    if (this.reqType && this._dataProvider.getUserInfoByKey('circuitReqType').includes(this.selectedRequestype)) {
      this.enableCircuit = true;
    } else {
      this.enableCircuit = false;
    }
    
    this._jiraService.loadFormData(this._dataProvider.JIRA_SERVICEDESK_MODEL, this.selectedRequestype).subscribe((rest: any) => {
      this.formEnable = true;
      const data = rest['reqCreate']['fields'];
      this.requestAPIData = rest;
      this.requestAttr = data.filter(obj => obj['fieldId'] !== this._dataProvider.getUserInfoByKey('emailNotificationField'));
      const circuitselect = this._dataProvider.getUserInfoByKey('circuitReqType').includes(this.selectedRequestype);
      if (circuitselect && this.enableCircuit) {
        this.enableCheck = true;
      } else if (circuitselect) {
        this.enableCheck = false;
        this.displaycircuitoption = true;
      } else {
        this.enableCheck = false;
      }
      for (let i = 0; i < data.length; i++) {
        if (this.requestAPIData['reqCreate']['fields'][i]['fieldId'] === 'reporter') {
          this.reporter = this.requestAPIData['reqCreate']['fields'][i]['defaultObject'].id;
        } else if (this.requestAPIData['reqCreate']['fields'][i]['fieldId'] === 'customfield_11200') {
          const test = [];
          const orgList = this.requestAPIData['reqCreate']['userOrganisations'];
          const test1 = { 'id': '', 'name': 'Private' };
          if (Array.isArray(orgList) && orgList.length) {
            for (const o of orgList) {
              if (o.name === this.getSelectedOrgName() || this.getSelectedOrgName() === 'ALL') {
                test.push(o);
              }
            }
          }
          if (Array.isArray(test) && test.length) {
            this.ddvalues['customfield_11200'] = test[0].id;
          }
          test.push(test1);
          this.requestusertype = test;
        }
      }
      this._customEvents.closeCreateTicketPopup.emit({ show: true });
    }, error => {
      this.cancelReq();
    }
    );
  }
  handleChange(e) {
    const isChecked = e.checked;
    if (isChecked) {
       this.siteName = '';
    }
  }

  sitevalueChange(data) {
    this.siteName = data;
    this.SiteNameId = data;
  }
  circuitvalueChange(data) {
    this.circuitId = data;
  }
  
  onUpload(event) {
    for (const file of event.files) {
      const filedata = {
        'filename': file.name,
        'filesize': file.size
      };
      this.uploadedFiles.push(filedata);
    }
  }
  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) onDragEnd(event) {
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    this.uploadFileForTicket(files);
  }

  uploadFileForTicket(files) {
    const status = this.enableButton;
    this.createLoader = true;
    for (const file of files) {
      this._jiraService.createTempFileforTicket(false, file.name,file.size, this.requestAPIData['portal']['projectId'], file)
      .subscribe((rest: any) => {
        this.commentattachID.push(rest['attachId']);
        this.createLoader = false;
        this.setGrowlMessage('success', 'Successfully uploaded file ' + file.name);
      }, () => {
        this.setGrowlMessage('error', 'Failed to upload file!!')
        this.enableButton = status;
        this.createLoader = false;
      });
      this.uploadedFiles.push({
        'filename': file.name,
        'filesize': file.size
      });
      $('#fileuploadcreatereq').val('');
    }

  }
  onFileChange(event) {
    this.uploadFileForTicket(event.target.files);
  }
  removeFiles(index) {
    this.uploadedFiles.splice(index, 1);
    this.commentattachID.splice(index, 1);
    $('#fileuploadcreatereq').val('');
  }
  createRequest() {
    if(this.enableButton){
      return;
    }
    this.enableButton = true;
    this.errorMessage = '';
    this.createLoader = true;
    const createReq = [];
    let isEmailReq = false;
    for (let i = 0; i < this.requestAPIData['reqCreate']['fields'].length; i++) {
      const keyvalue = this.requestAPIData['reqCreate']['fields'][i]['fieldId'];
      const value = $('#' + this.requestAPIData['reqCreate']['fields'][i]['fieldId']).val();
      if(this._dataProvider.getUserInfoByKey('emailNotificationField') && keyvalue === this._dataProvider.getUserInfoByKey('emailNotificationField')){
        isEmailReq = true;
      }
      let test;
      if (this.ddvalues.hasOwnProperty(keyvalue)) {
        test = {
          [keyvalue]: this.ddvalues[keyvalue]
        };
      } else if (keyvalue === 'reporter') {
        const cusreporter = this.requestAPIData['reqCreate']['fields'];
        const reporter = cusreporter[0]['defaultObject'].id ? cusreporter[0]['defaultObject'].id : '';
        test = {
          [keyvalue]: reporter ? reporter : ''
        };
      } else if (keyvalue === 'attachment') {
        test = {
          'filetoconvert': this.commentattachID
        };
      } else {
        test = {
          [keyvalue]: value ? value : ''
        };
      }
      createReq.push(
        test
      );
    }
    if(isEmailReq) {
      this._jiraService.post(this._dataProvider.SERVICE_NOTIFICATION_EMAILS, this.org, {
        'circuits': this.circuitId,
        'siteName': this.SiteNameId,
      }).subscribe((rest: any) => {
        if(!this._dataProvider.isStringEmpty(rest['emails'])) {
          createReq.push({[rest['fieldId']] : rest['emails']})
        }
        this.executeCreate(createReq);
        }, () => {
        this.executeCreate(createReq);
      })
    } else{
      this.executeCreate(createReq);
    }
    
    
  }

  executeCreate(createReq){
    this._jiraService.createRequestpost(this._dataProvider.JIRA_CREATE_REQUEST + this.selectedRequestype, createReq)
    .subscribe((rest: any) => {
      if (rest['status'] !== 200) {
        this.growlMessage = [];
        this.errorMessage = 'Failed to create request'
        this.commentattachID = [];
        this.createLoader = false;
        this.enableButton = false;
        this._customEvents.eventEmitter('Customer Service', 'Created Jira Request', 'Fail', rest['status']);
      } else {
        this._customEvents.eventEmitter('Customer Service', 'Created Jira Request', 'Success', rest['status']);
        this.growlMessage = [];
        this.commentattachID = [];
        const IssueId = rest['body']['issue']['key'];
        if ((this.SiteNameId || this.circuitId) && this.displaycircuitoption) {
          if (this.SiteNameId === 'null') {
            this.SiteNameId = '';
          }
          const body = {
            'circuits': this.circuitId,
            'issueId': IssueId,
            'siteName': this.SiteNameId,
          };
          this._jiraService.put(this._dataProvider.SERVICE_TICKET_UPDATE, this.org, body).subscribe(() => {
            this._customEvents.eventEmitter('Customer Service', 'Created Jira Request', 'Success', rest['status']);
            this.createLoader = false;
            this.closeForm.emit({status: 'success', message: this.getGrowlMessage('success','Inventory updated for request ' + IssueId)});
          }, () => {
            this.createLoader = false;
            this.closeForm.emit({status: 'error', message: this.getGrowlMessage('error','Failed to update inventory for request ' + IssueId)});
          });
        } else {
          this.createLoader = false;
          this.closeForm.emit({status: 'success', message: this.getGrowlMessage('success','Successfully created Request ' + IssueId)});
        }
      }
      }, () => {
      this.createLoader = false;
      this.closeForm.emit({status: 'error', message: this.getGrowlMessage('error','Failed to create ticket!!')});
      }
    );
  }
  reloadForm(dd) {
    this.loadFormData();
  }
  selectOption(dd1, id, fieldData) {
    const value = dd1.value ? dd1.value : '';
    this.ddvalues[id] = dd1.value ? dd1.value : '';
    if (fieldData.label === 'Urgency') {
      this.selectedUrgency = '';
      for (const d of fieldData.values) {
        if (d.value === value) {
          this.selectedUrgency = d.label;
        }
      }
    }
    if (fieldData.label === 'Impact') {
      this.selectedImpact = '';
      for (const d of fieldData.values) {
        if (d.value === value) {
          this.selectedImpact = d.label;
        }
      }
    }
    this.enableSlaDisplay();
    this.enableBtn(fieldData)
  }
  selectOrg(dd1, id) {
    this.ddvalues[id] = dd1.value.id ? dd1.value.id : '';
  }

  selectRadio(dd1, id, field) {
    this.ddvalues[id] = dd1.value ? dd1.value : '';
    this.enableBtn(field);
  }
  enableSlaDisplay() {
    this.displayPriority = false;
    if (this.selectedImpact !== '' && this.selectedUrgency !== '') {
      for (const pr of this.prioritydata) {
        if (pr['Impact'] === this.selectedImpact && pr['Urgency'] === this.selectedUrgency) {
          for (const sla of this.sladata) {
            if (sla['Priority'] === pr['Priority'] && sla['issueType'] === this.selectedRequestype) {
              this.priorty = { 'label': sla['Priority'], 'response_SLA': sla['Response'], 'resolution_SLA': sla['Resolution'] };
              this.displayPriority = true;
            }
          }
        }
      }
    }
  }

  getSelectedOrgName() {
    let orgName = '';
    if (this.org) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      user['orgs'].forEach(element => {
        if (element.key === this.org) {
          orgName = element.name;
        }
      });
    }
    return orgName;
  }

  getPlaceHolder(field){
    if(field && field['required']){
      return 'Required'
    }
    return ''
    
  }

  enableBtn(field) {
    if(!field['required']) {
      return;
    }
    let mandatoryList = [];
    const stringDt = ['text', 'textarea'];
    for (let i = 0; i < this.requestAPIData['reqCreate']['fields'].length; i++) {
      const keyvalue = this.requestAPIData['reqCreate']['fields'][i]['fieldId'];
      const validatefield = this.requestAPIData['reqCreate']['fields'][i]['required'];
      let value = $('#' + this.requestAPIData['reqCreate']['fields'][i]['fieldId']).val();
      if(!stringDt.includes(this.requestAPIData['reqCreate']['fields'][i]['fieldType'])) {
        value = this.ddvalues[keyvalue];
      }
      if (validatefield === true && keyvalue !== 'reporter' && this._dataProvider.isStringEmpty(value)) {
          mandatoryList.push(keyvalue);
      }
    }
    if(mandatoryList.length === 0) {
      this.enableButton = false;
      this.errorMessage = '';
    } else {
      this.enableButton = true;
      this.errorMessage = 'Please provides all mandatory fields.!!';
    }
 }

  cancelReq(){
    this.closeForm.emit({status: "cancel", message : ''});
  }

}
