import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {CustomEvents} from '../../../events/customevents';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';

import * as $ from 'jquery';
import * as L from 'leaflet';
import 'leaflet.markercluster';

@Component({
  selector: 'app-customer-circuite-map',
  templateUrl: './customer-circuite-map.component.html',
  styleUrls: ['./customer-circuite-map.component.scss']
})
export class CustomerCircuiteMapComponent implements OnInit {
  org: string;
  servicerequestDialog = false;
  isPermission;
  map;
  marker;
  mapCoords = [];
  @Input() id;


  constructor(private route: ActivatedRoute, private _customEvents: CustomEvents, private _portalhttp: PortalHttpServiceService ) {
    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
  });
  const user = JSON.parse(sessionStorage.getItem('user'));
 // this.isPermission = user['permissions'];
  user['orgs'].forEach(element => {
    if (element.key === this.org) {
     this.isPermission = element.permissions;
    }
  });
  }

  ngOnInit() {
    this.setMap();
    this._customEvents.showHideLoader.emit({ show: false });
    let nodeurl;
    if (this.id === '') {
      nodeurl  = '/api/v1/circuit/nodes';
    } else {
       nodeurl  = '/api/v1/circuit/nodes?circuit_id=' + this.id;
    }
    this._portalhttp.get(nodeurl, this.org).subscribe((res) => {
       this.loadMap( res['data']);
       }, (err) => {
       });
  }
  loadMap(data) {
    if(this.map){
      this.map.remove();
      this.setMap();
    }
    this.mapCoords = data;
    const markerClusters = L.markerClusterGroup({
      chunkedLoading: true,
      spiderfyOnMaxZoom: true,
      maxClusterRadius: 0.1,
      showCoverageOnHover: false
    });
    if (this.mapCoords && this.mapCoords.length > 0) {
    for (let i = 0; i < this.mapCoords.length; i++) {
      let Acoordinates;
      let Zcoordinates;
      let marker1;
      let  marker2;
      const htmltooltip = '<strong> Circuit ID : </strong>' + this.mapCoords[i].circuit_id__c +
         '<br/><strong> OrderStatus  : </strong>' + this.mapCoords[i].order_status__c;
      if (this.mapCoords[i].a_loc__c) {
      Acoordinates = {'lat': this.mapCoords[i].a_loc__c.lat,
              'lng': this.mapCoords[i].a_loc__c.lon};
      marker1 = this.createAMarker(Acoordinates, this.mapCoords[i], i, htmltooltip);
      markerClusters.addLayer(marker1);
      this.map.addLayer( markerClusters );
    }
      if (this.mapCoords[i].z_loc__c) {
       Zcoordinates = {'lat': this.mapCoords[i].z_loc__c.lat,
      'lng': this.mapCoords[i].z_loc__c.lon};
      marker2 =  this.createZMarker(Zcoordinates, this.mapCoords[i], i, htmltooltip);
      markerClusters.addLayer(marker2);
      }
      if(marker1) {
        let poly_marker1;
        marker1.on('mouseover', function (e) {
          if (Acoordinates && Zcoordinates) {
            const Lines = [];
            Lines.push(Acoordinates);
            Lines.push(Zcoordinates);
            poly_marker1 = new L.Polyline(Lines).addTo(markerClusters).bindTooltip(htmltooltip);
          
          }
      });
      marker1.on('mouseout', function (e) {
        if (poly_marker1) {
          markerClusters.removeLayer(poly_marker1._leaflet_id)
        }
    });
    marker1.on('click', function (e) {
      let marker_count = 0;
    let path_count = 0;
    const layers = markerClusters.getLayers();
      if (layers) {
      for (let z = 0; z < layers.length; z++) {
        if (layers[z]['_path']) {
          path_count++;
        } else {
          marker_count++;
        }
        }
      }
      if((marker_count ===2 && path_count <= 2) || (marker_count ===1)){
        marker1.openPopup();
      } else {
      markerClusters.clearLayers();
      if(marker1){
       markerClusters.addLayers(marker1);
      }
      if(marker2){
       markerClusters.addLayers(marker2);
      }
      if (Acoordinates && Zcoordinates) {
       const Lines = [];
       Lines.push(Acoordinates);
       Lines.push(Zcoordinates);
       new L.Polyline(Lines).addTo(markerClusters).bindTooltip(htmltooltip);
      }
    }
   });
      }
      if (marker2) {
        let poly_marker2;
        marker2.on('mouseover', function (e) {
          if (Acoordinates && Zcoordinates) {
            const Lines = [];
            Lines.push(Acoordinates);
            Lines.push(Zcoordinates);
            poly_marker2 = new L.Polyline(Lines).addTo(markerClusters).bindTooltip(htmltooltip);
          }
      });
      marker2.on('mouseout', function (e) {
        if (poly_marker2) {
          markerClusters.removeLayer(poly_marker2._leaflet_id)
        }
    });
    marker2.on('click', function (e) {
      let marker_count = 0;
      let path_count = 0;
      const layers = markerClusters.getLayers();
        if(layers){
          for(let z = 0; z<layers.length; z++) {
            if(layers[z]['_path']){
              path_count++;
            } else {
              marker_count++;
            }
            }
          }
    
    if((marker_count=== 2 && path_count <= 2) || (marker_count === 1)){
        marker2.openPopup();
      } else {
        markerClusters.clearLayers();
        if(marker1){
         markerClusters.addLayers(marker1);
        }
        if(marker2){
         markerClusters.addLayers(marker2);
        }
        if (Acoordinates && Zcoordinates) {
         const Lines = [];
         Lines.push(Acoordinates);
         Lines.push(Zcoordinates);
         new L.Polyline(Lines).addTo(markerClusters).bindTooltip(htmltooltip);
        }
      } 
   });
      }
    }
  }
    $( '#circuitmap' ).on( 'click', '.circuiteDetail', function() {
      const text = $(this).text().split('');
      displayDetails(text[0]);
    });
    $( '#circuitmap' ).on( 'click', '.createTicket', function() {
      const text = $(this).text().split(',');
      createTicket(text[0], text[1]);
    });
    const displayDetails = ((value) => {
      const result = this.mapCoords[value];
      const headers = 'circuitDetail';
      this._customEvents.displayRowWiseEvt.emit({ data: result, header: headers});
    });
    const createTicket = ((value, value1) => {
      const result = this.mapCoords[value];
      const headers = 'createTicket';
      this._customEvents.displayRowWiseEvt.emit({ data: result, header: headers, type: value1});
    });
  }

  setMap() {
    let minZoom;
    if (window.screen.width <= 425) {
      minZoom = 1;
    } else {
      minZoom = 2;
    }
    const customControl =  L.Control.extend({
      options: {
        position: 'topleft'
      },
      onAdd: function (map) {
        const container = L.DomUtil.create('button');
        container.title = 'Refresh';
        container.style.backgroundSize = '30px 30px';
        container.setAttribute('class', 'btn btn-default');
        const icon = document.createElement('span');
        icon.className = 'fa fa-refresh';
        container.appendChild(icon);
        container.style.width = '35px';
        container.style.height = '32px';
        container.onclick = function(e) {
          e.preventDefault();
          Reloadmap(e);
        };
        return container;
      }
    });
    const Reloadmap = ((value) => {
      $( '#circuitmap' ).unbind('click');
      this.loadMap(this.mapCoords);
     });
    const fistLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
    { 'attribution': 'Map data &copy; OpenStreetMap contributors', minZoom: minZoom, maxZoom: 13 });
    this.map = L.map('circuitmap', {
      zoom: 2,
      scrollWheelZoom: false,
      center: [30, -100],
      worldCopyJump: true,
      layers: [fistLayer]
    });
    this.map.addControl(new customControl());
    if (window.screen.width <= 425) {
      this.map.fitWorld();
    }

  }
  
  createAMarker(Acoordinates, mapCoordsdata, i, htmltooltip){
    const greenIcon = new L.Icon({
      iconUrl:  'https://cdn.mapmarker.io/api/v1/pin?text='+ mapCoordsdata.a_loc__c.code +'&size=30&background=006600&color=FFF&hoffset=-1',
      iconAnchor: [30, 35],
      iconSize: [50, 50],
    });
    let html;
    if (this.id === '') {
     html = '<strong>A Loc : </stromg><br/>'
    + mapCoordsdata.a_loc__c.name + '<strong>'
     +  '</strong><br/>' + '<strong> Circuit ID : </strong>' + mapCoordsdata.circuit_id__c +
     '<br/><strong> OrderStatus  : </strong>' + mapCoordsdata.order_status__c +
     '<br/><div ><span class="createTicket"><span style="display:none;">'
     + i + ',</span><span style="display:none;">aloc,</span><a>Create Ticket</a></span>  <span class="circuiteDetail"><span style="display:none;">'
     + i + '</span><a style="padding-left:12%;">Circuit Detail</a></span></div>';
    } else {
      html = '<strong>A Loc : </stromg><br/>'
      + mapCoordsdata.a_loc__c.name + '<strong>'
       +  '</strong><br/>' + '<strong> Circuit ID : </strong>' + mapCoordsdata.circuit_id__c +
       '<br/><strong> OrderStatus  : </strong>' + mapCoordsdata.order_status__c +
       '<br/><div ><span class="createTicket"><span style="display:none;">'
       + i + ',</span><span style="display:none;">aloc,</span><a>Create Ticket</a></span>  <span class="circuiteDetail"><span style="display:none;">'
       + i + '</span><a style="padding-left:12%;display:none;">Circuit Detail</a></span></div>';

    }
    return new L.Marker(Acoordinates, {icon: greenIcon}).bindPopup(html)
     .openPopup().bindTooltip(htmltooltip);
  }
  createZMarker(Zcoordinates, mapCoordsdata, i, htmltooltip) {
    const redIcon = new L.Icon({
      iconUrl: 'https://cdn.mapmarker.io/api/v1/pin?text='+ mapCoordsdata.z_loc__c.code +'&size=30&background=D94B43&color=FFF&hoffset=-1',
      iconAnchor: [30, 35],
      iconSize: [50, 50],
    });
    let html;
    if (this.id === '') {
     html = '<strong>Z Loc : </stromg><br/>' +
    mapCoordsdata.z_loc__c.name + '<strong>'
      +  '</strong><br>'  + '<strong> Circuit ID : </strong>' + mapCoordsdata.circuit_id__c +
      '<br/><strong> OrderStatus  : </strong>' + mapCoordsdata.order_status__c +
      '<br/><div><span class="createTicket"><span style="display:none;">'
      + i + ',</span><span style="display:none;">zloc,</span><a>Create Ticket</a></span><span class="circuiteDetail"><span style="display:none;">'
       + i + '</span><a style="padding-left:12%;">Circuit Detail</a></span></div>';
    } else {
      html = '<strong>Z Loc : </stromg><br/>' +
      mapCoordsdata.z_loc__c.name + '<strong>'
        +  '</strong><br>'  + '<strong> Circuit ID : </strong>' + mapCoordsdata.circuit_id__c +
        '<br/><strong> OrderStatus  : </strong>' + mapCoordsdata.order_status__c +
        '<br/><div><span class="createTicket"><span style="display:none;">'
        + i + ',</span><span style="display:none;">zloc,</span><a>Create Ticket</a></span><span class="circuiteDetail"><span style="display:none;">'
         + i + '</span><a style="padding-left:12%;display:none;">Circuit Detail</a></span></div>';
    }
    return new L.Marker(Zcoordinates, {icon: redIcon}).bindPopup(html)
      .openPopup().bindTooltip(htmltooltip);
  }
}
