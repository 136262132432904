import { EventEmitter } from '@angular/core';
import {PortalHttpServiceService} from '../../app/services/portal-http-service.service';
declare let ga: Function;

export class CustomEvents {

  /**
   * show hide global spinning loader
   * which will cover whole screen
   * **/
  public showHideLoader: EventEmitter<any> = new EventEmitter();

  /**
   * handle unauthorized user error
   */
  public unAuthorizedError: EventEmitter<any> = new EventEmitter();

  /**
   * navigate to Dashboard page after successfull login
   * or after valid session for localstorage
   */
  public routToDashBoard: EventEmitter<any> = new EventEmitter();
  public authorizeblock: EventEmitter<any> = new EventEmitter();
  /**
* Child will emit this when there is a click on each row
* parent component will decide based on data whether to Display dialog or not
*/
  public displayRowWiseEvt: EventEmitter<any> = new EventEmitter();
  public displayCustomerSiteEvt: EventEmitter<any> = new EventEmitter();
  public sendGtmEvt: EventEmitter<any> = new EventEmitter();

  /**
  * Child will emit this when there is a click on ADD icon
  */

  public displayGraph: EventEmitter<any> = new EventEmitter();

  public logoutEvt: EventEmitter<any> = new EventEmitter();
  public showJiraOption: EventEmitter<any> = new EventEmitter();
  public showGrafanaOption: EventEmitter<any> = new EventEmitter();
  public changeGraph: EventEmitter<any> = new EventEmitter();
  public selectQuckRangeGraph: EventEmitter<any> = new EventEmitter();
  public reloaddatepickerGraph: EventEmitter<any> = new EventEmitter();
  public timerangeGraph: EventEmitter<any> = new EventEmitter();
  public displayGridfromGraphComponent: EventEmitter<any> = new EventEmitter();
  public closeCreateTicketPopup: EventEmitter<any> = new EventEmitter();
  public eventEmitter(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
      (<any>window).ga('send', 'event', {
    eventCategory: eventCategory,
    eventLabel: eventLabel,
    eventAction: eventAction,
    eventValue: eventValue
    });
    }

}
