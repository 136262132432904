<div *ngIf="customerSite.length > 0">
<ng-select #Selectsite [allowClear]="true"
dropdownPosition = "top"
[items]="customerSite"
(data)="refreshValue($event)"
(selected)="siteselected($event)"
(removed)="removed($event)"
(typed)="typed($event)"
[active]="datachange"
[disabled] ="isdisable"
placeholder="Select Customer sites">
</ng-select>
</div>
