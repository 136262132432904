import { Component, OnInit, HostListener, Input, ViewChild, OnChanges, EventEmitter, Output } from '@angular/core';
import { PortalHttpServiceService } from '../../../../../services/portal-http-service.service';

@Component({
  selector: 'app-circuit-dropdown',
  templateUrl: './circuit-dropdown.component.html',
  styleUrls: ['./circuit-dropdown.component.scss']
})
export class CircuitDropdownComponent implements OnInit, OnChanges {
  private value: any = {};
  private _disabledV = '0';
  private disabled = false;
  public customerCuircuit: Array<any> = [];
  public itemvalue = [] ;
  @Input() org;
  @Input()  sitename;
  @Output() circuitvalueChange = new EventEmitter();
  @Input() precircuitValue;
  circuitdatachange;
  customerCircuitdisabled: boolean;
  constructor( private _portalhttp: PortalHttpServiceService) { }
  ngOnInit() {
  }
  ngOnChanges() {
    if (this.precircuitValue && this.precircuitValue.length === 3 ) {
      this.circuitdatachange = [{id: this.precircuitValue[1].toString(), text: this.precircuitValue[0].toString()}];
      this.customerCircuitdisabled = true;
      const valueCircuit = [];
      valueCircuit.push(this.precircuitValue[0]);
      this.circuitvalueChange.emit(valueCircuit);
      this.customerCuircuit.push({
      });
    } else if (this.precircuitValue && this.precircuitValue.length === 6) {
      this.circuitdatachange = [{id: this.precircuitValue[1].toString(), text: this.precircuitValue[0].toString()}];
      this.customerCircuitdisabled = true;
      const valueCircuit = [];
      valueCircuit.push(this.precircuitValue[0]);
      this.circuitvalueChange.emit(valueCircuit);
      this.customerCuircuit.push({
      });

    } else if (this.sitename) {
        this.circuitvalueChange.emit([]);
        this.loadData('');
    } else {
      this.customerCuircuit = [];
    }
  }
  public loadData(query) {
    const siteInfoUrl = '/api/v1/circuits/site?site=' + this.sitename;
    const body = {'limit': 100, 'offset': 0, 'query': query};
    this.customerCuircuit = [];
     this._portalhttp.post(siteInfoUrl, this.org, body).subscribe((res) => {
       this.customerCuircuit = [];
       res['data'].forEach((circuitdata: { circuit_id__c: string, order_status__c: string,
        product__c: string}) => {
        this.customerCuircuit.push({
          id: circuitdata.circuit_id__c,
          text: `<span>${circuitdata.circuit_id__c} </span>`
        });
      });
      this.customerCircuitdisabled = false;
      }, (err) => {
        this.customerCuircuit = [];
      });
  }
  private get disabledV(): string {
    return this._disabledV;
  }
  private set disabledV(value: string) {
    this._disabledV = value;
    this.disabled = this._disabledV === '1';
  }
  public Customerselected(value: any): void {
    const text = value.text.split('<br/>');
    this.itemvalue.push(text[0].replace('<span>', '').replace('</span>', ''));
    this.circuitvalueChange.emit(this.itemvalue);
  }
  public removed(value: any): void {
    this.itemvalue = [];
    this.circuitvalueChange.emit(this.itemvalue);
  }
  public typed(value: any): void {
    this.loadData(value);
  }
  public refreshValue(value: any): void {
    this.value = value;
  }

}
