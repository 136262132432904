import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/primeng';
import { GraphComponent } from './graph.component';
import { CalendarModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/primeng';
import { OverlayPanelModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';
import { SafePipeModule } from '../utils/safe.pipe';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { DialogModule } from 'primeng/primeng';
import { GraphContainerModule } from '../graph-container/graph-container.module';
import { GridTableModule } from '../grid-table/grid-table.module';
@NgModule({
  imports: [
    CommonModule ,
    TooltipModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    CheckboxModule,
    OverlayPanelModule,
    DropdownModule,
    DatePickerModule,
    DialogModule,
    GraphContainerModule,
    SafePipeModule,
    GridTableModule
    
    ],
  declarations: [GraphComponent],
  exports: [GraphComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GraphModule { }
