import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter  } from '@angular/core';
import { TableData } from '../../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { CustomEvents } from '../../../events/customevents';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import { Message } from 'primeng/api';
import * as L from 'leaflet';

@Component({
  selector: 'app-appsensor-grid',
  templateUrl: './appsensor-grid.component.html',
  styleUrls: ['./appsensor-grid.component.scss']
})
export class AppsensorGridComponent implements OnInit, GridTableEvents, OnChanges {
  @Input() org;
  @Output() appsensorGridValueChange = new EventEmitter();
  appsensorGrid;
 // deviceGridPopup;
  dataUrl;
  isRowSelect;
  selectedData;
  title;
  moreInfo;
  moreservice;
  header;
  appsensorServiceDetails;
  appsensorServiceDetailsUrl;
  createReqerrormsg: Message[] = [];
  isPermission;

  constructor(private _customEvents: CustomEvents, public _datePipe: DateConverterPipe, private _portalhttp: PortalHttpServiceService) {
    this.appsensorGrid = new TableData();
     this.appsensorServiceDetails = new TableData();
    this.isRowSelect = false;
    this.moreInfo = true;
    this.moreservice = true;
   }
  ngOnChanges(changes: SimpleChanges) {
   // this.dataUrl = '/api/v1/appsensor/';
  }

  ngOnInit() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    user['orgs'].forEach(element => {
      if (element.key === this.org) {
       this.isPermission = element.permissions;
      }
    });
    this.dataUrl = '/api/v1/appsensor/';
  }
  loadGrid(data) {
    if (this.appsensorGrid.cols.length === 0) {
      this.appsensorGrid.cols = data['headers'];
      const columns = [];
      this.appsensorGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      columns.push({
        'field': 'graph',
        'header': '',
        'size': '55px'
      });
      this.appsensorGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    rawData.forEach(element => {
        if (element['ref_count'] !== 0) {
         element['graph'] = 'Graph';
        } else {
          element['graph'] = 'No Graph';
        }
    });
    this.appsensorGrid.colData = rawData;
    this.appsensorGridValueChange.emit({id: rawData[0]._id, code: rawData[0].Code});
    this.appsensorGrid.totalRecords = data['total'];
  }
  rowSelect(event) {
    this.selectedData = event.data;
    this.title = event['data']['Code'];
    this.isRowSelect = true;
    this.header = this.appsensorGrid.cols;
    this.appsensorServiceDetailsUrl = '/api/v1/appsensor/service/' + event.data._id;
  }
  showGraph(event) {
    this.appsensorGridValueChange.emit({id: event._id, code: event.Code, type: 'appsensor', title: event.Code});
  }
  loadPopupGrid(event) {
  }

  loadAppSensorServiceDetail(data) {
    if (this.appsensorServiceDetails.cols.length === 0) {
      this.appsensorServiceDetails.cols = data['headers'];
      this.appsensorServiceDetails.selectedColumns = this.appsensorServiceDetails.cols;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach(element => {
        element['vreboot_date_time-date'] = element['vreboot_date_time-date']
        && (element['vreboot_date_time-date'] !== 0) ? this._datePipe.transform(element['reboot_date_time-date']) : '';
        element['lastupdated'] = element['lastupdated']
        && (element['lastupdated'] !== 0) ? this._datePipe.transform(element['lastupdated']) : '';
      });
    }
    this.appsensorServiceDetails.colData = rawData;
    this.appsensorServiceDetails.totalRecords = data['total'];
  }
  onReloadAppsensorData() {
    const reloadUrl = '/api/v1/appsensor/sync';
    this._portalhttp.get(reloadUrl, this.org).subscribe((res) => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Data Sync service  Started' });
      this.appsensorGrid.reloadEvent = '';
      }, err => {
        this.appsensorGrid.reloadEvent = '';
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Data Sync service not available!' });
      });
  }
}
