import { Injectable } from '@angular/core';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';


@Injectable()
export class DeviceGridService {
  constructor(private _httpService: PortalHttpServiceService) { }

  // get reboot and crash count
  getRebootCrashCounts(org) {
    const url = '/api/v1/device/status';
    const body = {
      'from': new Date().getTime(),
      'limit': 0,
      'offset': 0,
      'orderBy': {},
      'query': '',
      'to': 0
    };
    return this._httpService.post(url, org, body);
  }

  // get hardware summary
  getHardwareSummary(org, deviceId) {
    const url = '/api/v1/device/hardware/' + deviceId;
    const body = {
      'from': new Date().getTime(),
      'limit': 0,
      'offset': 0,
      'orderBy': {},
      'query': '',
      'to': 0
    };
    return this._httpService.post(url, org, body);
  }

    // get cpu and memory avg
    getCpuMemoryCounts(org, device) {
      const url = `api/v1/device/system/${device}`;
      const body = {
        'from': new Date().getTime(),
        'limit': 0,
        'offset': 0,
        'orderBy': {},
        'query': '',
        'to': new Date().getTime()
      };
      return this._httpService.post(url, org, body);
    }

    // get reboot details
    getRebootDetail(org, deviceId) {
      const url = '/api/v1/device/reboot/' + deviceId;
      const body = {
        'from': new Date().getTime(),
        'limit': 0,
        'offset': 0,
        'orderBy': {},
        'query': '',
        'to': 0
      };
      return this._httpService.post(url, org, body);
    }

}
