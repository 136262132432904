import { Component, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomEvents } from '../../../events/customevents';
import {ButtonModule} from 'primeng/button';
import { ActivatedRoute } from '@angular/router';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import { TableData } from '../../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { Message } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-custmer-emplyee',
  templateUrl: './custmer-emplyee.component.html',
  styleUrls: ['./custmer-emplyee.component.scss']
})
export class CustmerEmplyeeComponent implements OnInit , GridTableEvents, OnChanges {
  org;
  customerEmployeGrid;
  customerEmployeGridUrl;
  isRowSelect;
  customerEmployeeDialog = false;
  employeeDetail;
  createReqerrormsg: Message[] = [];
  isPermission;
  addcustomerEmployeeDialog = false;
  createEmployeeForm: FormGroup;
  submitted = false;
  selectedType;
  customerlconUrl;
  updateLconDialog = false;
  editForm = false;
  userfirstname;
  userlastname;
  useremail = '';
  userphone = '';
  usermobile;
  userother;
types = [
    {label: 'Employee', value: 'customerEmployeeGrid', icon: 'fa fa-address-book-o'},
    {label: 'LCON', value: 'lcon', icon: 'fa fa-address-book-o'},
];
  constructor(private route: ActivatedRoute,
    private _customEvents: CustomEvents, private _portalhttp: PortalHttpServiceService, public _datePipe: DateConverterPipe
    , private formBuilder: FormBuilder) {
    this.customerEmployeGrid = new TableData();
    this.isRowSelect = false;
    const user = JSON.parse(sessionStorage.getItem('user'));
    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
  });
  user['orgs'].forEach(element => {
    if (element.key === this.org) {
     this.isPermission = element.permissions;
    }
  });
   }
   ngOnChanges(changes: SimpleChanges) {
  }

  ngOnInit() {
    this.selectedType = 'customerEmployeeGrid';
    this.createEmployeeForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')]],
      phoneno: [''],
      mobileno: [''],
      other: ['']
  });
    const user = JSON.parse(sessionStorage.getItem('user'));
    this.customerEmployeGridUrl = '/api/v1/customer/employee';
    this.customerlconUrl = '/api/v1/customer/lcon/search';
  }
get f() { return this.createEmployeeForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.createEmployeeForm.invalid) {
      return;
  }
    if (this.createEmployeeForm.value.email !== null && this.createEmployeeForm.value.email !== ''
    || this.createEmployeeForm.value.phoneno !== null && this.createEmployeeForm.value.phoneno.trim() !== '' ) {
        const body =   {
          'Email': this.createEmployeeForm.value.email,
          'FirstName': this.createEmployeeForm.value.firstName,
          'LastName': this.createEmployeeForm.value.lastName,
          'Mobile': this.createEmployeeForm.value.mobileno,
          'Phone': this.createEmployeeForm.value.phoneno,
          'other_details': this.createEmployeeForm.value.other
        };
         const  creteuserURL = '/api/v1/customer/lcon';
         this._portalhttp.post(creteuserURL, this.org, body).subscribe((res) => {
          this.submitted = false;
          this.customerEmployeGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
          this.createReqerrormsg = [];
          this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully created LCON user !' });
           this.addcustomerEmployeeDialog = false;
           }, err => {
             this.customerEmployeGrid.reloadEvent  = '';
             this.createReqerrormsg = [];
             this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
           });

    } else {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please Provide Email Id or Phone No.!' });
    }
  }
  cancelCreateReq() {
    this.submitted = false;
    this.addcustomerEmployeeDialog = false;
  }
  addEmployee() {
    this.createEmployeeForm.reset();
    this.addcustomerEmployeeDialog = true;
  }
  loadGrid(data) {
    if (this.customerEmployeGrid.cols.length === 0) {
      this.customerEmployeGrid.cols = data['headers'];
      const columns = [];
      this.customerEmployeGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.customerEmployeGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.customerEmployeGrid.colData = rawData;
    this.customerEmployeGrid.totalRecords = data['total'];
  }
  loadPopupGrid() {
  }
  rowSelect(event) {
    this.employeeDetail = event.data;
    this.customerEmployeeDialog = true;
  }
  lconrowSelect(event) {
    this.editForm = false;
    this.employeeDetail = event.data;
    this.updateLconDialog = true;
  }
  updatelcon(value) {
    this.editForm = true;
    this.userfirstname = value.FirstName;
    this.userlastname = value.LastName;
    this.useremail = value.Email;
    this.userphone = value.Phone;
    this.usermobile = value.Mobile;
    this.userother = value.other_details;
  }
  emailvalidate(event) {
    $('#emailerror').css('display', 'none');
    if (event.which === 32) {
      event.preventDefault();
    }
  }
  updateLcon() {
    if (this.useremail) {
     const expr = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;
      if (!expr.test(this.useremail)) {
        $('#emailerror').css('display', 'inline-block');
        return;
      } else {
        $('#emailerror').css('display', 'none');
      }
    }
    this.employeeDetail.FirstName = this.userfirstname ;
    this.employeeDetail.LastName = this.userlastname;
    this.employeeDetail.Email = this.useremail;
    this.employeeDetail.Phone = this.userphone ? this.userphone : '';
    this.employeeDetail.Mobile = this.usermobile;
    this.employeeDetail.other_details = this.userother;
    if (this.useremail !== null && this.useremail !== '' && this.useremail !== undefined
    || this.userphone !== null  && this.userphone !== undefined  && this.userphone.trim() !== '') {
   const updateUrl = '/api/v1/customer/lcon/' + this.employeeDetail.Id;
  this._portalhttp.putWithError(updateUrl, this.org, this.employeeDetail).subscribe(() => {
     this.customerEmployeGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated LCON!' });
     this.updateLconDialog = false;
   }, error => {
     this.customerEmployeGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
   });
  } else {
    this.customerEmployeGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please Provide Email ID or Phone No.!!' });
    return;
  }
  }
  deleteLcon() {
    const deleteUrl = '/api/v1/customer/lcon/' + this.employeeDetail.Id;
    this._portalhttp.delete(deleteUrl, this.org).subscribe((res) => {
      console.log(res);
      this.customerEmployeGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully Deleted LCON!' });
      this.updateLconDialog = false;
    }, error => {
      this.customerEmployeGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '',
      detail: "This LCON user Can't be deleted beacuse, this LCON user  associated with some Site!!" });
    });
  }
  cancelupdateReq() {
    this.customerEmployeGrid.reloadEvent = '';
    this.editForm =  false;
    this.updateLconDialog = false;
  }
  onReloadEmployeeList() {
    const reloadUrl = '/api/v1/customer/employee/sync';
    this._portalhttp.get(reloadUrl, this.org).subscribe((res) => {
      this.customerEmployeGrid.reloadEvent = '';
      }, err => {
        this.customerEmployeGrid.reloadEvent = '';
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Scheduler service not available!' });
      });

  }
}
