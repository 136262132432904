<div>
    <h4 class="background"><span>Add Comment</span></h4>
    <div *ngIf="createLoader">
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
    </div>
    <div class="column3" draggable="true" style="margin-bottom: 1px;">
        <textarea rows="5" class="comentsbox" cols="60" pInputTextarea autoResize="autoResize"
            style="margin-top: 2%;" (keyup)="enableAddbtn()"
            placeholder="Comment on this request..."></textarea>
        <div class="column3" style="text-align: center;width:39.4%;">
            <ul style="padding:0px;">
                <li *ngFor="let filesup of uploadedFiles;index as i"
                    style="font-size: 12px;font-weight: 100;list-style-type: none;text-align:left;">
                    <label style="font-size: 14px;">{{filesup.filename}}</label>
                    <label style="padding-left:4%">{{filesup.filesize}}</label>
                    <button type="text" pButton label="X" (click)="removeFiles(i)"
                        style="height:11%;margin-top:3%;"></button>
                </li>
            </ul>
        </div>
    </div>
    <button type="button" pButton icon="pi" label="Add" (click)="addComments()" [disabled]="enableButton || !_dataProvider.isJiraSession()"
        style="height:11%;margin-left:1.9%;"></button>
    <span>
        <a href="javascript:void(0)" (click)="hideupload()" style="color: deepskyblue;">
            Cancel
        </a></span>
    <span style="padding-left: 5%;"> Drag & Drop to upload your files or
        <a href="javascript:void(0)" (click)="file.click()" style="color: deepskyblue;">
            browse
        </a></span>
    <input type="file" #file (change)="onFileChange($event)"
        style="display:none" class="fileupload" multiple />
    <div style="position: absolute;padding-left: 10%; margin-left: 29%;float: left;top: 122%;">
        <p-growl *ngIf="growlMessage.length!==0"  life='6000' [(value)]="growlMessage"></p-growl>
    </div>

</div>
<h4 class="background"><span>Comments</span></h4>
<div class="ui-grid comment" *ngFor="let i of comments">
    <div style="height:50px"><span class="highlightText">{{i.author.key}}</span> added a comment -
        {{  i.created}}</div>
    <div class="ui-grid" [innerHTML]="i.renderedBody"></div>
</div>