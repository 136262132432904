import { Component, OnInit } from '@angular/core';
import { CustomEvents } from '../events/customevents';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-setting',
  templateUrl: './admin-setting.component.html',
  styleUrls: ['./admin-setting.component.scss']
})
export class AdminSettingComponent implements OnInit {
  user: string;
  message: string;
  data;
  org;

  constructor(private customEvents: CustomEvents, private route: ActivatedRoute) { 
    this.message = '';
    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
  });
  }
  ngOnInit() {
    this.customEvents.showHideLoader.emit({ show: false });
  }

}
