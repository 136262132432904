import { Component, OnInit } from '@angular/core';
import { CustomEvents } from '../../events/customevents';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import { AuthenticateService } from '../../session/authenticate.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  selectedOrg;
  defaultOrg;
  data;
  constructor(private _customEvents: CustomEvents, private _authservice: AuthenticateService,
    private _portalHttp: PortalHttpServiceService) {
    this.data = [];

  }

  ngOnInit() {
    this._customEvents.showHideLoader.emit({ show: false });
    if (sessionStorage.getItem('user')) {
      this.data = JSON.parse(sessionStorage.getItem('user'));
      this.selectedOrg = sessionStorage.getItem('org');
      if (this.data['defaultOrg']) {
        this.defaultOrg = this.data['defaultOrg'];
      }
    }
    if (this.data.length !== 0 && this.data.hasOwnProperty('groups')) {
      const permission = this.data.groups;
      if (permission && permission.length > 0) {
        this.data.groups = permission.filter((element) => {
          element = element.trim();
          if (!element.startsWith('PORTAL')) {
            return element;
          }
        });
      }
    }

    this._portalHttp.DefaultOrg.subscribe((res) => {
      if (res) {
        this.selectedOrg = res;
      }
    });



  }

  changeOrg(value) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'profile_Changeorg', header: headers });
    this._portalHttp.setDefaultOrg(value['key']);
  }
}
