import { Routes } from '@angular/router';
import { LoaderComponent } from './spinning/loader.component';
import { AuthenticateService } from './session/authenticate.service';
import { ValidateService } from './session/validate.service';
import { LoginComponent } from './session/login/login.component';
import { LogoutComponent } from './session/logout/logout.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ErrorComponent } from './error/error.component';
import {IpSlaComponent} from './ip-sla/ip-sla.component';

export const routes: Routes = [
  { path: 'dashboard', loadChildren: () =>
  import('../app/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthenticateService] },
  { path: 'authorize', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'settings', loadChildren: () =>
  import('../app/admin-setting/admin-setting.module').then(m => m.AdminSettingModule), canActivate: [AuthenticateService]},
  { path: 'error', component: ErrorComponent},
  { path: 'ip-sla', component: IpSlaComponent, canActivate: [AuthenticateService]},
  { path: 'user', loadChildren: () => import('../app/user/user.module').then(m => m.UserModule) },
  { path: 'eap', loadChildren: () => import('../app/analytics/analytics.module').then(m => m.AnalyticsModule) },
  { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthenticateService] },
  { path: '**', component: LoaderComponent, canActivate: [ValidateService] }
];
