import { Component, OnInit, Input } from '@angular/core';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { TableData } from '../../../common/SharedClasses/TableData';
import { DatePickerEvents } from '../../../date-picker/date-picker.component';
import {CustomEvents} from '../../../events/customevents';

@Component({
  selector: 'app-alarm-grid',
  templateUrl: './alarm-grid.component.html',
  styleUrls: ['./alarm-grid.component.scss']
})
export class AlarmGridComponent implements OnInit, DatePickerEvents, GridTableEvents {

  @Input() org;
  @Input() isGrafanaSessionPresent;
  alarmData;
  alarmDataPopup;
  alarmPopUpGrid;
  isPopup: boolean;
  isGraphLoading;
  selectedData;
  subarry;
  isRowClick;
  dataUrl;
  id;
  // row click variables
  alarmDetails;
  alarmTitle;
  columns;
  fullHeader;
  fullData;
  fullOptions;

  constructor(private _datePipe: DateConverterPipe, private _customEvents: CustomEvents ) {
    this.alarmData = new TableData();
    this.alarmDataPopup = new TableData();
    this.isPopup = false;
    this.isGraphLoading = true;
    this.isRowClick = false;
    this.alarmData.state = 'Graph';
  }

  ngOnInit() {
    this.dataUrl = '/api/v1/alarm';
    this.id = 'event';
    // to set data for alarm grid in the home page
    this.setInitialData();

  }

  // row wise popUp for alarm grid in homepage
  rowSelect(event) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'device_Alarm', header: headers });
    this.selectedData = event.data;
    if (this.selectedData.hasOwnProperty('details')) {
      this.subarry = this.selectedData.details.split(';');
    }
    if (this.selectedData.hasOwnProperty('possible_causes')) {
      if (typeof this.selectedData['possible_causes'] === 'string') {
        this.selectedData['possible_causes'] = (this.selectedData['possible_causes']).replace('[', '');
        this.selectedData['possible_causes'] = (this.selectedData['possible_causes']).replace(']', '');
        if (this.selectedData['possible_causes'].length > 0) {
          this.selectedData['possible_causes'] = (this.selectedData['possible_causes']).split(',');
        }
      }

    }
    this.alarmDetails = this.selectedData;
    this.columns = Object.keys(this.selectedData.values[0]);
    const grid = this.selectedData.consumed_events;
    let alarmHeader = [];
    const options = [];
    this.alarmTitle = 'Alarm Info : ' + this.alarmDetails['values_short_display'];
    if (grid && grid.length > 0) {
      this.fullData = grid;
      this.fullData.forEach(element => {
        if (element.hasOwnProperty('entry_time')) {
          element['entry_time'] = this._datePipe.transform(element['entry_time']);
        }
        if (element.hasOwnProperty('receive_time')) {
          element['receive_time'] = this._datePipe.transform(element['receive_time']);
        }
      });

      const keys = (Object.keys(grid[0]));
      alarmHeader = [
        {
          'field': 'system_ip',
          'header': 'System Ip'
        },
        {
          'field': 'component',
          'header': 'Component'
        },
        {
          'field': 'eventname',
          'header': 'Event Name'
        },
        {
          'field': 'host-name',
          'header': 'Host Name'
        },
        {
          'field': 'entry_time',
          'header': 'Date & Time'
        },
        {
          'field': 'receive_time',
          'header': 'Receive Time'
        }
      ];
      if (keys.includes('severity-level')) {
        alarmHeader.push({
          'field': 'severity-level',
          'header': 'Severity Level'
        });
      }
      for (let i = 0; i < alarmHeader.length; i++) {
        options.push({ label: alarmHeader[i].header, value: alarmHeader[i] });
      }
      this.fullOptions = options;
      this.fullHeader = options.slice(0, 6).map(a => a.value);
    }
    this.isRowClick = true;
  }

  // sets data for alarm grid in home page
  setInitialData() {
    this.alarmData.reloadEvent = '';
    this.alarmData.isGraph = true;
  }

  // called when expand button clicked
  openPopUp() {
    this.alarmDataPopup.timeRangeToAppendEvent = this.alarmData.timeRangeToAppendEvent;
    this.alarmDataPopup.reloadEvent = '';
    this.alarmDataPopup.isGraph = true;
    this.alarmDataPopup.state = this.alarmData.state;
    this.isPopup = true;
  }

  // called initially on dashborad
  loadGrid(data: any) {
    if (this.alarmData.cols.length === 0) {
      this.alarmData.cols = data['headers'];
      const columns = [];
      this.alarmData.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.alarmData.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['entry_time'] = element.hasOwnProperty('entry_time') && (element['entry_time'] !== 0) ?
          this._datePipe.transform(element['entry_time']) : '';
      });
    }
    this.alarmData.colData = rawData;
    this.alarmData.totalRecords = data['total'];
  }

  // called for expand popup
  loadPopupGrid(data: any) {
    if (this.alarmDataPopup.cols.length === 0) {
      this.alarmDataPopup.cols = data['headers'];
      const columns = [];
      this.alarmDataPopup.cols.slice(0, 6).forEach(element => {
        columns.push(element);
      });
      this.alarmDataPopup.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element, index) => {
        element['entry_time'] = (element.hasOwnProperty('entry_time') && (element['entry_time'] !== 0)) ?
          this._datePipe.transform(element['entry_time']) : '';
        element['cleared_time'] = element.hasOwnProperty('cleared_time') && (element['cleared_time'] !== 0) ? this._datePipe.transform(element['cleared_time']) : '';
      });
    }
    this.alarmDataPopup.colData = rawData;
    this.alarmDataPopup.totalRecords = data['total'];
  }

  // swicthing gird to graph
  switchView(value) {
    const state = this.alarmData.state;
    if (state !== value) {
      this.alarmData.totalRecords = 0;
      this.alarmData.colData = [];
      this.alarmData.state = value;
    }
  }

  // refresh for grid
  refresh(event) {
    this.alarmData.totalRecords = 0;
    this.alarmData.colData = [];
    this.alarmData.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
  }

  // swicthing gird to graph for popUP
  switchPopupView(value) {
    const state = this.alarmDataPopup.state;
    if (state !== value) {
      this.alarmDataPopup.totalRecords = 0;
      this.alarmDataPopup.colData = [];
      this.alarmDataPopup.state = value;
    }
  }

  // refresh for grid for popUP
  refreshPopup(event) {
    this.alarmDataPopup.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.alarmDataPopup.totalRecords = 0;
    this.alarmDataPopup.colData = [];
  }

  // update time for dashboard
  updateTime(event) {
    this.alarmData.timeRangeToAppendEvent = event.time;
    this.alarmData.from = event.from;
    this.alarmData.to = event.to;
    this.alarmData.label = event.label;
    this.alarmData.rangeFrom = event.rangeFrom;
    this.alarmData.rangeTo = event.rangeTo;
    this.alarmData.dateFrom = event.dateFrom;
    this.alarmData.dateTo = event.dateTo;

  }

  // update time for popup
  updatePopupTime(alarm) {
    this.alarmDataPopup.timeRangeToAppendEvent = alarm.time;
    this.alarmDataPopup.from = alarm.from;
    this.alarmDataPopup.to = alarm.to;
  }

}



