import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/primeng';
import {SecurityComponent} from './security.component';
import {DeviceListModule} from './devices-list/device-list.module';
import { GrowlModule } from 'primeng/primeng';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    DeviceListModule,
    GrowlModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [SecurityComponent],
  exports: [SecurityComponent]
})
export class SecurityModule { }
