import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { AppInitialiserService } from '../services/app-initialiser.service';
import { CustomEvents } from '../events/customevents';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  @Input() blocktitle;
  @Input() collapseId;
  @Input() quickRangeHour = [];
  @Input() quickRangeDays = [];
  @Input() types = [{ label: 'Graph', value: 'Graph', icon: 'fa fa-line-chart' },
  { label: 'Grid', value: 'Grid', icon: 'fa fa-table' }];
  @Input() selectedQuickRangeFrom = 'now-24h';
  @Input() selectedQuickRangeTo = 'now';
  @Input() timerangelabel = 'Last 24 hour';
  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() selectedstyle = '';
  isOkButtonDisabled;
  @Output() switchView = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  @Output() updateTime = new EventEmitter<any>();
  @Output() expand = new EventEmitter<any>();
  @Output() appTypeChange = new EventEmitter<any>();

  // for swicth state is true or false
  @Input() state = 'Graph';
  @Input() isGraphChange = false;
  @Input() graphoption;
  // deciding links to be displayed
  @Input() isExpand;
  @Input() isTime;
  @Input() isSwitch;
  @Input() isReload;
  @Input() isDropdown;
  applicationtypes;
  @Input() selectedType;
  @Input() autorefresh = false;
  maxDate: Date;
  maxTime;
  isHideDateTo;
  selectedRange;
  hourElement;
  minuteElement;
  htmlElement;
  hour;
  minute;
  appInitialisedData;
  selectedOptions = [];
  graphDisplay = [];
  urlCollection = [];
  hasSelectedOptionsChanged;
  refreshdata;
  selectedRefresh;

  constructor(private appInitialiserService: AppInitialiserService, private _customevent: CustomEvents) { }

  // initialise the default values
  ngOnInit() {
    this.appInitialiserService.Properties.subscribe((res) => {
      this.appInitialisedData = res;
    });
    this.hasSelectedOptionsChanged = false;
    this.isOkButtonDisabled = true;
    this.isHideDateTo = true;
    this.maxDate = new Date();
    this.selectedRefresh = 'off';
    this.refreshdata = [
      { label: 'off', value: 'off' },
      { label: '10s', value: '10s' },
      { label: '30s', value: '30s' },
      { label: '1m', value: '1m' },
      { label: '5m', value: '5m' },
      { label: '10m', value: '10m' },
      { label: '15m', value: '15m' }
    ];
    if (this.graphoption) {
    for (const items of this.graphoption) {
      for (const i of items.graphUrls) {
        i['category'] = items.name;
        i['id'] = items.id;
        i['type'] = items.type;

      }
      this.urlCollection.push(items.graphUrls);
        for (const item of items.graphUrls) {
          if (item.default) {
            this.selectedOptions.push(item.name);
            this.graphDisplay.push(item);
          }
      }
    }
  }
    if (this.quickRangeHour.length === 0) {
      this.quickRangeHour = [
        { label: 'Last 30 minute', value: 'now-30m' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 3 hour', value: 'now-3h' },
        { label: 'Last 6 hour', value: 'now-6h' },
        { label: 'Last 12 hour', value: 'now-12h' },
        { label: 'Last 24 hour', value: 'now-24h' }
      ];
    }
    if (this.quickRangeDays.length === 0) {
      this.quickRangeDays = [
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 10 days', value: 'now-10d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' }
      ];
    }
    this.isTime = (typeof this.isTime !== 'undefined') ? this.isTime : true;
    this.isSwitch = (typeof this.isSwitch !== 'undefined') ? this.isSwitch : true;
    this.isExpand = (typeof this.isExpand !== 'undefined') ? this.isExpand : false;
    this.isReload = (typeof this.isReload !== 'undefined') ? this.isReload : true;
    this.isDropdown = (typeof this.isDropdown !== 'undefined') ? this.isDropdown : false;

    this.applicationtypes = [
      { label: 'Latency', value: 'LATENCY' },
      { label: 'Loss', value: 'LOSS' },
      { label: 'Jitter', value: 'JITTER' }
    ];
    this.applyTimeRange();
  }

  // once the view is loaded call the dom element
  ngAfterViewInit() {
    if (this.dateFrom) {
      this.isHideDateTo = false;
    }
  }

   // parent calles this method to pass the data
   setGraphs(data) {
    this.graphDisplay = [];
    const selectedNames = data;
    selectedNames.forEach(element => {
      this.urlCollection.forEach(item => {
        item.forEach(i => {
          if (element === i.name) {
            if (!i.fullView) {
              this.graphDisplay.unshift(i);
            } else {
              this.graphDisplay.push(i);
            }
          }
        });
      });
    });
  }
  // handles toggle btw grid and graph
  onSwicthChange(val) {
    this.switchView.emit(val.value);
  }

  ischangeGraph() {
   this. _customevent.changeGraph.emit({result: this.selectedOptions, header: 'graphHide'});
  }

  // when quick range is selected this method is called
  selectQuickRange(event, value: any) {
    this.isHideDateTo = true;
    this.isOkButtonDisabled = true;
    this.selectedQuickRangeFrom = value.value;
    this.dateFrom = null;
    this.dateTo = null;
    this.timerangelabel = value.label;
    this.applyTimeRange();
    this. _customevent.selectQuckRangeGraph.emit({result: this.selectedQuickRangeFrom , header: 'selectQuickrange'});
  }

  // when date is selected this method is called
  applyTimeRange() {
    const data = {};
    const from = this.dateFrom ? this.dateFrom.getTime().toString() : this.selectedQuickRangeFrom;
    if (this.dateFrom) {
      this.timerangelabel = 'Time Range';
    }
    const to = this.dateTo ? this.dateTo.getTime().toString() : this.selectedQuickRangeTo;
    data['type'] = this.state;
    data['time'] = '&from=' + from + '&to=' + to;
    data['from'] = this.dateFrom ? this.dateFrom.getTime() : this.converToUTC(this.selectedQuickRangeFrom);
    data['to'] = this.dateTo ? this.dateTo.getTime() : new Date().getTime();
    data['rangeFrom'] = this.selectedQuickRangeFrom;
    data['rangeTo'] = this.selectedQuickRangeTo;
    data['dateFrom'] = this.dateFrom;
    data['dateTo'] = this.dateTo;
    data['label'] = this.timerangelabel;
    this.updateTime.emit(data);
  }

  graphdaone() {
    this. _customevent.timerangeGraph.emit({result: this.selectedRefresh , header: 'timeselect'});
  }

  // for grid change quick range to UTC format
  converToUTC(data) {
    const currentDate = new Date();
    let finalDate;
    const selectedRange = data.slice(data.indexOf('-') + 1, data.length - 1);
    if (data.endsWith('m')) {
      finalDate = currentDate.getUTCMinutes() - selectedRange;
      currentDate.setUTCMinutes(finalDate);
    } else if (data.endsWith('h')) {
      finalDate = currentDate.getUTCHours() - selectedRange;
      currentDate.setUTCHours(finalDate);
    } else if (data.endsWith('d')) {
      finalDate = currentDate.getUTCDate() - selectedRange;
      currentDate.setUTCDate(finalDate);
    }
    return currentDate.getTime();
  }

  // for reload button click
  reload() {
    this. _customevent.reloaddatepickerGraph.emit({result: '' , header: 'reloadGraph'});
    this.refresh.emit(new Date().getTime());
  }

  // for expand button click
  expandView() {
    this.expand.emit();
  }

  ChangeInType(event) {
    this.appTypeChange.emit(event);
  }

  // loading todate based on fromdate
  displayToDate(date) {
    this.isHideDateTo = false;
    this.maxDate = new Date();
    this.dateTo = this.maxDate;
  }

  //  validating todate
  validateTime() {
    this.isOkButtonDisabled = false;
  }
}
export interface DatePickerEvents {
  switchView(event): void;
  refresh(event): void;
  updateTime(event): void;
  switchPopupView(event): void;
  refreshPopup(event): void;
  updatePopupTime(event): void;
}
