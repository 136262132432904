<p-growl [(value)]="msgs" life="3000"></p-growl>
<p-table *ngIf="errormsg.length===0" [id]="id" [columns]="selectedColumns" [value]="colData" #advancedTable selectionMode="single"
    (onRowSelect)="onRowSelect($event)" [scrollable]="true" [scrollHeight]="height" [resizableColumns]="true" [loading]="loading"
    loadingIcon="fa fa-spinner" [lazy]="true" (onLazyLoad)="loadDataLazy($event)" [paginator]="true" pageLinks="3" [rows]="rows"
    [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]" [responsive]="true" [reorderableColumns]="true" columnResizeMode="expand">

    <ng-template pTemplate="caption">
        <div class="popUpHeader">
            <div *ngIf="clearSearch" class="search" [ngStyle]="(id =='gridwithautoheight')?{'visibility':'hidden'}:''" [ngClass]="(popup)?'searchinpopupheader':'searchinnormalheader'">
                <i class="fa fa-search"></i>
                <input type="text" #dashfilter pInputText size="50" placeholder="Search" (input)="advancedTable.filterGlobal($event.target.value, 'contains')" autocomplete="off">
                <span>
                    <b> Total {{totalRecords}} Rows</b>
                </span>
            </div>
            <div *ngIf="popup" class="popupmultiselect">
                    <p-button *ngIf="showDownload"icon="fas fa-download" iconPos="right" label="Export" [style]="{'font-size': '10px', 'margin-right': '6px','top': '-7px'}" (click)="download()">
                </p-button>
                <p-multiSelect *ngIf="clearSearch" maxSelectedLabels="0" [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                    id="graph" appendTo="body" selectedItemsLabel="{0} columns selected" [style]="{minWidth: '170px'}" defaultLabel="Choose Columns"></p-multiSelect>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columns">
        </colgroup>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [style.width]="col.size" [ngClass]="(id !=='soc')?(popup)?'textwrapwithwidth':'textwrapheader':''"
                [pSortableColumn]="(col.sortable)?col.field:''" [ngStyle]="{'cursor':(col.sortable)?'pointer':'default'}" pResizableColumn
                pReorderableColumn>
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
            </th>
            <th [style.width]="'40px'"></th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.filterType" [style.padding-left]="'2'" #custTab>
                <input id="jiratext" *ngSwitchCase="'keyword'" pInputText type="text" [style.width]="(col.field=='key')?'100px':(col.field=='summary')?'300px':'200px'" (input)="advancedTable.filter($event.target.value, col.field, col.filterMatchMode)" autocomplete="off">
                <p-dropdown *ngSwitchCase="'select'" [options]="col.selectOptions" [autoWidth]="false" [style]="{'width':'130px'}" appendTo="body" (onChange)="advancedTable.filter($event.value, col.field, 'equals')"></p-dropdown>
                <p-multiSelect *ngSwitchCase="'multiselect'" class="multiselect" maxSelectedLabels="0" selectedItemsLabel="{0} Selected" [panelStyle]="{minWidth:'12em'}"  [options]="col.selectOptions" [style]="{'width':'138px'}" appendTo="body" (onChange)="advancedTable.filter($event.value, col.field, 'equals')"></p-multiSelect>
                <div *ngSwitchCase="'date_created'">
                        <i class="fa fa-close" (click)="createDate=null;advancedTable.filter(null, col.field, col.filterMatchMode)" style="cursor:pointer" *ngIf="createDate"></i>
                        <p-calendar [appendTo]="custTab" [(ngModel)]="createDate" [showIcon]="true" (onSelect)="dateFilter($event, col.field, advancedTable)" (onInput)="dateFilter($event, col.field, advancedTable)">
                        </p-calendar>
                    </div>
                <div *ngSwitchCase="'date_updated'">
                    <i class="fa fa-close" (click)="updateDate=null;advancedTable.filter(null, col.field, col.filterMatchMode)" style="cursor:pointer" *ngIf="updateDate"></i>
                    <p-calendar [appendTo]="custTab" [(ngModel)]="updateDate" [showIcon]="true" (onSelect)="dateFilter($event, col.field, advancedTable)" (onInput)="dateFilter($event, col.field, advancedTable)">
                    </p-calendar>
                </div>
            </th>
            <th [style.width]="'40px'"></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" style="text-indent: 5px">
            <td *ngFor="let col of columns;let isLast = last" [style.width]="col.size" [ngClass]="popup?'textwrapwithwidth':'textwrapbody'"
                class="ui-resizable-column" [pTooltip]="rowData[col.field]" tooltipPosition="top">
                <span class="ui-column-title">{{col.header}}</span>
                <span *ngIf="(col.field !== 'graph') && (col.header !== 'State') && (col.field !== 'icon') && (col.field !== 'jiraicon')">
                    {{ rowData[col.field]}}
                </span>
            </td>
            <td [style.width]="'40px'">
                <span [pTooltip]="ticketTitle" tooltipPosition="top">
                    <i (click)="OnIconClick($event,rowData)" [class]="ticketicon" aria-hidden="true"></i>
                </span>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length" [style.width]="'2500px'">
                No records found
            </td>
        </tr>
    </ng-template>
</p-table>
<p-dialog header="Export as .xls File" *ngIf="selectgroupgDialog" [focusOnShow]='false' [(visible)]="selectgroupgDialog" [modal]="true"
[responsive]="true" showEffect="fade" height='400' width='450'>
<div style="height:200px">
    <div style="margin-top:3%;">
        <label> No. of rows to download: {{totalRecords}}</label>
    </div>
    <div style="margin-top:3%;">
            <p-listbox [options]="groupName" [(ngModel)]="selectedgroup" [style]="{'width':'250px'}" [listStyle]="{'max-height':'150px'}">
                    <ng-template let-car pTemplate="item">
                      <div class="ui-helper-clearfix">
                        <span>{{car.label}}</span>
                      </div>
                    </ng-template>
                  </p-listbox>
    </div>
    <p-messages *ngIf="errorgroupbymsg.length!==0" [(value)]="errorgroupbymsg" [closable]="false"></p-messages>
  </div>
  <p-footer>
        <p-button icon="fas fa-paper-plane" iconPos="right" label="Submit" [style]="{'font-size': '13px','margin-top':'10px'}" (click)="selectgroupBy()">
            </p-button>
  </p-footer>
</p-dialog>
<p-messages *ngIf="errormsg.length!==0" [(value)]="errormsg" [closable]="false"></p-messages>