    <app-advance-grid id="circuitReport" [totalRecords]="circuiteDataReport.totalRecords" height="auto"  popup="true" [selectedColumns]="circuiteDataReport.selectedColumns"
                    [cols]="circuiteDataReport.cols" [colData]="circuiteDataReport.colData"   [org]="org" [dataUrl]="dataUrl"
                      [lazy]="true"  (update)="advanceLoadGrid($event)" 
                     (advanceRowSelect)="advanceRowSelect($event)" ></app-advance-grid>
                    <p-dialog header="Circuit Information " *ngIf="circuitReportDialog" [(visible)]="circuitReportDialog" [modal]="true" [responsive]="true" showEffect="fade" [style]="{height :'auto', width:'1200'}" >
                            <p-accordion [multiple]="true">
                                    <p-accordionTab  *ngFor="let group of Header;index as i" [selected] ="i === 0" header ="{{group.label}}" style="height:100%;color:red;">
                                     <div class="row" *ngIf="Header?.length !==0">
                                        <div class="col-lg-12 col-offset-12 ">
                                              <div class="col-lg-12 col-offset-11 " *ngFor="let formvalue of group.rows" style="padding-bottom:.7%;">
                                                  <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;"> {{formvalue.field}}</span> </div>
                                                  <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="!formvalue.ref" ><span  [innerHtml]='formvalue.value'></span></div>
                                                  <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="formvalue.ref" ><span> <a title="{{formvalue.value}}" (click)='displaygridInfo(formvalue)' style="color:skyblue;text-decoration:underline;" [innerHtml]='formvalue.value'></a></span></div>
                                              </div>
                                        </div>
                                      </div>
                                    </p-accordionTab>
                                </p-accordion>
                    </p-dialog>
                    <p-dialog [header]="CircuitReoprtInfoTitle" *ngIf="circuitInfoReoprtDialog" [(visible)]="circuitInfoReoprtDialog" [modal]="true" [responsive]="true" showEffect="fade" [style]="{height :'auto', width:'1200'}">
                            <p-accordion  [multiple]="true">
                                 <p-accordionTab  *ngFor="let circuitInfo of circuitInfoReportData; index as i" [selected]="i === 0" header ="{{circuitInfo.label}}" style="height:100%;color:red;">
                                                    <div class="row" *ngIf="circuitInfoReportData?.length !==0">
                                                    <div class="col-lg-12 col-offset-12 ">
                                                            <div class="col-lg-12 col-offset-11 " *ngFor="let formvalue of circuitInfo.rows" style="padding-bottom:.7%;">
                                                            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">{{formvalue.field}}</span> </div>
                                                            <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="!formvalue.ref" ><span [innerHtml]='formvalue.value'></span></div>
                                                            <div class="col-lg-6" style="padding:0% !important;text-align:left;" *ngIf="formvalue.ref" ><span> <a title="{{formvalue.value}}" (click)='displaygridInfo(formvalue)' style="color:skyblue;text-decoration:underline;" [innerHtml]='formvalue.value'></a></span></div>
                                                            </div>
                                                    </div>
                                                    </div>
                                   </p-accordionTab>
                                            </p-accordion> 
                                    </p-dialog>

  