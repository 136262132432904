import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FileUploadModule} from 'primeng/fileupload';
import {FormsModule} from '@angular/forms';
import {RadioButtonModule} from 'primeng/radiobutton';
import {GrowlModule} from 'primeng/growl';
import {SelectModule} from 'ng2-select';
import {SiteDropdownComponent} from './site-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    SelectModule
  ],
  declarations: [SiteDropdownComponent],
  exports: [SiteDropdownComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class SiteDropdownModule { }
