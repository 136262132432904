import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule, SelectButtonModule } from 'primeng/primeng';
import { DatePickerComponent } from './date-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/primeng';
import { OverlayPanelModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';
import {InputSwitchModule} from 'primeng/primeng';
@NgModule({
  imports: [
    CommonModule ,
    TooltipModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    CheckboxModule,
    OverlayPanelModule,
    DropdownModule,
    InputSwitchModule,
    SelectButtonModule
  ],
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class DatePickerModule { }
