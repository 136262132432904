import { Component, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomEvents } from '../../../events/customevents';
import {ButtonModule} from 'primeng/button';
import { ActivatedRoute } from '@angular/router';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import { TableData } from '../../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { Message } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-group-notifications',
  templateUrl: './group-notifications.component.html',
  styleUrls: ['./group-notifications.component.scss']
})
export class GroupNotificationsComponent implements OnInit, GridTableEvents  {
  org: string;
  isRowSelect;
  groupNotificationGrid;
  groupNotificationGridUrl;
  GroupNotificationDialog = false;
  createGroupDialog = false;
  tempValue;
  groupName;
  userName;
  submitted;
  createGroupForm;
  createReqerrormsg;
  datauserUrl;
  userGrid;
  selectedItems = [];
  selectedUser = [];
  groupId;
  slecetedData;
  selectedEmail = [];
  selectedItemsCreate = [];
  selectedCreateUser = [];
  creategroupselectedName;
  selectedCreateUserobj = [];
  isPermission;
  deletegroupmsg;

  constructor(private route: ActivatedRoute, private _portalhttp: PortalHttpServiceService, private _customEvents: CustomEvents,
    private formBuilder: FormBuilder) {
    this.groupNotificationGrid = new TableData();
    this.userGrid = new TableData();
    this.isRowSelect = false;
    const user = JSON.parse(sessionStorage.getItem('user'));
    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
  });
  user['orgs'].forEach(element => {
    if (element.key === this.org) {
     this.isPermission = element.permissions;
    }
  });
  }
  ngOnInit() {
    this._customEvents.showHideLoader.emit({ show: false });
    this.groupNotificationGridUrl = '/api/v1/notification/groups/search';
    this.groupNotificationGrid.reloadEvent = '';
  }
  loadGrid(data) {
    if (this.groupNotificationGrid.cols.length === 0) {
      this.groupNotificationGrid.cols = data['headers'];
      const columns = [];
     this.groupNotificationGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.groupNotificationGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.groupNotificationGrid.colData = rawData;
    this.groupNotificationGrid.totalRecords = data['total'];
  }
  loadPopupGrid(event) {
    console.log('loadpopup grid');
 }
 loaduserGrid(data: any) {
  if (this.userGrid.cols.length === 0) {
    this.userGrid.cols = data['headers'];
    const columns = [];

    this.userGrid.cols.forEach(element => {
      if (element.hasOwnProperty('default') && element.default) {
        delete element.size;
        columns.push(element);
      }
    });
    this.userGrid.selectedColumns = columns;
  }
  const rawData = data['data'];
  this.userGrid.colData = rawData;
  this.userGrid.totalRecords = data['total'];
}
  rowSelect(event) {
    this.deletegroupmsg =[];
     this.datauserUrl = '/api/v1/customer/employee' ;
     this.groupName = event.data['Code'];
     this.userName = event.data['users'];
     this.groupId = event.data['_id'];
     const selectedUrl = '/api/v1/notification/groups/users/' + this.groupId;
     const body = {'limit': 200};
     this.selectedEmail = [];
     this.selectedUser = [];
     this._portalhttp.post(selectedUrl, this.org, body).subscribe((res) => {
        this.slecetedData = res['data'];
        this.selectedItems = res['data'];
        for (let i = 0; i < this.slecetedData.length; i++) {
          this.selectedEmail.push(this.slecetedData[i]['Id']);
          this.selectedUser.push(this.slecetedData[i]['Email']);
        }
        this.GroupNotificationDialog = true;
       }, err => {
       });
  }
  slectedUserName(event) {
    this.selectedUser = [];
    this.selectedEmail = [];
   for (let i = 0; i < event.length; i++) {
    this.selectedUser.push(event[i]['Email']);
    this.selectedEmail.push(event[i]['Id']);
  }
  }
  groupvalidate(event) {
    if (event.which === 32) {
      this.f.creategroupName.errors.required = true;
      event.preventDefault();
    }
  }
  unselectUserName(event) {
    console.log(event);
  }
  hideupdateDialog(event) {
    this.selectedEmail = [];
    this.selectedItems = [];
    this.selectedUser = [];
    this.userName = '';
    this.GroupNotificationDialog = false;
  }
  removeUser(event) {
   for (let i = 0; i < this.userGrid.colData.length; i++) {
      if (this.userGrid.colData[i]['Email'] === event.value) {
        const index = this.selectedEmail.indexOf(this.userGrid.colData[i]['Id']);
        if (index !== -1) {
        this.selectedEmail.splice(index, 1);
        this.selectedItems.splice(index, 1);
        this.selectedItems = this.selectedItems;
        }
      }
   }
  }
  enableIcon(data) {
    $('.' + data).css('display', 'inline-block');
    $('.' + data).prop('disabled', false);
    this.tempValue = $('.' + data).val();
   }
   clearIcon(data) {
      $('.' + data).val(this.tempValue);
      $('.' + data).prop('disabled', true);
   }
  cancelReq() {
    this.GroupNotificationDialog = false;
    this.deletegroupmsg = [];
    this.groupNotificationGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
}
deleteGroup() {
  const deleteUrl = '/api/v1/notification/groups/' + this.groupId;
  const headers = 'GtmEvents';
  this._customEvents.sendGtmEvt.emit({ data: 'SOC_Deletegroup', header: headers });
  this._portalhttp.delete(deleteUrl, this.org).subscribe((res) => {
    if (res['data'] === 'Success') {
        this.GroupNotificationDialog = false;
        this.groupNotificationGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully deleted Group!' });
    } else {
    this.deletegroupmsg = [];
    this.deletegroupmsg.push({ severity: 'error', summary: '', detail: res['data'] });
    document.getElementById('deletegroup').scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, error => {
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
  });
}
updateNotification() {
  const  updateUrl = '/api/v1/notification/groups/' + this.groupId;
  const body = {
    'employees': this.selectedEmail,
    'name': this.groupName
  };
  const headers = 'GtmEvents';
  this._customEvents.sendGtmEvt.emit({ data: 'SOC_Updategroup', header: headers });
  this._portalhttp.putWitoutRetry(updateUrl, this.org, body).subscribe(() => {
    this.selectedEmail = [];
    this.selectedItems = [];
    this.selectedUser = [];
    this.userName = '';
    this.GroupNotificationDialog = false;
    this.groupNotificationGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated Group!' });
  }, error => {
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
  });
}
slectedCreateUserName(event) {
  this.selectedCreateUserobj.push(event.data);
  this.selectedItemsCreate = [];
  this.selectedCreateUser = [];
  for (let i = 0; i < event.length; i++) {
  if (this.selectedCreateUser.indexOf(event[i]['Email']) === -1) {
  this.selectedItemsCreate.push(event[i]['Id']);
  this.selectedCreateUser.push(event[i]['Email']);
  }
}
}
oncreateNotification() {
  this.userName = '';
  this.selectedEmail = [];
  this.selectedItems = [];
  this.selectedItemsCreate = [];
  this.selectedCreateUser = [];
  this.createGroupDialog = true;
  this.datauserUrl = '/api/v1/customer/employee';
  this.submitted = false;
  this.createGroupForm = this.formBuilder.group({
    creategroupName: ['', Validators.required],
    creategroupselectedName: ['', '' ]
});
}
removeCreateUser(event) {
  for (let i = 0; i < this.userGrid.colData.length; i++) {
    if (this.userGrid.colData[i]['Email'] === event.value) {
      const index = this.selectedItemsCreate.indexOf(this.userGrid.colData[i]['Id']);
      this.selectedItemsCreate.splice(index, 1);
    }
 }
}
rowUnselectCreateUser(event) {
 
}
hideCreateDialog(event) {
  this.selectedCreateUser = [];
  this.selectedItemsCreate = [];
  this.createGroupDialog = false;
}
get f() { return this.createGroupForm.controls; }

cancelCreateReq() {
  this.selectedCreateUser = [];
  this.selectedItemsCreate = [];
  this.createGroupDialog = false;
}
onSubmit() {
  this.submitted = true;
  if (this.createGroupForm.invalid) {
      return;
  }
  if (this.createGroupForm.value.creategroupName.trim() === '') {
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Please enter group name!!' });
     return;
  }
  const createUrl = '/api/v1/notification/groups';
  const body = {
    'employees': this.selectedItemsCreate,
    'name': this.createGroupForm.value.creategroupName
  };
  const headers = 'GtmEvents';
  this._customEvents.sendGtmEvt.emit({ data: 'SOC_Creategroup', header: headers });
  this._portalhttp.post(createUrl, this.org, body).subscribe((res) => {
    this.createGroupDialog = false;
    this.groupNotificationGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully created group !' });
    }, err => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
    });
  }
}

