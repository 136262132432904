import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DevicesListComponent} from './devices-list.component';
import {PickListModule} from 'primeng/picklist';
import {ButtonModule} from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import {FormsModule} from '@angular/forms';
import {GrowlModule} from 'primeng/growl';
import {TabViewModule} from 'primeng/tabview';
import { GraphContainerModule } from '../../../graph-container/graph-container.module';
import { GraphModule } from '../../../graph/graph.module';

@NgModule({
  imports: [
    CommonModule,
    PickListModule,
    ButtonModule,
    DialogModule,
    GridTableModule,
    TooltipModule,
    FormsModule,
    GrowlModule,
    TabViewModule,
    GraphContainerModule,
    GraphModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DevicesListComponent],
  exports: [DevicesListComponent]
})
export class DeviceListModule { }
