import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent} from './configuration.component';
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ConfigurationComponent],
  exports :[ConfigurationComponent]
})
export class ConfigurationModule { }
