import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { BaseCtrl } from '../base/BaseCtrl';
import { ActivatedRoute } from '@angular/router';
import { CustomEvents } from '../events/customevents';
import { AppInitialiserService } from '../services/app-initialiser.service';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent extends BaseCtrl implements OnInit, AfterViewInit {

  @Input() graphs;
  @Input() graphName;
  @Input() fullView;
  @Input() scrollTo;
  @Input()  isShow = true;
  options;
  selectedOptions = [];
  graphDisplay = [];
  quickRangeHour;
  quickRangeDays;

  dateFrom: Date;
  dateTo: Date;
  isShowDateTo;
  hasSelectedOptionsChanged;
  timeRangeToAppend;
  refresh;
  selectedQuickRangeFrom;
  selectedQuickRangeTo;
  selectedRange;
  selectedRefresh;
  appendedRefresh;
  urlCollection;
  reloadParam;
  timerangelabel;
  maxDate;
  timeRangeToAppendGraph;
  isDisplayPopup;
  currentDevice;
  isOkButtonDisabled;
  org;
  switchState;
  deviceUrl;
  reloadParamPopup;
  timerangelabelPopup;

  appInitialisedData;

  constructor(private route: ActivatedRoute, private _customevent: CustomEvents,
  private appInitialiserService: AppInitialiserService) {
    super();

  }

  // initialise default values
  ngOnInit() {
    this.appInitialiserService.Properties.subscribe((res) => {
      this.appInitialisedData = res;
    });
    this.isOkButtonDisabled = true;
    this.isDisplayPopup = false;
    this.isShowDateTo = true;
    this.isShow = false;
    this.hasSelectedOptionsChanged = false;
    this.timeRangeToAppend = '&from=now-24h&to=now';
    this.selectedQuickRangeFrom = 'now-24h';
    this.selectedQuickRangeTo = 'now';
    this.selectedRefresh = 'off';
    this.urlCollection = [];
    this.reloadParam = '';
    this.timerangelabel = 'Last 24 hour';
    this.graphDisplay = [];
    this.options = [];
    this.options = this.graphs;
    for (const items of this.options) {
      for (const i of items.graphUrls) {
        i['category'] = items.name;
        i['id'] = items.id;
        i['type'] = items.type;

      }
      this.urlCollection.push(items.graphUrls);
        for (const item of items.graphUrls) {
          if (item.default) {
            this.selectedOptions.push(item.name);
            this.graphDisplay.push(item);
          }
      }
    }
    this.quickRangeHour = [
      { label: 'Last 30 minute', value: 'now-30m' },
      { label: 'Last 1 hour', value: 'now-1h' },
      { label: 'Last 3 hour', value: 'now-3h' },
      { label: 'Last 6 hour', value: 'now-6h' },
      { label: 'Last 12 hour', value: 'now-12h' },
      { label: 'Last 24 hour', value: 'now-24h' }
    ];
    this.quickRangeDays = [
      { label: 'Last 2 days', value: 'now-2d' },
      { label: 'Last 7 days', value: 'now-7d' },
      { label: 'Last 10 days', value: 'now-10d' },
      { label: 'Last 30 days', value: 'now-30d' },
      { label: 'Last 60 days', value: 'now-60d' }
    ];

    this.refresh = [
      { label: 'off', value: 'off' },
      { label: '10s', value: '10s' },
      { label: '30s', value: '30s' },
      { label: '1m', value: '1m' },
      { label: '5m', value: '5m' },
      { label: '10m', value: '10m' },
      { label: '15m', value: '15m' }
    ];
    this.maxDate = new Date();
    this.route.queryParams.subscribe((params) => {
      this.org = params['org'];
    });
    this._customevent.changeGraph.subscribe((value) => {
      if (value.header === 'graphHide') {
       this.setGraphs(value.result);
      }

    });
    this._customevent.selectQuckRangeGraph.subscribe((value) => {
      if (value.header === 'selectQuickrange') {
       this.selectnewQuickRange(value.result);
      }
    });
    this._customevent.reloaddatepickerGraph.subscribe((value) => {
      if (value.header === 'reloadGraph') {
       this.reload();
      }
    });
    this._customevent.timerangeGraph.subscribe((value) => {
      if (value.header === 'timeselect') {
        this.selectedRefresh = value.result;
       this.applyTimeRange();
      }
    });
  }

  // parent calles this method to pass the data
  setGraphs(data) {
    this.graphDisplay = [];
    const selectedNames = data;
    selectedNames.forEach(element => {
      this.urlCollection.forEach(item => {
        item.forEach(i => {
          if (element === i.name) {
            if (!i.fullView) {
              this.graphDisplay.unshift(i);
            } else {
              this.graphDisplay.push(i);
            }
          }
        });
      });
    });
  }

  // on quick range selection this method is called

  selectnewQuickRange(value: any) {
    this.selectedQuickRangeFrom = value;
    this.dateFrom = null;
    this.dateTo = null;
    this.applyTimeRange();
    this.timerangelabel = value.label;
  }
  selectQuickRange(value: any) {
    this.selectedQuickRangeFrom = value.value;
    this.dateFrom = null;
    this.dateTo = null;
    this.applyTimeRange();
    this.timerangelabel = value.label;
  }

  // on date & time selection this methos is called
  applyTimeRange() {
    const from = this.dateFrom ? this.dateFrom.getTime().toString() : this.selectedQuickRangeFrom;
    if (this.dateFrom) {
      this.timerangelabel = 'Time Range';
    }
    const to = this.dateTo ? this.dateTo.getTime().toString() : this.selectedQuickRangeTo;
    const refresh = 'off' === this.selectedRefresh ? '' : '&refresh=' + this.selectedRefresh;
    this.timeRangeToAppend = '&from=' + from + '&to=' + to + refresh;
    this.timerangelabel = '&from=' + from + '&to=' + to + refresh;
  }

  // rerfresh button click handled here
  reload() {
    this.reloadParam = '&_timestamp=' + new Date().getTime().toString();
  }

  // get the id of graph container
  ngAfterViewInit() {
    if (this.scrollTo) {
      document.getElementById(this.scrollTo).scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  // setting layout for graphs
  isFullView(index: any, last: any): boolean {
    if (this.fullView) {
      return this.fullView;
    } else {
      return (last && (index + 1) % 2 !== 0);
    }
  }

  // loading todate based on fromdate
  displayToDate(date) {
    this.isShowDateTo = false;
    this.maxDate = new Date();
    this.dateTo = this.maxDate;
  }

  //  validating todate
  validateTime() {
    this.isOkButtonDisabled = false;
  }

  // default values are set when expand is clicked
  getPopUpInfo(value) {
    this.timeRangeToAppendGraph = '&from=now-24h&to=now';
    this.reloadParamPopup = '';
    this.timerangelabelPopup = this.timerangelabel;
    this.deviceUrl = value.url;
    this.currentDevice = value;
    this.switchState = 'Graph';
    if ((!this.graphName && this.graphName)) {
      this._customevent.displayGridfromGraphComponent.emit({data: this});
    } else {
      this.isDisplayPopup = true;
    }
  }

  // swicthing gird to graph
  switchPopupView(value) {
    this.switchState = value;
    if (value == 'Graph') {
      this.deviceUrl = this.currentDevice.url;
    } else {
      this.deviceUrl = '';
    }

  }

  // refresh for grid
  refreshPopup(event) {
    this.reloadParamPopup = '&_timestamp=' + new Date().getTime().toString();
  }

  // whenever time or date changes the state is maintained
  updatePopupTime(event) {
    this.timeRangeToAppendGraph = event.time;
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;
    this.timerangelabelPopup = event.label;
    this.selectedQuickRangeFrom = event.rangeFrom;
    this.selectedQuickRangeTo = event.rangeTo;
  }

}


