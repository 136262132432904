<div>
    <app-date-picker [collapseId]="graphName" [isGraphChange]= "true" [autorefresh]="true" [isSwitch]="false"  [timerangelabel] ="" [selectedstyle]="{'width':'600px'}" [selectedQuickRangeFrom]="selectedQuickRangeFrom"
    [selectedQuickRangeTo]="selectedQuickRangeTo" [dateFrom]="dateFrom" [dateTo]="dateTo" (switchView)="switchPopupView($event)"
    (refresh)="refreshPopup($event)" (updateTime)="updatePopupTime($event)" [graphoption]="options" style="display: flow-root"></app-date-picker>
 <div class="ui-g ui-fluid" style="display:flow-root">
    <p-overlayPanel #panel1  class="customdatepicker" [style]="{'width':'600px' ,'float':'right'}">
      <div class="ui-g-12 ui-md-6">
        <h5>Quick Ranges</h5>
        <div class="border">
          <ul>
            <div class="col-xs-12 col-sm-6">
              <div class="row">
                <li *ngFor="let range of quickRangeHour" class="col-xs-12">
                  <a herf="#" (click)="panel1.hide(); selectQuickRange(range)">{{range.label}}</a>
                </li>
              </div>
            </div>
            <li *ngFor="let range of quickRangeDays" class="col-xs-12 col-sm-6">
              <a herf="#" (click)="panel1.hide(); selectQuickRange(range)">{{range.label}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="ui-g-12 ui-md-6 ">
        <h5>Time Range</h5>
        <div class="dateSpace">
          <label>From </label>
          <p-calendar #from [(ngModel)]="dateFrom"  [showTime]="true" [showIcon]="true" [placeholder]="selectedQuickRangeFrom" [maxDate]="maxDate"
            (onSelect)="validateTime() ;displayToDate(dateFrom)">
            <p-footer>
              <div class="ui-g-12" style="text-align:end">
                <button class="btn btn-primary" [disabled]="isOkButtonDisabled" (click)="from.overlayVisible = false ">Ok</button>
              </div>
            </p-footer>
          </p-calendar>
        </div>
        <div class="dateSpace">
          <label>To </label>
          <p-calendar #to [(ngModel)]="dateTo" [showTime]="true" [disabled]="isShowDateTo" [showIcon]="true" [minDate]="dateFrom" [maxDate]="maxDate"
            (onSelect)="validateTime()" [placeholder]="selectedQuickRangeTo">
            <p-footer>
              <div class="ui-g-12" style="text-align:end">
                <button class="btn btn-primary" [disabled]="isOkButtonDisabled" (click)="to.overlayVisible = false ">Ok</button>
              </div>
            </p-footer>
          </p-calendar>
        </div>
        <div class="dateSpace">
          <label>Refresh </label>
          <p-dropdown [options]="refresh" [(ngModel)]="selectedRefresh" [style]="{'width':'91px'}" placeholder="Select"></p-dropdown>
        </div>
      </div>
      <div style="float:right">
        <button class="btn btn-primary" (click)="panel1.hide(); applyTimeRange()">Done</button>
      </div>
    </p-overlayPanel>
    <p-overlayPanel #panel2 [style]="{'height':'auto'}">
      <div class="graphContainer">
        <div *ngFor="let option of options">
          <h5 style="font-weight:600;">{{option.name}}</h5>
          <div class="ui-g">
            <div class="ui-g-12 ui-md-6 ui-lg-3"  *ngFor="let graph of option.graphUrls">
              <p-checkbox name="group2"  value="{{graph.name}}"  label="{{graph.name}}" [disabled]="(selectedOptions.length >= appInitialisedData.graphMaxCount) && (selectedOptions.indexOf(graph.name) == -1)"
                 [(ngModel)]="selectedOptions"  inputId="{{graph.name}}" (ngModelChange)="hasSelectedOptionsChanged = true">
              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div style="float:right; margin-top:15px">
        <button class="btn btn-primary" (click)="panel2.hide(); (hasSelectedOptionsChanged)?setGraphs(selectedOptions):''">Done</button>
      </div>
    </p-overlayPanel>
  </div>

  <div *ngFor="let item of graphDisplay;let last = last;let index = index" [ngClass]="isFullView(index,last) ?'col-md-12':'col-md-6 space'">
    <span *ngIf="!fullView && isShow">
      <i class="fa fa-th-large done" aria-hidden="true" (click)="getPopUpInfo(item)" pTooltip="Expand" tooltipPosition="left"></i>
    </span>
    <iframe [src]="item.url + timeRangeToAppend + reloadParam | safe" width="100%" height="250" frameborder="0" (load)="(last)?isShow=true:''"></iframe>
  </div>
</div>
<p-dialog [contentStyle]="{'height':'400px'}" [min-height]="800" *ngIf="isDisplayPopup" [header]="currentDevice.category" [(visible)]="isDisplayPopup"
  [modal]="true" showEffect="fade" styleClass="gridactive" [responsive]="true" (onHide)="isImpactedDetail=false; isProbableCause = false;">

  <app-date-picker [collapseId]="graphName" [timerangelabel]="timerangelabelPopup" [selectedstyle]="{'width':'600px'}" [selectedQuickRangeFrom]="selectedQuickRangeFrom"
    [selectedQuickRangeTo]="selectedQuickRangeTo" [dateFrom]="dateFrom" [dateTo]="dateTo" (switchView)="switchPopupView($event)"
    (refresh)="refreshPopup($event)" (updateTime)="updatePopupTime($event)" style="display: flow-root"></app-date-picker>

  <section *ngIf="switchState === 'Graph'" id="error">
    <app-graph-container isGrafanaSessionPresent="true" [timeRangeToAppendEvent]="timeRangeToAppendGraph" [reloadEvent]="reloadParamPopup"
      [org]="org" [deviceUrl]="deviceUrl"></app-graph-container>
  </section>

  <section *ngIf="switchState === 'Grid'" id="error">
    <app-graph-container [timeRangeToAppendEvent]="timeRangeToAppendGraph" isGrafanaSessionPresent="true" [reloadEvent]="reloadParamPopup"
      [org]="org" [deviceUrl]="deviceUrl" switchState='Grid'></app-graph-container>
  </section>
</p-dialog>