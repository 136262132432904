import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { CustomEvents } from '../events/customevents';
import { PortalHttpServiceService } from '../services/portal-http-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import {DateConverterPipe} from '../utils/dateConverter.pipe';
import {GrafanaService} from '../dashboard/device/service/grafana.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/primeng';
import { getRectCenter } from '@fullcalendar/core';

@Component({
  selector: 'app-ip-sla',
  templateUrl: './ip-sla.component.html',
  styleUrls: ['./ip-sla.component.scss']
})
export class IpSlaComponent implements OnInit, OnDestroy {
   msgs: Message[] = [];
   msgsAPI: Message[] = [];
   cities1 = [];
   cities2 = [];
   records = [];
   selstartPoint;
   selendPoint;
   pacakgeloss;
   latency;
   jitter;
   infoData = {};
   displayResult = false;
   isStartpointsel = true;
   ipsladata = {};
   ipslaCustomDateRange;
   daterange = 'latest';
   daterangeLabel = 'Latest';
   ipslaHeader;
     nodes = [];
   types = [{ label: 'Current', value: 'Current', icon: 'fa fa-clock-o' },
     { label: 'Custom', value: 'Custom', icon: 'fa fa-clock-o' }];
     isGrafanaSessionPresent;
     isDisplayGraph;
     renewSession = false;
     ipslaDialog = false;
     displayGraphListener: any;
     graphCollection = [];
     graphs = [];
     graphName;
     scrollTo;
     fullView;
     graphData = [];
     org;
  constructor(private customEvents: CustomEvents, private _portalHttp: PortalHttpServiceService,
    private sanitized: DomSanitizer, private _datePipe: DateConverterPipe,
    private _grafanaService: GrafanaService, private route: ActivatedRoute) {
      this.isGrafanaSessionPresent = false;

     }
     initializeDefaults() {
      this.isDisplayGraph = false;
    }

  ngOnInit() {
    this.customEvents.showGrafanaOption.subscribe(value => {
      this.graphData['source'] = '';
      this.graphData['target'] = '';
      this.loadGraphForIpSLA(this.graphData);
    });
   this.customEvents.showHideLoader.emit({ show: true });
    this.loadData(this.daterange);
  }
  loadData(data) {
    const url = '/api/v1/ip/sla?daterange=' + data;
      this.ipsladata = [];
      this._portalHttp.getwithcontenttype(url, 'ALL').subscribe((res: any) => {
        const obj = res['data'];
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            obj[key]['@timestamp'] = this._datePipe.transform(obj[key]['@timestamp']);
          }
       }
          this.ipsladata = res['data'];
          this.nodes = res['nodes'];
          this.loadCity1();
          this.customEvents.showHideLoader.emit({ show: false });
        }, error => {
          this.customEvents.showHideLoader.emit({ show: false });
          this.msgsAPI = [];
          this.msgsAPI.push({ severity: 'error', detail: 'Oops something went down while Loading Data !!' });
        });
  }
  loadCity1() {
    this.cities1 = [];
    this.cities1.push({'label': 'Select', 'value': null});
    for (let i = 0; i < this.nodes.length; i++) {
      this.cities1.push({'label': this.nodes[i].label, 'value': {'name': this.nodes[i].value, 'code': this.nodes[i].label}});
    }
  }
  resetfilter(dd) {
   dd.resetFilter();
  }
  selectStartPoint(event) {
      if (event.value) {
        this.cities2 = [];
        this.cities2.push({'label': 'Select', 'value': null});
        for (let i = 0; i < this.nodes.length; i++) {
          if (event.value.name !== this.nodes[i].value) {
            this.cities2.push({'label': this.nodes[i].label, 'value': {'name': this.nodes[i].value, 'code': this.nodes[i].label}});
          }
        }
        this.isStartpointsel = false;
      } else {
        this.isStartpointsel = true;
        this.infoData = {};
        this.displayResult = false;
        this.selendPoint = [0];
      }
  }
  getIPInfo(event) {
    this.customEvents.showHideLoader.emit({ show: true });
    if (this.selstartPoint && this.selstartPoint !== undefined && this.selendPoint && this.selendPoint !== undefined ) {
   const url = '/api/v1/ip/sla/route/' + this.selstartPoint.name + '/' + this.selendPoint.name + '?daterange=' + this.daterange;
    this._portalHttp.getwithcontenttype(url, 'ALL').subscribe((res: any) => {
       this.infoData = res;
       this.getLastTenRecords(this.selstartPoint.name, this.selendPoint.name);
       this.displayResult = true;
      });
    } else {
      this.customEvents.showHideLoader.emit({ show: false });
      this.infoData = [];
      this.displayResult = false;
      this.msgs = [];
      this.msgs.push({ severity: 'error', detail: 'Please select start and end points.' });
    }
  }
  getLastTenRecords(source, target) {
    const url = '/api/v1/ip/sla/route/' + this.selstartPoint.name + '/' + this.selendPoint.name + '/list';
    this._portalHttp.getwithcontenttype(url, 'ALL').subscribe((res: any) => {
      const obj = res['data'];
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key]['@timestamp'] = this._datePipe.transform(obj[key]['@timestamp']);
        }
     }
      this.records = res['data'];
     });
     this.customEvents.showHideLoader.emit({ show: false });
  }
  updateTime(event) {
    if (event) {
    this.customEvents.showHideLoader.emit({ show: true });
    this.daterange = event.value;
    this.daterangeLabel = event.label;
    this.isStartpointsel = true;
    this.infoData = {};
    this.displayResult = false;
    this.selendPoint = '';
    this.selstartPoint = '';
    this.loadData(this.daterange);
    }
  }
  onShowGraph(source, target) {
    this.isDisplayGraph = false;
    this.graphData['source'] = source;
    this.graphData['target'] = target;
    this.loadGraphForIpSLA(this.graphData);
  }
  closeDialog(event) {
    this.isDisplayGraph = false;
    this.ipslaDialog = false;
  }

  loadGraphForIpSLA(value) {
    if (value.source !== '' && value.target !== '') {
    this.org = sessionStorage.getItem('org');
    this.isDisplayGraph = false;
    this.graphs = [];
    const graphUrl = '/api/graph/ipsla/' + value.source + '/' + value.target;
      this._portalHttp.get(graphUrl, this.org).subscribe((rest: any) => {
        if (rest['data'].length === 0) {
          return;
        } else {
          this.graphCollection = rest['data'];
          for (const item of this.graphCollection) {
            item['id'] = 'ipsla';
            item['type'] = 'ipsala';
            this.graphs.push(item);
            this.graphName = '';
            this.fullView = true;
          }
          const source = this.nodes.find(o => o.value === value.source);
          const target = this.nodes.find(o => o.value === value.target);
          this.ipslaHeader = source.label  + ' --> ' +  target.label;
          this.isDisplayGraph = true;
          this.ipslaDialog = true;
        }
      }, error => {
       this.msgs = [];
       this.msgs.push({ severity: 'error', detail: 'Oops something went down' });
        this.isDisplayGraph = false;
        this.renewSession = true;
      }
      );
    }

  }
  ngOnDestroy() {
    if (this.displayGraphListener) {
      this.displayGraphListener.unsubscribe();
    }
  }
  getBaseline(data, type){
    if(data['baseline'] && data['baseline'][type]) {
      return '(' + data['baseline'][type] +')';
    } 
    return '';
  }

  getColor(val, type){
    if(val && val['baseline'] && val['baseline'][type +'_color']) {
      return val['baseline'][type +'_color'];
    } 
    return 'white';
  }
}
