import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableData } from '../../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { CustomEvents } from '../../../events/customevents';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { DeviceGridService } from './device-grid.service';
import * as L from 'leaflet';

@Component({
  selector: 'app-device-grid',
  templateUrl: './device-grid.component.html',
  styleUrls: ['./device-grid.component.scss']
})
export class DeviceGridComponent implements OnInit, GridTableEvents, OnChanges {
  @Input() org;
  deviceGrid;
  deviceGridPopup;
  dataUrl;
  _custEvents;
  isOpenInfo;
  isShowMap;
  marker;
  map;

  // variables of row select
  isRowSelect;
  devices;
  counts;
  storage;
  header;
  selectedData;
  title;
  subDevices;
  subValues;
  // for more info
  disp;
  moreInfo;
  // reboot details variable
  deviceRebootDetails;
  rebootDetailUrl;

  constructor(private _customEvents: CustomEvents, public service: DeviceGridService, public _datePipe: DateConverterPipe) {
    this.deviceGrid = new TableData();
    this.deviceGridPopup = new TableData();
    this.deviceRebootDetails = new TableData();
    this.isOpenInfo = false;
    this._custEvents = _customEvents;
    this.isRowSelect = false;
    this.disp = false;
    this.moreInfo = false;
  }


  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    this.dataUrl = '';
    if (changes['org']) {
      this.dataUrl = '/api/v1/device/details';
      this._custEvents.displayRowWiseEvt.subscribe((value: any) => {
        if (value.isGraph) {
          this.rowSelect(value);
        }
      });
    }

  }

  ngOnInit() {
    this.isShowMap = false;
  }

  // to be implemented
  rowSelect(event) {
    this.disp = false;
    this.moreInfo = false;
    this.isShowMap = false;
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'device_netsataus', header: headers });
    this.selectedData = event.data;
    this.selectedData['uptime-date'] = this.selectedData['uptime-date']
    && (this.selectedData['uptime-date'] !== 0 && typeof (this.selectedData['uptime-date']) !== 'string') ?
      this._datePipe.transform(this.selectedData['uptime-date'])
      : (typeof (this.selectedData['uptime-date']) === 'string') ? this.selectedData['uptime-date'] : '';
    this.selectedData['lastupdated'] = this.selectedData['lastupdated']
    && (this.selectedData['lastupdated'] !== 0 && typeof (this.selectedData['lastupdated']) !== 'string') ?
      this._datePipe.transform(this.selectedData['lastupdated'])
      : (typeof (this.selectedData['lastupdated']) === 'string') ? this.selectedData['lastupdated'] : '';
    const param = event.data['deviceId'];
    this.title = 'Device Info : ' + this.selectedData['host-name'];
    this.devices = {};
    this.counts = {
      'crashCount': 0,
      'rebootCount': 0
    };
    this.storage = {
      'CPU': 'N/A',
      'Memory': 'N/A'
    };
    this.header = this.deviceGrid.cols;

    this.service.getHardwareSummary(this.org, param).subscribe((rest: any) => {
      let data = [];
      data = rest.data;
      for (const item of data) {
        this.addDeviceDetail(item);
      }
    });
    this.counts.rebootCount = this.selectedData.reboot_count;
    this.counts.crashCount = this.selectedData.crash_count;

    this.service.getCpuMemoryCounts(this.org, param).subscribe((rest: any) => {
      const data = rest.data[0];
      this.storage.CPU = (data['cpu_avg'] === 0) ? 'N/A' : data['cpu_avg'].toFixed(2) + '%';
      this.storage.Memory = (data['mem_util'] === 0) ? 'N/A' : data['mem_util'].toFixed(2) + '%';
    });
    this.rebootDetailUrl = '/api/v1/device/reboot/' + param;
    this.isRowSelect = true;
  }

  // setting hardware status for devices
  addDeviceDetail(item) {
    if (Object.keys(item.details).length > 0) {
      this.devices[item['hw-class']] = item.details;
    } else {
      this.devices[item['hw-class']] = item.status;
    }
  }

  // for net status popUp detailed view functionality
  checkType(item) {
    if (typeof item === 'object') {
      this.detailValues(item);
    }
    return typeof (item);
  }

  // hardware status display for net status popUp
  detailValues(item) {
    this.subDevices = [];
    this.subValues = [];
    this.subDevices = Object.keys(item);
    for (let i = 0; i < this.subDevices.length; i++) {
      this.subValues.push(Object.entries(item[this.subDevices[i]]));
    }
  }

  // to be implemented
  loadPopupGrid(data) {
    if (this.deviceGridPopup.cols.length === 0) {
      this.deviceGridPopup.cols = data['headers'];
      this.deviceGridPopup.selectedColumns = this.deviceGridPopup.cols.slice(0, 6);
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach(element => {
        element['uptime-date'] = element['uptime-date']
        && (element['uptime-date'] !== 0) ? this._datePipe.transform(element['uptime-date']) : '';
        element['lastupdated'] = element['lastupdated']
        && (element['lastupdated'] !== 0) ? this._datePipe.transform(element['lastupdated']) : '';
      });
    }
    this.deviceGridPopup.colData = rawData;
    this.deviceGridPopup.totalRecords = data['total'];
  }


  // method is called  when we click on reboot card arrw
  loadRebootDetail(data) {
    if (this.deviceRebootDetails.cols.length === 0) {
      this.deviceRebootDetails.cols = data['headers'];
      this.deviceRebootDetails.selectedColumns = this.deviceRebootDetails.cols;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach(element => {
        element['vreboot_date_time-date'] = element['vreboot_date_time-date']
        && (element['vreboot_date_time-date'] !== 0) ? this._datePipe.transform(element['reboot_date_time-date']) : '';
        element['lastupdated'] = element['lastupdated']
        && (element['lastupdated'] !== 0) ? this._datePipe.transform(element['lastupdated']) : '';
      });
    }
    this.deviceRebootDetails.colData = rawData;
    this.deviceRebootDetails.totalRecords = data['total'];
  }
  hideDialog() {
    this.disp = false;
    this.moreInfo = false;
    this.isShowMap = false;
    this.isRowSelect =  false;
  }

  // load dashboard tabel
  loadGrid(data: any) {
    if (this.deviceGrid.cols.length === 0) {
      this.deviceGrid.cols = data['headers'];
      const columns = [];
      columns.push({
        'field': 'reachability',
        'header': 'State',
        'sortable': true,
        'size': '70px'
      });
      this.deviceGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      columns.push({
        'field': 'graph',
        'header': '',
        'size': '42px'
      });
      this.deviceGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    rawData.forEach(element => {
      if (element['device-type'] === 'vedge') {
        element['graph'] = 'Graph';
      } else {
        element['graph'] = 'No Graph';
      }
    });
    this.deviceGrid.colData = rawData;
    this.deviceGrid.totalRecords = data['total'];
  }

  // to be impleented
  expandView() {
    this.isOpenInfo = true;
  }

  // on click of each graph icon this method is called
  openGraphContainer(data) {
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'device_netsataus_graph', header: headers });
    this._custEvents.displayGraph.emit({ name: data['host-name'], id: data['deviceId'], type: data['device-type'] });
  }

  // draw map on popup
  openMap(item) {
    if (!this.marker) {
      this.isShowMap = true;
      setTimeout(() => {
        const fistLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: 1, maxZoom: 13 });
        this.map = L.map('sitemap', {
          zoom: 4,
          center: [0, 0],
          scrollWheelZoom: false,
          worldCopyJump: true,
          layers: [fistLayer]
        });

        this.marker = L.marker(new L.LatLng(parseFloat(item.latitude), parseFloat(item.longitude)), {
          icon: L.divIcon({
            html: ' <i class="fa fa-map-marker" style="font-size:45px;color:#186BA0;margin-left:-13px;margin-top:-15px" aria-hidden="true"></i>',
            iconAnchor: [0, 0],
            iconSize: [0, 0]
          })
        });
        this.marker.addTo(this.map);
        this.map.flyTo([parseFloat(item.latitude), parseFloat(item.longitude)], 10);
      }, 0);
    } else {
      this.isShowMap = false;
      this.marker = null;
    }
  }
}

