import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DateConverterPipe } from '../../utils/dateConverter.pipe';
import { TableData } from '../../common/SharedClasses/TableData';
import { AnalyticsService } from '../service/analytics.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-anomaly-result',
  templateUrl: './anomaly-result.component.html',
  styleUrls: ['./anomaly-result.component.scss']
})
export class AnomalyResultComponent implements OnInit, OnChanges {

  @Input() selectedQuickRangeFrom;
  @Input() selectedQuickRangeTo;
  @Input() timerangelabel;
  @Input() url;
  @Input() currentSelection;
  anomalyResult;

  isGraphLoading;
  selectedData;
  dataUrl;
  id;
  anomalyData;
  anomalyHeader;

  isGrafanaSessionPresent = true;
  org;

  appsesnsorid;
  anomaly;

  constructor(private _datePipe: DateConverterPipe, private _route: ActivatedRoute,
    private service: AnalyticsService) {
    this.anomalyResult = new TableData();
    this.isGraphLoading = true;
    this.anomalyResult.state = 'Graph';
    this.anomaly = new FormGroup({
      appSensor: new FormControl('appmon-6942', [Validators.required]),
    });
  }



  ngOnInit() {
    this.appsesnsorid = 'appmon-6942';
    this.id = 'anomalyResult';
    this.setInitialData();
    this.dataUrl = `api/v1/analytics/anomaly/${this.currentSelection.name}`;
    this._route.queryParams.subscribe(params => {
      this.org = params['org'] || sessionStorage.getItem('org');
    });
  }

  ngOnChanges() {

  }

  // sets data for audit grid in home page
  setInitialData() {
    this.anomalyResult.reloadEvent = '';
    this.anomalyResult.isGraph = true;
  }

  onAppsensorSelect(event) {
    this.appsesnsorid = event.value.name;
  }

  loadGrid(data: any) {

    this.anomalyData = data['data'];

    this.anomalyHeader = [
      {
        field: '@timestamp',
        header: 'Timestamp',
        sortable: true
      },
      {
        field: 'appsensor_id',
        header: 'Appsensor',
        sortable: true
      },
      {
        field: 'source_host',
        header: 'Source',
        sortable: true
      },
      {
        field: 'target_host',
        header: 'Target',
        sortable: true
      },
      {
        field: 'latency',
        header: 'Latency',
        sortable: true
      }];

    this.anomalyResult.cols = this.anomalyHeader;

    this.anomalyResult.selectedColumns = this.anomalyResult.cols;

    let rawData = this.anomalyData;
    if (rawData.length > 0) {
      rawData.forEach((element) => {
        element['@timestamp'] = element.hasOwnProperty('@timestamp') && (element['@timestamp'] !== 0) ?
          this._datePipe.transform(element['@timestamp']) : '';
      });
    }
    this.anomalyResult.colData = rawData;
    this.anomalyResult.totalRecords = data.total;
  }


  // swicthing gird to graph
  switchView(value) {
    const state = this.anomalyResult.state;
    if (state !== value) {
      this.anomalyResult.totalRecords = 0;
      this.anomalyResult.colData = [];
      this.anomalyResult.state = value;
    }
  }

  // refresh for grid
  refresh(event) {
    this.anomalyResult.totalRecords = 0;
    this.anomalyResult.colData = [];
    this.anomalyResult.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
  }

  updateTime(event) {
    this.anomalyResult.timeRangeToAppendEvent = event.time;
    this.anomalyResult.from = event.from;
    this.anomalyResult.to = event.to;
    this.anomalyResult.label = event.label;
    this.anomalyResult.rangeFrom = event.rangeFrom;
    this.anomalyResult.rangeTo = event.rangeTo;
    this.anomalyResult.dateFrom = event.dateFrom;
    this.anomalyResult.dateTo = event.dateTo;

  }


}
