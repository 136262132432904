
<app-grid-table [reload]="devicelistGrid.reloadEvent"  [popup]="false" [isHeaderWrap]="false" id="gridwithsmallwidth" [totalRecords]="devicelistGrid.totalRecords" [selectedColumns]="devicelistGrid.selectedColumns"
[cols]="devicelistGrid.cols" height="248px" [colData]="devicelistGrid.colData" [org]="org" [dataUrl]="devicelistGridsUrl" (update)="loadGrid($event)"
(rowSelect)="rowSelect($event)" [to]="0" [regionValue]='selectedregions'></app-grid-table>

<div id="devicelist_info_details">
    <div class="col-lg-12 col-offset-12" *ngIf="devicelistDialog" style="border: 1px solid lightgray;margin-top: 2%;">
        <div style ="margin-bottom:2%;margin-top:2%;">
            <a style="float:right;" (click)="hidedeviceDetaillist()"><i class="fa fa-times" aria-hidden="true" style ="font-size: 23Px;color:#000;font-weight:normal;"></i></a> 
        </div>
        <p-tabView>
         <p-tabPanel  header="Graph">
            <div id="graph-block-2" >
                <section class="graphContainer" *ngIf="devicelistDialog">
                    <app-graph [graphs]="graphs"    [graphName]="graphName" [fullView]="fullView" [scrollTo]="scrollTo"></app-graph>
                </section>
             </div>
         </p-tabPanel>
                <p-tabPanel  header="Device Details" style="text-align:center;">
                <div class="row" *ngIf="devicesDetaildata?.length !==0">
                        <div class="col-lg-12 col-offset-12 " *ngIf="devicesDetaildata?.length !==0">
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Device ID </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['device-id']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                         <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Model </span> </div>
                                         <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['model']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                         <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Throughput (KB/Sec) </span> </div>
                                         <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['throughput']}}</span></div>
                                 </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                         <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">CPS </span> </div>
                                         <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['cps']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Data Plane CPU (%) </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['dp-cpu']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                         <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Sessions</span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['sessions']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Fans </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['fans']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">HostName </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['hostname']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Logging Rate (Log/Sec) </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['logging-rate']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Management Plane CPU (%) </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['mp-cpu']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;"> Management Plane MEM (%) </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['mp-mem']}}</span></div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Ports </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['ports']}}</span>
                                            <i class="fa fa-plug"   aria-hidden="true"></i>
                                        </div>
                                </div>
                                <div class="col-lg-12 col-offset-11"  style="padding-bottom:.7%;">
                                        <div class="col-lg-2" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Power Supply </span> </div>
                                        <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span *ngIf="devicesDetaildata">{{devicesDetaildata['power']}}</span>
                                            <i class="fa fa-power-off"   aria-hidden="true"></i>
                                        </div>
                                </div>
                        </div>
                        </div>
                </p-tabPanel>
            </p-tabView>
    </div>
</div>

