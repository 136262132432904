import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrgmangementComponent} from '../orgmangement/orgmangement.component';
import { AuthenticateService } from '../../session/authenticate.service';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import {ButtonModule} from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { GridTableModule } from '../../grid-table/grid-table.module';
import { TooltipModule } from 'primeng/tooltip';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {AccordionModule} from 'primeng/accordion';
import {GrowlModule} from 'primeng/growl';
import { ReactiveFormsModule } from '@angular/forms';
import {OnboardusermanagmentModule} from '../onboardusermanagment/onboardusermanagment.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DialogModule,
    GridTableModule,
    TooltipModule,
    InputTextModule,
    FormsModule,
    AccordionModule,
    GrowlModule,
    ReactiveFormsModule,
    OnboardusermanagmentModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [OrgmangementComponent],
  exports: [OrgmangementComponent],
})
export class OrgmanagementModule { }
