<div  class="row" *ngIf="_dataProvider.isPermission(org, 'portal_customer_service_view_linked_ticket')">
    <div style="width: 45%;float: left;padding-left: 1%;">
         <a style="padding-right:2%;" (click)="showTicketDetail('details')">Ticket Details</a>
         <a (click)="showTicketDetail('linked')" *ngIf="_dataProvider.isPermission(org, 'portal_customer_service_view_linked_ticket')">Linked Tickets</a>
    </div>
    <div *ngIf="showTicketDetails" style="text-align:right;padding-right: 1%;padding-bottom: 1%;" >
         <p-dropdown #dd [options]="addList" [(ngModel)]="selectedList"  [style]="{'background':'#186ba0;border: 1px solid #186ba0;margin-bottom:2%;'}" (onChange)="getValue(dd)"></p-dropdown>
    </div>
</div>
<div *ngIf="showTicketDetails" class="row" style="padding-left:1%;padding-right:1%;">
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left: 0% !important;">
        <div class="col-lg-12 col-offset-11 " style="padding-bottom:.7%;padding-left :0% !important">
            <div class="col-lg-12" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight: bold;">{{eventDetails.summary}}</span> </div>
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;height: 21px;">
        <div class="col-lg-12 col-offset-11 " style="padding-bottom:.7%;">
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left:0% !important;">
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Ticket Number :</span> </div>
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span>{{eventDetails.key}}</span> </div>
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left:0% !important;">
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Start Date :</span> </div>
            <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span>{{_datePipe.transform(eventDetails['"Start Date & Time"'])}}</span> </div>
        </div>
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">End Date:</span> </div>
            <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span>{{_datePipe.transform(eventDetails['"End Date & Time"'])}}</span> </div>
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left:0% !important;">
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Ticket Status:</span> </div>
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span>{{eventDetails.status}}</span> </div>
        </div>
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Customer Name:</span> </div>
            <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span>{{eventDetails.organization}}</span> </div>
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left:0% !important;">
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Circuit ID:</span> </div>
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span>{{(eventDetails['"CFN Circuit"'])?(eventDetails['"CFN Circuit"']):'NA'}}</span> </div>
        </div>
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;">Site ID:</span> </div>
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span>{{(eventDetails['"Site ID"'])?(eventDetails['"Site ID"']):'NA'}}</span> </div>
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left:0% !important;">
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;"> Type:</span> </div>
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span>{{eventDetails.requestType}}</span></div>
        </div>
        <div *ngIf="!_dataProvider.isPermission(org, 'portal_customer_service_view_linked_ticket')" class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;"></span> </div>
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span></span> </div>
        </div>
        <div *ngIf="_dataProvider.isPermission(org, 'portal_customer_service_view_linked_ticket')" class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:600;"> Carrier Circuit:</span> </div>
            <div class="col-lg-3" style="padding:0% !important;text-align:left;"><span>{{(eventDetails['"Carrier Circuit ID"'])?(eventDetails['"Carrier Circuit ID"']):'NA'}}</span></div>
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;height: 21px;">
        <div class="col-lg-12 col-offset-11 " style="padding-bottom:.7%;">
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left: 0% !important;">
        <div class="col-lg-12 col-offset-11 " style="padding-bottom:.7%;padding-left :0% !important">
            <div class="col-lg-12" style="padding:0% !important;text-align:left;"><span style="font-size:14px;font-weight:bold;">Description:</span> </div>
        </div>
    </div>
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left:0% !important;">
        <div class="col-lg-6 col-offset-11 "  style="padding-bottom:.7%;padding-left:0% !important;">
            <div class="col-lg-6" style="padding:0% !important;text-align:left;"><span [innerHTML]="eventDetails.description"></span></div>
        </div>
       
    </div>
    
    <div class="col-lg-12 col-offset-12" style="border:1px solid black;padding-left: 0% !important;">
    <app-jira-comment [org] ="org" [issueId]="selectedIssue"></app-jira-comment>
    </div>
</div>
    <div class="row" *ngIf="showTicketDetails === false">
    <div style ="padding-left:1%;padding-right:1%;padding-top:2%;">
        <app-grid-table [reload]="linkedTicketGrid.reloadEvent" [isGlobalSearch]="false" [popup]="true" id="gridwithsmallwidth"
        [totalRecords]="linkedTicketGrid.totalRecords" [selectedColumns]="linkedTicketGrid.selectedColumns"
        [cols]="linkedTicketGrid.cols" height="auto" [colData]="linkedTicketGrid.colData" [org]="org"
        [dataUrl]="linkedTicketGridUrl" (update)="loadlinkedTicketGrid($event)" (rowSelect)="linkedTicketGridRowSelect($event)" [to]="0">
    </app-grid-table>
   </div>
</div>
