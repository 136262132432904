import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';


@Component({
  selector: 'app-ip-sla-datepicker',
  templateUrl: './ip-sla-datepicker.component.html',
  styleUrls: ['./ip-sla-datepicker.component.scss']
})
export class IpSlaDatepickerComponent implements OnInit {
  @Input() blocktitle;
  @Input() quickRangeHour = [];
  @Input() quickRangeDays = [];
  @Input()  quickRangeMonth = [];
  types = [{ label: 'Current', value: 'Current', icon: 'fa fa-line-chart' },
  { label: 'Custom', value: 'Custom', icon: 'fa fa-table' }];
  @Input() selectedQuickRangeFrom = 'now-24h';
  @Input() timerangelabel = 'Latest';
  @Input() isTime;
  @Output() updateTime = new EventEmitter<any>();
  yerslist = [];
  collapseId;
  selectedyear = 1;
  constructor() { }
  ngOnInit() {
    if (this.quickRangeHour.length === 0) {
      this.quickRangeHour = [
        { label: 'Latest', value: 'latest' },
        { label: 'Last 1 hour', value: 'now-1h' },
        { label: 'Last 3 hour', value: 'now-3h' },
        { label: 'Last 6 hour', value: 'now-6h' },
        { label: 'Last 12 hour', value: 'now-12h' },
        { label: 'Last 24 hour', value: 'now-24h' }
      ];
      this.quickRangeMonth = [
        { label: 'Jan', value: '01' },
        { label: 'Feb', value: '02' },
        { label: 'Mar', value: '03' },
        { label: 'Apr', value: '04' },
        { label: 'May', value: '05' },
        { label: 'Jun', value: '06' },
        { label: 'Jul', value: '07' },
        { label: 'Aug', value: '08' },
        { label: 'Sep', value: '09' },
        { label: 'Oct', value: '10' },
        { label: 'Nov', value: '11' },
        { label: 'Dec', value: '12' },
      ];
      const min = new Date().getFullYear() - 1;
      const max = min + 5;
      for (let i = min; i <= max; i++) {
        this.yerslist.push(i);
        if (i === new Date().getFullYear()) {
         this.selectedyear = i;
        } else {
          $('#dropdown select').append('<option value=' + i + '>' + i + '</option>');
        }
     }
    }
    if (this.quickRangeDays.length === 0) {
      this.quickRangeDays = [
        { label: 'Last 2 days', value: 'now-2d' },
        { label: 'Last 7 days', value: 'now-7d' },
        { label: 'Last 10 days', value: 'now-10d' },
        { label: 'Last 30 days', value: 'now-30d' },
        { label: 'Last 60 days', value: 'now-60d' }
      ];
    }
    this.isTime = (typeof this.isTime !== 'undefined') ? this.isTime : true;
  }
  // when quick range is selected this method is called
  selectQuickRange(event, value: any) {
    this.selectedQuickRangeFrom = value.value;
    this.timerangelabel = value.label;
    const data = {};
    data['value'] = this.selectedQuickRangeFrom;
    data['label'] = value.label;
    this.updateTime.emit(data);
  }

  selectQuickRangeMonth(event, value: any) {
    this.selectedQuickRangeFrom = value.value;
    const yearlabel = $('#dropdown select').val();
    this.timerangelabel = value.label + '-' + yearlabel;
    const data = {};
    data['value'] =  yearlabel + '.' + value.value;
    data['label'] = value.label + '-' + yearlabel;
    this.updateTime.emit(data);
  }
}
export interface DatePickerEvents {
  updateTime(event): void;
}



