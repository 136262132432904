import { Component, OnInit, EventEmitter } from '@angular/core';
import { CustomEvents } from '../../../events/customevents';
import { ActivatedRoute } from '@angular/router';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import { TableData } from '../../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { Message } from 'primeng/api';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {SelectItem} from 'primeng/api';
interface Requestusertype {
  name: string;
  code: string;
}

@Component({
  selector: 'app-userassociate',
  templateUrl: './userassociate.component.html',
  styleUrls: ['./userassociate.component.scss']
})
export class UserassociateComponent implements OnInit, GridTableEvents {
  org: string;
  loading;
  associategroupDialog = false;
  groupListDisplay;
  orgListDisplay;
  selectedgroupListDisplay;
  selectedorgListDisplay;
  isRowSelect;
  selectedData;
  title;
  groupGrid;
  groupGridsUrl;
  userInfo;
  username;
  useremail;
  userdisplayName;
  userpassword;
  userfirstName;
  usermiddleName;
  userlastName;
  tempValue;
  createReqerrormsg: Message[] = [];
  userconfirmpassword;
  closeForm = new EventEmitter<string>();
  createuserDialog = false;
  createuserForm: FormGroup;
  createInviteuserForm: FormGroup;
  submitted = false;
  enableUser;
  matchpwd = false;
  gluuStatus;
  isAdmin = false;
  popupwidth = '600';
  isPermission;
  inviteuserDialog = false;
  inviteuserEmail;
  submittedInviteForm = false;
  selectedValue;
  ischecked = false;
  isEnableAdmin = false;
  updateCustomerRole: any[];
  selectedupdateCustomerRole: SelectItem[];
  constructor(private route: ActivatedRoute,
    private _customEvents: CustomEvents, private _portalhttp: PortalHttpServiceService, public _datePipe: DateConverterPipe
    , private formBuilder: FormBuilder) {
    this.groupGrid = new TableData();
    this.isRowSelect = false;
    const user = JSON.parse(sessionStorage.getItem('user'));
    this.route.queryParams.subscribe(params => {
      this.org = params['org'];
  });
  user['orgs'].forEach(element => {
    if (element.key === this.org) {
     this.isPermission = element.permissions;
    }
  });
   }
  ngOnInit() {
    this._customEvents.showHideLoader.emit({ show: false });
    this.groupGridsUrl = '/api/v1/user/search';
    this.groupGrid.reloadEvent = '';
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user['groups'].indexOf('PORTAL_ROLE_ADMIN') !== -1) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }
  loadGrid(data) {
    if (this.groupGrid.cols.length === 0) {
      this.groupGrid.cols = data['headers'];
      const columns = [];
      this.groupGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.groupGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.groupGrid.colData = rawData;
    this.groupGrid.totalRecords = data['total'];
  }
  rowSelect(event) {
    if (this.isAllowed('portal_settings_user_update')) {
         this.isAdmin = true;
         this.popupwidth = 'auto';
    } else {
      this.isAdmin = false;
      this.popupwidth = '600';
    }
    this.loadGroups(event);
    this.associategroupDialog = true;
    this.groupListDisplay = [];
    this.orgListDisplay = [];
    this.selectedgroupListDisplay = [];
    this.selectedorgListDisplay = [];
    this.userInfo = event['data'];
    this.username = this.userInfo.userName;
    this.useremail = this.userInfo.email;
    this.userdisplayName = this.userInfo.displayName;
    this.userpassword = this.userInfo.password;
    this.userfirstName = this.userInfo.givenName;
    this.usermiddleName  = this.userInfo.middleName;
    this.userlastName = this.userInfo.familyName;
    this.gluuStatus = this.userInfo.gluuStatus;
    this.selectedValue = this.gluuStatus;
    this.isEnableAdmin = this.userInfo.isadmin ? this.userInfo.isadmin : false;
  }
 loadPopupGrid(event) {
 }
  showDialog() {
    this.associategroupDialog = true;
  }
  enableIcon(data) {
   $('.' + data).css('display', 'inline-block');
   $('.' + data).prop('disabled', false);
   this.tempValue = $('.' + data).val();
   if (data === 'password') {
    $('.conpassword').val('');
    $('.conpassword').css('display', 'block');
    $('.conpassword').prop('disabled', false);
   }
  }
  clearIcon(data) {
     $('.' + data).val(this.tempValue);
     $('.' + data).prop('disabled', true);
     if (data === 'password') {
      $('.conpassword').css('display', 'none');
      $('.conpassword').val('');
      $('.conpassword').prop('disabled', true);
     }
  }
  updateUser() {
    this.userInfo.displayName = this.userdisplayName;
    this.userInfo.familyName = this.userlastName;
    this.userInfo.givenName = this.userfirstName;
    this.userInfo.middleName = this.usermiddleName;
    this.userInfo.isadmin = this.isEnableAdmin ? this.isEnableAdmin : false;
    if (this.userconfirmpassword) {
       if (this.userconfirmpassword !== this.userpassword ) {
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Both password should be same!' });
         return;
       } else {
         this.userInfo.password = this.userpassword;
       }
    }
    this.userInfo.groups = this.selectedgroupListDisplay ? this.selectedgroupListDisplay : [];
    this.userInfo.organizations =   this.selectedorgListDisplay ? this.selectedorgListDisplay : [];
    this.userInfo.roles = [];
    if (this.selectedupdateCustomerRole && this.selectedupdateCustomerRole.length > 0) {
      for (let i = 0; i < this.selectedupdateCustomerRole.length; i++) {
        this.userInfo.roles.push(this.selectedupdateCustomerRole[i]['code']);
    }
    }
     this.userInfo.gluuStatus = this.selectedValue;
     const headers = 'GtmEvents';
     this._customEvents.sendGtmEvt.emit({ data: 'usermangment_UpdateUser', header: headers });
    const updateUrl = '/api/v1/user';
    this._portalhttp.putWitoutRetry(updateUrl, this.org, this.userInfo).subscribe(() => {
      this.groupGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully updated user info!' });
      this.userconfirmpassword = '';
      this.associategroupDialog = false;
    }, error => {
      this.groupGrid.reloadEvent = '';
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
    });
}
  resetQR() {
   const qrURL = '/api/v1/user/qr/reset';
   const body = {
     'id': this.userInfo.id
   };
   const headers = 'GtmEvents';
   this._customEvents.sendGtmEvt.emit({ data: 'usermangment_resetQRCode', header: headers });
   this._portalhttp.putWitoutRetry(qrURL, this.org, body).subscribe(() => {
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully reset QR Code !' });
     this.associategroupDialog = false;
   }, error => {
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
   });
  }
  cancelReq() {
      this.associategroupDialog = false;
      this.groupGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
  }
  oncreateUser () {
    if (this.isAllowed('portal_settings_user_create')) {
         this.isAdmin = true;
         this.popupwidth = 'auto';
    } else {
      this.isAdmin = false;
      this.popupwidth = '600';
    }
    this.loadGroups(null);
    this.createuserDialog = true;
    this.submitted = false;
    this.matchpwd = false;
    this.createuserForm = this.formBuilder.group({
      userName: ['', Validators.required],
      displayName: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.nullValidator],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      enableuser: ['', Validators.nullValidator],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
      admin: ['']
  });
  }
  onSubmit() {
    this.submitted = true;
    if (this.createuserForm.invalid) {
        return;
    }
    if (this.createuserForm.value.password !== this.createuserForm.value.confirmpassword ) {
      this.matchpwd = true;
      return;
    } else {
      this.matchpwd = false;
    }
    const creteuserURL = '/api/v1/user';
    const roles = [];
    if (this.selectedupdateCustomerRole && this.selectedupdateCustomerRole.length > 0) {
      for (let i = 0; i < this.selectedupdateCustomerRole.length; i++) {
        roles.push(this.selectedupdateCustomerRole[i]['code']);
    }
  }
  const headers = 'GtmEvents';
  this._customEvents.sendGtmEvt.emit({ data: 'usermangment_CreateUser', header: headers });
    const body = {
      'gluuStatus': this.createuserForm.value.enableuser ? this.createuserForm.value.enableuser : 'active',
      'displayName': this.createuserForm.value.displayName,
      'email': this.createuserForm.value.email,
      'familyName': this.createuserForm.value.lastName,
      'givenName': this.createuserForm.value.firstName,
      'groups': this.selectedgroupListDisplay ? this.selectedgroupListDisplay : [],
      'organizations' :  this.selectedorgListDisplay ? this.selectedorgListDisplay : [],
      'roles' : roles,
      'id': '',
      'middleName': this.createuserForm.value.middleName,
      'password': this.createuserForm.value.password,
      'userName': this.createuserForm.value.userName,
      'isadmin': this.ischecked
    };
    this._portalhttp.post(creteuserURL, this.org, body).subscribe((res) => {
     this.submitted = false;
     this.groupGrid.reloadEvent = '&_timestamp=' + new Date().getTime().toString();
     this.createReqerrormsg = [];
     this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Successfully created user !' });
      this.createuserDialog = false;
      }, err => {
        this.groupGrid.reloadEvent  = '';
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'error', summary: '', detail: 'Oops, something went wrong!!' });
      });

}
onInviteUser() {
 this.inviteuserDialog = true;
 this.submittedInviteForm = false;
 this.createInviteuserForm = this.formBuilder.group({
  inviteuserEmail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]]
});
}
get f() { return this.createInviteuserForm.controls; }
updateInviteUser() {
  this.submittedInviteForm = true;
  if (this.createInviteuserForm.invalid) {
      return;
  }
  const headers = 'GtmEvents';
  this._customEvents.sendGtmEvt.emit({ data: 'usermangment_InviteUser', header: headers });
  const InviteUrl = '/api/v1/user/invite?email=' + this.createInviteuserForm.value.inviteuserEmail;
  this._portalhttp.get(InviteUrl, this.org).subscribe((res) => {
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'The invitation has been sent. !' });
    this.inviteuserDialog = false;
    }, (err) => {
    });

}
cancelIniteUserReq() {
  this.inviteuserDialog = false;
  this.submittedInviteForm = false;
}

cancelCreateReq() {
  this.submitted = false;
  this.createuserDialog = false;
}

loadGroups(event) {
  const roleUrl = '/api/v1/group/type';
  this._portalhttp.get(roleUrl, this.org).subscribe((res) => {
    this.resetList();
    this.updateCustomerRole = this.getDropDownLabel(res['roleList']);
    if (event) {
      this.selectedupdateCustomerRole = this.getDropDownLabel( event['data'].roles);
    }
    if (this.isAllowed('portal_settings_user_update_groups')) {
      this.setGroupsPickList(event, res['groupList']);
      this.setOrgPickList(event, res['organizationList']);
    }
    }, err => {
    });
}

setOrgPickList(event, groupList) {
  if (event) {
    for (let i = 0; i < event['data'].organizations.length; i++) {
     const index = groupList.map(function(o) { return o.displayName; }).indexOf(event['data'].organizations[i].displayName);
          groupList.splice(index, 1);
       }
       this.selectedorgListDisplay = event['data'].organizations ? event['data'].organizations : [];
     }
     this.orgListDisplay = groupList;
}
setGroupsPickList(event, groupList) {
  if (event) {
    for (let i = 0; i < event['data'].groups.length; i++) {
     const index = groupList.map(function(o) { return o.displayName; }).indexOf(event['data'].groups[i].displayName);
          groupList.splice(index, 1);
       }
       this.selectedgroupListDisplay = event['data'].groups ? event['data'].groups : [];
     }
     this.groupListDisplay = groupList;
}

getDropDownLabel(roles) {
  const roleList = [];
  for (let i = 0; i < roles.length; i++) {
    const permissionobj = {name: roles[i].label , code: roles[i]};
    roleList.push(permissionobj);
  }
  return roleList;
}
resetList() {
  this.updateCustomerRole = [];
  this.selectedupdateCustomerRole = [];
  this.selectedgroupListDisplay = [];
  this.selectedorgListDisplay = [];
  this.groupListDisplay = [];
  this.orgListDisplay = [];
}

isAllowed (name) {
  return (this.isPermission && this.isPermission.length !== 0 && this.isPermission.indexOf(name) !== -1);
}
}
