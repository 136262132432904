import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { NgModule} from '@angular/core';


@Pipe({ name: 'dateConverter' })
export class DateConverterPipe implements PipeTransform {

    defaultZone: string;
    preferredZone: string;
    myDate: Date;
    data;

    constructor() {
        this.defaultZone = moment.tz.guess();
        this.data = JSON.parse(sessionStorage.getItem('user'));
        if (this.data && this.data.hasOwnProperty('zone')) {
            if (this.data.zone === 'local') {
                this.preferredZone = this.defaultZone;
            } else {
            this.preferredZone = this.data.zone;
            }
        } else {
            this.preferredZone = this.defaultZone;
        }
    }
    transform(value) {
        let timestamp;
        this.defaultZone = moment.tz.guess();
        this.data = JSON.parse(sessionStorage.getItem('user'));
        if (this.data && this.data.hasOwnProperty('zone')) {
            if (this.data.zone === 'local') {
                this.preferredZone = this.defaultZone;
            } else {
            this.preferredZone = this.data.zone;
            }
        } else {
            this.preferredZone = this.defaultZone;
        }
        if (typeof (value) === 'string') {
            timestamp = moment.utc(value);
        } else {
            timestamp = value;
        }
        this.myDate = moment.tz(timestamp, this.preferredZone).format('DD MMM YYYY HH:mm:ss z');
        return this.myDate;
    }
}

@NgModule({
    declarations: [ DateConverterPipe ],
    exports: [ DateConverterPipe ]
  })
  export class DatePipeModule {}
