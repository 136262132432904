import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AdvanceGridTableEvents } from '../../../advance-grid/advance-grid.component';
import { TableData } from '../../../common/SharedClasses/TableData';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import { CustomEvents } from '../../../events/customevents';
import { Message } from 'primeng/api';
import { DataProviderService } from '../../../services/data-provider.service';
import { MessageBaseCtrl } from '../../../base/MessageBaseCtrl';




@Component({
  selector: 'app-customer-circuite-grid',
  templateUrl: './customer-circuite-grid.component.html',
  styleUrls: ['./customer-circuite-grid.component.scss']
})
export class CustomerCircuiteGridComponent extends MessageBaseCtrl implements OnInit, AdvanceGridTableEvents, OnDestroy {
  @Input() org;
  @Input() slectedCircuit;
  dataUrl;
  circuiteData;
  isRowClick;
  servicerequestDialog = false;
  Header: any[] = [];
  circuitticketicon;
  circuitticketTitle;
  circuitInfoDialog = false;
  CircuitInfoTitle;
  circuitInfoData;
  circuitdata;
  servicerequestDialog1;
  config;
  showReportTab: boolean;
  id;
  detailId;
  createReqerrormsg: Message[] = [];
  types = [{ label: 'Grid', value: 'Graph', icon: 'fa fa-table' },
  { label: 'Map', value: 'Grid', icon: 'fa fa-map-marker' }];
  isPermission;
  activeindex;
  constructor(public _datePipe: DateConverterPipe,public _dataProvider: DataProviderService, private _portalhttp: PortalHttpServiceService, private _customEvents: CustomEvents) {
    super();
    const user = JSON.parse(sessionStorage.getItem('user'));
    user['orgs'].forEach(element => {
      if (element.key === this.org) {
       this.isPermission = element.permissions;
      }
    });
   }

  ngOnInit() {
    const user = JSON.parse(sessionStorage.getItem('user'));
     user['orgs'].forEach(element => {
       if (element.key === this.org) {
        this.isPermission = element.permissions;
       }
     });
    this.dataUrl = '/api/v1/circuits';
    this.circuiteData = new TableData();
    this.circuiteData.cols = [];
    this.isRowClick = false;
    this.circuitticketTitle = 'Create Ticket';
    this.circuitticketicon = 'fas fa-list-ul';
    this.circuiteData.state = 'Graph';
    this.id = '';
    this._customEvents.displayRowWiseEvt.subscribe((value: any) => {
    if(value['header'] === 'circuitDetail') {
     this.displayDetail(value);
    } else {
      this.createTicket(value);
    }
    });
  }

  advanceLoadGrid(data: any) {
    if (this.circuiteData.cols.length === 0) {
      this.circuiteData.cols = data['headers'];
      const columns = [];
      this.circuiteData.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.circuiteData.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element) => {
        if (element['created']) {
          element['created'] = element['created'] && (element['created'] !== 0) ?
            this._datePipe.transform(element['created']) : '';
        }
        if (element['updated']) {
          element['updated'] = element['updated'] && (element['updated'] !== 0) ?
            this._datePipe.transform(element['updated']) : '';
        }
      });
    }
    this.circuiteData.colData = rawData;
    this.circuiteData.totalRecords = data['total'];
  }
 advanceRowSelect(event) {
   this.isRowClick = false;
    this.detailId = event['data']['Id'];
    const circuitInfoUrl = '/api/v1/circuit/info?' + 'type=Circuit&id=' + event['data']['Id'];
    this.slectedCircuit = event['data']['Id'];
    this.servicerequestDialog = false;
    this._portalhttp.get(circuitInfoUrl, this.org).subscribe((res) => {
      this.isRowClick = true;
      this.servicerequestDialog = true;
      setTimeout(function() {
        this.Header = res['data'];
      }, 300);
      this.Header = res['data'];
     this.config = JSON.parse(sessionStorage.getItem('user'));
     if ((this.config['groups'].indexOf('PORTAL_ROLE_ADMIN') !== -1 || this.config['groups'].indexOf('PORTAL_ROLE_NOC') !== -1)
     && (event['data']['recordtypeid'] === 'Colo' ||
     event['data']['recordtypeid'] === 'Carrier' || event['data']['recordtypeid'] === 'Cross Connect' )) {
       this.showReportTab = true;
     }
     document.getElementById('circuit_info_details').scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, (err) => {
     });
}

displayDetail(data) {
  this.detailId = data['data']._id;
  const circuitInfoUrl = '/api/v1/circuit/info?' + 'type=Circuit&id=' + data['data']._id;
  this.slectedCircuit = data['data']._id;
  this.servicerequestDialog = false;
  this._portalhttp.get(circuitInfoUrl, this.org).subscribe((res) => {
    this.isRowClick = true;
    this.Header = res['data'];
   this.servicerequestDialog = true;
   this.config = JSON.parse(sessionStorage.getItem('user'));
   if ((this.config['groups'].indexOf('PORTAL_ROLE_ADMIN') !== -1 || this.config['groups'].indexOf('PORTAL_ROLE_NOC') !== -1)
   && (data['data'].recordtypeid === 'Colo' ||
   data['data'].recordtypeid === 'Carrier' || data['data'].recordtypeid === 'Cross Connect' )) {
     this.showReportTab = true;
   }
   document.getElementById('circuit_info_details').scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, (err) => {
   });
}
createTicket(data) {
 const jiraSessionPresent = sessionStorage.getItem('JiraPresent');
 if (jiraSessionPresent === 'true') {
 this.servicerequestDialog1 = true;
 const cirucuitDat = [];
 cirucuitDat.push(data['data'].circuit_id__c);
 cirucuitDat.push(data['data'].order_status__c);
 cirucuitDat.push(data['data'].site_name__c ? data['data'].site_name__c : '');
 this.circuitdata = cirucuitDat;
 } else {
   this.createReqerrormsg = [];
   this.createReqerrormsg.push({ severity: 'error', summary: '',
    detail: ' You Do not have permission to Raise Request, Please Contact Admin!!' });
 }
}
advanceIconClick(data) {
  const jiraSessionPresent = sessionStorage.getItem('JiraPresent');
  if (jiraSessionPresent === 'true') {
  this.servicerequestDialog1 = true;
  const cirucuitDat = [];
  cirucuitDat.push(data['circuit_id__c']);
  cirucuitDat.push(data['order_status__c']);
  cirucuitDat.push(data['site_name__c']);
  this.circuitdata = cirucuitDat;
  } else {
    this.createReqerrormsg = [];
    this.createReqerrormsg.push({ severity: 'error', summary: '',
     detail: ' You Do not have permission to Raise Request, Please Contact Admin!!' });
  }
}
  hideCircuiteInformation() {
    this.servicerequestDialog = false;
  }
  displaygridInfo(data) {
    this.circuitInfoDialog = false;
    const circuitInfoUrl = '/api/v1/circuit/info?' + 'type=' + data['type'] + '&id=' + data['id'] + '&isPopup=true';
    this._portalhttp.get(circuitInfoUrl, this.org).subscribe((res) => {
        this.circuitInfoDialog = true;
        this.CircuitInfoTitle = data['field'];
        this.circuitInfoData = res['data'];
     }, (err) => {
     });
  }
  onReloadCircuit() {
    const reloadUrl = '/api/v1/circuit/sync';
    this._portalhttp.get(reloadUrl, this.org).subscribe((res) => {
      this.createReqerrormsg = [];
      this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Scheduler service  started!' });
      }, err => {
        this.createReqerrormsg = [];
        this.createReqerrormsg.push({ severity: 'success', summary: '', detail: 'Scheduler service not available!' });
      });
  }
  ngOnDestroy() {
  }
  switchView(value) {
    const state = this.circuiteData.state;
    if (state !== value) {
      this.id = '';
      this.circuiteData.totalRecords = 0;
      this.circuiteData.colData = [];
      this.circuiteData.state = value;
    }

  }
  closeForm(value) {
    this.servicerequestDialog1 = false;
    this.growlMessage = [];
    if(value['status'] !== 'cancel'){
      this.growlMessage.push(value['message']);
    } 
  }
}
