import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MapComponent } from './map.component';
import { MapServiceService } from './map-service.service';
@NgModule({
  imports: [
    CommonModule ,
    CommonModule,
    FormsModule
  ],
  declarations: [MapComponent],
  exports: [MapComponent],
  providers: [MapServiceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class MapModule { }
