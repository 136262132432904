<div class="container-fluid" style="min-height:84%">
  <div class="col-md-12 form-group">
    <div class="col-md-12 screen-shadow">
      <section>
        <div class="col-md-8 col-offset-6">
          <div class="text-center uppercontainer">
            <i class="far fa-user fa-5x"></i>
          </div>
          <div class="text-left intro" *ngIf ="data">
            <h3>{{data.name}}</h3>
            <h5>User Name : <b>{{data.username}}</b></h5>
            <h5>Email-id : <b>{{data.email}}</b></h5>
            <h5 *ngIf="defaultOrg">Organization : <b>{{selectedOrg}}</b></h5>
          </div>
        </div>
        <div class="col-md-4 separation">
          <h4> <i class="fas fa-unlock titles"></i>Permissions</h4>
          <ul class="list-unstyled text-left permissionlist" *ngIf="data.groups && data.groups.length>0; else noperm" >
            <li *ngFor="let perm of data.groups">{{perm}}</li>
          </ul>
          <ng-template #noperm>
            No permissions granted
          </ng-template>
        </div>
        <div class="col-md-12 ">
          <h4> <i class="fas fa-sitemap titles"></i>Organizations</h4>
          <div class="table-responsive" *ngIf="data.orgs && data.orgs.length>0; else nodata">
            <table class="table borderless table-hover">
              <tr *ngFor="let org of data.orgs" [ngClass]="(org.key == selectedOrg)?'selected':''">
                <td>
                  <input id="question2" name="question" type="radio" class="with-font" value="{{org.key}}" [checked]="(org.key == selectedOrg)"
                  />
                  <label *ngIf="(org.key == selectedOrg)">Current</label>
                  <label for="question2" *ngIf="(org.key != selectedOrg)" (click)="changeOrg(org)">Select</label>
                </td>
                <td>{{org.key}}</td>
                <td>{{org.name}}</td>
              </tr>
            </table>
          </div>
          <ng-template #nodata>
            No organization mapped
          </ng-template>
        </div>
      </section>
    </div>
  </div>
</div>