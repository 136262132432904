<app-grid-table [reload]="onboardGrid.reloadEvent" [popup]="false" id="gridwithsmallwidth"
    [totalRecords]="onboardGrid.totalRecords" [selectedColumns]="onboardGrid.selectedColumns" [cols]="onboardGrid.cols"
    height="248px" [colData]="onboardGrid.colData" [org]="org" [dataUrl]="onboardGridsUrl" (update)="loadGrid($event)"
    (rowSelect)="rowSelect($event)" [to]="0"></app-grid-table>

<p-dialog header="Update Customer" *ngIf="onboardorgDialog" [focusOnShow]='false' [(visible)]="onboardorgDialog"
    [modal]="true" [responsive]="true" showEffect="fade" height='auto' [width]="1000">
    <div class="row">
        <div class="col-md-12 offset-md-12">
            <div>
                <h5 class="first">Customer </h5>
                <input class="firstName" type="text" pInputText [(ngModel)]="customer" style="width:60%;" disabled />
            </div>
            <div>
                <h5 class="first">Customer Key</h5>
                <input class="customerkey" type="text" pInputText [(ngModel)]="customerkey" style="width:60%;"
                    disabled />
                <span *ngIf="!editCustomerKey">
                    <label class="editIcon" (click)="enableIcon('customerkey')"><i class="far fa-edit"></i></label>
                    <span class="customerkey" style="display:none;">
                        <label class="clearIcon" (click)="clearIcon('customerkey')"><i class="fa fa-times"
                                aria-hidden="true"></i></label>
                    </span>
                </span>
            </div>
            <div>
                 <h5 class="first">Website </h5>
                 <input class="website" type="text" pInputText [(ngModel)]="userwebsite" style="width:60%;" disabled />
            </div>
            <div>
                <h5 class="first">Email</h5>
                <input class="email" type="text" pInputText [(ngModel)]="userEmail" style="width:60%;" disabled />
                <label class="editIcon" (click)="enableIcon('email')" style="display:none;"><i class="far fa-edit"></i></label>
                <span class="email" style="display:none;">
                    <label class="clearIcon" (click)="clearIcon('email')"><i class="fa fa-times"
                            aria-hidden="true"></i></label>
                </span>
            </div>
            <div>
                <h5 class="first">Phone</h5>
                <input class="phone" type="text" pInputText [(ngModel)]="userPhone" style="width:60%;" disabled />
                <label class="editIcon" (click)="enableIcon('phone')"  style="display:none;"><i class="far fa-edit"></i></label>
                <span class="phone" style="display:none;">
                    <label class="clearIcon" (click)="clearIcon('phone')"><i class="fa fa-times"
                            aria-hidden="true"></i></label>
                </span>
            </div>
            <div>
                <h5 class="first">Postcode</h5>
                <input class="Postcode" type="text" pInputText [(ngModel)]="userPostcode" style="width:60%;" disabled />
                <label class="editIcon" (click)="enableIcon('Postcode')"  style="display:none;"><i class="far fa-edit"></i></label>
                <span class="Postcode" style="display:none;">
                    <label class="clearIcon" (click)="clearIcon('Postcode')"><i class="fa fa-times"
                            aria-hidden="true"></i></label>
                </span>
            </div>
            <div>
                <h5 class="first">City</h5>
                <input class="city" type="text" pInputText [(ngModel)]="userCity" style="width:60%;" disabled />
                <label class="editIcon" (click)="enableIcon('city')"  style="display:none;"><i class="far fa-edit"></i></label>
                <span class="city" style="display:none;">
                    <label class="clearIcon" (click)="clearIcon('city')"><i class="fa fa-times"
                            aria-hidden="true"></i></label>
                </span>
            </div>
            <div>
                <h5 class="first">Address</h5>
                <input class="address" type="text" pInputText [(ngModel)]="userAddress" style="width:60%;" disabled />
                <label class="editIcon" (click)="enableIcon('address')"  style="display:none;"><i class="far fa-edit"></i></label>
                <span class="address" style="display:none;">
                    <label class="clearIcon" (click)="clearIcon('address')"><i class="fa fa-times"
                            aria-hidden="true"></i></label>
                </span>
            </div>
            <div>
                 <h5 class="first">Permissions</h5>
                 <p-listbox [options]="customertype" [style]="{'width':'265px'}" [(ngModel)]="selectedCustomerType" multiple="multiple" checkbox="checkbox" filter="filter" optionLabel="name">
                 </p-listbox>
                </div>
            <div style="margin-top:2%;" class="groupbox">
                <h5 class="first">Dashboard List</h5>
                <p-pickList [source]="dashboardListDisplay" [target]="selecteddashboardListDisplay"
                    sourceHeader="Available" filterBy="Code" targetHeader="Selected" [responsive]="true" dragdrop="true"
                    sourceFilterPlaceholder="Search by dashboard name"
                    targetFilterPlaceholder="Search by dashboard name" [showTargetFilter]='true'
                    (onMoveToTarget)='onMoveToTarget($event)' (onMoveToSource)='onMoveToSource($event)'
                    (onMoveAllToSource)='onMoveAllToSource($event)' (onMoveAllToTarget)='onMoveAllToTarget($event)'
                    [sourceStyle]="{'height':'200px','text-weight':'bold'}" [targetStyle]="{'height':'200px'}"
                    [showSourceControls]='false' [showTargetControls]='false'>
                    <ng-template let-groupname pTemplate="item">
                        <div class="ui-helper-clearfix">
                            <div style="font-size:14px;float:left;margin:15px 5px 0 0">{{groupname.Code}}</div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
            <div style="margin-top:2%;" class="groupbox">
                <h5 class="first">External App</h5>
                <p-pickList [source]="externalAppListDisplay" [target]="selectedexternalAppListDisplay"
                    sourceHeader="Available" filterBy="Code" targetHeader="Selected" [responsive]="true" dragdrop="true"
                    sourceFilterPlaceholder="Search by External App" targetFilterPlaceholder="Search by External App"
                    [showTargetFilter]='true' (onMoveToTarget)='onMoveToTargetAppList($event)'
                    (onMoveToSource)='onMoveToSourceAppList($event)'
                    (onMoveAllToSource)='onMoveAllToSourceAppList($event)'
                    (onMoveAllToTarget)='onMoveAllToTargetAppList($event)'
                    [sourceStyle]="{'height':'200px','text-weight':'bold'}" [targetStyle]="{'height':'200px'}"
                    [showSourceControls]='false' [showTargetControls]='false'>
                    <ng-template let-appname pTemplate="item">
                        <div class="ui-helper-clearfix">
                            <div style="font-size:14px;float:left;margin:15px 5px 0 0">{{appname.Code}}</div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>
    </div>
    <p-footer>
        <p-button icon="fa fa-check-square" iconPos="right" label="Update" (click)="updatecustomer()"></p-button>
        <p-button icon="fa fa-times" iconPos="right" label="Cancel" (click)='cancelReq()'></p-button>
    </p-footer>
</p-dialog>
<p-growl *ngIf="createReqerrormsg.length!==0" [(value)]="createReqerrormsg"></p-growl>