<div class="container-fluid">
    <p-growl [(value)]="msgs" life="1000"></p-growl>
    <div class="col-md-12 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_status') !=-1">
        <app-device-status *ngIf="onOrgChange" [org]="org"></app-device-status>
    </div>
    <div class="col-md-4 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_grid') !=-1">
        <div class="col-md-12 screen-shadow">
            <section>
                <app-device-grid *ngIf="onOrgChange" [org]="org"></app-device-grid>
            </section>
        </div>
    </div>

    <div class="col-md-8 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_map') !=-1">
        <div class="col-md-12 screen-shadow">
            <h2 class="screen-header">Site Clusters</h2>
            <div>
                <app-map *ngIf="onOrgChange" [org]="org"></app-map>
            </div>
        </div>
    </div>
    <div id="graph-block-1" class="col-md-12 form-group" *ngIf="isDisplayGraph">
        <section class="graphContainer">
            <div>
                <i class="fa fa-window-close-o close" aria-hidden="true" (click)="isDisplayGraph=false;" pTooltip="Close" tooltipPosition="left"></i>
            </div>
            <app-graph [graphs]="graphs" [graphName]="graphName" [fullView]="fullView" [scrollTo]="scrollTo"></app-graph>
        </section>
    </div>
    <div class="col-md-4 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_transport_interface') !=-1">
        <div class="col-md-12 rowHeight">
            <app-tranport-interface *ngIf="onOrgChange" [org]="org"></app-tranport-interface>
        </div>
    </div>
    <div class="col-md-4 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_control_status') !=-1">
        <div class="col-md-12 rowHeight">
            <section>
                <app-control-status *ngIf="onOrgChange" [org]="org"></app-control-status>
            </section>
        </div>
    </div>
    <div class="col-md-4 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_site_health') !=-1">
        <div class="col-md-12 rowHeight">
            <section>
                <app-site-health *ngIf="onOrgChange" [org]="org"></app-site-health>
            </section>
        </div>
    </div>

    <div class="col-md-6 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_application_aware') !=-1">
        <div class="col-md-12 switchContainer">
            <app-application-routing [org]="org"></app-application-routing>
        </div>
    </div>
    <div class="col-md-6 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_auditlog') !=-1">
        <div class="col-md-12 switchContainer">
            <app-audit-grid *ngIf="onOrgChange" [isGrafanaSessionPresent]="isGrafanaSessionPresent" [org]="org"></app-audit-grid>
        </div>
    </div>
    <div class="col-md-6 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_events') !=-1">
        <div class="col-md-12 switchContainer">
            <app-event-grid *ngIf="onOrgChange" [isGrafanaSessionPresent]="isGrafanaSessionPresent" [org]="org"></app-event-grid>
        </div>
    </div>
    <div class="col-md-6 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('portal_devices_alarms') !=-1">
        <div class="col-md-12 switchContainer">
            <app-alarm-grid *ngIf="onOrgChange" [isGrafanaSessionPresent]="isGrafanaSessionPresent" [org]="org"></app-alarm-grid>
        </div>
    </div>

    <p-dialog *ngIf="isApplicationPopUp" header="Application aware routing" [(visible)]="isApplicationPopUp" [modal]="true" showEffect="fade"
        [responsive]="true" styleClass="gridactive">
        <app-graph [graphs]="graphs" [graphName]="graphName" id="modal" [fullView]="fullView"></app-graph>
    </p-dialog>
    <app-footer></app-footer>
</div>