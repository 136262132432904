import { Injectable } from '@angular/core';
import { CustomEvents } from '../../events/customevents';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseCtrl } from '../../base/BaseCtrl';

@Injectable()
export class UserService extends BaseCtrl{

  config;
  unAuthorizedErrorListener: any;
  user;

  constructor(private _http: HttpClient, private _customEvents: CustomEvents) {
    super();
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  // put without Retry
  public putWitoutRetry(url: string, org: string, body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.user['token'],
        'org': org
      })
    };
    return this._http.put(this.ENV.serviceUrl + url, body, httpOptions).catch((error: any) => this.catchError(error));
  }



  // handles error
  catchError(error: any): any {
    if (error.status === 401) {
      this.unAuthorizedErrorListener = this._customEvents.unAuthorizedError.emit({});
    } else {
      console.log('Cluster Load Data Error');
    }
  }
}
