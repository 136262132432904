import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { GrowlModule } from 'primeng/growl';
import {SelectTableComponent} from './select-table.component';
import { MultiSelectModule } from 'primeng/primeng';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule,
    CommonModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    TableModule,
    ButtonModule,
    MessagesModule,
    PaginatorModule,
    GrowlModule,
    MultiSelectModule
  ],
  declarations: [SelectTableComponent],
  exports: [SelectTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SelectTableModule { }
