<div style="height:100%">
  <app-date-picker blocktitle="Alarm" style="width:100%" collapseId="alarm" (switchView)="switchView($event)" isExpand="true"
    (refresh)="refresh($event)" (updateTime)="updateTime($event)" (expand)="openPopUp()" style="display: flow-root"></app-date-picker>

  <section *ngIf="alarmData.state === 'Graph'" id="error">
    <app-graph-container [isGrafanaSessionPresent]="isGrafanaSessionPresent" [timeRangeToAppendEvent]="alarmData.timeRangeToAppendEvent"
      [reloadEvent]="alarmData.reloadEvent" [org]="org" urlid="Alarm"></app-graph-container>
  </section>
  <section *ngIf="alarmData.state === 'Grid'">
    <app-grid-table [reload]="alarmData.reloadEvent" [totalRecords]="alarmData.totalRecords" [selectedColumns]="alarmData.selectedColumns"
      [cols]="alarmData.cols" [colData]="alarmData.colData"  [from]="alarmData.from" [to]="alarmData.to" [org]="org" [dataUrl]="dataUrl"
      (update)="loadGrid($event)" (rowSelect)="rowSelect($event)"></app-grid-table>
  </section>
</div>
<p-dialog [contentStyle]="{'height':'500px'}" *ngIf="isPopup" header="Alarms" [positionTop]="50" [(visible)]="isPopup" [modal]="true" showEffect="fade"
  [responsive]="true" styleClass="gridactive" resizable="true">
  <app-date-picker collapseId="alarmpopup" [selectedstyle]="{'width':'600px'}" [state]="alarmData.state" [selectedQuickRangeFrom]="alarmData.rangeFrom"
    [selectedQuickRangeTo]="alarmData.rangeTo" [dateFrom]="alarmData.dateFrom" [dateTo]="alarmData.dateTo" [timerangelabel]="alarmData.label"
    (switchView)="switchPopupView($event)" (refresh)="refreshPopup($event)" (updateTime)="updatePopupTime($event)" style="display: flow-root"></app-date-picker>
  <section *ngIf="alarmDataPopup.state === 'Graph'" id="error">
    <app-graph-container [isGrafanaSessionPresent]="isGrafanaSessionPresent" [timeRangeToAppendEvent]="alarmDataPopup.timeRangeToAppendEvent"
      [reloadEvent]="alarmDataPopup.reloadEvent" [org]="org" urlid="Alarm"></app-graph-container>
  </section>
  <section *ngIf="alarmDataPopup.state === 'Grid'">
    <app-grid-table [popup]="true" [reload]="alarmDataPopup.reloadEvent" [totalRecords]="alarmDataPopup.totalRecords" [selectedColumns]="alarmDataPopup.selectedColumns"
      [cols]="alarmDataPopup.cols" [colData]="alarmDataPopup.colData"  [from]="alarmDataPopup.from" [to]="alarmDataPopup.to"
      [org]="org" [dataUrl]="dataUrl" (update)="loadPopupGrid($event)"></app-grid-table>
  </section>

</p-dialog>


<p-dialog *ngIf="isRowClick" id="alarmRowPopup" [header]="alarmTitle" [(visible)]="isRowClick" [modal]="true" showEffect="fade"
  styleClass="gridactive" [responsive]="true" (onHide)="isImpactedDetail=false; isProbableCause = false;">
  <div class="row">
    <div class="col-sm-12 outerSpace">
      <div class="col-sm-12 rebootDetail" pTooltip="Description" tooltipPosition="top">
        <h5>Description</h5>
        <i class="fa fa-exclamation-circle rightSpace" aria-hidden="true" *ngIf="alarmDetails.severity && alarmDetails.severity === 'Critical'">
          <span> {{alarmDetails.severity}}</span>
        </i>
        <i class="fa fa-arrow-circle-up rightSpace" aria-hidden="true" *ngIf="alarmDetails.severity && alarmDetails.severity === 'Major'">
          <span>{{alarmDetails.severity}}</span>
        </i>
        <i class="fa fa-arrow-circle-down rightSpace" aria-hidden="true" *ngIf="alarmDetails.severity &&  alarmDetails.severity === 'Medium'">
          <span>{{alarmDetails.severity}}</span>
        </i>
        <i class="fa fa-chevron-circle-down rightSpace" aria-hidden="true" *ngIf="alarmDetails.severity &&  alarmDetails.severity === 'Minor'">
          <span>{{alarmDetails.severity}}</span>
        </i>
        <span class="rightSpace"> | </span>
        <span>{{alarmDetails.message}}</span>
        <div>
          <span class="rightSpace">
            <b> Alarm Date</b> : </span>
          <span>{{alarmDetails.entry_time}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12  outerSpace">
      <div class="col-sm-6  popUpcards space" [ngStyle]="(alarmDetails.possible_causes.length>0)?{'cursor':'pointer'}:{'cursor':'default'}">
        <div class="col-sm-12 popUpcards" (click)="isImpactedDetail = false; isProbableCause =!isProbableCause" pTooltip="Probable Cause"
          tooltipPosition="top">
          <h5>Probable Cause
            <span class="rightAlign" *ngIf="alarmDetails.possible_causes.length>0">
              <i [ngClass]="(!isProbableCause)?'fa fa-caret-right' :'fa fa-caret-down' " aria-hidden="true"></i>
            </span>
          </h5>
        </div>
      </div>
      <div class="col-sm-6  popUpcards space rightAlign" [ngStyle]="(alarmDetails.values && alarmDetails.values.length!= 0)?{'cursor':'pointer'}:{'cursor':'default'}">
        <div class="col-sm-12 popUpcards" (click)="isProbableCause = false; isImpactedDetail =!isImpactedDetail" pTooltip="Impacted Entity"
          tooltipPosition="top">
          <h5>Impacted Entity
            <span class="rightAlign" *ngIf="alarmDetails.values && alarmDetails.values.length>0 ">
              <i [ngClass]="(!isImpactedDetail)?'fa fa-caret-right' :'fa fa-caret-down' " aria-hidden="true"></i>
            </span>
          </h5>

        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isImpactedDetail && alarmDetails.values && alarmDetails.values.length>0 ">
    <div class="col-sm-12 outerSpace">
      <div class="col-sm-12 rebootDetail">
        <div class="ui-grid  ui-grid-responsive ui-fluid customcenter">
          <div class="ui-grid-row" *ngFor="let row of alarmDetails.values">
            <div class="ui-grid-col-{{12/columns.length}}" *ngFor="let header of columns">
              <div>
                <b>{{header}}</b> : {{row[header]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isProbableCause && alarmDetails.possible_causes.length>0">
    <div class="col-sm-12 outerSpace">
      <div class="col-sm-12 rebootDetail">
        <span *ngIf="alarmDetails.possible_causes.length>0">
          <div *ngFor="let row of alarmDetails.possible_causes">
            <b>{{row}}</b>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div class="row rebootDetail" *ngIf="alarmDetails.consumed_events && alarmDetails.consumed_events.length>0">
    <app-grid-table id="gridwithautoheight1" [popup]="true" [totalRecords]="fullData.length" [selectedColumns]="fullHeader" [cols]="fullHeader"
      [colData]="fullData" [to]="" [org]="org" [paginator]="false" (rowSelect)="poprowSelect($event)"></app-grid-table>
  </div>
</p-dialog>