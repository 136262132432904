import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { BaseCtrl } from '../base/BaseCtrl';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthenticateService extends BaseCtrl implements CanActivate, CanActivateChild {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  constructor(private _route: Router) {
    super();
  }

  canActivate(): boolean {
    if (sessionStorage.getItem('user')
      && sessionStorage.getItem('user').startsWith('{')) {
      this.loggedIn.next(true);
      return true;
    }
    this.loggedIn.next(false);
    return false;
  }

  canActivateChild() {
    if (sessionStorage.getItem('org')) {
      this.loggedIn.next(true);
      return true;
    } else {
      this.loggedIn.next(false);
      return false;
    }
  }

  logout() {
    this.loggedIn.next(false);
  }
}

