import { Component } from '@angular/core';
import { CustomEvents } from '../../events/customevents';
import { BaseCtrl } from '../../base/BaseCtrl';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Operator } from 'rxjs/Rx';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import { AppInitialiserService } from '../../services/app-initialiser.service';
import { JiraTicketServiceService } from '../../services/jira-ticket-service.service';
import { Inject } from '@angular/core';


@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent extends BaseCtrl {
  enablelogin;
  loginUrl: string;
  grafanalogoutUrl: string;
  jiraUrl: string;
  showJira = false;
  confluenceUrl: string;
  config;
  user;
  checkRoute;
  isGrafanaPermissionPresent = false;
  isJiraPermissionPresent = false;
  count = 0;
  checkingSession;
  private _customEvents: CustomEvents; private _appInitialiserService: AppInitialiserService;
  private oidcSecurityService: OidcSecurityService; private _custHttp: PortalHttpServiceService;
  private _jirahttp: JiraTicketServiceService;
  constructor(@Inject(CustomEvents) _customEvents: CustomEvents,
  @Inject(OidcSecurityService) oidcSecurityService: OidcSecurityService,
  @Inject(PortalHttpServiceService) _custHttp: PortalHttpServiceService,
  @Inject(JiraTicketServiceService) _jirahttp: JiraTicketServiceService,
  @Inject(AppInitialiserService) _appInitialiserService: AppInitialiserService) {
    super();
    this._customEvents = _customEvents;
    this.oidcSecurityService = oidcSecurityService;
    this._custHttp = _custHttp;
    this._jirahttp = _jirahttp;
    this._appInitialiserService = _appInitialiserService;
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.grafanalogoutUrl = this.config['grafanaUrl'] + 'logout';
    this.loginUrl = this.config['grafanaUrl'] + 'login/generic_oauth';
    this.jiraUrl = this.config['jiraLoginUrl'];
    this.showJira = true;
    this.confluenceUrl = this.config['confluenceLoginUrl'];
    this.getUserData().subscribe((res) => {
      const data = res['data'];
      sessionStorage.setItem('user', JSON.stringify(data));
      sessionStorage.setItem('username', data['username']);
      this.setOrg(data);
    });
  }

  setOrg(data) {
    if (sessionStorage.getItem('org')) {
      if (data['orgs'] && data['orgs'].length > 0 ) {
        for (const org of data['orgs']) {
          if (sessionStorage.getItem('org') === org.key) {
          this._customEvents.routToDashBoard.emit({ orgname: sessionStorage.getItem('org') });
            return;
          }
        }
      }
    } else if (data.defaultOrg) {
      const org = data.defaultOrg.key;
      sessionStorage.setItem('selctedorg', data.defaultOrg.name);
      sessionStorage.setItem('org', org);
     this._customEvents.routToDashBoard.emit({ orgname: sessionStorage.getItem('org') });
      return;
    }
  }
  // check whether service api is authorised or not
  getUserData() {
    const url = '/api/v1/sidebar/' + this.oidcSecurityService.getToken();
    return this._custHttp.getDatawithoutHeader(this.ENV.serviceUrl + url).retryWhen((errors: Observable<any>) => {
      let errorCount = 0;
      return errors.flatMap((error: any) => {
        if (errorCount++ < 3 && error.status >= 500) {
          return Observable.of(error.status).delay(1000);
        }
        return Observable.throw(error);
      });
    }).catch((error: any) => this.catchError(error));
  }


  // if service api is unauthorised navigate to logout page after 5 retries
  catchError(error: any): any {
    this._customEvents.unAuthorizedError.emit({});
    this._customEvents.showHideLoader.emit({ show: false });
    this._customEvents.logoutEvt.emit({});
  }
}

