import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AdvanceGridTableEvents } from '../../../../advance-grid/advance-grid.component';
import { TableData } from '../../../../common/SharedClasses/TableData';
import { DateConverterPipe } from '../../../../utils/dateConverter.pipe';
import { PortalHttpServiceService } from '../../../../services/portal-http-service.service';
import { CustomEvents } from '../../../../events/customevents';

@Component({
  selector: 'app-customer-circuit-report',
  templateUrl: './customer-circuit-report.component.html',
  styleUrls: ['./customer-circuit-report.component.scss']
})
export class CustomerCircuitReportComponent implements OnInit, AdvanceGridTableEvents {
  @Input() org;
  @Input() slectedCircuit;
  dataUrl;
  circuiteDataReport;
  isRowClick;
  circuitReportDialog: boolean;
  Header;
  CircuitReoprtInfoTitle;
  circuitInfoReoprtDialog;
  circuitInfoReportData;
  constructor(public _datePipe: DateConverterPipe, private _portalhttp: PortalHttpServiceService, private _customEvents: CustomEvents) {
  }

  ngOnInit() {
    this.dataUrl = '/api/v1/circuits/customer?circuit_id=' + this.slectedCircuit;
    this.circuiteDataReport = new TableData();
    this.circuiteDataReport.cols = [];
    this.isRowClick = false;
  }
  advanceLoadGrid(data: any) {
   if (this.circuiteDataReport.cols.length === 0) {
      this.circuiteDataReport.cols = data['headers'];
      const columns = [];
      this.circuiteDataReport.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.circuiteDataReport.selectedColumns = columns;
    }
    const rawData = data['data'];
    if (rawData.length > 0) {
      rawData.forEach((element) => {
        if (element['created']) {
          element['created'] = element['created'] && (element['created'] !== 0) ?
            this._datePipe.transform(element['created']) : '';
        }
        if (element['updated']) {
          element['updated'] = element['updated'] && (element['updated'] !== 0) ?
            this._datePipe.transform(element['updated']) : '';
        }
      });
    }
    this.circuiteDataReport.colData = rawData;
    this.circuiteDataReport.totalRecords = data['total'];
  }
  advanceRowSelect(event) {
    const circuitInfoUrl = '/api/v1/circuit/info?' + 'type=Circuit&id=' + event['data']['Id'] + '&isPopup=true';
    this.slectedCircuit = event['data']['Id'];
    this._portalhttp.get(circuitInfoUrl, this.org).subscribe((res) => {
    this.isRowClick = true;
    this.Header = res['data'];
    this.circuitReportDialog = true;
     }, (err) => {
     });
  }
  displaygridInfo(data) {
    const circuitInfoUrl = '/api/v1/circuit/info?' + 'type=' + data['type'] + '&id=' + data['id'] + '&isPopup=true';
    this._portalhttp.get(circuitInfoUrl, this.org).subscribe((res) => {
      this.CircuitReoprtInfoTitle = data['field'];
      this.circuitInfoReportData = res['data'];
      this.circuitInfoReoprtDialog = true;
     }, (err) => {
     });
  }
}
