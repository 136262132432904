import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatePickerModule } from '../../../date-picker/date-picker.module';
import { DialogModule } from 'primeng/dialog';
import { GridTableModule } from '../../../grid-table/grid-table.module';
import { EventGridComponent } from './event-grid.component';
import { GraphContainerModule } from '../../../graph-container/graph-container.module';

@NgModule({
  imports: [
    CommonModule ,
    DatePickerModule,
    DialogModule,
    GraphContainerModule,
    GridTableModule
  ],
  declarations: [EventGridComponent],
  exports: [EventGridComponent]
})

export class EventGridModule { }
