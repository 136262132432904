<div class="container-fluid" >
  <div class="col-md-12 form-group">
      <div class="col-md-12 screen-shadow" >
          <div style="margin-bottom:6px;" >
            <b>IP SLA Information</b>
         <div>
            <app-ip-sla-datepicker [types]="types"  (updateTime)="updateTime($event)"   [isTime] ="true"   [isExpand]="false"  style="display: flow-root;float:right; margin-top:-6.3%;"></app-ip-sla-datepicker>
          </div>
          </div>
          <div *ngIf="msgsAPI.length!==0">
              <p-messages *ngIf="msgsAPI.length!==0" [(value)]="msgsAPI"></p-messages>
          </div>
          <p-tabView id="ipslatab">
            <p-tabPanel header="Select EndPoints">
              <div class="row">
              <div  class="col-md-2 offset-md-2">
                <h5>Starting Point</h5>
                <p-dropdown [options]="cities1" #ddstart [filter]="true" (onClick)="resetfilter(ddstart)" resetFilterOnHide="true" [style]="{'width':'122px'}" [(ngModel)]="selstartPoint" (onChange)="selectStartPoint($event)"></p-dropdown>
              </div>
              <div class="col-md-2 offset-md-2">
                  <h5>Ending  Point</h5>
                  <p-dropdown [options]="cities2" #ddend [filter]="true" (onClick)="resetfilter(ddend)" resetFilterOnHide ="true" [(ngModel)]="selendPoint" [style]="{'width':'122px'}"[disabled]="isStartpointsel"></p-dropdown>
                </div>
               <div class="col-md-2offset-md-2" id="ipbtn">
                  <h5 style="visibility: hidden;">Ending  Point</h5>
                  <p-button label="Get Info" (onClick)="getIPInfo($event)"  [disabled]="isStartpointsel"></p-button> 
               </div>
              </div>
               <div style="margin-top: 37px;" *ngIf ="displayResult">
                 <h5> Result ( <b>{{daterangeLabel}}</b>) </h5>
                 <table style="border:1px solid black;width:360px;"  *ngIf ="displayResult">
                   <thead>
                     <tr style="background-color: #cccc;">
                       <th style="border:1px solid black;text-align:center;font-weight:normal;">Avg Jitter</th>
                       <th style="border:1px solid black;text-align:center;font-weight:normal;"> Avg Latency</th>
                       <th style="border:1px solid black;text-align:center;font-weight:normal;"> Avg Packet Loss</th>
                       <th style="border:1px solid black;text-align:center;font-weight:normal;">Graph</th>
                      </tr>
                      <tr>
                          <th [style.background-color] ="getColor(infoData, 'avg_jitter')" style="border:1px solid black;text-align:center;"><span>{{(infoData.avg_jitter )}}</span> <span *ngIf=" infoData && infoData.hasOwnProperty('avg_jitter') ===true"> ms {{getBaseline(infoData, 'avg_jitter')}}</span> <span *ngIf=" infoData && infoData.hasOwnProperty('avg_jitter') === false"> No Data</span></th>
                          <th [style.background-color] ="getColor(infoData, 'avg_latency')" style="border:1px solid black;text-align:center;"><span>{{(infoData.avg_latency )}}</span><span *ngIf=" infoData && infoData.hasOwnProperty('avg_latency') === true"> ms {{getBaseline(infoData, 'avg_latency')}}</span><span *ngIf=" infoData && infoData.hasOwnProperty('avg_latency') === false">No Data</span></th>
                          <th [style.background-color] ="getColor(infoData, 'avg_packet_loss')" style="border:1px solid black;text-align:center;"><span>{{(infoData.avg_packet_loss )}}</span> <span *ngIf=" infoData && infoData.hasOwnProperty('avg_packet_loss') === true">% {{getBaseline(infoData, 'avg_packet_loss')}}</span><span *ngIf=" infoData && infoData.hasOwnProperty('avg_packet_loss') ===false">No Data</span></th>
                          <th style="border:1px solid black;text-align:center;">
                            <a (click)="onShowGraph(infoData.source,infoData.target)" [ngClass]="(true)?'fa fa-line-chart':'fa fa-ban'"></a>
                            </th>
                       </tr>
                   </thead>
                 </table>
                 <hr>
                 <h5> Last 10 Records </h5>
                 <table style="border:1px solid black;width:39%;"  *ngIf ="records">
                   <thead>
                     <tr style="background-color: #cccc;">
                      <th style="border:1px solid black;text-align:center;font-weight:normal;">Record Time</th>
                       <th style="border:1px solid black;text-align:center;font-weight:normal;">Avg Jitter</th>
                       <th style="border:1px solid black;text-align:center;font-weight:normal;">Avg Latency</th>
                       <th style="border:1px solid black;text-align:center;font-weight:normal;">Avg Packet Loss</th>
                      </tr>
                      <tr *ngFor="let record of records">
                        <th style="border:1px solid black;text-align:center;padding-left:2%;">{{(record['@timestamp']) ? (record['@timestamp']):'No Data'}}</th>
                          <th style="border:1px solid black;text-align:center;"><span>{{(record.avg_jitter)}}</span><span *ngIf="record.avg_jitter || record.avg_jitter===0"> ms</span> </th>
                          <th style="border:1px solid black;text-align:center;"><span>{{(record.avg_latency)}}</span> <span *ngIf="record.avg_latency || record.avg_latency ===0">ms</span></th>
                          <th style="border:1px solid black;text-align:center;"><span>{{(record.avg_packet_loss)}}</span> %</th>
                       </tr>
                   </thead>
                 </table>
               </div>
            </p-tabPanel>
            <p-tabPanel header="Jitter">
                <app-ip-sla-table [data]="ipsladata" [nodes] = "nodes" type = "avg_jitter" unit ="ms" ></app-ip-sla-table>
            </p-tabPanel>
            <p-tabPanel header="Latency">
                <app-ip-sla-table [data]="ipsladata" [nodes] = "nodes" type = "avg_latency" unit ="ms" ></app-ip-sla-table> 
            </p-tabPanel>
            <p-tabPanel header="Packet Loss ">
                <app-ip-sla-table [data]="ipsladata" [nodes] = "nodes" type = "avg_packet_loss" unit ="%" ></app-ip-sla-table>

          </p-tabPanel>
        </p-tabView>
        <p-dialog [header]="ipslaHeader" *ngIf="ipslaDialog" [positionLeft] = "0" [baseZIndex]="1" [(visible)]="ipslaDialog" [modal]="true" [responsive]="true" showEffect="fade" height='auto' width='1000'>
            <div id="graph-block-3"  *ngIf="isDisplayGraph" (onHide)="closeDialog($event)">
                    <app-graph [graphs]="graphs" [graphName]="graphName"  [fullView]="fullView" [scrollTo]="scrollTo"></app-graph>
            </div>
       </p-dialog>
       <p-growl *ngIf="msgs.length!==0" [(value)]="msgs"></p-growl>
      </div>
  </div>
</div>

