import { Component, OnInit, OnDestroy , ViewEncapsulation} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { BaseCtrl } from './base/BaseCtrl';
import { CustomEvents } from './events/customevents';
import { OidcSecurityService, AuthWellKnownEndpoints, OpenIDImplicitFlowConfiguration} from 'angular-auth-oidc-client';
import { Title } from '@angular/platform-browser';
import { AuthenticateService } from './session/authenticate.service';
import { Observable } from 'rxjs/Rx';
import { AppInitialiserService } from './services/app-initialiser.service';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseCtrl implements OnInit, OnDestroy {

  unAuthorizedErrorListener: any;
  routToDashBoardListener: any;
  loadDashBoardListener: any;
  authorizedListener: any;
  isAuthorizedSubscription: Subscription;
  logoutListener: any;
  isAuth$: Observable<boolean>;
  initialdata;
  user;
  private _route: Router; private titleService: Title; private appInitialiserService: AppInitialiserService;
    private _customEvents: CustomEvents; private _authservice: AuthenticateService;
    public securityService: OidcSecurityService; public authWellKnownEndpoints: AuthWellKnownEndpoints;
  constructor(@Inject(Router) _route: Router, @Inject(Title) titleService: Title,
  @Inject(AppInitialiserService) appInitialiserService: AppInitialiserService,
  @Inject(CustomEvents) _customEvents: CustomEvents,
  @Inject(AuthenticateService) _authservice: AuthenticateService,
  @Inject(OidcSecurityService) securityService: OidcSecurityService,
   authWellKnownEndpoints: AuthWellKnownEndpoints) {
    super();
    this._route = _route;
    this.titleService = titleService;
    this.appInitialiserService = appInitialiserService;
    this._customEvents = _customEvents;
    this._authservice = _authservice;
    this.securityService = securityService;
   this.authWellKnownEndpoints = authWellKnownEndpoints;
    const name = (this.ENV.name === 'PROD') ? '' : this.ENV.name;
    this.titleService.setTitle('Gamma Portal ' + name);
    console.log('Build V:- ' + this.APP_VERSION);
    console.log('Environment :- ' + this.ENV.name);
    this.initialdata = {};
    (<any>window).ga('create', this.ENV.gtmkey, 'auto');
    this._route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          let page = event.urlAfterRedirects.split('?');
          (<any>window).ga('set', 'dimension1', sessionStorage.getItem('username'));
          (<any>window).ga('set', 'dimension2',  sessionStorage.getItem('selctedorg'));
          (<any>window).ga('set', 'dimension3', this.ENV.name);
          (<any>window).ga('set', 'page', page[0]);
          (<any>window).ga('send', 'pageview');
      }
    });
  }
  ngOnInit() {
    this.appInitialiserService.Properties.subscribe((res) => {
      this.initialdata = res;
      sessionStorage.setItem('config', JSON.stringify(res));
      this.setupModule();
    });
    this.isAuth$ = this._authservice.isLoggedIn;
    this.authWellKnownEndpoints.onWellKnownEndpointsLoaded.subscribe(() => {
      this._route.navigate(['/']);
    });

    this.routToDashBoardListener = this._customEvents.routToDashBoard.subscribe((value: any) => {
      const route = this.getRoute(value.orgname);
      if (route) {
        this._route.navigate([route], { queryParams: { org: value.orgname } });

      } else {
        this._route.navigate(['/dashboard']);

      }
    });

    this.authorizedListener = this._customEvents.authorizeblock.subscribe((value: any) => {
      this._route.navigate(['/authorize']);
    });

    this.logoutListener = this._customEvents.logoutEvt.subscribe((value: any) => {
      this._authservice.logout();
      this._route.navigate(['/logout']);
    });

    this.unAuthorizedErrorListener = this._customEvents.unAuthorizedError.subscribe((value: any) => {
      this.securityService.authorize();
    });
  }

  getRoute(orgname) {
    let route;
    if (orgname) {
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.user['orgs'].forEach(element => {
        if (element.key === orgname) {
          route = element.defaultValue;
            const submenu = element.dashboardMenu;
            const eapMenu = element.eapMenu;
            const settingMenu = element.settingsMenu;
          if (submenu instanceof Array && submenu.length > 1) {
            route = submenu[0].value;
          } else if (eapMenu instanceof Array && eapMenu.length > 0) {
            route = eapMenu[0].value;
          } else if (settingMenu instanceof Array && settingMenu.length > 0) {
            route = settingMenu[0].value;
          } else {
            route = '/user/profile';
          }
        }
      });
    }
    return route;
  }

  ngOnDestroy() {
    if (this.unAuthorizedErrorListener) {
      this.unAuthorizedErrorListener.unsubscribe();
    }
    if (this.routToDashBoardListener) {
      this.routToDashBoardListener.unsubscribe();
    }
    if (this.loadDashBoardListener) {
      this.loadDashBoardListener.unsubscribe();
    }
    if (this.authorizedListener) {

      this.authorizedListener.unsubscribe();
    }


  }
  setupModule() {
    const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
    openIDImplicitFlowConfiguration.stsServer = this.initialdata['gluuUrl'];
    // openIDImplicitFlowConfiguration.redirect_url = this.ENV.name === 'LOCAL' ? 'http://localhost:4200/' : this.initialdata['apiUrl'];
    // openIDImplicitFlowConfiguration.post_logout_redirect_uri = this.ENV.name === 'LOCAL' ? 'http://localhost:4200/logout' : this.initialdata['logoutRedirectUrl'];
    openIDImplicitFlowConfiguration.redirect_url = this.initialdata['apiUrl'];
    openIDImplicitFlowConfiguration.post_logout_redirect_uri = this.initialdata['logoutRedirectUrl'];
    openIDImplicitFlowConfiguration.client_id = this.initialdata['clientId'];
    openIDImplicitFlowConfiguration.response_type = 'id_token token';
    openIDImplicitFlowConfiguration.scope = this.initialdata['scope'];
    openIDImplicitFlowConfiguration.startup_route = '/authorize';
    openIDImplicitFlowConfiguration.forbidden_route = '/auth/login';
    openIDImplicitFlowConfiguration.unauthorized_route = '/home';
    openIDImplicitFlowConfiguration.log_console_warning_active = true;
    openIDImplicitFlowConfiguration.log_console_debug_active = true;
    openIDImplicitFlowConfiguration.auto_userinfo = false;
    openIDImplicitFlowConfiguration.silent_renew = false;
    openIDImplicitFlowConfiguration.silent_renew_offset_in_seconds = 600;
    openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 21600;
    this.securityService.setupModule(openIDImplicitFlowConfiguration);
    return openIDImplicitFlowConfiguration;
 }

}

