<p-messages [(value)]="errorMsgForJirasession" *ngIf="errorMsgForJirasession.length !=0"></p-messages>
<div class="error" *ngIf="message ; else noerror">
    {{message}}
</div>
<iframe style="display:none;" [src]="jiraUrl + time | safe"></iframe>
<iframe style="display:none;" [src]="confluenceUrl | safe"></iframe>
<iframe style="display:none;" [src]="loginUrl | safe"></iframe>
<ng-template #noerror>
    <router-outlet></router-outlet>
</ng-template>

  


