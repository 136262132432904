import { Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomEvents } from '../../../events/customevents';
import { PortalHttpServiceService } from '../../../services/portal-http-service.service';
import { TableData } from '../../../common/SharedClasses/TableData';
import { GridTableEvents } from '../../../grid-table/grid-table.component';
import { DateConverterPipe } from '../../../utils/dateConverter.pipe';
import {GrafanaService} from '../../../dashboard/device/service/grafana.service';

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss']
})
export class DevicesListComponent implements OnInit , GridTableEvents, OnChanges {
  devicelistGridsUrl;
  devicelistGrid;
  isRowSelect;
  org: string;
  devicelistDialog = false;
  devicesDetaildata;
  @Input() selectedregions;
  regionvalue;
  userdata;
  regions;
   // Graph
   graphCollection = [];
   graphs: any[] = [];
   graphName;
   scrollTo;
   fullView;
   isGrafanaSessionPresent;
   isApplicationPopUp;
   displayGraphListener: any;
   renewSession = false;
   urls = [];
   deviceID;
   deviceRegion;
   isPermission;

  constructor(private route: ActivatedRoute,
     private _customEvents: CustomEvents, private _portalhttp: PortalHttpServiceService, public _datePipe: DateConverterPipe,
     private _grafanaService: GrafanaService) {
     this.devicelistGrid = new TableData();
     this.isRowSelect = false;
     this.isGrafanaSessionPresent = false;
     this.route.queryParams.subscribe(params => {
      this.org = params['org'];
       });
       const user = JSON.parse(sessionStorage.getItem('user'));
       user['orgs'].forEach(element => {
         if (element.key === this.org) {
          this.isPermission = element.permissions;
         }
       });
       const grafana = sessionStorage.getItem('grafanaPresent');
       if (grafana === 'true') {
         this.loadGraphs();
       }
   }
   ngOnChanges(changes: SimpleChanges) {
   this.selectedregions = this.selectedregions;
  }

  ngOnInit() {
     this.devicelistGridsUrl = '/api/v1/security/device';
     this.devicelistGrid.reloadEvent = '';
  }
  setInitialData() {
    this.devicelistGrid.state = 'Graph';
  }
  loadGrid(data) {
    this.graphs = [];
    if (this.devicelistGrid.cols.length === 0) {
      this.devicelistGrid.cols = data['headers'];
      const columns = [];
      this.devicelistGrid.cols.forEach(element => {
        if (element.hasOwnProperty('default') && element.default) {
          columns.push(element);
        }
      });
      this.devicelistGrid.selectedColumns = columns;
    }
    const rawData = data['data'];
    this.devicelistGrid.colData = rawData;
    this.devicelistGrid.totalRecords = data['total'];
  }
  rowSelect(event) {
    this.deviceID = event.data['device-id'];
    this.deviceRegion = event.data['region'];
    this.devicesDetaildata = '';
    this.devicesDetaildata = event.data;
    this.loadGraphs();
    document.getElementById('devicelist_info_details').scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
  hidedeviceDetaillist() {
    this.devicelistDialog = false;
  }
  loadPopupGrid(event) {}
  // load top application
  loadGraphs() {
    this.graphs = [];
    this.devicelistDialog = false;
    const graphURL = '/api/graph/urls/security?device=' + this.deviceID + '&theme=light';
    this._portalhttp.getwithRegion(graphURL, this.org, this.deviceRegion).subscribe((rest: any) => {
      if (rest['data'].length === 0) {
        return;
      } else {
        this.graphCollection = rest['data'];
        for (const item of this.graphCollection) {
          item['type'] = 'Devices';
          this.graphs.push(item);
          this.graphName = '';
          this.scrollTo = 'graph-block-2';
          this.fullView = false;
        }
        if (this.deviceID) {
          this.devicelistDialog = true;
        }
      }
    }, (error) => {
    });
  }
}
