import { Component, OnInit } from '@angular/core';
import { CustomEvents } from '../../events/customevents';
import { PortalHttpServiceService } from '../../services/portal-http-service.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-passwordchange',
  templateUrl: './passwordchange.component.html',
  styleUrls: ['./passwordchange.component.scss']
})
export class PasswordchangeComponent implements OnInit {

  newpwd;
  confirmpwd;
  cocurrentpwd;
  isSave;
  msgs;

  constructor(private _customEvents: CustomEvents, private _userService: UserService) { }

  ngOnInit() {
    this._customEvents.showHideLoader.emit({ show: false });
    this.isSave = false;
  }

  onSubmit(value) {
    const body = {
      'currentPassword': value.form.value.passwords.current_password,
      'newPassword': value.form.value.passwords.new_password
    };
    const headers = 'GtmEvents';
    this._customEvents.sendGtmEvt.emit({ data: 'profile_changePassword', header: headers });
    this._userService.putWitoutRetry('/api/user/change/password', 'NA', body).subscribe((rest: any) => {
     this.msgs = [];
      if (rest['status'] !== 200) {
      this.msgs.push({ severity: 'error', detail: rest['data'] });
     } else {
      this.msgs.push({ severity: 'info', detail: 'Password updated' });
      this.isSave = true;
     }
    }, error => {
      this.msgs = [];
      this.msgs.push({ severity: 'error', detail: 'Current Password is invalid. Please enter Valid Password !!'});
    }
    );
  }
  validate(value, e) {
    if (((e.keyCode === 32) && (this.newpwd.trim().length === 0))) {
      this.newpwd  = '';
      e.preventDefault();
    } else {
      return true;
    }
  }
}
