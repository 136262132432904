<div class="container-fluid" style="min-height: 91%">
  <div class="row">
    <div class="col-4 col-offset-4">
      <div class="col-center-block">
        <section class="logout-form">
              
          <img src="/assets/imgs/logo.png" alt="apcela" />
          <img [src]='grafanalogoutUrl' style='display:none;'>
          <img [src]='gluulogoutUrl' style='display:none;'>
          <img [src]='logoutUrl' style='display:none;'>
          <img [src]='logoutUrl' style='display:none;'>
           <h4 class="text-center error">{{errorMessage}}</h4>
          <h2 class="text-center">You Have Been Logged Out</h2>
          <h4 class="text-center">Thank you for using Apcela Portal</h4>
          <p class="text-center"> Please
            <a [routerLink]='["/"]'>
              <i class="fa fa-sign-out" aria-hidden="true"></i>click here</a> to Sign In</p>
        </section>
        <div *ngIf="showJiraLogout">
        <iframe style="visibility:hidden" [src]="jiralogoutUrl | safe"></iframe>
        </div>
        <iframe style="visibility:hidden" [src]="idpGluu | safe"></iframe>
      </div>
    </div>
  </div>
</div>