import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DeviceComponent } from './device/device.component';
import { SocComponent } from './soc/soc.component';
import { JiraComponent } from './jira/jira.component';
import { AuthenticateService } from '../session/authenticate.service';
import {AppsensorComponent} from './appsensor/appsensor.component';
import {SecurityComponent} from './security/security.component';

const dashboardRoutes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            {
                path: 'home',
                component: JiraComponent
            },
            {
                path: 'soc',
                component: SocComponent
            },
            {
                path: 'device',
                component: DeviceComponent
            },
            {
                path: 'appsensor',
                component: AppsensorComponent
            },
            {
                path: 'security',
                component: SecurityComponent
            }
        ]
    }
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(dashboardRoutes)
    ],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
