<p-growl [(value)]="msgs" life="1000"></p-growl>
<div class="container-fluid" style="min-height:84%">
    <div class="col-md-12 form-group" style="padding:0px;padding-left:15px;">
       <div class="col-md-12" style="padding:0px;padding-left:1px;">
           <div *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('security_device_grid') !=-1">
             <label style="padding-right: 10px;">Region : </label>
              <p-dropdown #dd [options]="regions" [autoDisplayFirst]='true'  [(ngModel)]="selectedregions" (onChange)="getRegionValue(dd)"></p-dropdown>
           </div>
           <div *ngIf="showErrorPage">
               <section>
                 <h4 class="text-center error">Oops!! Something went down </h4>
                 <h4 class="text-center">Please Reload application..</h4>
               </section>
           </div>
         </div>
   </div>
   <div class="col-md-12 form-group" *ngIf="isPermission && isPermission?.length !==0 && isPermission.indexOf('security_device_grid') !=-1">
    <div class="col-md-12 screen-shadow">
            <div>
              <h5 style="font-weight: bold;">Devices</h5>
        </div>
         <app-devices-list [org]="org" [selectedregions]='selectedregions'></app-devices-list>
    </div>
</div>
 </div>
