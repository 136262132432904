import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import {AdminSettingComponent} from './admin-setting.component';
import {AuthenticateService} from '../session/authenticate.service';
import {UserassociateComponent} from '../admin-setting/usermangement/userassociate/userassociate.component';
import {OrgmangementComponent} from '../admin-setting/orgmangement/orgmangement.component';
const adminsettingRoutes: Routes = [
  {
      path: '',
      component: AdminSettingComponent,
      children: [
          { path: '', redirectTo: 'users', pathMatch: 'full' },
          {
              path: 'users',
              component: UserassociateComponent
          },
          {
              path: 'organizations',
              component: OrgmangementComponent
          },
      ]
  }
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(adminsettingRoutes)
  ],
  exports: [RouterModule]
})
export class AdminSettingRoutingModule { }
