import { Component, OnInit, Input, OnChanges} from '@angular/core';
import { PortalHttpServiceService } from '../services/portal-http-service.service';

@Component({
  selector: 'app-graph-container',
  templateUrl: './graph-container.component.html',
  styleUrls: ['./graph-container.component.scss']
})
export class GraphContainerComponent implements OnInit , OnChanges {

  @Input() isGrafanaSessionPresent;
  @Input() timeRangeToAppendEvent;
  @Input() reloadEvent;
  @Input() org;
  @Input() urlid;
  @Input() deviceUrl;
  @Input() switchState = 'Graph';
  url;
  isGraphLoading;
  constructor(private _portalhttp: PortalHttpServiceService) {
  }

  ngOnInit() {
    this.loadGraphs();
  }

  ngOnChanges() {
    this.loadGraphs();
  }
  // call graph api for dialog as well as event grid in home page
  loadGraphs() {
    this.isGraphLoading = true;
    if (this.urlid) {
      this._portalhttp.get('/api/graph/dashboard/urls', this.org).subscribe((rest: any) => {
       const data = rest.data;
       if (data.length > 0) {
        const urls = rest.data[0].graphUrls;
        for (const item of urls) {
          if (item.name === this.urlid) {
            this.url = item.url;
            this.isGraphLoading = false;
          } else {
            this.isGraphLoading = false;
          }
        }
      } else {
        this.isGraphLoading = false;
      }
      }, (error) => {
        this.isGraphLoading = false;
      });
    } else {
      if (this.deviceUrl) {
        this.url = this.deviceUrl;
      } else {
        this.isGraphLoading = false;
      }
    }

  }

}
