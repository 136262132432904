<div>
  <nav class="navbar navbar-default">
    <div class="navbar-header">
      <a class="navbar-brand" [pTooltip]="blocktitle" tooltipPosition="right">
        <b>{{blocktitle}}</b>
      </a>
      <button type="button" class="navbar-toggle" data-toggle="collapse" [attr.data-target]="'#' + collapseId">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" [attr.id]="collapseId">
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="isTime">
          <a herf="#" (click)="panel1.toggle($event)" pTooltip="Time" tooltipPosition="left">{{timerangelabel}}
            <i class="fa fa-clock-o" aria-hidden="true"></i>
          </a>
        </li>
        <li *ngIf="isSwitch">
          <a herf="#">
            <p-selectButton [options]="types" (onChange)="onSwicthChange($event)" [pTooltip]="state" tooltipPosition="left" [(ngModel)]="state"></p-selectButton>
          </a>
        </li>

        <li *ngIf="isDropdown">
          <a herf="#">
            <p-dropdown id="application" [style]="{'margin-right': '10px', 'width':'auto'}" (onChange)="ChangeInType($event)" pTooltip="Type"
              tooltipPosition="left" [options]="applicationtypes" [(ngModel)]="selectedType"></p-dropdown>
          </a>
        </li>
        <li *ngIf="isGraphChange">
            <a herf="#" (click)="panel2.toggle($event)">
              <i class="fa fa-list" aria-hidden="true" pTooltip="Graph" tooltipPosition="left"></i>
            </a>
          </li>
        <li *ngIf="isReload">
          <a herf="#" (click)="reload()">
            <i class="fa fa-refresh" aria-hidden="true" pTooltip="Refresh" tooltipPosition="left"></i>
          </a>
        </li>
       
        <li>
          <a herf="#" (click)="expandView()" *ngIf="isExpand">
            <i class="fa fa-th-large" aria-hidden="true" pTooltip="Expand" tooltipPosition="left"></i>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <p-overlayPanel class="ui-g ui-fluid" #panel1 id="customdatepicker" [ngStyle]="selectedstyle">
    <div class="ui-g-12 ui-md-6">
      <h5>Quick Ranges</h5>
      <div class="border">
        <ul class="quickrange">
          <div class="col-xs-12 col-sm-6">
            <div class="row">
              <li *ngFor="let range of quickRangeHour" class="col-xs-12">
                <a herf="#" (click)="panel1.hide(); selectQuickRange($event,range)">{{range.label}}</a>
              </li>
            </div>
          </div>
          <li *ngFor="let range of quickRangeDays" class="col-xs-12 col-sm-6">
            <a herf="#" (click)="panel1.hide(); selectQuickRange($event,range)">{{range.label}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="ui-g-12 ui-md-6">
      <h5>Time Range</h5>
      <div class="dateSpace">
        <label>From </label>
        <p-calendar #from [(ngModel)]="dateFrom" [showTime]="false" [showIcon]="true" [placeholder]="selectedQuickRangeFrom" [maxDate]="maxDate"
          (onSelect)="validateTime() ;displayToDate(dateFrom)">
          <p-footer>
            <div class="ui-g-12" style="text-align:right;padding:0px !important;">
              <button class="btn btn-primary" [disabled]="isOkButtonDisabled" (click)="from.overlayVisible = false ">Ok</button>
            </div>
          </p-footer>
        </p-calendar>
      </div>
      <div class="dateSpace">
        <label>To </label>
        <p-calendar #to [(ngModel)]="dateTo" [showTime]="false" [disabled]="isHideDateTo" [showIcon]="true" [minDate]="dateFrom" [maxDate]="maxDate"
          (onSelect)="validateTime()" [placeholder]="selectedQuickRangeTo">
          <p-footer>
            <div class="ui-g-12" style="text-align:right;padding:0px !important;">
              <button class="btn btn-primary" [disabled]="isOkButtonDisabled" (click)="to.overlayVisible = false ">Ok</button>
            </div>
          </p-footer>
        </p-calendar>
      </div>
      <div class="dateSpace" *ngIf="autorefresh">
          <label style="margin-left:-35%;">Refresh </label>
          <div class="ui-g-12" style="text-align:right;padding:0px !important;width: 20px; margin-left: 24%;">
            <p-dropdown [options]="refreshdata" [(ngModel)]="selectedRefresh" [style]="{'min-width':'20px','width':'177px'}" placeholder="Select"></p-dropdown>
          </div>
          </div>
    </div>
    <div class="ui-g-12" style="text-align:right">
      <button class="btn btn-primary" (click)="panel1.hide(); applyTimeRange();graphdaone()">Done</button>
    </div>
  </p-overlayPanel>
  <p-overlayPanel #panel2 [style]="{'height':'auto'}">
      <div class="graphContainer">
        <div *ngFor="let optionchk of graphoption">
          <h5 style="font-weight:600;">{{optionchk.name}}</h5>
          <div class="ui-g">
            <div class="ui-g-12 ui-md-6 ui-lg-3"  *ngFor="let graph of optionchk.graphUrls">
              <p-checkbox name="group2"  value="{{graph.name}}"  label="{{graph.name}}" [disabled]="(selectedOptions.length >= appInitialisedData.graphMaxCount) && (selectedOptions.indexOf(graph.name) == -1)"
                 [(ngModel)]="selectedOptions"  inputId="{{graph.name}}" (ngModelChange)="hasSelectedOptionsChanged = true">
              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div style="float:right; margin-top:15px">
        <button class="btn btn-primary" (click)="panel2.hide(); ischangeGraph();">Done</button>
      </div>
    </p-overlayPanel>

</div>