import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ProgressBarModule} from 'primeng/progressbar';
import { JiraTicketDetailsComponent } from './jira-ticket-details.component';
import { JiraCommentModule } from '../jira-comment/jira-comment.module';
import {DialogModule} from 'primeng/dialog';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule,
    ProgressBarModule,
    DialogModule,
    JiraCommentModule
  ],
  declarations: [JiraTicketDetailsComponent],
  exports: [JiraTicketDetailsComponent]
})
export class JiraTicketDetailsModule { }
