<p-tabView (onChange)="ChangeTab($event)" [activeIndex]="activepanel">
    <p-tabPanel header="Customer Service" style="text-align:center;" [disabled]="isJiraSessionPresent">
        <h2 style="text-align:left;padding-left:21%;font-size:24px;font-weight:normal">Welcome to Apcela Customer
            Service</h2>
        <br />
        <input id="Searchinput" type="text" autocomplete="off" placeholder="What do you need help with?" size="80"
            style=" width: 50%;padding: 15px 40px 15px 15px;box-sizing: border-box;border-radius: 3.01px;border: 1px solid #ccc;font-size: 20px;"
            pInputText (keyup)="searchKBs()">
        <a class="search-icon">
            <i class="fa fa-search" *ngIf="showSearchIcon"
                style=" position:relative;width:75px !important;right:6%;font-size:27px;font-weight:normal;color:lightgrey;"></i>
              <i class="fa fa-times-circle" *ngIf="!showSearchIcon"
                style=" position:relative;width:75px !important;right:6%;font-size:27px;font-weight:normal;color:lightgrey;"
                (click)="clearsearchKBs()"></i>
            </a>
        <ul>
            <li style="list-style-type:none;font-size:14px;font-weight:100;" *ngIf="showKBsLoader">
             <p-progressSpinner [style]="{width: '50px', height: '50px',position:'relative',right:'6%' ,top:'-10%'}" strokeWidth="2" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </li>
            <li *ngFor="let searchresult of searchResult;index as i" [attr.data-index]="i"
                style="list-style-type:none;font-size:14px;font-weight:100;">
                <div style="text-align:left;padding-left:19%;padding-top:4%;padding-right:6%;">
                    <label><a title="{{searchresult.title}}" (click)="openKBpopup(i)"
                            style="color:#3572b0;font-size:16px;" [innerHTML]='searchresult.title'></a>-</label><label
                        style="font-size:16px;padding-left:1.5%;">{{searchresult.portalName}}</label>
                    <br />
                    <label [innerHTML]='searchresult.bodyTextHighlights'
                        style="font-size:14px;font-weight:100;"></label>
                </div>
            </li>
            <li *ngIf="displayemptyResponse || this.searchResult?.length < 0" style="list-style-type:none;font-size:14px;font-weight:100;">
                <label style="float:left;padding-left:19%;padding-top:1%;">No solutions found, browse the list of
                    portals below to raise a request</label>
            </li>
        </ul>
        <div *ngIf="showStanderReq" style="text-align:center;padding-left:21%;padding-top:2%;" id="leftpanel">
            <p-tabView orientation="left" >
                <p-tabPanel [header]="reqgroup.name"  *ngFor="let reqgroup of reqGroups;"
                    style="text-align:left;width:25%;padding-top: 0px;border:none !important;" [activeIndex]='0'>
                    <div class="ui-grid-col-4" *ngFor="let kbsReqType of kbsReqTypeoption;"
                        style="list-style-type:none;font-size:14px;font-weight:100;text-align:center;display: contents;">
                        <div *ngFor="let group of kbsReqType.groups">
                            <div *ngIf="reqgroup.id === group" style="padding-bottom:3%;">
                                <div class="ui-grid-col-2"
                                    style="text-align: left;position: absolute;margin-top: 0%;left:44%;">
                                    <span><i class="fas fa-desktop" style="font-size: 18px;color:#3572b0; display: inline-block;padding: 5px;"></i></span>
                                    <span><a title="{{kbsReqType.name}}"
                                            style="color:#3572b0;padding-left:10%;"
                                            (click)="showDialog(kbsReqType.name,kbsReqType.id)"><strong>{{kbsReqType.name}}</strong></a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
        <p-dialog [header]="kbtitle" *ngIf="kbDialog" [(visible)]="kbDialog" [modal]="true" [responsive]="true"
            showEffect="fade" styleClass="jirapopupwidth">
            <iframe [src]="KBURL | safe" width="100%" height="600px"></iframe>
        </p-dialog>
    </p-tabPanel>
    <p-tabPanel header="Tickets">
        <app-advance-grid id="jira" *ngIf="showJiraGrid" [showDownload]="true" [totalRecords]="jiraData.totalRecords" height="auto"
            popup="true" [selectedColumns]="jiraData.selectedColumns" [cols]="jiraData.cols"
            [colData]="jiraData.colData" [org]="org" [dataUrl]="_dataProvider.SERVICE_TICKET_SEARCH" [requestTypeDropDown]="requestTypeDropDown"
            [lazy]="true" [normalDropDown]="normalDropDown" [multiselct]="multiselct" (update)="advanceLoadGrid($event)"
            (advanceRowSelect)="advanceRowSelect($event)" (advanceIconClick)="advanceIconClick($event)"
            [ticketicon]="ticketicon" [ticketTitle]="ticketTitle"></app-advance-grid>
    </p-tabPanel>
    <p-tabPanel header="Calendar">
        <div style="position:absolute;top:7.9%;right:14%;display:none;" id="exportCal">
         <button type="text" icon="fas fa fa-download" iconPos="right" pButton label="Export" (click)="showExportDialog()" *ngIf="_dataProvider.isPermission(org, 'portal_customer_service_export_ticket')"></button>
         </div>
    <p-dialog header="export event as xls file" *ngIf="exportCalDialog" [(visible)]="exportCalDialog" [modal]="true" [responsive]="true"
         showEffect="fade" [width]="800" [height]="950">
         <div style="margin-left:14%">
                <h5>From</h5>
                  <p-calendar [(ngModel)]="dateFrom"  [showIcon]="true"></p-calendar>
              </div>
         <div style="margin-left:14%">
            <h5>To</h5>
              <p-calendar [(ngModel)]="dateTo"  [showIcon]="true" [style]='{"left":"0px !important"}'></p-calendar>
              </div>
           
             <div style="margin-top:3%;margin-left:14%;margin-bottom:6%;" >
                    <p-listbox [options]="groupName" [(ngModel)]="selectedgroup" [style]="{'width':'250px'}" [listStyle]="{'max-height':'150px'}">
                            <ng-template let-car pTemplate="item">
                              <div class="ui-helper-clearfix">
                                <span>{{car.label}}</span>
                              </div>
                            </ng-template>
                     </p-listbox>
            </div>
            <p-footer>
            <div style="text-align:left;">
               <button type="text" icon="fas fa-download" iconPos="right" pButton label="Submit" (click)="exportExcelFile()"></button>
            </div>
            </p-footer>
    </p-dialog>
        <div style="position: absolute;top:14.9%;right: 5%;display:none;" id="colortab">
        <span class="square" style="background-color:red;cursor:pointer;"></span><span style="margin-top: 0%;color:red;font-weight:bold;">  Emergency Maintenance</span><br/>
        <span class="square" style="background-color:purple;cursor:pointer;"></span><span style="margin-top: 0%;color:purple;font-weight:bold;">  Planned Maintenance</span><br/>
        <span class="square" style="background-color:gray;cursor:pointer;"></span><span style="margin-top: 0%;color:gray;font-weight:bold;">  Completed Maintenance</span><br/>
        </div>
        <div style="width:80%;z-index:unset;" *ngIf="showCalendar">
            <p-progressBar *ngIf="showCalendarLoader" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
            <p-fullCalendar [events]="events" #sc  [options]="options"></p-fullCalendar>
        </div>
    </p-tabPanel>
</p-tabView>

   <div>
        <p-growl *ngIf="growlMessage.length!==0" life='3000' [(value)]="growlMessage"></p-growl>
    </div>
<app-form *ngIf="servicerequestDialog" [greetMessage]="childmessage" [org]='org' [reqType]="reqType" (closeForm)="closeForm($event)"></app-form>
<p-dialog id="jiraRowPopup" [focusOnShow]= "false" *ngIf="selectedData" [header]="'Ticket - '+selectedData.key" [(visible)]="openTicketDialogue"
    [modal]="true" showEffect="fade" [responsive]="true" styleClass="jirapopupwidth" resizable="true" (onHide)="closeTicketDetails($event)">
    <app-jira-ticket-details *ngIf="openTicketDialogue" [detailsField]="detailsField" [org] = "org" [selectedData] = "selectedData"></app-jira-ticket-details>
</p-dialog>
<p-dialog [focusOnShow]= "false" *ngIf="eventDetailsDialog" [(visible)]="eventDetailsDialog" [modal]="true" [responsive]="true"
showEffect="fade" styleClass="jirapopupwidth" (onHide)="hideDialog($event)">
<app-maintenance-ticket-details [org] ="org" [eventDetails] ="eventDetails" [IssueId]="IssueId"></app-maintenance-ticket-details>
</p-dialog>